import axios from "axios";
import { ufs } from "@/data/ufs";

type getAddressResponse = {
  address: {
    city_district?: string;
    municipality: string;
    postcode?: string;
    state: string;
    town?: string;
    city?: string;
    suburb?: string;
    hamlet?: string;
    road?: string;
  };
};

export async function getAddressByLocation({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) {
  try {
    const response = await axios.get<getAddressResponse>(
      "https://nominatim.openstreetmap.org/reverse.php",
      {
        params: {
          zoom: 18,
          format: "jsonv2",
          lat: latitude,
          lon: longitude,
        },
      },
    );

    if (!response.data.address) {
      return {
        street: "",
        city: "",
        neighborhood: "",
        postcode: "",
      };
    }

    const {
      road,
      suburb,
      hamlet,
      town,
      city,
      city_district: cityDistrict,
      state,
      postcode,
    } = response.data.address;

    const uf = ufs.UF.find((u) => u.nome === state)?.sigla;
    const cityLeaflet = town || city || cityDistrict;

    return {
      street: road ?? "",
      city: cityLeaflet && uf ? `${cityLeaflet} - ${uf}` : "",
      neighborhood: (suburb || hamlet) ?? "",
      postcode: postcode ?? "",
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
