import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { MapPin } from "@phosphor-icons/react";

import { Button } from "@/components";
import { DataLocationAtom } from "@/components/location-input/atoms/data-location-atom";
import { CurrentStepLocationAtom } from "@/components/location-input/atoms/current-step-location-atom";
import { UserPositionAtom } from "@/components/location-input/atoms/user-position-atom";
import { useAppStore } from "@/store/useAppStore";

export function ButtonForMap() {
  const { t } = useTranslation();

  const appLocation = useAppStore((state) => state.app?.location);

  const setDataLocation = useSetAtom(DataLocationAtom);
  const setCurrentStepLocation = useSetAtom(CurrentStepLocationAtom);
  const userLocation = useAtomValue(UserPositionAtom);

  function handleOnClick() {
    const latitude = userLocation.latitude
      ? userLocation.latitude
      : appLocation?.latitude
      ? Number(appLocation.latitude)
      : 0;

    const longitude = userLocation.longitude
      ? userLocation.longitude
      : appLocation?.longitude
      ? Number(appLocation.longitude)
      : 0;

    setDataLocation({
      address: {
        postcode: "",
        city: "",
        neighborhood: "",
        street: "",
        number: "",
        complement: "",
        landmark: "",
      },
      location: {
        latitude,
        longitude,
      },
    });

    setCurrentStepLocation("map");
  }

  return (
    <Button type="button" onClick={handleOnClick} size="lg" variant="secondary">
      <MapPin weight="bold" />
      {t("location.Aponte localização no mapa")}
    </Button>
  );
}
