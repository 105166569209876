import { useAtomValue } from "jotai";
import { Map as LeafletMap } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";

import { MapController } from "./components/map-controller";
import { BackButton } from "./components/back-button";
import { MapPin } from "./components/map-pin";
import { BoxMessage } from "./components/box-message";
import { MarkerUserPosition } from "./components/marker-user-position";
import { ButtonForUserPosition } from "./components/button-for-user-position";
import { Footer } from "./components/footer";
import { DataLocationAtom } from "../../atoms/data-location-atom";

export function MapStep() {
  const dataLocation = useAtomValue(DataLocationAtom);

  function handleMapCreated(map: LeafletMap | null) {
    if (map) {
      map.invalidateSize();
    }
  }

  return (
    <div className="relative h-full w-full">
      <MapContainer
        ref={handleMapCreated}
        center={[dataLocation.location.latitude, dataLocation.location.longitude]}
        zoom={16}
        zoomControl={false}
        touchZoom="center"
        scrollWheelZoom="center"
        doubleClickZoom="center"
        className="relative h-full w-full"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        <BackButton />

        <MapController />

        <MapPin />

        <BoxMessage />

        <MarkerUserPosition />

        <ButtonForUserPosition />

        <Footer />
      </MapContainer>
    </div>
  );
}
