import { ChangeEvent } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { Controller, useFormContext } from "react-hook-form";
import { TextInput } from "@astrolabe-ui/react";
import { CircleNotch } from "@phosphor-icons/react";

import { Button } from "@/components";
import { FormData } from "../form-location-input";
import { DataLocationAtom } from "@/components/location-input/atoms/data-location-atom";
import { CurrentStepLocationAtom } from "@/components/location-input/atoms/current-step-location-atom";
import { UserPositionAtom } from "@/components/location-input/atoms/user-position-atom";
import { SelectCity } from "@/components/location-input/components/select-city";
import { getAddressByPostCode, getLocationByAddress } from "@/services/cities";
import { useAppStore } from "@/store/useAppStore";
import { formatCep } from "@/utils/formats";

const NUMBER_OF_CHARACTERS_POST_CODE = 9;

export function Form() {
  const { t } = useTranslation();

  const appLocation = useAppStore((state) => state.app?.location);

  const setDataLocation = useSetAtom(DataLocationAtom);
  const setCurrentStepLocation = useSetAtom(CurrentStepLocationAtom);
  const userPosition = useAtomValue(UserPositionAtom);

  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext<FormData>();

  const { mutateAsync: mutateGetAddress, isLoading: isLoadingGetAddress } = useMutation({
    mutationFn: ({ postCode }: { postCode: string }) => getAddressByPostCode({ postCode }),
  });

  const { mutateAsync: mutateGetLocation, isLoading: isLoadingGetLocation } = useMutation({
    mutationFn: ({ address }: { address: string }) => getLocation({ address }),
  });

  async function handleOnChangePostcode(value: string) {
    const postCode = formatCep(value);

    setValue("postcode", postCode);

    if (postCode.length === NUMBER_OF_CHARACTERS_POST_CODE) {
      getAddress({ postCode: value });
    }
  }

  async function getAddress({ postCode }: { postCode: string }) {
    const response = await mutateGetAddress({ postCode });

    if (!response) {
      return;
    }

    reset((prev) => ({
      postcode: prev.postcode,
      street: response.street,
      city: response.city,
      neighborhood: response.neighborhood,
      number: "",
      complement: "",
      landmark: "",
    }));
  }

  async function getLocation({ address }: { address: string }) {
    try {
      const response = await getLocationByAddress({ address });

      if (response) {
        return {
          latitude: response.lat,
          longitude: response.lng,
        };
      }

      if (userPosition.latitude && userPosition.longitude) {
        return {
          latitude: userPosition.latitude,
          longitude: userPosition.longitude,
        };
      }

      if (appLocation) {
        return {
          latitude: Number(appLocation.latitude),
          longitude: Number(appLocation.longitude),
        };
      }

      return {
        latitude: 0,
        longitude: 0,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function onSubmit(data: FormData) {
    try {
      const address = `${data.city.split(" - ")[1]} ${data.city.split(" - ")[0]} ${
        data.neighborhood
      } ${data.street} ${data.number ?? ""}`;

      const response = await mutateGetLocation({ address });

      setDataLocation((prev) => ({
        location: {
          latitude: response ? response.latitude : prev.location.latitude,
          longitude: response ? response.longitude : prev.location.longitude,
        },
        address: {
          postcode: data.postcode,
          city: data.city,
          neighborhood: data.neighborhood,
          street: data.street,
          number: data.number,
          complement: "",
          landmark: "",
        },
      }));

      setCurrentStepLocation("map");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-1 flex-col justify-between gap-4">
      <div className="grid grid-cols-4 gap-4">
        <div className="relative col-span-4 w-full sm:col-span-2">
          <TextInput
            floating
            placeholder={t("auth.CEP")}
            maxLength={9}
            inputMode="numeric"
            {...register("postcode", {
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                handleOnChangePostcode(e.target.value),
            })}
          />

          {isLoadingGetAddress ? (
            <div className="absolute right-4 top-1/2 z-10 -translate-y-1/2 text-slate-600">
              <CircleNotch className="animate-spin text-slate-600" />
            </div>
          ) : null}
        </div>

        <div className="col-span-4 sm:col-span-2">
          <Controller
            control={control}
            name="city"
            render={({ field: { value, onChange } }) => (
              <SelectCity
                title="Selecione a cidade"
                selected={value}
                onChange={onChange}
                error={errors.city?.message}
              />
            )}
          />
        </div>

        <div className="col-span-4">
          <TextInput
            floating
            placeholder={t("auth.Bairro")}
            error={errors.neighborhood?.message}
            {...register("neighborhood")}
          />
        </div>

        <div className="col-span-4 sm:col-span-3">
          <TextInput
            floating
            placeholder={t("auth.Rua")}
            error={errors.street?.message}
            {...register("street")}
          />
        </div>

        <div className="col-span-4 sm:col-span-1">
          <TextInput floating placeholder={t("auth.Número")} {...register("number")} />
        </div>
      </div>

      <Button type="submit" size="lg" full loading={isLoadingGetLocation} disabled={!isValid}>
        {t("general.Continuar")}
      </Button>
    </form>
  );
}
