import { useAtom, useAtomValue } from "jotai";
import { IonModal } from "@ionic/react";

import { ReactPortal } from "@/components/ReactPortal";
import { FormLocationInput } from "./steps/form-location-input/form-location-input";
import { MapStep } from "./steps/map-step/map-step";
import { ConfirmAddress } from "./steps/confirm-address/confirm-address";
import { CurrentStepLocationAtom } from "./atoms/current-step-location-atom";
import { LocationInputIsOpenAtom } from "./atoms/location-input-is-open-atom";

export type Address = {
  address: {
    city: string;
    uf: string;
    neighborhood: string;
    street: string;
    number?: string;
    postcode: string;
    complement?: string;
    landmark?: string;
  };
  location: {
    latitude: number;
    longitude: number;
  };
};

type LocationInputProps = {
  handleOnChange: (address: Address) => void;
};

export function LocationInput({ handleOnChange }: LocationInputProps) {
  const [isOpen, setIsOpen] = useAtom(LocationInputIsOpenAtom);
  const currentStep = useAtomValue(CurrentStepLocationAtom);

  return (
    <ReactPortal>
      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} backdropDismiss={false}>
        <div className="min-h-full w-full overflow-auto bg-white">
          {currentStep === "form" ? <FormLocationInput /> : null}

          {currentStep === "map" ? <MapStep /> : null}

          {currentStep === "confirm" ? (
            <ConfirmAddress handleChangeAddress={handleOnChange} />
          ) : null}
        </div>
      </IonModal>
    </ReactPortal>
  );
}
