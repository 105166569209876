import { useCallback, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { useAtom, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Text } from "@astrolabe-ui/react";
import { X } from "@phosphor-icons/react";

import { ButtonForMap } from "./components/button-for-map";
import { Address } from "./components/address";
import { Form } from "./components/form";
import { UserPositionAtom } from "@/components/location-input/atoms/user-position-atom";
import { LocationInputIsOpenAtom } from "@/components/location-input/atoms/location-input-is-open-atom";
import { CurrentStepLocationAtom } from "@/components/location-input/atoms/current-step-location-atom";
import {
  DataLocationAtom,
  dataLocationInitialValues,
} from "@/components/location-input/atoms/data-location-atom";

const formLocationInputSchema = z.object({
  postcode: z.string(),
  city: z.string().min(1, "Informe a cidade"),
  neighborhood: z.string().min(1, "Informe o bairro"),
  street: z.string().min(1, "Informe a rua"),
  number: z.string(),
});

export type FormData = z.infer<typeof formLocationInputSchema>;

export function FormLocationInput() {
  const { t } = useTranslation();

  const [dataLocation, setDataLocation] = useAtom(DataLocationAtom);
  const setLocationInputIsOpen = useSetAtom(LocationInputIsOpenAtom);
  const setCurrentStep = useSetAtom(CurrentStepLocationAtom);
  const setUserPosition = useSetAtom(UserPositionAtom);

  const methods = useForm<FormData>({
    resolver: zodResolver(formLocationInputSchema),
    mode: "onChange",
    defaultValues: {
      postcode: dataLocation.address.postcode ?? "",
      city: dataLocation.address.city ?? "",
      neighborhood: dataLocation.address.neighborhood ?? "",
      street: dataLocation.address.street ?? "",
      number: dataLocation.address.number ?? "",
    },
  });

  const getLocationUser = useCallback(async () => {
    if (Capacitor.isNativePlatform()) {
      const permission = await Geolocation.requestPermissions();

      if (permission.location === "granted") {
        const position = await Geolocation.getCurrentPosition();

        setUserPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      }

      return;
    }

    if ("geolocation" in navigator) {
      const position = await getLocationUserWeb();

      if (position?.coords) {
        setUserPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      }
    }
  }, [setUserPosition]);

  useEffect(() => {
    getLocationUser();
  }, [getLocationUser]);

  function getLocationUserWeb(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject),
    );
  }

  function handleClose() {
    setLocationInputIsOpen(false);
    setDataLocation(dataLocationInitialValues);
    setCurrentStep("form");
  }

  return (
    <FormProvider {...methods}>
      <div className="flex min-h-full w-full flex-col gap-6 px-4 pb-[calc(var(--safe-area-inset-bottom)+1.5rem)] pt-[calc(var(--safe-area-inset-top)+1.5rem)]">
        <header className="flex w-full items-center after:flex-1 after:content-['']">
          <div className="flex-1">
            <button
              onClick={handleClose}
              className="flex h-9 w-9 items-center justify-center rounded-full border-thin border-slate-200 transition-colors hover:bg-slate-50"
            >
              <X weight="bold" size={16} className="text-primary-500" />
            </button>
          </div>

          <Text size="md" weight="medium" leading="relaxed" color="primary-500">
            {t("general.Localização")}
          </Text>
        </header>

        <div className="flex flex-1 flex-col gap-6">
          <ButtonForMap />

          <Address />

          <Form />
        </div>
      </div>
    </FormProvider>
  );
}
