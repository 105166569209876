import { Marker } from "react-leaflet";
import { useAtomValue } from "jotai";
import L from "leaflet";

import { UserPositionAtom } from "@/components/location-input/atoms/user-position-atom";

export function MarkerUserPosition() {
  const userPosition = useAtomValue(UserPositionAtom);

  if (!userPosition.latitude && !userPosition.longitude) {
    return null;
  }

  const customIcon = L.divIcon({
    html: `
      <div style="
        width: 44px;
        height: 44px;
        background-color: rgba(85, 122, 255, .1);    
        border-radius: 50%;   
        display: flex;
        justify-content: center;
        align-items: center;
      ">
        <div style="
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: #557aff;
          border: 3px solid #fff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
          box-sizing: initial;
        "></div>
      </div>
    `,
    iconSize: [44, 44],
    iconAnchor: [22, 22],
    className: "",
  });

  return <Marker icon={customIcon} position={[userPosition.latitude, userPosition.longitude]} />;
}
