import { Controller, useFormContext } from "react-hook-form";
import { useAtom, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { TextInput } from "@astrolabe-ui/react";

import { Button } from "@/components";
import { SelectCity } from "@/components/location-input/components/select-city";
import { FormConfirmData } from "../confirm-address";
import { Address } from "@/components/location-input/location-input";
import { LocationInputIsOpenAtom } from "@/components/location-input/atoms/location-input-is-open-atom";
import { CurrentStepLocationAtom } from "@/components/location-input/atoms/current-step-location-atom";
import {
  DataLocationAtom,
  dataLocationInitialValues,
} from "@/components/location-input/atoms/data-location-atom";

type FormConfirmProps = {
  handleChangeAddress: (address: Address) => void;
};

export function FormConfirm({ handleChangeAddress }: FormConfirmProps) {
  const { t } = useTranslation();

  const [{ location }, setDataLocation] = useAtom(DataLocationAtom);
  const setLocationInputIsOpen = useSetAtom(LocationInputIsOpenAtom);
  const setCurrentStep = useSetAtom(CurrentStepLocationAtom);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useFormContext<FormConfirmData>();

  function onSubmit({
    city,
    neighborhood,
    street,
    number,
    postcode,
    landmark,
    complement,
  }: FormConfirmData) {
    const [cityFormatted, uf] = city.split(" - ");

    handleChangeAddress({
      address: {
        city: cityFormatted,
        uf,
        neighborhood,
        street,
        number,
        postcode,
        complement,
        landmark,
      },
      location: {
        latitude: location.latitude,
        longitude: location.longitude,
      },
    });

    handleCloseLocationInput();
  }

  function handleCloseLocationInput() {
    setLocationInputIsOpen(false);
    setDataLocation(dataLocationInitialValues);
    setCurrentStep("form");
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="absolute bottom-0 z-[1000] flex h-[calc(100%-120px)] flex-1 flex-col justify-between gap-4 bg-gradient-to-t from-white from-[94%] to-white/20 px-4 pb-[max(var(--safe-area-inset-top),1.5rem)] pt-16"
    >
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 sm:col-span-2">
          <TextInput
            floating
            placeholder={t("auth.CEP")}
            maxLength={9}
            inputMode="numeric"
            {...register("postcode")}
          />
        </div>

        <div className="col-span-4 sm:col-span-2">
          <Controller
            control={control}
            name="city"
            render={({ field: { value, onChange } }) => (
              <SelectCity
                title="Selecione a cidade"
                selected={value}
                onChange={onChange}
                error={errors.city?.message}
              />
            )}
          />
        </div>

        <div className="col-span-4">
          <TextInput
            floating
            placeholder={t("auth.Bairro")}
            error={errors.neighborhood?.message}
            {...register("neighborhood")}
          />
        </div>

        <div className="col-span-4 sm:col-span-3">
          <TextInput
            floating
            placeholder={t("auth.Rua")}
            error={errors.street?.message}
            {...register("street")}
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <TextInput floating placeholder={t("auth.Número")} {...register("number")} />
        </div>

        <div className="col-span-2">
          <TextInput floating placeholder={t("auth.Complemento")} {...register("complement")} />
        </div>

        <div className="col-span-4 sm:col-span-2">
          <TextInput
            floating
            placeholder={t("protocols.Ponto de referência")}
            {...register("landmark")}
          />
        </div>
      </div>

      <Button type="submit" size="lg" full disabled={!isValid}>
        {t("location.Confirmar localização")}
      </Button>
    </form>
  );
}
