import { useAtomValue } from "jotai";
import { MapContainer, TileLayer } from "react-leaflet";
import { Map as LeafletMap } from "leaflet";

import { DataLocationAtom } from "@/components/location-input/atoms/data-location-atom";
import { MapPin } from "./map-pin";

export function MapLocation() {
  const dataLocation = useAtomValue(DataLocationAtom);

  function handleMapCreated(map: LeafletMap | null) {
    if (map) {
      map.invalidateSize();
    }
  }

  return (
    <div className="h-[160px] w-full overflow-hidden">
      <MapContainer
        ref={handleMapCreated}
        center={[dataLocation.location.latitude, dataLocation.location.longitude]}
        zoom={16}
        zoomControl={false}
        doubleClickZoom={false}
        closePopupOnClick={false}
        dragging={false}
        trackResize={false}
        touchZoom={false}
        scrollWheelZoom={false}
        className="relative h-full w-full"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapPin />
      </MapContainer>
    </div>
  );
}
