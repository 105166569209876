import { useAtomValue } from "jotai";
import { useMap } from "react-leaflet";
import { IconButton } from "@astrolabe-ui/react";
import { GpsFix } from "@phosphor-icons/react";

import { UserPositionAtom } from "@/components/location-input/atoms/user-position-atom";

export function ButtonForUserPosition() {
  const userPosition = useAtomValue(UserPositionAtom);
  const map = useMap();

  if (!userPosition.latitude && !userPosition.longitude) {
    return null;
  }

  function handleGoPosition() {
    map.flyTo([userPosition.latitude, userPosition.longitude]);
  }

  return (
    <IconButton
      type="button"
      size="lg"
      rounded="full"
      icon={<GpsFix size={20} className="text-primary-500" />}
      onClick={handleGoPosition}
      className="absolute bottom-[calc(var(--safe-area-inset-bottom)+6rem)] right-4 z-[500] h-12 w-12 cursor-pointer drop-shadow-lg"
    />
  );
}
