import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";

import { IsDraggingMap } from "../atoms/is-dragging-map";
import { cn } from "@/lib/utils";

export function BoxMessage() {
  const { t } = useTranslation();

  const isDragging = useAtomValue(IsDraggingMap);

  return (
    <div
      className={cn(
        "visible absolute left-1/2 top-1/2 z-[500] flex w-full max-w-52 -translate-x-1/2 -translate-y-[calc(100%+40px)] scale-100 flex-col rounded-md border border-slate-400 bg-white px-4 py-2 text-center opacity-100 shadow-lg transition-all lg:-translate-x-0 lg:rounded-lg lg:rounded-bl-none",
        {
          "invisible scale-75 opacity-0": isDragging,
        },
      )}
    >
      <Text size="xs" weight="medium" color="slate-700">
        {t("location.A localização está correta?")}
      </Text>
      <Text size="xs">{t("location.Caso não seja, arraste o mapa.")}</Text>
    </div>
  );
}
