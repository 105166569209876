import { useSetAtom } from "jotai";
import { IconButton } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { CurrentStepLocationAtom } from "@/components/location-input/atoms/current-step-location-atom";
import { DataLocationAtom } from "@/components/location-input/atoms/data-location-atom";

export function BackButton() {
  const setCurrentStepLocation = useSetAtom(CurrentStepLocationAtom);
  const setDataLocation = useSetAtom(DataLocationAtom);

  function handleGoBack() {
    setDataLocation((prev) => ({
      ...prev,
      address: {
        postcode: "",
        city: "",
        neighborhood: "",
        street: "",
        number: "",
        complement: "",
        landmark: "",
      },
    }));

    setCurrentStepLocation("map");
  }

  return (
    <IconButton
      type="button"
      size="lg"
      rounded="full"
      icon={<CaretLeft size={20} className="text-primary-500" />}
      onClick={handleGoBack}
      className="absolute left-4 top-[calc(var(--safe-area-inset-top)+1rem)] z-[500] drop-shadow-lg"
    />
  );
}
