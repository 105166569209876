import { useAtomValue } from "jotai";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import { FormConfirm } from "./components/form-confirm";
import { MapLocation } from "./components/map-location";
import { BackButton } from "./components/back-button";
import { DataLocationAtom } from "../../atoms/data-location-atom";
import { Address } from "@/components/location-input/location-input";

const formConfirmSchema = z.object({
  postcode: z.string(),
  city: z.string().min(1, "Informe a cidade"),
  neighborhood: z.string().min(1, "Informe o bairro"),
  street: z.string().min(1, "Informe a rua"),
  number: z.string(),
  complement: z.string(),
  landmark: z.string(),
});

export type FormConfirmData = z.infer<typeof formConfirmSchema>;

type ConfirmAddressProps = {
  handleChangeAddress: (address: Address) => void;
};

export function ConfirmAddress({ handleChangeAddress }: ConfirmAddressProps) {
  const dataLocationAtom = useAtomValue(DataLocationAtom);

  const methods = useForm<FormConfirmData>({
    resolver: zodResolver(formConfirmSchema),
    mode: "onChange",
    defaultValues: {
      postcode: dataLocationAtom.address.postcode ?? "",
      city: dataLocationAtom.address.city ?? "",
      neighborhood: dataLocationAtom.address.neighborhood ?? "",
      street: dataLocationAtom.address.street ?? "",
      number: dataLocationAtom.address.number ?? "",
      complement: dataLocationAtom.address.complement ?? "",
      landmark: dataLocationAtom.address.landmark ?? "",
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="relative flex min-h-full w-full flex-col">
        <BackButton />

        <MapLocation />

        <FormConfirm handleChangeAddress={handleChangeAddress} />
      </div>
    </FormProvider>
  );
}
