export const cities = [
  {
    id: 0,
    label: "Abadia de goiás - GO",
  },
  {
    id: 1,
    label: "Abadia dos dourados - MG",
  },
  {
    id: 2,
    label: "Abadiânia - GO",
  },
  {
    id: 3,
    label: "Abaeté - MG",
  },
  {
    id: 4,
    label: "Abaetetuba - PA",
  },
  {
    id: 5,
    label: "Abaiara - CE",
  },
  {
    id: 6,
    label: "Abaíra - BA",
  },
  {
    id: 7,
    label: "Abaré - BA",
  },
  {
    id: 8,
    label: "Abatiá - PR",
  },
  {
    id: 9,
    label: "Abdon batista - SC",
  },
  {
    id: 10,
    label: "Abel figueiredo - PA",
  },
  {
    id: 11,
    label: "Abelardo luz - SC",
  },
  {
    id: 12,
    label: "Abre campo - MG",
  },
  {
    id: 13,
    label: "Abreu e lima - PE",
  },
  {
    id: 14,
    label: "Abreulândia - TO",
  },
  {
    id: 15,
    label: "Acaiaca - MG",
  },
  {
    id: 16,
    label: "Açailândia - MA",
  },
  {
    id: 17,
    label: "Acajutiba - BA",
  },
  {
    id: 18,
    label: "Acará - PA",
  },
  {
    id: 19,
    label: "Acarape - CE",
  },
  {
    id: 20,
    label: "Acaraú - CE",
  },
  {
    id: 21,
    label: "Acari - RN",
  },
  {
    id: 22,
    label: "Acauã - PI",
  },
  {
    id: 23,
    label: "Aceguá - RS",
  },
  {
    id: 24,
    label: "Acopiara - CE",
  },
  {
    id: 25,
    label: "Acorizal - MT",
  },
  {
    id: 26,
    label: "Acrelândia - AC",
  },
  {
    id: 27,
    label: "Acreúna - GO",
  },
  {
    id: 28,
    label: "Açucena - MG",
  },
  {
    id: 29,
    label: "Adamantina - SP",
  },
  {
    id: 30,
    label: "Adelândia - GO",
  },
  {
    id: 31,
    label: "Adolfo - SP",
  },
  {
    id: 32,
    label: "Adrianópolis - PR",
  },
  {
    id: 33,
    label: "Adustina - BA",
  },
  {
    id: 34,
    label: "Afogados da ingazeira - PE",
  },
  {
    id: 35,
    label: "Afonso bezerra - RN",
  },
  {
    id: 36,
    label: "Afonso cláudio - ES",
  },
  {
    id: 37,
    label: "Afonso cunha - MA",
  },
  {
    id: 38,
    label: "Afrânio - PE",
  },
  {
    id: 39,
    label: "Afuá - PA",
  },
  {
    id: 40,
    label: "Agrestina - PE",
  },
  {
    id: 41,
    label: "Agricolândia - PI",
  },
  {
    id: 42,
    label: "Agrolândia - SC",
  },
  {
    id: 43,
    label: "Agronômica - SC",
  },
  {
    id: 44,
    label: "Água azul do norte - PA",
  },
  {
    id: 45,
    label: "Água boa - MG",
  },
  {
    id: 46,
    label: "Água boa - MT",
  },
  {
    id: 47,
    label: "Água branca - AL",
  },
  {
    id: 48,
    label: "Água branca - PB",
  },
  {
    id: 49,
    label: "Água branca - PI",
  },
  {
    id: 50,
    label: "Água clara - MS",
  },
  {
    id: 51,
    label: "Água comprida - MG",
  },
  {
    id: 52,
    label: "Água doce - SC",
  },
  {
    id: 53,
    label: "Água doce do maranhão - MA",
  },
  {
    id: 54,
    label: "Água doce do norte - ES",
  },
  {
    id: 55,
    label: "Água fria - BA",
  },
  {
    id: 56,
    label: "Água fria de goiás - GO",
  },
  {
    id: 57,
    label: "Água limpa - GO",
  },
  {
    id: 58,
    label: "Água nova - RN",
  },
  {
    id: 59,
    label: "Água preta - PE",
  },
  {
    id: 60,
    label: "Água santa - RS",
  },
  {
    id: 61,
    label: "Aguaí - SP",
  },
  {
    id: 62,
    label: "Aguanil - MG",
  },
  {
    id: 63,
    label: "Águas belas - PE",
  },
  {
    id: 64,
    label: "Águas da prata - SP",
  },
  {
    id: 65,
    label: "Águas de chapecó - SC",
  },
  {
    id: 66,
    label: "Águas de lindóia - SP",
  },
  {
    id: 67,
    label: "Águas de santa bárbara - SP",
  },
  {
    id: 68,
    label: "Águas de são pedro - SP",
  },
  {
    id: 69,
    label: "Águas formosas - MG",
  },
  {
    id: 70,
    label: "Águas frias - SC",
  },
  {
    id: 71,
    label: "Águas lindas de goiás - GO",
  },
  {
    id: 72,
    label: "Águas mornas - SC",
  },
  {
    id: 73,
    label: "Águas vermelhas - MG",
  },
  {
    id: 74,
    label: "Agudo - RS",
  },
  {
    id: 75,
    label: "Agudos - SP",
  },
  {
    id: 76,
    label: "Agudos do sul - PR",
  },
  {
    id: 77,
    label: "Águia branca - ES",
  },
  {
    id: 78,
    label: "Aguiar - PB",
  },
  {
    id: 79,
    label: "Aguiarnópolis - TO",
  },
  {
    id: 80,
    label: "Aimorés - MG",
  },
  {
    id: 81,
    label: "Aiquara - BA",
  },
  {
    id: 82,
    label: "Aiuaba - CE",
  },
  {
    id: 83,
    label: "Aiuruoca - MG",
  },
  {
    id: 84,
    label: "Ajuricaba - RS",
  },
  {
    id: 85,
    label: "Alagoa - MG",
  },
  {
    id: 86,
    label: "Alagoa grande - PB",
  },
  {
    id: 87,
    label: "Alagoa nova - PB",
  },
  {
    id: 88,
    label: "Alagoinha - PB",
  },
  {
    id: 89,
    label: "Alagoinha - PE",
  },
  {
    id: 90,
    label: "Alagoinha do piauí - PI",
  },
  {
    id: 91,
    label: "Alagoinhas - BA",
  },
  {
    id: 92,
    label: "Alambari - SP",
  },
  {
    id: 93,
    label: "Albertina - MG",
  },
  {
    id: 94,
    label: "Alcântara - MA",
  },
  {
    id: 95,
    label: "Alcântaras - CE",
  },
  {
    id: 96,
    label: "Alcantil - PB",
  },
  {
    id: 97,
    label: "Alcinópolis - MS",
  },
  {
    id: 98,
    label: "Alcobaça - BA",
  },
  {
    id: 99,
    label: "Aldeias altas - MA",
  },
  {
    id: 100,
    label: "Alecrim - RS",
  },
  {
    id: 101,
    label: "Alegre - ES",
  },
  {
    id: 102,
    label: "Alegrete - RS",
  },
  {
    id: 103,
    label: "Alegrete do piauí - PI",
  },
  {
    id: 104,
    label: "Alegria - RS",
  },
  {
    id: 105,
    label: "Além paraíba - MG",
  },
  {
    id: 106,
    label: "Alenquer - PA",
  },
  {
    id: 107,
    label: "Alexandria - RN",
  },
  {
    id: 108,
    label: "Alexânia - GO",
  },
  {
    id: 109,
    label: "Alfenas - MG",
  },
  {
    id: 110,
    label: "Alfredo chaves - ES",
  },
  {
    id: 111,
    label: "Alfredo marcondes - SP",
  },
  {
    id: 112,
    label: "Alfredo vasconcelos - MG",
  },
  {
    id: 113,
    label: "Alfredo wagner - SC",
  },
  {
    id: 114,
    label: "Algodão de jandaíra - PB",
  },
  {
    id: 115,
    label: "Alhandra - PB",
  },
  {
    id: 116,
    label: "Aliança - PE",
  },
  {
    id: 117,
    label: "Aliança do tocantins - TO",
  },
  {
    id: 118,
    label: "Almadina - BA",
  },
  {
    id: 119,
    label: "Almas - TO",
  },
  {
    id: 120,
    label: "Almeirim - PA",
  },
  {
    id: 121,
    label: "Almenara - MG",
  },
  {
    id: 122,
    label: "Almino afonso - RN",
  },
  {
    id: 123,
    label: "Almirante tamandaré - PR",
  },
  {
    id: 124,
    label: "Almirante tamandaré do sul - RS",
  },
  {
    id: 125,
    label: "Aloândia - GO",
  },
  {
    id: 126,
    label: "Alpercata - MG",
  },
  {
    id: 127,
    label: "Alpestre - RS",
  },
  {
    id: 128,
    label: "Alpinópolis - MG",
  },
  {
    id: 129,
    label: "Alta floresta - MT",
  },
  {
    id: 130,
    label: "Alta floresta d'oeste - RO",
  },
  {
    id: 131,
    label: "Altair - SP",
  },
  {
    id: 132,
    label: "Altamira - PA",
  },
  {
    id: 133,
    label: "Altamira do maranhão - MA",
  },
  {
    id: 134,
    label: "Altamira do paraná - PR",
  },
  {
    id: 135,
    label: "Altaneira - CE",
  },
  {
    id: 136,
    label: "Alterosa - MG",
  },
  {
    id: 137,
    label: "Altinho - PE",
  },
  {
    id: 138,
    label: "Altinópolis - SP",
  },
  {
    id: 139,
    label: "Alto alegre - RR",
  },
  {
    id: 140,
    label: "Alto alegre - RS",
  },
  {
    id: 141,
    label: "Alto alegre - SP",
  },
  {
    id: 142,
    label: "Alto alegre do maranhão - MA",
  },
  {
    id: 143,
    label: "Alto alegre do pindaré - MA",
  },
  {
    id: 144,
    label: "Alto alegre dos parecis - RO",
  },
  {
    id: 145,
    label: "Alto araguaia - MT",
  },
  {
    id: 146,
    label: "Alto bela vista - SC",
  },
  {
    id: 147,
    label: "Alto boa vista - MT",
  },
  {
    id: 148,
    label: "Alto caparaó - MG",
  },
  {
    id: 149,
    label: "Alto do rodrigues - RN",
  },
  {
    id: 150,
    label: "Alto feliz - RS",
  },
  {
    id: 151,
    label: "Alto garças - MT",
  },
  {
    id: 152,
    label: "Alto horizonte - GO",
  },
  {
    id: 153,
    label: "Alto jequitibá - MG",
  },
  {
    id: 154,
    label: "Alto longá - PI",
  },
  {
    id: 155,
    label: "Alto paraguai - MT",
  },
  {
    id: 156,
    label: "Alto paraíso - PR",
  },
  {
    id: 157,
    label: "Alto paraíso - RO",
  },
  {
    id: 158,
    label: "Alto paraíso de goiás - GO",
  },
  {
    id: 159,
    label: "Alto paraná - PR",
  },
  {
    id: 160,
    label: "Alto parnaíba - MA",
  },
  {
    id: 161,
    label: "Alto piquiri - PR",
  },
  {
    id: 162,
    label: "Alto rio doce - MG",
  },
  {
    id: 163,
    label: "Alto rio novo - ES",
  },
  {
    id: 164,
    label: "Alto santo - CE",
  },
  {
    id: 165,
    label: "Alto taquari - MT",
  },
  {
    id: 166,
    label: "Altônia - PR",
  },
  {
    id: 167,
    label: "Altos - PI",
  },
  {
    id: 168,
    label: "Alumínio - SP",
  },
  {
    id: 169,
    label: "Alvarães - AM",
  },
  {
    id: 170,
    label: "Alvarenga - MG",
  },
  {
    id: 171,
    label: "Álvares florence - SP",
  },
  {
    id: 172,
    label: "Álvares machado - SP",
  },
  {
    id: 173,
    label: "Álvaro de carvalho - SP",
  },
  {
    id: 174,
    label: "Alvinlândia - SP",
  },
  {
    id: 175,
    label: "Alvinópolis - MG",
  },
  {
    id: 176,
    label: "Alvorada - RS",
  },
  {
    id: 177,
    label: "Alvorada - TO",
  },
  {
    id: 178,
    label: "Alvorada de minas - MG",
  },
  {
    id: 179,
    label: "Alvorada do gurguéia - PI",
  },
  {
    id: 180,
    label: "Alvorada do norte - GO",
  },
  {
    id: 181,
    label: "Alvorada do oeste - RO",
  },
  {
    id: 182,
    label: "Alvorada do sul - PR",
  },
  {
    id: 183,
    label: "Amajari - RR",
  },
  {
    id: 184,
    label: "Amambai - MS",
  },
  {
    id: 185,
    label: "Amapá - AP",
  },
  {
    id: 186,
    label: "Amapá do maranhão - MA",
  },
  {
    id: 187,
    label: "Amaporã - PR",
  },
  {
    id: 188,
    label: "Amaraji - PE",
  },
  {
    id: 189,
    label: "Amaral ferrador - RS",
  },
  {
    id: 190,
    label: "Amaralina - GO",
  },
  {
    id: 191,
    label: "Amarante - PI",
  },
  {
    id: 192,
    label: "Amarante do maranhão - MA",
  },
  {
    id: 193,
    label: "Amargosa - BA",
  },
  {
    id: 194,
    label: "Amaturá - AM",
  },
  {
    id: 195,
    label: "Amélia rodrigues - BA",
  },
  {
    id: 196,
    label: "América dourada - BA",
  },
  {
    id: 197,
    label: "Americana - SP",
  },
  {
    id: 198,
    label: "Americano do brasil - GO",
  },
  {
    id: 199,
    label: "Américo brasiliense - SP",
  },
  {
    id: 200,
    label: "Américo de campos - SP",
  },
  {
    id: 201,
    label: "Ametista do sul - RS",
  },
  {
    id: 202,
    label: "Amontada - CE",
  },
  {
    id: 203,
    label: "Amorinópolis - GO",
  },
  {
    id: 204,
    label: "Amparo - PB",
  },
  {
    id: 205,
    label: "Amparo - SP",
  },
  {
    id: 206,
    label: "Amparo de são francisco - SE",
  },
  {
    id: 207,
    label: "Amparo do serra - MG",
  },
  {
    id: 208,
    label: "Ampére - PR",
  },
  {
    id: 209,
    label: "Anadia - AL",
  },
  {
    id: 210,
    label: "Anagé - BA",
  },
  {
    id: 211,
    label: "Anahy - PR",
  },
  {
    id: 212,
    label: "Anajás - PA",
  },
  {
    id: 213,
    label: "Anajatuba - MA",
  },
  {
    id: 214,
    label: "Analândia - SP",
  },
  {
    id: 215,
    label: "Anamã - AM",
  },
  {
    id: 216,
    label: "Ananás - TO",
  },
  {
    id: 217,
    label: "Ananindeua - PA",
  },
  {
    id: 218,
    label: "Anápolis - GO",
  },
  {
    id: 219,
    label: "Anapu - PA",
  },
  {
    id: 220,
    label: "Anapurus - MA",
  },
  {
    id: 221,
    label: "Anastácio - MS",
  },
  {
    id: 222,
    label: "Anaurilândia - MS",
  },
  {
    id: 223,
    label: "Anchieta - ES",
  },
  {
    id: 224,
    label: "Anchieta - SC",
  },
  {
    id: 225,
    label: "Andaraí - BA",
  },
  {
    id: 226,
    label: "Andirá - PR",
  },
  {
    id: 227,
    label: "Andorinha - BA",
  },
  {
    id: 228,
    label: "Andradas - MG",
  },
  {
    id: 229,
    label: "Andradina - SP",
  },
  {
    id: 230,
    label: "André da rocha - RS",
  },
  {
    id: 231,
    label: "Andrelândia - MG",
  },
  {
    id: 232,
    label: "Angatuba - SP",
  },
  {
    id: 233,
    label: "Angelândia - MG",
  },
  {
    id: 234,
    label: "Angélica - MS",
  },
  {
    id: 235,
    label: "Angelim - PE",
  },
  {
    id: 236,
    label: "Angelina - SC",
  },
  {
    id: 237,
    label: "Angical - BA",
  },
  {
    id: 238,
    label: "Angical do piauí - PI",
  },
  {
    id: 239,
    label: "Angico - TO",
  },
  {
    id: 240,
    label: "Angicos - RN",
  },
  {
    id: 241,
    label: "Angra dos reis - RJ",
  },
  {
    id: 242,
    label: "Anguera - BA",
  },
  {
    id: 243,
    label: "Ângulo - PR",
  },
  {
    id: 244,
    label: "Anhangüera - GO",
  },
  {
    id: 245,
    label: "Anhembi - SP",
  },
  {
    id: 246,
    label: "Anhumas - SP",
  },
  {
    id: 247,
    label: "Anicuns - GO",
  },
  {
    id: 248,
    label: "Anísio de abreu - PI",
  },
  {
    id: 249,
    label: "Anita garibaldi - SC",
  },
  {
    id: 250,
    label: "Anitápolis - SC",
  },
  {
    id: 251,
    label: "Anori - AM",
  },
  {
    id: 252,
    label: "Anta gorda - RS",
  },
  {
    id: 253,
    label: "Antas - BA",
  },
  {
    id: 254,
    label: "Antonina - PR",
  },
  {
    id: 255,
    label: "Antonina do norte - CE",
  },
  {
    id: 256,
    label: "Antônio almeida - PI",
  },
  {
    id: 257,
    label: "Antônio cardoso - BA",
  },
  {
    id: 258,
    label: "Antônio carlos - MG",
  },
  {
    id: 259,
    label: "Antônio carlos - SC",
  },
  {
    id: 260,
    label: "Antônio dias - MG",
  },
  {
    id: 261,
    label: "Antônio gonçalves - BA",
  },
  {
    id: 262,
    label: "Antônio joão - MS",
  },
  {
    id: 263,
    label: "Antônio martins - RN",
  },
  {
    id: 264,
    label: "Antonio olinto - PR",
  },
  {
    id: 265,
    label: "Antônio prado - RS",
  },
  {
    id: 266,
    label: "Antônio prado de minas - MG",
  },
  {
    id: 267,
    label: "Aparecida - PB",
  },
  {
    id: 268,
    label: "Aparecida - SP",
  },
  {
    id: 269,
    label: "Aparecida d'oeste - SP",
  },
  {
    id: 270,
    label: "Aparecida de goiânia - GO",
  },
  {
    id: 271,
    label: "Aparecida do rio doce - GO",
  },
  {
    id: 272,
    label: "Aparecida do rio negro - TO",
  },
  {
    id: 273,
    label: "Aparecida do taboado - MS",
  },
  {
    id: 274,
    label: "Aperibé - RJ",
  },
  {
    id: 275,
    label: "Apiacá - ES",
  },
  {
    id: 276,
    label: "Apiacás - MT",
  },
  {
    id: 277,
    label: "Apiaí - SP",
  },
  {
    id: 278,
    label: "Apicum-açu - MA",
  },
  {
    id: 279,
    label: "Apiúna - SC",
  },
  {
    id: 280,
    label: "Apodi - RN",
  },
  {
    id: 281,
    label: "Aporá - BA",
  },
  {
    id: 282,
    label: "Aporé - GO",
  },
  {
    id: 283,
    label: "Apuarema - BA",
  },
  {
    id: 284,
    label: "Apucarana - PR",
  },
  {
    id: 285,
    label: "Apuí - AM",
  },
  {
    id: 286,
    label: "Apuiarés - CE",
  },
  {
    id: 287,
    label: "Aquidabã - SE",
  },
  {
    id: 288,
    label: "Aquidauana - MS",
  },
  {
    id: 289,
    label: "Aquiraz - CE",
  },
  {
    id: 290,
    label: "Arabutã - SC",
  },
  {
    id: 291,
    label: "Araçagi - PB",
  },
  {
    id: 292,
    label: "Araçaí - MG",
  },
  {
    id: 293,
    label: "Aracaju - SE",
  },
  {
    id: 294,
    label: "Araçariguama - SP",
  },
  {
    id: 295,
    label: "Araçás - BA",
  },
  {
    id: 296,
    label: "Aracati - CE",
  },
  {
    id: 297,
    label: "Aracatu - BA",
  },
  {
    id: 298,
    label: "Araçatuba - SP",
  },
  {
    id: 299,
    label: "Araci - BA",
  },
  {
    id: 300,
    label: "Aracitaba - MG",
  },
  {
    id: 301,
    label: "Aracoiaba - CE",
  },
  {
    id: 302,
    label: "Araçoiaba - PE",
  },
  {
    id: 303,
    label: "Araçoiaba da serra - SP",
  },
  {
    id: 304,
    label: "Aracruz - ES",
  },
  {
    id: 305,
    label: "Araçu - GO",
  },
  {
    id: 306,
    label: "Araçuaí - MG",
  },
  {
    id: 307,
    label: "Aragarças - GO",
  },
  {
    id: 308,
    label: "Aragoiânia - GO",
  },
  {
    id: 309,
    label: "Aragominas - TO",
  },
  {
    id: 310,
    label: "Araguacema - TO",
  },
  {
    id: 311,
    label: "Araguaçu - TO",
  },
  {
    id: 312,
    label: "Araguaiana - MT",
  },
  {
    id: 313,
    label: "Araguaína - TO",
  },
  {
    id: 314,
    label: "Araguainha - MT",
  },
  {
    id: 315,
    label: "Araguanã - MA",
  },
  {
    id: 316,
    label: "Araguanã - TO",
  },
  {
    id: 317,
    label: "Araguapaz - GO",
  },
  {
    id: 318,
    label: "Araguari - MG",
  },
  {
    id: 319,
    label: "Araguatins - TO",
  },
  {
    id: 320,
    label: "Araioses - MA",
  },
  {
    id: 321,
    label: "Aral moreira - MS",
  },
  {
    id: 322,
    label: "Aramari - BA",
  },
  {
    id: 323,
    label: "Arambaré - RS",
  },
  {
    id: 324,
    label: "Arame - MA",
  },
  {
    id: 325,
    label: "Aramina - SP",
  },
  {
    id: 326,
    label: "Arandu - SP",
  },
  {
    id: 327,
    label: "Arantina - MG",
  },
  {
    id: 328,
    label: "Arapeí - SP",
  },
  {
    id: 329,
    label: "Arapiraca - AL",
  },
  {
    id: 330,
    label: "Arapoema - TO",
  },
  {
    id: 331,
    label: "Araponga - MG",
  },
  {
    id: 332,
    label: "Arapongas - PR",
  },
  {
    id: 333,
    label: "Araporã - MG",
  },
  {
    id: 334,
    label: "Arapoti - PR",
  },
  {
    id: 335,
    label: "Arapuá - MG",
  },
  {
    id: 336,
    label: "Arapuã - PR",
  },
  {
    id: 337,
    label: "Araputanga - MT",
  },
  {
    id: 338,
    label: "Araquari - SC",
  },
  {
    id: 339,
    label: "Arara - PB",
  },
  {
    id: 340,
    label: "Araranguá - SC",
  },
  {
    id: 341,
    label: "Araraquara - SP",
  },
  {
    id: 342,
    label: "Araras - SP",
  },
  {
    id: 343,
    label: "Ararendá - CE",
  },
  {
    id: 344,
    label: "Arari - MA",
  },
  {
    id: 345,
    label: "Araricá - RS",
  },
  {
    id: 346,
    label: "Araripe - CE",
  },
  {
    id: 347,
    label: "Araripina - PE",
  },
  {
    id: 348,
    label: "Araruama - RJ",
  },
  {
    id: 349,
    label: "Araruna - PB",
  },
  {
    id: 350,
    label: "Araruna - PR",
  },
  {
    id: 351,
    label: "Arataca - BA",
  },
  {
    id: 352,
    label: "Aratiba - RS",
  },
  {
    id: 353,
    label: "Aratuba - CE",
  },
  {
    id: 354,
    label: "Aratuípe - BA",
  },
  {
    id: 355,
    label: "Arauá - SE",
  },
  {
    id: 356,
    label: "Araucária - PR",
  },
  {
    id: 357,
    label: "Araújos - MG",
  },
  {
    id: 358,
    label: "Araxá - MG",
  },
  {
    id: 359,
    label: "Arceburgo - MG",
  },
  {
    id: 360,
    label: "Arco-íris - SP",
  },
  {
    id: 361,
    label: "Arcos - MG",
  },
  {
    id: 362,
    label: "Arcoverde - PE",
  },
  {
    id: 363,
    label: "Areado - MG",
  },
  {
    id: 364,
    label: "Areal - RJ",
  },
  {
    id: 365,
    label: "Arealva - SP",
  },
  {
    id: 366,
    label: "Areia - PB",
  },
  {
    id: 367,
    label: "Areia branca - RN",
  },
  {
    id: 368,
    label: "Areia branca - SE",
  },
  {
    id: 369,
    label: "Areia de baraúnas - PB",
  },
  {
    id: 370,
    label: "Areial - PB",
  },
  {
    id: 371,
    label: "Areias - SP",
  },
  {
    id: 372,
    label: "Areiópolis - SP",
  },
  {
    id: 373,
    label: "Arenápolis - MT",
  },
  {
    id: 374,
    label: "Arenópolis - GO",
  },
  {
    id: 375,
    label: "Arez - RN",
  },
  {
    id: 376,
    label: "Argirita - MG",
  },
  {
    id: 377,
    label: "Aricanduva - MG",
  },
  {
    id: 378,
    label: "Arinos - MG",
  },
  {
    id: 379,
    label: "Aripuanã - MT",
  },
  {
    id: 380,
    label: "Ariquemes - RO",
  },
  {
    id: 381,
    label: "Ariranha - SP",
  },
  {
    id: 382,
    label: "Ariranha do ivaí - PR",
  },
  {
    id: 383,
    label: "Armação dos búzios - RJ",
  },
  {
    id: 384,
    label: "Armazém - SC",
  },
  {
    id: 385,
    label: "Arneiroz - CE",
  },
  {
    id: 386,
    label: "Aroazes - PI",
  },
  {
    id: 387,
    label: "Aroeiras - PB",
  },
  {
    id: 388,
    label: "Aroeiras do itaim - PI",
  },
  {
    id: 389,
    label: "Arraial - PI",
  },
  {
    id: 390,
    label: "Arraial do cabo - RJ",
  },
  {
    id: 391,
    label: "Arraias - TO",
  },
  {
    id: 392,
    label: "Arroio do meio - RS",
  },
  {
    id: 393,
    label: "Arroio do padre - RS",
  },
  {
    id: 394,
    label: "Arroio do sal - RS",
  },
  {
    id: 395,
    label: "Arroio do tigre - RS",
  },
  {
    id: 396,
    label: "Arroio dos ratos - RS",
  },
  {
    id: 397,
    label: "Arroio grande - RS",
  },
  {
    id: 398,
    label: "Arroio trinta - SC",
  },
  {
    id: 399,
    label: "Artur nogueira - SP",
  },
  {
    id: 400,
    label: "Aruanã - GO",
  },
  {
    id: 401,
    label: "Arujá - SP",
  },
  {
    id: 402,
    label: "Arvoredo - SC",
  },
  {
    id: 403,
    label: "Arvorezinha - RS",
  },
  {
    id: 404,
    label: "Ascurra - SC",
  },
  {
    id: 405,
    label: "Aspásia - SP",
  },
  {
    id: 406,
    label: "Assaí - PR",
  },
  {
    id: 407,
    label: "Assaré - CE",
  },
  {
    id: 408,
    label: "Assis - SP",
  },
  {
    id: 409,
    label: "Assis brasil - AC",
  },
  {
    id: 410,
    label: "Assis chateaubriand - PR",
  },
  {
    id: 411,
    label: "Assú - RN",
  },
  {
    id: 412,
    label: "Assunção - PB",
  },
  {
    id: 413,
    label: "Assunção do piauí - PI",
  },
  {
    id: 414,
    label: "Astolfo dutra - MG",
  },
  {
    id: 415,
    label: "Astorga - PR",
  },
  {
    id: 416,
    label: "Atalaia - AL",
  },
  {
    id: 417,
    label: "Atalaia - PR",
  },
  {
    id: 418,
    label: "Atalaia do norte - AM",
  },
  {
    id: 419,
    label: "Atalanta - SC",
  },
  {
    id: 420,
    label: "Ataléia - MG",
  },
  {
    id: 421,
    label: "Atibaia - SP",
  },
  {
    id: 422,
    label: "Atílio vivácqua - ES",
  },
  {
    id: 423,
    label: "Augustinópolis - TO",
  },
  {
    id: 424,
    label: "Augusto corrêa - PA",
  },
  {
    id: 425,
    label: "Augusto de lima - MG",
  },
  {
    id: 426,
    label: "Augusto pestana - RS",
  },
  {
    id: 427,
    label: "Áurea - RS",
  },
  {
    id: 428,
    label: "Aurelino leal - BA",
  },
  {
    id: 429,
    label: "Auriflama - SP",
  },
  {
    id: 430,
    label: "Aurilândia - GO",
  },
  {
    id: 431,
    label: "Aurora - CE",
  },
  {
    id: 432,
    label: "Aurora - SC",
  },
  {
    id: 433,
    label: "Aurora do pará - PA",
  },
  {
    id: 434,
    label: "Aurora do tocantins - TO",
  },
  {
    id: 435,
    label: "Autazes - AM",
  },
  {
    id: 436,
    label: "Avaí - SP",
  },
  {
    id: 437,
    label: "Avanhandava - SP",
  },
  {
    id: 438,
    label: "Avaré - SP",
  },
  {
    id: 439,
    label: "Aveiro - PA",
  },
  {
    id: 440,
    label: "Avelino lopes - PI",
  },
  {
    id: 441,
    label: "Avelinópolis - GO",
  },
  {
    id: 442,
    label: "Axixá - MA",
  },
  {
    id: 443,
    label: "Axixá do tocantins - TO",
  },
  {
    id: 444,
    label: "Babaçulândia - TO",
  },
  {
    id: 445,
    label: "Bacabal - MA",
  },
  {
    id: 446,
    label: "Bacabeira - MA",
  },
  {
    id: 447,
    label: "Bacuri - MA",
  },
  {
    id: 448,
    label: "Bacurituba - MA",
  },
  {
    id: 449,
    label: "Bady bassitt - SP",
  },
  {
    id: 450,
    label: "Baependi - MG",
  },
  {
    id: 451,
    label: "Bagé - RS",
  },
  {
    id: 452,
    label: "Bagre - PA",
  },
  {
    id: 453,
    label: "Baía da traição - PB",
  },
  {
    id: 454,
    label: "Baía formosa - RN",
  },
  {
    id: 455,
    label: "Baianópolis - BA",
  },
  {
    id: 456,
    label: "Baião - PA",
  },
  {
    id: 457,
    label: "Baixa grande - BA",
  },
  {
    id: 458,
    label: "Baixa grande do ribeiro - PI",
  },
  {
    id: 459,
    label: "Baixio - CE",
  },
  {
    id: 460,
    label: "Baixo guandu - ES",
  },
  {
    id: 461,
    label: "Balbinos - SP",
  },
  {
    id: 462,
    label: "Baldim - MG",
  },
  {
    id: 463,
    label: "Baliza - GO",
  },
  {
    id: 464,
    label: "Balneário arroio do silva - SC",
  },
  {
    id: 465,
    label: "Balneário barra do sul - SC",
  },
  {
    id: 466,
    label: "Balneário camboriú - SC",
  },
  {
    id: 467,
    label: "Balneário gaivota - SC",
  },
  {
    id: 468,
    label: "Balneário piçarras - SC",
  },
  {
    id: 469,
    label: "Balneário pinhal - RS",
  },
  {
    id: 470,
    label: "Balneário rincão - SC",
  },
  {
    id: 471,
    label: "Balsa nova - PR",
  },
  {
    id: 472,
    label: "Bálsamo - SP",
  },
  {
    id: 473,
    label: "Balsas - MA",
  },
  {
    id: 474,
    label: "Bambuí - MG",
  },
  {
    id: 475,
    label: "Banabuiú - CE",
  },
  {
    id: 476,
    label: "Bananal - SP",
  },
  {
    id: 477,
    label: "Bananeiras - PB",
  },
  {
    id: 478,
    label: "Bandeira - MG",
  },
  {
    id: 479,
    label: "Bandeira do sul - MG",
  },
  {
    id: 480,
    label: "Bandeirante - SC",
  },
  {
    id: 481,
    label: "Bandeirantes - MS",
  },
  {
    id: 482,
    label: "Bandeirantes - PR",
  },
  {
    id: 483,
    label: "Bandeirantes do tocantins - TO",
  },
  {
    id: 484,
    label: "Bannach - PA",
  },
  {
    id: 485,
    label: "Banzaê - BA",
  },
  {
    id: 486,
    label: "Barão - RS",
  },
  {
    id: 487,
    label: "Barão de antonina - SP",
  },
  {
    id: 488,
    label: "Barão de cocais - MG",
  },
  {
    id: 489,
    label: "Barão de cotegipe - RS",
  },
  {
    id: 490,
    label: "Barão de grajaú - MA",
  },
  {
    id: 491,
    label: "Barão de melgaço - MT",
  },
  {
    id: 492,
    label: "Barão de monte alto - MG",
  },
  {
    id: 493,
    label: "Barão do triunfo - RS",
  },
  {
    id: 494,
    label: "Baraúna - PB",
  },
  {
    id: 495,
    label: "Baraúna - RN",
  },
  {
    id: 496,
    label: "Barbacena - MG",
  },
  {
    id: 497,
    label: "Barbalha - CE",
  },
  {
    id: 498,
    label: "Barbosa - SP",
  },
  {
    id: 499,
    label: "Barbosa ferraz - PR",
  },
  {
    id: 500,
    label: "Barcarena - PA",
  },
  {
    id: 501,
    label: "Barcelona - RN",
  },
  {
    id: 502,
    label: "Barcelos - AM",
  },
  {
    id: 503,
    label: "Bariri - SP",
  },
  {
    id: 504,
    label: "Barra - BA",
  },
  {
    id: 505,
    label: "Barra bonita - SC",
  },
  {
    id: 506,
    label: "Barra bonita - SP",
  },
  {
    id: 507,
    label: "Barra d'alcântara - PI",
  },
  {
    id: 508,
    label: "Barra da estiva - BA",
  },
  {
    id: 509,
    label: "Barra de guabiraba - PE",
  },
  {
    id: 510,
    label: "Barra de santa rosa - PB",
  },
  {
    id: 511,
    label: "Barra de santana - PB",
  },
  {
    id: 512,
    label: "Barra de santo antônio - AL",
  },
  {
    id: 513,
    label: "Barra de são francisco - ES",
  },
  {
    id: 514,
    label: "Barra de são miguel - AL",
  },
  {
    id: 515,
    label: "Barra de são miguel - PB",
  },
  {
    id: 516,
    label: "Barra do bugres - MT",
  },
  {
    id: 517,
    label: "Barra do chapéu - SP",
  },
  {
    id: 518,
    label: "Barra do choça - BA",
  },
  {
    id: 519,
    label: "Barra do corda - MA",
  },
  {
    id: 520,
    label: "Barra do garças - MT",
  },
  {
    id: 521,
    label: "Barra do guarita - RS",
  },
  {
    id: 522,
    label: "Barra do jacaré - PR",
  },
  {
    id: 523,
    label: "Barra do mendes - BA",
  },
  {
    id: 524,
    label: "Barra do ouro - TO",
  },
  {
    id: 525,
    label: "Barra do piraí - RJ",
  },
  {
    id: 526,
    label: "Barra do quaraí - RS",
  },
  {
    id: 527,
    label: "Barra do ribeiro - RS",
  },
  {
    id: 528,
    label: "Barra do rio azul - RS",
  },
  {
    id: 529,
    label: "Barra do rocha - BA",
  },
  {
    id: 530,
    label: "Barra do turvo - SP",
  },
  {
    id: 531,
    label: "Barra dos coqueiros - SE",
  },
  {
    id: 532,
    label: "Barra funda - RS",
  },
  {
    id: 533,
    label: "Barra longa - MG",
  },
  {
    id: 534,
    label: "Barra mansa - RJ",
  },
  {
    id: 535,
    label: "Barra velha - SC",
  },
  {
    id: 536,
    label: "Barracão - PR",
  },
  {
    id: 537,
    label: "Barracão - RS",
  },
  {
    id: 538,
    label: "Barras - PI",
  },
  {
    id: 539,
    label: "Barreira - CE",
  },
  {
    id: 540,
    label: "Barreiras - BA",
  },
  {
    id: 541,
    label: "Barreiras do piauí - PI",
  },
  {
    id: 542,
    label: "Barreirinha - AM",
  },
  {
    id: 543,
    label: "Barreirinhas - MA",
  },
  {
    id: 544,
    label: "Barreiros - PE",
  },
  {
    id: 545,
    label: "Barretos - SP",
  },
  {
    id: 546,
    label: "Barrinha - SP",
  },
  {
    id: 547,
    label: "Barro - CE",
  },
  {
    id: 548,
    label: "Barro alto - BA",
  },
  {
    id: 549,
    label: "Barro alto - GO",
  },
  {
    id: 550,
    label: "Barro duro - PI",
  },
  {
    id: 551,
    label: "Barro preto - BA",
  },
  {
    id: 552,
    label: "Barrocas - BA",
  },
  {
    id: 553,
    label: "Barrolândia - TO",
  },
  {
    id: 554,
    label: "Barroquinha - CE",
  },
  {
    id: 555,
    label: "Barros cassal - RS",
  },
  {
    id: 556,
    label: "Barroso - MG",
  },
  {
    id: 557,
    label: "Barueri - SP",
  },
  {
    id: 558,
    label: "Bastos - SP",
  },
  {
    id: 559,
    label: "Bataguassu - MS",
  },
  {
    id: 560,
    label: "Batalha - AL",
  },
  {
    id: 561,
    label: "Batalha - PI",
  },
  {
    id: 562,
    label: "Batatais - SP",
  },
  {
    id: 563,
    label: "Batayporã - MS",
  },
  {
    id: 564,
    label: "Baturité - CE",
  },
  {
    id: 565,
    label: "Bauru - SP",
  },
  {
    id: 566,
    label: "Bayeux - PB",
  },
  {
    id: 567,
    label: "Bebedouro - SP",
  },
  {
    id: 568,
    label: "Beberibe - CE",
  },
  {
    id: 569,
    label: "Bela cruz - CE",
  },
  {
    id: 570,
    label: "Bela vista - MS",
  },
  {
    id: 571,
    label: "Bela vista da caroba - PR",
  },
  {
    id: 572,
    label: "Bela vista de goiás - GO",
  },
  {
    id: 573,
    label: "Bela vista de minas - MG",
  },
  {
    id: 574,
    label: "Bela vista do maranhão - MA",
  },
  {
    id: 575,
    label: "Bela vista do paraíso - PR",
  },
  {
    id: 576,
    label: "Bela vista do piauí - PI",
  },
  {
    id: 577,
    label: "Bela vista do toldo - SC",
  },
  {
    id: 578,
    label: "Belágua - MA",
  },
  {
    id: 579,
    label: "Belém - AL",
  },
  {
    id: 580,
    label: "Belém - PA",
  },
  {
    id: 581,
    label: "Belém - PB",
  },
  {
    id: 582,
    label: "Belém de maria - PE",
  },
  {
    id: 583,
    label: "Belém do brejo do cruz - PB",
  },
  {
    id: 584,
    label: "Belém do piauí - PI",
  },
  {
    id: 585,
    label: "Belém do são francisco - PE",
  },
  {
    id: 586,
    label: "Belford roxo - RJ",
  },
  {
    id: 587,
    label: "Belmiro braga - MG",
  },
  {
    id: 588,
    label: "Belmonte - BA",
  },
  {
    id: 589,
    label: "Belmonte - SC",
  },
  {
    id: 590,
    label: "Belo campo - BA",
  },
  {
    id: 591,
    label: "Belo horizonte - MG",
  },
  {
    id: 592,
    label: "Belo jardim - PE",
  },
  {
    id: 593,
    label: "Belo monte - AL",
  },
  {
    id: 594,
    label: "Belo oriente - MG",
  },
  {
    id: 595,
    label: "Belo vale - MG",
  },
  {
    id: 596,
    label: "Belterra - PA",
  },
  {
    id: 597,
    label: "Beneditinos - PI",
  },
  {
    id: 598,
    label: "Benedito leite - MA",
  },
  {
    id: 599,
    label: "Benedito novo - SC",
  },
  {
    id: 600,
    label: "Benevides - PA",
  },
  {
    id: 601,
    label: "Benjamin constant - AM",
  },
  {
    id: 602,
    label: "Benjamin constant do sul - RS",
  },
  {
    id: 603,
    label: "Bento de abreu - SP",
  },
  {
    id: 604,
    label: "Bento fernandes - RN",
  },
  {
    id: 605,
    label: "Bento gonçalves - RS",
  },
  {
    id: 606,
    label: "Bequimão - MA",
  },
  {
    id: 607,
    label: "Berilo - MG",
  },
  {
    id: 608,
    label: "Berizal - MG",
  },
  {
    id: 609,
    label: "Bernardino batista - PB",
  },
  {
    id: 610,
    label: "Bernardino de campos - SP",
  },
  {
    id: 611,
    label: "Bernardo do mearim - MA",
  },
  {
    id: 612,
    label: "Bernardo sayão - TO",
  },
  {
    id: 613,
    label: "Bertioga - SP",
  },
  {
    id: 614,
    label: "Bertolínia - PI",
  },
  {
    id: 615,
    label: "Bertópolis - MG",
  },
  {
    id: 616,
    label: "Beruri - AM",
  },
  {
    id: 617,
    label: "Betânia - PE",
  },
  {
    id: 618,
    label: "Betânia do piauí - PI",
  },
  {
    id: 619,
    label: "Betim - MG",
  },
  {
    id: 620,
    label: "Bezerros - PE",
  },
  {
    id: 621,
    label: "Bias fortes - MG",
  },
  {
    id: 622,
    label: "Bicas - MG",
  },
  {
    id: 623,
    label: "Biguaçu - SC",
  },
  {
    id: 624,
    label: "Bilac - SP",
  },
  {
    id: 625,
    label: "Biquinhas - MG",
  },
  {
    id: 626,
    label: "Birigui - SP",
  },
  {
    id: 627,
    label: "Biritiba mirim - SP",
  },
  {
    id: 628,
    label: "Biritinga - BA",
  },
  {
    id: 629,
    label: "Bituruna - PR",
  },
  {
    id: 630,
    label: "Blumenau - SC",
  },
  {
    id: 631,
    label: "Boa esperança - ES",
  },
  {
    id: 632,
    label: "Boa esperança - MG",
  },
  {
    id: 633,
    label: "Boa esperança - PR",
  },
  {
    id: 634,
    label: "Boa esperança do iguaçu - PR",
  },
  {
    id: 635,
    label: "Boa esperança do norte - MT",
  },
  {
    id: 636,
    label: "Boa esperança do sul - SP",
  },
  {
    id: 637,
    label: "Boa hora - PI",
  },
  {
    id: 638,
    label: "Boa nova - BA",
  },
  {
    id: 639,
    label: "Boa saúde - RN",
  },
  {
    id: 640,
    label: "Boa ventura - PB",
  },
  {
    id: 641,
    label: "Boa ventura de são roque - PR",
  },
  {
    id: 642,
    label: "Boa viagem - CE",
  },
  {
    id: 643,
    label: "Boa vista - PB",
  },
  {
    id: 644,
    label: "Boa vista - RR",
  },
  {
    id: 645,
    label: "Boa vista da aparecida - PR",
  },
  {
    id: 646,
    label: "Boa vista das missões - RS",
  },
  {
    id: 647,
    label: "Boa vista do buricá - RS",
  },
  {
    id: 648,
    label: "Boa vista do cadeado - RS",
  },
  {
    id: 649,
    label: "Boa vista do gurupi - MA",
  },
  {
    id: 650,
    label: "Boa vista do incra - RS",
  },
  {
    id: 651,
    label: "Boa vista do ramos - AM",
  },
  {
    id: 652,
    label: "Boa vista do sul - RS",
  },
  {
    id: 653,
    label: "Boa vista do tupim - BA",
  },
  {
    id: 654,
    label: "Boca da mata - AL",
  },
  {
    id: 655,
    label: "Boca do acre - AM",
  },
  {
    id: 656,
    label: "Bocaina - PI",
  },
  {
    id: 657,
    label: "Bocaina - SP",
  },
  {
    id: 658,
    label: "Bocaina de minas - MG",
  },
  {
    id: 659,
    label: "Bocaina do sul - SC",
  },
  {
    id: 660,
    label: "Bocaiúva - MG",
  },
  {
    id: 661,
    label: "Bocaiúva do sul - PR",
  },
  {
    id: 662,
    label: "Bodó - RN",
  },
  {
    id: 663,
    label: "Bodocó - PE",
  },
  {
    id: 664,
    label: "Bodoquena - MS",
  },
  {
    id: 665,
    label: "Bofete - SP",
  },
  {
    id: 666,
    label: "Boituva - SP",
  },
  {
    id: 667,
    label: "Bom conselho - PE",
  },
  {
    id: 668,
    label: "Bom despacho - MG",
  },
  {
    id: 669,
    label: "Bom jardim - MA",
  },
  {
    id: 670,
    label: "Bom jardim - PE",
  },
  {
    id: 671,
    label: "Bom jardim - RJ",
  },
  {
    id: 672,
    label: "Bom jardim da serra - SC",
  },
  {
    id: 673,
    label: "Bom jardim de goiás - GO",
  },
  {
    id: 674,
    label: "Bom jardim de minas - MG",
  },
  {
    id: 675,
    label: "Bom jesus - PB",
  },
  {
    id: 676,
    label: "Bom jesus - PI",
  },
  {
    id: 677,
    label: "Bom jesus - RN",
  },
  {
    id: 678,
    label: "Bom jesus - RS",
  },
  {
    id: 679,
    label: "Bom jesus - SC",
  },
  {
    id: 680,
    label: "Bom jesus da lapa - BA",
  },
  {
    id: 681,
    label: "Bom jesus da penha - MG",
  },
  {
    id: 682,
    label: "Bom jesus da serra - BA",
  },
  {
    id: 683,
    label: "Bom jesus das selvas - MA",
  },
  {
    id: 684,
    label: "Bom jesus de goiás - GO",
  },
  {
    id: 685,
    label: "Bom jesus do amparo - MG",
  },
  {
    id: 686,
    label: "Bom jesus do araguaia - MT",
  },
  {
    id: 687,
    label: "Bom jesus do galho - MG",
  },
  {
    id: 688,
    label: "Bom jesus do itabapoana - RJ",
  },
  {
    id: 689,
    label: "Bom jesus do norte - ES",
  },
  {
    id: 690,
    label: "Bom jesus do oeste - SC",
  },
  {
    id: 691,
    label: "Bom jesus do sul - PR",
  },
  {
    id: 692,
    label: "Bom jesus do tocantins - PA",
  },
  {
    id: 693,
    label: "Bom jesus do tocantins - TO",
  },
  {
    id: 694,
    label: "Bom jesus dos perdões - SP",
  },
  {
    id: 695,
    label: "Bom lugar - MA",
  },
  {
    id: 696,
    label: "Bom princípio - RS",
  },
  {
    id: 697,
    label: "Bom princípio do piauí - PI",
  },
  {
    id: 698,
    label: "Bom progresso - RS",
  },
  {
    id: 699,
    label: "Bom repouso - MG",
  },
  {
    id: 700,
    label: "Bom retiro - SC",
  },
  {
    id: 701,
    label: "Bom retiro do sul - RS",
  },
  {
    id: 702,
    label: "Bom sucesso - MG",
  },
  {
    id: 703,
    label: "Bom sucesso - PB",
  },
  {
    id: 704,
    label: "Bom sucesso - PR",
  },
  {
    id: 705,
    label: "Bom sucesso de itararé - SP",
  },
  {
    id: 706,
    label: "Bom sucesso do sul - PR",
  },
  {
    id: 707,
    label: "Bombinhas - SC",
  },
  {
    id: 708,
    label: "Bonfim - MG",
  },
  {
    id: 709,
    label: "Bonfim - RR",
  },
  {
    id: 710,
    label: "Bonfim do piauí - PI",
  },
  {
    id: 711,
    label: "Bonfinópolis - GO",
  },
  {
    id: 712,
    label: "Bonfinópolis de minas - MG",
  },
  {
    id: 713,
    label: "Boninal - BA",
  },
  {
    id: 714,
    label: "Bonito - BA",
  },
  {
    id: 715,
    label: "Bonito - MS",
  },
  {
    id: 716,
    label: "Bonito - PA",
  },
  {
    id: 717,
    label: "Bonito - PE",
  },
  {
    id: 718,
    label: "Bonito de minas - MG",
  },
  {
    id: 719,
    label: "Bonito de santa fé - PB",
  },
  {
    id: 720,
    label: "Bonópolis - GO",
  },
  {
    id: 721,
    label: "Boqueirão - PB",
  },
  {
    id: 722,
    label: "Boqueirão do leão - RS",
  },
  {
    id: 723,
    label: "Boqueirão do piauí - PI",
  },
  {
    id: 724,
    label: "Boquim - SE",
  },
  {
    id: 725,
    label: "Boquira - BA",
  },
  {
    id: 726,
    label: "Borá - SP",
  },
  {
    id: 727,
    label: "Boracéia - SP",
  },
  {
    id: 728,
    label: "Borba - AM",
  },
  {
    id: 729,
    label: "Borborema - PB",
  },
  {
    id: 730,
    label: "Borborema - SP",
  },
  {
    id: 731,
    label: "Borda da mata - MG",
  },
  {
    id: 732,
    label: "Borebi - SP",
  },
  {
    id: 733,
    label: "Borrazópolis - PR",
  },
  {
    id: 734,
    label: "Bossoroca - RS",
  },
  {
    id: 735,
    label: "Botelhos - MG",
  },
  {
    id: 736,
    label: "Botucatu - SP",
  },
  {
    id: 737,
    label: "Botumirim - MG",
  },
  {
    id: 738,
    label: "Botuporã - BA",
  },
  {
    id: 739,
    label: "Botuverá - SC",
  },
  {
    id: 740,
    label: "Bozano - RS",
  },
  {
    id: 741,
    label: "Braço do norte - SC",
  },
  {
    id: 742,
    label: "Braço do trombudo - SC",
  },
  {
    id: 743,
    label: "Braga - RS",
  },
  {
    id: 744,
    label: "Bragança - PA",
  },
  {
    id: 745,
    label: "Bragança paulista - SP",
  },
  {
    id: 746,
    label: "Braganey - PR",
  },
  {
    id: 747,
    label: "Branquinha - AL",
  },
  {
    id: 748,
    label: "Brás pires - MG",
  },
  {
    id: 749,
    label: "Brasil novo - PA",
  },
  {
    id: 750,
    label: "Brasilândia - MS",
  },
  {
    id: 751,
    label: "Brasilândia de minas - MG",
  },
  {
    id: 752,
    label: "Brasilândia do sul - PR",
  },
  {
    id: 753,
    label: "Brasilândia do tocantins - TO",
  },
  {
    id: 754,
    label: "Brasiléia - AC",
  },
  {
    id: 755,
    label: "Brasileira - PI",
  },
  {
    id: 756,
    label: "Brasília - DF",
  },
  {
    id: 757,
    label: "Brasília de minas - MG",
  },
  {
    id: 758,
    label: "Brasnorte - MT",
  },
  {
    id: 759,
    label: "Braúna - SP",
  },
  {
    id: 760,
    label: "Braúnas - MG",
  },
  {
    id: 761,
    label: "Brazabrantes - GO",
  },
  {
    id: 762,
    label: "Brazópolis - MG",
  },
  {
    id: 763,
    label: "Brejão - PE",
  },
  {
    id: 764,
    label: "Brejetuba - ES",
  },
  {
    id: 765,
    label: "Brejinho - PE",
  },
  {
    id: 766,
    label: "Brejinho - RN",
  },
  {
    id: 767,
    label: "Brejinho de nazaré - TO",
  },
  {
    id: 768,
    label: "Brejo - MA",
  },
  {
    id: 769,
    label: "Brejo alegre - SP",
  },
  {
    id: 770,
    label: "Brejo da madre de deus - PE",
  },
  {
    id: 771,
    label: "Brejo de areia - MA",
  },
  {
    id: 772,
    label: "Brejo do cruz - PB",
  },
  {
    id: 773,
    label: "Brejo do piauí - PI",
  },
  {
    id: 774,
    label: "Brejo dos santos - PB",
  },
  {
    id: 775,
    label: "Brejo grande - SE",
  },
  {
    id: 776,
    label: "Brejo grande do araguaia - PA",
  },
  {
    id: 777,
    label: "Brejo santo - CE",
  },
  {
    id: 778,
    label: "Brejões - BA",
  },
  {
    id: 779,
    label: "Brejolândia - BA",
  },
  {
    id: 780,
    label: "Breu branco - PA",
  },
  {
    id: 781,
    label: "Breves - PA",
  },
  {
    id: 782,
    label: "Britânia - GO",
  },
  {
    id: 783,
    label: "Brochier - RS",
  },
  {
    id: 784,
    label: "Brodowski - SP",
  },
  {
    id: 785,
    label: "Brotas - SP",
  },
  {
    id: 786,
    label: "Brotas de macaúbas - BA",
  },
  {
    id: 787,
    label: "Brumadinho - MG",
  },
  {
    id: 788,
    label: "Brumado - BA",
  },
  {
    id: 789,
    label: "Brunópolis - SC",
  },
  {
    id: 790,
    label: "Brusque - SC",
  },
  {
    id: 791,
    label: "Bueno brandão - MG",
  },
  {
    id: 792,
    label: "Buenópolis - MG",
  },
  {
    id: 793,
    label: "Buenos aires - PE",
  },
  {
    id: 794,
    label: "Buerarema - BA",
  },
  {
    id: 795,
    label: "Bugre - MG",
  },
  {
    id: 796,
    label: "Buíque - PE",
  },
  {
    id: 797,
    label: "Bujari - AC",
  },
  {
    id: 798,
    label: "Bujaru - PA",
  },
  {
    id: 799,
    label: "Buri - SP",
  },
  {
    id: 800,
    label: "Buritama - SP",
  },
  {
    id: 801,
    label: "Buriti - MA",
  },
  {
    id: 802,
    label: "Buriti alegre - GO",
  },
  {
    id: 803,
    label: "Buriti bravo - MA",
  },
  {
    id: 804,
    label: "Buriti de goiás - GO",
  },
  {
    id: 805,
    label: "Buriti do tocantins - TO",
  },
  {
    id: 806,
    label: "Buriti dos lopes - PI",
  },
  {
    id: 807,
    label: "Buriti dos montes - PI",
  },
  {
    id: 808,
    label: "Buriticupu - MA",
  },
  {
    id: 809,
    label: "Buritinópolis - GO",
  },
  {
    id: 810,
    label: "Buritirama - BA",
  },
  {
    id: 811,
    label: "Buritirana - MA",
  },
  {
    id: 812,
    label: "Buritis - MG",
  },
  {
    id: 813,
    label: "Buritis - RO",
  },
  {
    id: 814,
    label: "Buritizal - SP",
  },
  {
    id: 815,
    label: "Buritizeiro - MG",
  },
  {
    id: 816,
    label: "Butiá - RS",
  },
  {
    id: 817,
    label: "Caapiranga - AM",
  },
  {
    id: 818,
    label: "Caaporã - PB",
  },
  {
    id: 819,
    label: "Caarapó - MS",
  },
  {
    id: 820,
    label: "Caatiba - BA",
  },
  {
    id: 821,
    label: "Cabaceiras - PB",
  },
  {
    id: 822,
    label: "Cabaceiras do paraguaçu - BA",
  },
  {
    id: 823,
    label: "Cabeceira grande - MG",
  },
  {
    id: 824,
    label: "Cabeceiras - GO",
  },
  {
    id: 825,
    label: "Cabeceiras do piauí - PI",
  },
  {
    id: 826,
    label: "Cabedelo - PB",
  },
  {
    id: 827,
    label: "Cabixi - RO",
  },
  {
    id: 828,
    label: "Cabo de santo agostinho - PE",
  },
  {
    id: 829,
    label: "Cabo frio - RJ",
  },
  {
    id: 830,
    label: "Cabo verde - MG",
  },
  {
    id: 831,
    label: "Cabrália paulista - SP",
  },
  {
    id: 832,
    label: "Cabreúva - SP",
  },
  {
    id: 833,
    label: "Cabrobó - PE",
  },
  {
    id: 834,
    label: "Caçador - SC",
  },
  {
    id: 835,
    label: "Caçapava - SP",
  },
  {
    id: 836,
    label: "Caçapava do sul - RS",
  },
  {
    id: 837,
    label: "Cacaulândia - RO",
  },
  {
    id: 838,
    label: "Cacequi - RS",
  },
  {
    id: 839,
    label: "Cáceres - MT",
  },
  {
    id: 840,
    label: "Cachoeira - BA",
  },
  {
    id: 841,
    label: "Cachoeira alta - GO",
  },
  {
    id: 842,
    label: "Cachoeira da prata - MG",
  },
  {
    id: 843,
    label: "Cachoeira de goiás - GO",
  },
  {
    id: 844,
    label: "Cachoeira de minas - MG",
  },
  {
    id: 845,
    label: "Cachoeira de pajeú - MG",
  },
  {
    id: 846,
    label: "Cachoeira do arari - PA",
  },
  {
    id: 847,
    label: "Cachoeira do piriá - PA",
  },
  {
    id: 848,
    label: "Cachoeira do sul - RS",
  },
  {
    id: 849,
    label: "Cachoeira dos índios - PB",
  },
  {
    id: 850,
    label: "Cachoeira dourada - GO",
  },
  {
    id: 851,
    label: "Cachoeira dourada - MG",
  },
  {
    id: 852,
    label: "Cachoeira grande - MA",
  },
  {
    id: 853,
    label: "Cachoeira paulista - SP",
  },
  {
    id: 854,
    label: "Cachoeiras de macacu - RJ",
  },
  {
    id: 855,
    label: "Cachoeirinha - PE",
  },
  {
    id: 856,
    label: "Cachoeirinha - RS",
  },
  {
    id: 857,
    label: "Cachoeirinha - TO",
  },
  {
    id: 858,
    label: "Cachoeiro de itapemirim - ES",
  },
  {
    id: 859,
    label: "Cacimba de areia - PB",
  },
  {
    id: 860,
    label: "Cacimba de dentro - PB",
  },
  {
    id: 861,
    label: "Cacimbas - PB",
  },
  {
    id: 862,
    label: "Cacimbinhas - AL",
  },
  {
    id: 863,
    label: "Cacique doble - RS",
  },
  {
    id: 864,
    label: "Cacoal - RO",
  },
  {
    id: 865,
    label: "Caconde - SP",
  },
  {
    id: 866,
    label: "Caçu - GO",
  },
  {
    id: 867,
    label: "Caculé - BA",
  },
  {
    id: 868,
    label: "Caem - BA",
  },
  {
    id: 869,
    label: "Caetanópolis - MG",
  },
  {
    id: 870,
    label: "Caetanos - BA",
  },
  {
    id: 871,
    label: "Caeté - MG",
  },
  {
    id: 872,
    label: "Caetés - PE",
  },
  {
    id: 873,
    label: "Caetité - BA",
  },
  {
    id: 874,
    label: "Cafarnaum - BA",
  },
  {
    id: 875,
    label: "Cafeara - PR",
  },
  {
    id: 876,
    label: "Cafelândia - PR",
  },
  {
    id: 877,
    label: "Cafelândia - SP",
  },
  {
    id: 878,
    label: "Cafezal do sul - PR",
  },
  {
    id: 879,
    label: "Caiabu - SP",
  },
  {
    id: 880,
    label: "Caiana - MG",
  },
  {
    id: 881,
    label: "Caiapônia - GO",
  },
  {
    id: 882,
    label: "Caibaté - RS",
  },
  {
    id: 883,
    label: "Caibi - SC",
  },
  {
    id: 884,
    label: "Caiçara - PB",
  },
  {
    id: 885,
    label: "Caiçara - RS",
  },
  {
    id: 886,
    label: "Caiçara do norte - RN",
  },
  {
    id: 887,
    label: "Caiçara do rio do vento - RN",
  },
  {
    id: 888,
    label: "Caicó - RN",
  },
  {
    id: 889,
    label: "Caieiras - SP",
  },
  {
    id: 890,
    label: "Cairu - BA",
  },
  {
    id: 891,
    label: "Caiuá - SP",
  },
  {
    id: 892,
    label: "Cajamar - SP",
  },
  {
    id: 893,
    label: "Cajapió - MA",
  },
  {
    id: 894,
    label: "Cajari - MA",
  },
  {
    id: 895,
    label: "Cajati - SP",
  },
  {
    id: 896,
    label: "Cajazeiras - PB",
  },
  {
    id: 897,
    label: "Cajazeiras do piauí - PI",
  },
  {
    id: 898,
    label: "Cajazeirinhas - PB",
  },
  {
    id: 899,
    label: "Cajobi - SP",
  },
  {
    id: 900,
    label: "Cajueiro - AL",
  },
  {
    id: 901,
    label: "Cajueiro da praia - PI",
  },
  {
    id: 902,
    label: "Cajuri - MG",
  },
  {
    id: 903,
    label: "Cajuru - SP",
  },
  {
    id: 904,
    label: "Calçado - PE",
  },
  {
    id: 905,
    label: "Calçoene - AP",
  },
  {
    id: 906,
    label: "Caldas - MG",
  },
  {
    id: 907,
    label: "Caldas brandão - PB",
  },
  {
    id: 908,
    label: "Caldas novas - GO",
  },
  {
    id: 909,
    label: "Caldazinha - GO",
  },
  {
    id: 910,
    label: "Caldeirão grande - BA",
  },
  {
    id: 911,
    label: "Caldeirão grande do piauí - PI",
  },
  {
    id: 912,
    label: "Califórnia - PR",
  },
  {
    id: 913,
    label: "Calmon - SC",
  },
  {
    id: 914,
    label: "Calumbi - PE",
  },
  {
    id: 915,
    label: "Camacã - BA",
  },
  {
    id: 916,
    label: "Camaçari - BA",
  },
  {
    id: 917,
    label: "Camacho - MG",
  },
  {
    id: 918,
    label: "Camalaú - PB",
  },
  {
    id: 919,
    label: "Camamu - BA",
  },
  {
    id: 920,
    label: "Camanducaia - MG",
  },
  {
    id: 921,
    label: "Camapuã - MS",
  },
  {
    id: 922,
    label: "Camaquã - RS",
  },
  {
    id: 923,
    label: "Camaragibe - PE",
  },
  {
    id: 924,
    label: "Camargo - RS",
  },
  {
    id: 925,
    label: "Cambará - PR",
  },
  {
    id: 926,
    label: "Cambará do sul - RS",
  },
  {
    id: 927,
    label: "Cambé - PR",
  },
  {
    id: 928,
    label: "Cambira - PR",
  },
  {
    id: 929,
    label: "Camboriú - SC",
  },
  {
    id: 930,
    label: "Cambuci - RJ",
  },
  {
    id: 931,
    label: "Cambuí - MG",
  },
  {
    id: 932,
    label: "Cambuquira - MG",
  },
  {
    id: 933,
    label: "Cametá - PA",
  },
  {
    id: 934,
    label: "Camocim - CE",
  },
  {
    id: 935,
    label: "Camocim de são félix - PE",
  },
  {
    id: 936,
    label: "Campanário - MG",
  },
  {
    id: 937,
    label: "Campanha - MG",
  },
  {
    id: 938,
    label: "Campestre - AL",
  },
  {
    id: 939,
    label: "Campestre - MG",
  },
  {
    id: 940,
    label: "Campestre da serra - RS",
  },
  {
    id: 941,
    label: "Campestre de goiás - GO",
  },
  {
    id: 942,
    label: "Campestre do maranhão - MA",
  },
  {
    id: 943,
    label: "Campina da lagoa - PR",
  },
  {
    id: 944,
    label: "Campina das missões - RS",
  },
  {
    id: 945,
    label: "Campina do monte alegre - SP",
  },
  {
    id: 946,
    label: "Campina do simão - PR",
  },
  {
    id: 947,
    label: "Campina grande - PB",
  },
  {
    id: 948,
    label: "Campina grande do sul - PR",
  },
  {
    id: 949,
    label: "Campina verde - MG",
  },
  {
    id: 950,
    label: "Campinaçu - GO",
  },
  {
    id: 951,
    label: "Campinápolis - MT",
  },
  {
    id: 952,
    label: "Campinas - SP",
  },
  {
    id: 953,
    label: "Campinas do piauí - PI",
  },
  {
    id: 954,
    label: "Campinas do sul - RS",
  },
  {
    id: 955,
    label: "Campinorte - GO",
  },
  {
    id: 956,
    label: "Campo alegre - AL",
  },
  {
    id: 957,
    label: "Campo alegre - SC",
  },
  {
    id: 958,
    label: "Campo alegre de goiás - GO",
  },
  {
    id: 959,
    label: "Campo alegre de lourdes - BA",
  },
  {
    id: 960,
    label: "Campo alegre do fidalgo - PI",
  },
  {
    id: 961,
    label: "Campo azul - MG",
  },
  {
    id: 962,
    label: "Campo belo - MG",
  },
  {
    id: 963,
    label: "Campo belo do sul - SC",
  },
  {
    id: 964,
    label: "Campo bom - RS",
  },
  {
    id: 965,
    label: "Campo bonito - PR",
  },
  {
    id: 966,
    label: "Campo do brito - SE",
  },
  {
    id: 967,
    label: "Campo do meio - MG",
  },
  {
    id: 968,
    label: "Campo do tenente - PR",
  },
  {
    id: 969,
    label: "Campo erê - SC",
  },
  {
    id: 970,
    label: "Campo florido - MG",
  },
  {
    id: 971,
    label: "Campo formoso - BA",
  },
  {
    id: 972,
    label: "Campo grande - AL",
  },
  {
    id: 973,
    label: "Campo grande - MS",
  },
  {
    id: 974,
    label: "Campo grande - RN",
  },
  {
    id: 975,
    label: "Campo grande do piauí - PI",
  },
  {
    id: 976,
    label: "Campo largo - PR",
  },
  {
    id: 977,
    label: "Campo largo do piauí - PI",
  },
  {
    id: 978,
    label: "Campo limpo de goiás - GO",
  },
  {
    id: 979,
    label: "Campo limpo paulista - SP",
  },
  {
    id: 980,
    label: "Campo magro - PR",
  },
  {
    id: 981,
    label: "Campo maior - PI",
  },
  {
    id: 982,
    label: "Campo mourão - PR",
  },
  {
    id: 983,
    label: "Campo novo - RS",
  },
  {
    id: 984,
    label: "Campo novo de rondônia - RO",
  },
  {
    id: 985,
    label: "Campo novo do parecis - MT",
  },
  {
    id: 986,
    label: "Campo redondo - RN",
  },
  {
    id: 987,
    label: "Campo verde - MT",
  },
  {
    id: 988,
    label: "Campos altos - MG",
  },
  {
    id: 989,
    label: "Campos belos - GO",
  },
  {
    id: 990,
    label: "Campos borges - RS",
  },
  {
    id: 991,
    label: "Campos de júlio - MT",
  },
  {
    id: 992,
    label: "Campos do jordão - SP",
  },
  {
    id: 993,
    label: "Campos dos goytacazes - RJ",
  },
  {
    id: 994,
    label: "Campos gerais - MG",
  },
  {
    id: 995,
    label: "Campos lindos - TO",
  },
  {
    id: 996,
    label: "Campos novos - SC",
  },
  {
    id: 997,
    label: "Campos novos paulista - SP",
  },
  {
    id: 998,
    label: "Campos sales - CE",
  },
  {
    id: 999,
    label: "Campos verdes - GO",
  },
  {
    id: 1000,
    label: "Camutanga - PE",
  },
  {
    id: 1001,
    label: "Cana verde - MG",
  },
  {
    id: 1002,
    label: "Canaã - MG",
  },
  {
    id: 1003,
    label: "Canaã dos carajás - PA",
  },
  {
    id: 1004,
    label: "Canabrava do norte - MT",
  },
  {
    id: 1005,
    label: "Cananéia - SP",
  },
  {
    id: 1006,
    label: "Canapi - AL",
  },
  {
    id: 1007,
    label: "Canápolis - BA",
  },
  {
    id: 1008,
    label: "Canápolis - MG",
  },
  {
    id: 1009,
    label: "Canarana - BA",
  },
  {
    id: 1010,
    label: "Canarana - MT",
  },
  {
    id: 1011,
    label: "Canas - SP",
  },
  {
    id: 1012,
    label: "Canavieira - PI",
  },
  {
    id: 1013,
    label: "Canavieiras - BA",
  },
  {
    id: 1014,
    label: "Candeal - BA",
  },
  {
    id: 1015,
    label: "Candeias - BA",
  },
  {
    id: 1016,
    label: "Candeias - MG",
  },
  {
    id: 1017,
    label: "Candeias do jamari - RO",
  },
  {
    id: 1018,
    label: "Candelária - RS",
  },
  {
    id: 1019,
    label: "Candiba - BA",
  },
  {
    id: 1020,
    label: "Cândido de abreu - PR",
  },
  {
    id: 1021,
    label: "Cândido godói - RS",
  },
  {
    id: 1022,
    label: "Cândido mendes - MA",
  },
  {
    id: 1023,
    label: "Cândido mota - SP",
  },
  {
    id: 1024,
    label: "Cândido rodrigues - SP",
  },
  {
    id: 1025,
    label: "Cândido sales - BA",
  },
  {
    id: 1026,
    label: "Candiota - RS",
  },
  {
    id: 1027,
    label: "Candói - PR",
  },
  {
    id: 1028,
    label: "Canela - RS",
  },
  {
    id: 1029,
    label: "Canelinha - SC",
  },
  {
    id: 1030,
    label: "Canguaretama - RN",
  },
  {
    id: 1031,
    label: "Canguçu - RS",
  },
  {
    id: 1032,
    label: "Canhoba - SE",
  },
  {
    id: 1033,
    label: "Canhotinho - PE",
  },
  {
    id: 1034,
    label: "Canindé - CE",
  },
  {
    id: 1035,
    label: "Canindé de são francisco - SE",
  },
  {
    id: 1036,
    label: "Canitar - SP",
  },
  {
    id: 1037,
    label: "Canoas - RS",
  },
  {
    id: 1038,
    label: "Canoinhas - SC",
  },
  {
    id: 1039,
    label: "Cansanção - BA",
  },
  {
    id: 1040,
    label: "Cantá - RR",
  },
  {
    id: 1041,
    label: "Cantagalo - MG",
  },
  {
    id: 1042,
    label: "Cantagalo - PR",
  },
  {
    id: 1043,
    label: "Cantagalo - RJ",
  },
  {
    id: 1044,
    label: "Cantanhede - MA",
  },
  {
    id: 1045,
    label: "Canto do buriti - PI",
  },
  {
    id: 1046,
    label: "Canudos - BA",
  },
  {
    id: 1047,
    label: "Canudos do vale - RS",
  },
  {
    id: 1048,
    label: "Canutama - AM",
  },
  {
    id: 1049,
    label: "Capanema - PA",
  },
  {
    id: 1050,
    label: "Capanema - PR",
  },
  {
    id: 1051,
    label: "Capão alto - SC",
  },
  {
    id: 1052,
    label: "Capão bonito - SP",
  },
  {
    id: 1053,
    label: "Capão bonito do sul - RS",
  },
  {
    id: 1054,
    label: "Capão da canoa - RS",
  },
  {
    id: 1055,
    label: "Capão do cipó - RS",
  },
  {
    id: 1056,
    label: "Capão do leão - RS",
  },
  {
    id: 1057,
    label: "Caparaó - MG",
  },
  {
    id: 1058,
    label: "Capela - AL",
  },
  {
    id: 1059,
    label: "Capela - SE",
  },
  {
    id: 1060,
    label: "Capela de santana - RS",
  },
  {
    id: 1061,
    label: "Capela do alto - SP",
  },
  {
    id: 1062,
    label: "Capela do alto alegre - BA",
  },
  {
    id: 1063,
    label: "Capela nova - MG",
  },
  {
    id: 1064,
    label: "Capelinha - MG",
  },
  {
    id: 1065,
    label: "Capetinga - MG",
  },
  {
    id: 1066,
    label: "Capim - PB",
  },
  {
    id: 1067,
    label: "Capim branco - MG",
  },
  {
    id: 1068,
    label: "Capim grosso - BA",
  },
  {
    id: 1069,
    label: "Capinópolis - MG",
  },
  {
    id: 1070,
    label: "Capinzal - SC",
  },
  {
    id: 1071,
    label: "Capinzal do norte - MA",
  },
  {
    id: 1072,
    label: "Capistrano - CE",
  },
  {
    id: 1073,
    label: "Capitão - RS",
  },
  {
    id: 1074,
    label: "Capitão andrade - MG",
  },
  {
    id: 1075,
    label: "Capitão de campos - PI",
  },
  {
    id: 1076,
    label: "Capitão enéas - MG",
  },
  {
    id: 1077,
    label: "Capitão gervásio oliveira - PI",
  },
  {
    id: 1078,
    label: "Capitão leônidas marques - PR",
  },
  {
    id: 1079,
    label: "Capitão poço - PA",
  },
  {
    id: 1080,
    label: "Capitólio - MG",
  },
  {
    id: 1081,
    label: "Capivari - SP",
  },
  {
    id: 1082,
    label: "Capivari de baixo - SC",
  },
  {
    id: 1083,
    label: "Capivari do sul - RS",
  },
  {
    id: 1084,
    label: "Capixaba - AC",
  },
  {
    id: 1085,
    label: "Capoeiras - PE",
  },
  {
    id: 1086,
    label: "Caputira - MG",
  },
  {
    id: 1087,
    label: "Caraá - RS",
  },
  {
    id: 1088,
    label: "Caracaraí - RR",
  },
  {
    id: 1089,
    label: "Caracol - MS",
  },
  {
    id: 1090,
    label: "Caracol - PI",
  },
  {
    id: 1091,
    label: "Caraguatatuba - SP",
  },
  {
    id: 1092,
    label: "Caraí - MG",
  },
  {
    id: 1093,
    label: "Caraíbas - BA",
  },
  {
    id: 1094,
    label: "Carambeí - PR",
  },
  {
    id: 1095,
    label: "Caranaíba - MG",
  },
  {
    id: 1096,
    label: "Carandaí - MG",
  },
  {
    id: 1097,
    label: "Carangola - MG",
  },
  {
    id: 1098,
    label: "Carapebus - RJ",
  },
  {
    id: 1099,
    label: "Carapicuíba - SP",
  },
  {
    id: 1100,
    label: "Caratinga - MG",
  },
  {
    id: 1101,
    label: "Carauari - AM",
  },
  {
    id: 1102,
    label: "Caraúbas - PB",
  },
  {
    id: 1103,
    label: "Caraúbas - RN",
  },
  {
    id: 1104,
    label: "Caraúbas do piauí - PI",
  },
  {
    id: 1105,
    label: "Caravelas - BA",
  },
  {
    id: 1106,
    label: "Carazinho - RS",
  },
  {
    id: 1107,
    label: "Carbonita - MG",
  },
  {
    id: 1108,
    label: "Cardeal da silva - BA",
  },
  {
    id: 1109,
    label: "Cardoso - SP",
  },
  {
    id: 1110,
    label: "Cardoso moreira - RJ",
  },
  {
    id: 1111,
    label: "Careaçu - MG",
  },
  {
    id: 1112,
    label: "Careiro - AM",
  },
  {
    id: 1113,
    label: "Careiro da várzea - AM",
  },
  {
    id: 1114,
    label: "Cariacica - ES",
  },
  {
    id: 1115,
    label: "Caridade - CE",
  },
  {
    id: 1116,
    label: "Caridade do piauí - PI",
  },
  {
    id: 1117,
    label: "Carinhanha - BA",
  },
  {
    id: 1118,
    label: "Carira - SE",
  },
  {
    id: 1119,
    label: "Cariré - CE",
  },
  {
    id: 1120,
    label: "Cariri do tocantins - TO",
  },
  {
    id: 1121,
    label: "Caririaçu - CE",
  },
  {
    id: 1122,
    label: "Cariús - CE",
  },
  {
    id: 1123,
    label: "Carlinda - MT",
  },
  {
    id: 1124,
    label: "Carlópolis - PR",
  },
  {
    id: 1125,
    label: "Carlos barbosa - RS",
  },
  {
    id: 1126,
    label: "Carlos chagas - MG",
  },
  {
    id: 1127,
    label: "Carlos gomes - RS",
  },
  {
    id: 1128,
    label: "Carmésia - MG",
  },
  {
    id: 1129,
    label: "Carmo - RJ",
  },
  {
    id: 1130,
    label: "Carmo da cachoeira - MG",
  },
  {
    id: 1131,
    label: "Carmo da mata - MG",
  },
  {
    id: 1132,
    label: "Carmo de minas - MG",
  },
  {
    id: 1133,
    label: "Carmo do cajuru - MG",
  },
  {
    id: 1134,
    label: "Carmo do paranaíba - MG",
  },
  {
    id: 1135,
    label: "Carmo do rio claro - MG",
  },
  {
    id: 1136,
    label: "Carmo do rio verde - GO",
  },
  {
    id: 1137,
    label: "Carmolândia - TO",
  },
  {
    id: 1138,
    label: "Carmópolis - SE",
  },
  {
    id: 1139,
    label: "Carmópolis de minas - MG",
  },
  {
    id: 1140,
    label: "Carnaíba - PE",
  },
  {
    id: 1141,
    label: "Carnaúba dos dantas - RN",
  },
  {
    id: 1142,
    label: "Carnaubais - RN",
  },
  {
    id: 1143,
    label: "Carnaubal - CE",
  },
  {
    id: 1144,
    label: "Carnaubeira da penha - PE",
  },
  {
    id: 1145,
    label: "Carneirinho - MG",
  },
  {
    id: 1146,
    label: "Carneiros - AL",
  },
  {
    id: 1147,
    label: "Caroebe - RR",
  },
  {
    id: 1148,
    label: "Carolina - MA",
  },
  {
    id: 1149,
    label: "Carpina - PE",
  },
  {
    id: 1150,
    label: "Carrancas - MG",
  },
  {
    id: 1151,
    label: "Carrapateira - PB",
  },
  {
    id: 1152,
    label: "Carrasco bonito - TO",
  },
  {
    id: 1153,
    label: "Caruaru - PE",
  },
  {
    id: 1154,
    label: "Carutapera - MA",
  },
  {
    id: 1155,
    label: "Carvalhópolis - MG",
  },
  {
    id: 1156,
    label: "Carvalhos - MG",
  },
  {
    id: 1157,
    label: "Casa branca - SP",
  },
  {
    id: 1158,
    label: "Casa grande - MG",
  },
  {
    id: 1159,
    label: "Casa nova - BA",
  },
  {
    id: 1160,
    label: "Casca - RS",
  },
  {
    id: 1161,
    label: "Cascalho rico - MG",
  },
  {
    id: 1162,
    label: "Cascavel - CE",
  },
  {
    id: 1163,
    label: "Cascavel - PR",
  },
  {
    id: 1164,
    label: "Caseara - TO",
  },
  {
    id: 1165,
    label: "Caseiros - RS",
  },
  {
    id: 1166,
    label: "Casimiro de abreu - RJ",
  },
  {
    id: 1167,
    label: "Casinhas - PE",
  },
  {
    id: 1168,
    label: "Casserengue - PB",
  },
  {
    id: 1169,
    label: "Cássia - MG",
  },
  {
    id: 1170,
    label: "Cássia dos coqueiros - SP",
  },
  {
    id: 1171,
    label: "Cassilândia - MS",
  },
  {
    id: 1172,
    label: "Castanhal - PA",
  },
  {
    id: 1173,
    label: "Castanheira - MT",
  },
  {
    id: 1174,
    label: "Castanheiras - RO",
  },
  {
    id: 1175,
    label: "Castelândia - GO",
  },
  {
    id: 1176,
    label: "Castelo - ES",
  },
  {
    id: 1177,
    label: "Castelo do piauí - PI",
  },
  {
    id: 1178,
    label: "Castilho - SP",
  },
  {
    id: 1179,
    label: "Castro - PR",
  },
  {
    id: 1180,
    label: "Castro alves - BA",
  },
  {
    id: 1181,
    label: "Cataguases - MG",
  },
  {
    id: 1182,
    label: "Catalão - GO",
  },
  {
    id: 1183,
    label: "Catanduva - SP",
  },
  {
    id: 1184,
    label: "Catanduvas - PR",
  },
  {
    id: 1185,
    label: "Catanduvas - SC",
  },
  {
    id: 1186,
    label: "Catarina - CE",
  },
  {
    id: 1187,
    label: "Catas altas - MG",
  },
  {
    id: 1188,
    label: "Catas altas da noruega - MG",
  },
  {
    id: 1189,
    label: "Catende - PE",
  },
  {
    id: 1190,
    label: "Catiguá - SP",
  },
  {
    id: 1191,
    label: "Catingueira - PB",
  },
  {
    id: 1192,
    label: "Catolândia - BA",
  },
  {
    id: 1193,
    label: "Catolé do rocha - PB",
  },
  {
    id: 1194,
    label: "Catu - BA",
  },
  {
    id: 1195,
    label: "Catuípe - RS",
  },
  {
    id: 1196,
    label: "Catuji - MG",
  },
  {
    id: 1197,
    label: "Catunda - CE",
  },
  {
    id: 1198,
    label: "Caturaí - GO",
  },
  {
    id: 1199,
    label: "Caturama - BA",
  },
  {
    id: 1200,
    label: "Caturité - PB",
  },
  {
    id: 1201,
    label: "Catuti - MG",
  },
  {
    id: 1202,
    label: "Caucaia - CE",
  },
  {
    id: 1203,
    label: "Cavalcante - GO",
  },
  {
    id: 1204,
    label: "Caxambu - MG",
  },
  {
    id: 1205,
    label: "Caxambu do sul - SC",
  },
  {
    id: 1206,
    label: "Caxias - MA",
  },
  {
    id: 1207,
    label: "Caxias do sul - RS",
  },
  {
    id: 1208,
    label: "Caxingó - PI",
  },
  {
    id: 1209,
    label: "Ceará-mirim - RN",
  },
  {
    id: 1210,
    label: "Cedral - MA",
  },
  {
    id: 1211,
    label: "Cedral - SP",
  },
  {
    id: 1212,
    label: "Cedro - CE",
  },
  {
    id: 1213,
    label: "Cedro - PE",
  },
  {
    id: 1214,
    label: "Cedro de são joão - SE",
  },
  {
    id: 1215,
    label: "Cedro do abaeté - MG",
  },
  {
    id: 1216,
    label: "Celso ramos - SC",
  },
  {
    id: 1217,
    label: "Centenário - RS",
  },
  {
    id: 1218,
    label: "Centenário - TO",
  },
  {
    id: 1219,
    label: "Centenário do sul - PR",
  },
  {
    id: 1220,
    label: "Central - BA",
  },
  {
    id: 1221,
    label: "Central de minas - MG",
  },
  {
    id: 1222,
    label: "Central do maranhão - MA",
  },
  {
    id: 1223,
    label: "Centralina - MG",
  },
  {
    id: 1224,
    label: "Centro do guilherme - MA",
  },
  {
    id: 1225,
    label: "Centro novo do maranhão - MA",
  },
  {
    id: 1226,
    label: "Cerejeiras - RO",
  },
  {
    id: 1227,
    label: "Ceres - GO",
  },
  {
    id: 1228,
    label: "Cerqueira césar - SP",
  },
  {
    id: 1229,
    label: "Cerquilho - SP",
  },
  {
    id: 1230,
    label: "Cerrito - RS",
  },
  {
    id: 1231,
    label: "Cerro azul - PR",
  },
  {
    id: 1232,
    label: "Cerro branco - RS",
  },
  {
    id: 1233,
    label: "Cerro corá - RN",
  },
  {
    id: 1234,
    label: "Cerro grande - RS",
  },
  {
    id: 1235,
    label: "Cerro grande do sul - RS",
  },
  {
    id: 1236,
    label: "Cerro largo - RS",
  },
  {
    id: 1237,
    label: "Cerro negro - SC",
  },
  {
    id: 1238,
    label: "Cesário lange - SP",
  },
  {
    id: 1239,
    label: "Céu azul - PR",
  },
  {
    id: 1240,
    label: "Cezarina - GO",
  },
  {
    id: 1241,
    label: "Chã de alegria - PE",
  },
  {
    id: 1242,
    label: "Chã grande - PE",
  },
  {
    id: 1243,
    label: "Chã preta - AL",
  },
  {
    id: 1244,
    label: "Chácara - MG",
  },
  {
    id: 1245,
    label: "Chalé - MG",
  },
  {
    id: 1246,
    label: "Chapada - RS",
  },
  {
    id: 1247,
    label: "Chapada da natividade - TO",
  },
  {
    id: 1248,
    label: "Chapada de areia - TO",
  },
  {
    id: 1249,
    label: "Chapada do norte - MG",
  },
  {
    id: 1250,
    label: "Chapada dos guimarães - MT",
  },
  {
    id: 1251,
    label: "Chapada gaúcha - MG",
  },
  {
    id: 1252,
    label: "Chapadão do céu - GO",
  },
  {
    id: 1253,
    label: "Chapadão do lageado - SC",
  },
  {
    id: 1254,
    label: "Chapadão do sul - MS",
  },
  {
    id: 1255,
    label: "Chapadinha - MA",
  },
  {
    id: 1256,
    label: "Chapecó - SC",
  },
  {
    id: 1257,
    label: "Charqueada - SP",
  },
  {
    id: 1258,
    label: "Charqueadas - RS",
  },
  {
    id: 1259,
    label: "Charrua - RS",
  },
  {
    id: 1260,
    label: "Chaval - CE",
  },
  {
    id: 1261,
    label: "Chavantes - SP",
  },
  {
    id: 1262,
    label: "Chaves - PA",
  },
  {
    id: 1263,
    label: "Chiador - MG",
  },
  {
    id: 1264,
    label: "Chiapetta - RS",
  },
  {
    id: 1265,
    label: "Chopinzinho - PR",
  },
  {
    id: 1266,
    label: "Choró - CE",
  },
  {
    id: 1267,
    label: "Chorozinho - CE",
  },
  {
    id: 1268,
    label: "Chorrochó - BA",
  },
  {
    id: 1269,
    label: "Chuí - RS",
  },
  {
    id: 1270,
    label: "Chupinguaia - RO",
  },
  {
    id: 1271,
    label: "Chuvisca - RS",
  },
  {
    id: 1272,
    label: "Cianorte - PR",
  },
  {
    id: 1273,
    label: "Cícero dantas - BA",
  },
  {
    id: 1274,
    label: "Cidade gaúcha - PR",
  },
  {
    id: 1275,
    label: "Cidade ocidental - GO",
  },
  {
    id: 1276,
    label: "Cidelândia - MA",
  },
  {
    id: 1277,
    label: "Cidreira - RS",
  },
  {
    id: 1278,
    label: "Cipó - BA",
  },
  {
    id: 1279,
    label: "Cipotânea - MG",
  },
  {
    id: 1280,
    label: "Ciríaco - RS",
  },
  {
    id: 1281,
    label: "Claraval - MG",
  },
  {
    id: 1282,
    label: "Claro dos poções - MG",
  },
  {
    id: 1283,
    label: "Cláudia - MT",
  },
  {
    id: 1284,
    label: "Cláudio - MG",
  },
  {
    id: 1285,
    label: "Clementina - SP",
  },
  {
    id: 1286,
    label: "Clevelândia - PR",
  },
  {
    id: 1287,
    label: "Coaraci - BA",
  },
  {
    id: 1288,
    label: "Coari - AM",
  },
  {
    id: 1289,
    label: "Cocal - PI",
  },
  {
    id: 1290,
    label: "Cocal de telha - PI",
  },
  {
    id: 1291,
    label: "Cocal do sul - SC",
  },
  {
    id: 1292,
    label: "Cocal dos alves - PI",
  },
  {
    id: 1293,
    label: "Cocalinho - MT",
  },
  {
    id: 1294,
    label: "Cocalzinho de goiás - GO",
  },
  {
    id: 1295,
    label: "Cocos - BA",
  },
  {
    id: 1296,
    label: "Codajás - AM",
  },
  {
    id: 1297,
    label: "Codó - MA",
  },
  {
    id: 1298,
    label: "Coelho neto - MA",
  },
  {
    id: 1299,
    label: "Coimbra - MG",
  },
  {
    id: 1300,
    label: "Coité do nóia - AL",
  },
  {
    id: 1301,
    label: "Coivaras - PI",
  },
  {
    id: 1302,
    label: "Colares - PA",
  },
  {
    id: 1303,
    label: "Colatina - ES",
  },
  {
    id: 1304,
    label: "Colíder - MT",
  },
  {
    id: 1305,
    label: "Colina - SP",
  },
  {
    id: 1306,
    label: "Colinas - MA",
  },
  {
    id: 1307,
    label: "Colinas - RS",
  },
  {
    id: 1308,
    label: "Colinas do sul - GO",
  },
  {
    id: 1309,
    label: "Colinas do tocantins - TO",
  },
  {
    id: 1310,
    label: "Colméia - TO",
  },
  {
    id: 1311,
    label: "Colniza - MT",
  },
  {
    id: 1312,
    label: "Colômbia - SP",
  },
  {
    id: 1313,
    label: "Colombo - PR",
  },
  {
    id: 1314,
    label: "Colônia do gurguéia - PI",
  },
  {
    id: 1315,
    label: "Colônia do piauí - PI",
  },
  {
    id: 1316,
    label: "Colônia leopoldina - AL",
  },
  {
    id: 1317,
    label: "Colorado - PR",
  },
  {
    id: 1318,
    label: "Colorado - RS",
  },
  {
    id: 1319,
    label: "Colorado do oeste - RO",
  },
  {
    id: 1320,
    label: "Coluna - MG",
  },
  {
    id: 1321,
    label: "Combinado - TO",
  },
  {
    id: 1322,
    label: "Comendador gomes - MG",
  },
  {
    id: 1323,
    label: "Comendador levy gasparian - RJ",
  },
  {
    id: 1324,
    label: "Comercinho - MG",
  },
  {
    id: 1325,
    label: "Comodoro - MT",
  },
  {
    id: 1326,
    label: "Conceição - PB",
  },
  {
    id: 1327,
    label: "Conceição da aparecida - MG",
  },
  {
    id: 1328,
    label: "Conceição da barra - ES",
  },
  {
    id: 1329,
    label: "Conceição da barra de minas - MG",
  },
  {
    id: 1330,
    label: "Conceição da feira - BA",
  },
  {
    id: 1331,
    label: "Conceição das alagoas - MG",
  },
  {
    id: 1332,
    label: "Conceição das pedras - MG",
  },
  {
    id: 1333,
    label: "Conceição de ipanema - MG",
  },
  {
    id: 1334,
    label: "Conceição de macabu - RJ",
  },
  {
    id: 1335,
    label: "Conceição do almeida - BA",
  },
  {
    id: 1336,
    label: "Conceição do araguaia - PA",
  },
  {
    id: 1337,
    label: "Conceição do canindé - PI",
  },
  {
    id: 1338,
    label: "Conceição do castelo - ES",
  },
  {
    id: 1339,
    label: "Conceição do coité - BA",
  },
  {
    id: 1340,
    label: "Conceição do jacuípe - BA",
  },
  {
    id: 1341,
    label: "Conceição do lago-açu - MA",
  },
  {
    id: 1342,
    label: "Conceição do mato dentro - MG",
  },
  {
    id: 1343,
    label: "Conceição do pará - MG",
  },
  {
    id: 1344,
    label: "Conceição do rio verde - MG",
  },
  {
    id: 1345,
    label: "Conceição do tocantins - TO",
  },
  {
    id: 1346,
    label: "Conceição dos ouros - MG",
  },
  {
    id: 1347,
    label: "Conchal - SP",
  },
  {
    id: 1348,
    label: "Conchas - SP",
  },
  {
    id: 1349,
    label: "Concórdia - SC",
  },
  {
    id: 1350,
    label: "Concórdia do pará - PA",
  },
  {
    id: 1351,
    label: "Condado - PB",
  },
  {
    id: 1352,
    label: "Condado - PE",
  },
  {
    id: 1353,
    label: "Conde - BA",
  },
  {
    id: 1354,
    label: "Conde - PB",
  },
  {
    id: 1355,
    label: "Condeúba - BA",
  },
  {
    id: 1356,
    label: "Condor - RS",
  },
  {
    id: 1357,
    label: "Cônego marinho - MG",
  },
  {
    id: 1358,
    label: "Confins - MG",
  },
  {
    id: 1359,
    label: "Confresa - MT",
  },
  {
    id: 1360,
    label: "Congo - PB",
  },
  {
    id: 1361,
    label: "Congonhal - MG",
  },
  {
    id: 1362,
    label: "Congonhas - MG",
  },
  {
    id: 1363,
    label: "Congonhas do norte - MG",
  },
  {
    id: 1364,
    label: "Congonhinhas - PR",
  },
  {
    id: 1365,
    label: "Conquista - MG",
  },
  {
    id: 1366,
    label: "Conquista d'oeste - MT",
  },
  {
    id: 1367,
    label: "Conselheiro lafaiete - MG",
  },
  {
    id: 1368,
    label: "Conselheiro mairinck - PR",
  },
  {
    id: 1369,
    label: "Conselheiro pena - MG",
  },
  {
    id: 1370,
    label: "Consolação - MG",
  },
  {
    id: 1371,
    label: "Constantina - RS",
  },
  {
    id: 1372,
    label: "Contagem - MG",
  },
  {
    id: 1373,
    label: "Contenda - PR",
  },
  {
    id: 1374,
    label: "Contendas do sincorá - BA",
  },
  {
    id: 1375,
    label: "Coqueiral - MG",
  },
  {
    id: 1376,
    label: "Coqueiro baixo - RS",
  },
  {
    id: 1377,
    label: "Coqueiro seco - AL",
  },
  {
    id: 1378,
    label: "Coqueiros do sul - RS",
  },
  {
    id: 1379,
    label: "Coração de jesus - MG",
  },
  {
    id: 1380,
    label: "Coração de maria - BA",
  },
  {
    id: 1381,
    label: "Corbélia - PR",
  },
  {
    id: 1382,
    label: "Cordeiro - RJ",
  },
  {
    id: 1383,
    label: "Cordeirópolis - SP",
  },
  {
    id: 1384,
    label: "Cordeiros - BA",
  },
  {
    id: 1385,
    label: "Cordilheira alta - SC",
  },
  {
    id: 1386,
    label: "Cordisburgo - MG",
  },
  {
    id: 1387,
    label: "Cordislândia - MG",
  },
  {
    id: 1388,
    label: "Coreaú - CE",
  },
  {
    id: 1389,
    label: "Coremas - PB",
  },
  {
    id: 1390,
    label: "Corguinho - MS",
  },
  {
    id: 1391,
    label: "Coribe - BA",
  },
  {
    id: 1392,
    label: "Corinto - MG",
  },
  {
    id: 1393,
    label: "Cornélio procópio - PR",
  },
  {
    id: 1394,
    label: "Coroaci - MG",
  },
  {
    id: 1395,
    label: "Coroados - SP",
  },
  {
    id: 1396,
    label: "Coroatá - MA",
  },
  {
    id: 1397,
    label: "Coromandel - MG",
  },
  {
    id: 1398,
    label: "Coronel barros - RS",
  },
  {
    id: 1399,
    label: "Coronel bicaco - RS",
  },
  {
    id: 1400,
    label: "Coronel domingos soares - PR",
  },
  {
    id: 1401,
    label: "Coronel ezequiel - RN",
  },
  {
    id: 1402,
    label: "Coronel fabriciano - MG",
  },
  {
    id: 1403,
    label: "Coronel freitas - SC",
  },
  {
    id: 1404,
    label: "Coronel joão pessoa - RN",
  },
  {
    id: 1405,
    label: "Coronel joão sá - BA",
  },
  {
    id: 1406,
    label: "Coronel josé dias - PI",
  },
  {
    id: 1407,
    label: "Coronel macedo - SP",
  },
  {
    id: 1408,
    label: "Coronel martins - SC",
  },
  {
    id: 1409,
    label: "Coronel murta - MG",
  },
  {
    id: 1410,
    label: "Coronel pacheco - MG",
  },
  {
    id: 1411,
    label: "Coronel pilar - RS",
  },
  {
    id: 1412,
    label: "Coronel sapucaia - MS",
  },
  {
    id: 1413,
    label: "Coronel vivida - PR",
  },
  {
    id: 1414,
    label: "Coronel xavier chaves - MG",
  },
  {
    id: 1415,
    label: "Córrego danta - MG",
  },
  {
    id: 1416,
    label: "Córrego do bom jesus - MG",
  },
  {
    id: 1417,
    label: "Córrego do ouro - GO",
  },
  {
    id: 1418,
    label: "Córrego fundo - MG",
  },
  {
    id: 1419,
    label: "Córrego novo - MG",
  },
  {
    id: 1420,
    label: "Correia pinto - SC",
  },
  {
    id: 1421,
    label: "Corrente - PI",
  },
  {
    id: 1422,
    label: "Correntes - PE",
  },
  {
    id: 1423,
    label: "Correntina - BA",
  },
  {
    id: 1424,
    label: "Cortês - PE",
  },
  {
    id: 1425,
    label: "Corumbá - MS",
  },
  {
    id: 1426,
    label: "Corumbá de goiás - GO",
  },
  {
    id: 1427,
    label: "Corumbaíba - GO",
  },
  {
    id: 1428,
    label: "Corumbataí - SP",
  },
  {
    id: 1429,
    label: "Corumbataí do sul - PR",
  },
  {
    id: 1430,
    label: "Corumbiara - RO",
  },
  {
    id: 1431,
    label: "Corupá - SC",
  },
  {
    id: 1432,
    label: "Coruripe - AL",
  },
  {
    id: 1433,
    label: "Cosmópolis - SP",
  },
  {
    id: 1434,
    label: "Cosmorama - SP",
  },
  {
    id: 1435,
    label: "Costa marques - RO",
  },
  {
    id: 1436,
    label: "Costa rica - MS",
  },
  {
    id: 1437,
    label: "Cotegipe - BA",
  },
  {
    id: 1438,
    label: "Cotia - SP",
  },
  {
    id: 1439,
    label: "Cotiporã - RS",
  },
  {
    id: 1440,
    label: "Cotriguaçu - MT",
  },
  {
    id: 1441,
    label: "Couto de magalhães de minas - MG",
  },
  {
    id: 1442,
    label: "Couto magalhães - TO",
  },
  {
    id: 1443,
    label: "Coxilha - RS",
  },
  {
    id: 1444,
    label: "Coxim - MS",
  },
  {
    id: 1445,
    label: "Coxixola - PB",
  },
  {
    id: 1446,
    label: "Craíbas - AL",
  },
  {
    id: 1447,
    label: "Crateús - CE",
  },
  {
    id: 1448,
    label: "Crato - CE",
  },
  {
    id: 1449,
    label: "Cravinhos - SP",
  },
  {
    id: 1450,
    label: "Cravolândia - BA",
  },
  {
    id: 1451,
    label: "Criciúma - SC",
  },
  {
    id: 1452,
    label: "Crisólita - MG",
  },
  {
    id: 1453,
    label: "Crisópolis - BA",
  },
  {
    id: 1454,
    label: "Crissiumal - RS",
  },
  {
    id: 1455,
    label: "Cristais - MG",
  },
  {
    id: 1456,
    label: "Cristais paulista - SP",
  },
  {
    id: 1457,
    label: "Cristal - RS",
  },
  {
    id: 1458,
    label: "Cristal do sul - RS",
  },
  {
    id: 1459,
    label: "Cristalândia - TO",
  },
  {
    id: 1460,
    label: "Cristalândia do piauí - PI",
  },
  {
    id: 1461,
    label: "Cristália - MG",
  },
  {
    id: 1462,
    label: "Cristalina - GO",
  },
  {
    id: 1463,
    label: "Cristiano otoni - MG",
  },
  {
    id: 1464,
    label: "Cristianópolis - GO",
  },
  {
    id: 1465,
    label: "Cristina - MG",
  },
  {
    id: 1466,
    label: "Cristinápolis - SE",
  },
  {
    id: 1467,
    label: "Cristino castro - PI",
  },
  {
    id: 1468,
    label: "Cristópolis - BA",
  },
  {
    id: 1469,
    label: "Crixás - GO",
  },
  {
    id: 1470,
    label: "Crixás do tocantins - TO",
  },
  {
    id: 1471,
    label: "Croatá - CE",
  },
  {
    id: 1472,
    label: "Cromínia - GO",
  },
  {
    id: 1473,
    label: "Crucilândia - MG",
  },
  {
    id: 1474,
    label: "Cruz - CE",
  },
  {
    id: 1475,
    label: "Cruz alta - RS",
  },
  {
    id: 1476,
    label: "Cruz das almas - BA",
  },
  {
    id: 1477,
    label: "Cruz do espírito santo - PB",
  },
  {
    id: 1478,
    label: "Cruz machado - PR",
  },
  {
    id: 1479,
    label: "Cruzália - SP",
  },
  {
    id: 1480,
    label: "Cruzaltense - RS",
  },
  {
    id: 1481,
    label: "Cruzeiro - SP",
  },
  {
    id: 1482,
    label: "Cruzeiro da fortaleza - MG",
  },
  {
    id: 1483,
    label: "Cruzeiro do iguaçu - PR",
  },
  {
    id: 1484,
    label: "Cruzeiro do oeste - PR",
  },
  {
    id: 1485,
    label: "Cruzeiro do sul - AC",
  },
  {
    id: 1486,
    label: "Cruzeiro do sul - PR",
  },
  {
    id: 1487,
    label: "Cruzeiro do sul - RS",
  },
  {
    id: 1488,
    label: "Cruzeta - RN",
  },
  {
    id: 1489,
    label: "Cruzília - MG",
  },
  {
    id: 1490,
    label: "Cruzmaltina - PR",
  },
  {
    id: 1491,
    label: "Cubatão - SP",
  },
  {
    id: 1492,
    label: "Cubati - PB",
  },
  {
    id: 1493,
    label: "Cuiabá - MT",
  },
  {
    id: 1494,
    label: "Cuité - PB",
  },
  {
    id: 1495,
    label: "Cuité de mamanguape - PB",
  },
  {
    id: 1496,
    label: "Cuitegi - PB",
  },
  {
    id: 1497,
    label: "Cujubim - RO",
  },
  {
    id: 1498,
    label: "Cumari - GO",
  },
  {
    id: 1499,
    label: "Cumaru - PE",
  },
  {
    id: 1500,
    label: "Cumaru do norte - PA",
  },
  {
    id: 1501,
    label: "Cumbe - SE",
  },
  {
    id: 1502,
    label: "Cunha - SP",
  },
  {
    id: 1503,
    label: "Cunha porã - SC",
  },
  {
    id: 1504,
    label: "Cunhataí - SC",
  },
  {
    id: 1505,
    label: "Cuparaque - MG",
  },
  {
    id: 1506,
    label: "Cupira - PE",
  },
  {
    id: 1507,
    label: "Curaçá - BA",
  },
  {
    id: 1508,
    label: "Curimatá - PI",
  },
  {
    id: 1509,
    label: "Curionópolis - PA",
  },
  {
    id: 1510,
    label: "Curitiba - PR",
  },
  {
    id: 1511,
    label: "Curitibanos - SC",
  },
  {
    id: 1512,
    label: "Curiúva - PR",
  },
  {
    id: 1513,
    label: "Currais - PI",
  },
  {
    id: 1514,
    label: "Currais novos - RN",
  },
  {
    id: 1515,
    label: "Curral de cima - PB",
  },
  {
    id: 1516,
    label: "Curral de dentro - MG",
  },
  {
    id: 1517,
    label: "Curral novo do piauí - PI",
  },
  {
    id: 1518,
    label: "Curral velho - PB",
  },
  {
    id: 1519,
    label: "Curralinho - PA",
  },
  {
    id: 1520,
    label: "Curralinhos - PI",
  },
  {
    id: 1521,
    label: "Curuá - PA",
  },
  {
    id: 1522,
    label: "Curuçá - PA",
  },
  {
    id: 1523,
    label: "Cururupu - MA",
  },
  {
    id: 1524,
    label: "Curvelândia - MT",
  },
  {
    id: 1525,
    label: "Curvelo - MG",
  },
  {
    id: 1526,
    label: "Custódia - PE",
  },
  {
    id: 1527,
    label: "Cutias - AP",
  },
  {
    id: 1528,
    label: "Damianópolis - GO",
  },
  {
    id: 1529,
    label: "Damião - PB",
  },
  {
    id: 1530,
    label: "Damolândia - GO",
  },
  {
    id: 1531,
    label: "Darcinópolis - TO",
  },
  {
    id: 1532,
    label: "Dário meira - BA",
  },
  {
    id: 1533,
    label: "Datas - MG",
  },
  {
    id: 1534,
    label: "David canabarro - RS",
  },
  {
    id: 1535,
    label: "Davinópolis - GO",
  },
  {
    id: 1536,
    label: "Davinópolis - MA",
  },
  {
    id: 1537,
    label: "Delfim moreira - MG",
  },
  {
    id: 1538,
    label: "Delfinópolis - MG",
  },
  {
    id: 1539,
    label: "Delmiro gouveia - AL",
  },
  {
    id: 1540,
    label: "Delta - MG",
  },
  {
    id: 1541,
    label: "Demerval lobão - PI",
  },
  {
    id: 1542,
    label: "Denise - MT",
  },
  {
    id: 1543,
    label: "Deodápolis - MS",
  },
  {
    id: 1544,
    label: "Deputado irapuan pinheiro - CE",
  },
  {
    id: 1545,
    label: "Derrubadas - RS",
  },
  {
    id: 1546,
    label: "Descalvado - SP",
  },
  {
    id: 1547,
    label: "Descanso - SC",
  },
  {
    id: 1548,
    label: "Descoberto - MG",
  },
  {
    id: 1549,
    label: "Desterro - PB",
  },
  {
    id: 1550,
    label: "Desterro de entre rios - MG",
  },
  {
    id: 1551,
    label: "Desterro do melo - MG",
  },
  {
    id: 1552,
    label: "Dezesseis de novembro - RS",
  },
  {
    id: 1553,
    label: "Diadema - SP",
  },
  {
    id: 1554,
    label: "Diamante - PB",
  },
  {
    id: 1555,
    label: "Diamante d'oeste - PR",
  },
  {
    id: 1556,
    label: "Diamante do norte - PR",
  },
  {
    id: 1557,
    label: "Diamante do sul - PR",
  },
  {
    id: 1558,
    label: "Diamantina - MG",
  },
  {
    id: 1559,
    label: "Diamantino - MT",
  },
  {
    id: 1560,
    label: "Dianópolis - TO",
  },
  {
    id: 1561,
    label: "Dias d'ávila - BA",
  },
  {
    id: 1562,
    label: "Dilermando de aguiar - RS",
  },
  {
    id: 1563,
    label: "Diogo de vasconcelos - MG",
  },
  {
    id: 1564,
    label: "Dionísio - MG",
  },
  {
    id: 1565,
    label: "Dionísio cerqueira - SC",
  },
  {
    id: 1566,
    label: "Diorama - GO",
  },
  {
    id: 1567,
    label: "Dirce reis - SP",
  },
  {
    id: 1568,
    label: "Dirceu arcoverde - PI",
  },
  {
    id: 1569,
    label: "Divina pastora - SE",
  },
  {
    id: 1570,
    label: "Divinésia - MG",
  },
  {
    id: 1571,
    label: "Divino - MG",
  },
  {
    id: 1572,
    label: "Divino das laranjeiras - MG",
  },
  {
    id: 1573,
    label: "Divino de são lourenço - ES",
  },
  {
    id: 1574,
    label: "Divinolândia - SP",
  },
  {
    id: 1575,
    label: "Divinolândia de minas - MG",
  },
  {
    id: 1576,
    label: "Divinópolis - MG",
  },
  {
    id: 1577,
    label: "Divinópolis de goiás - GO",
  },
  {
    id: 1578,
    label: "Divinópolis do tocantins - TO",
  },
  {
    id: 1579,
    label: "Divisa alegre - MG",
  },
  {
    id: 1580,
    label: "Divisa nova - MG",
  },
  {
    id: 1581,
    label: "Divisópolis - MG",
  },
  {
    id: 1582,
    label: "Dobrada - SP",
  },
  {
    id: 1583,
    label: "Dois córregos - SP",
  },
  {
    id: 1584,
    label: "Dois irmãos - RS",
  },
  {
    id: 1585,
    label: "Dois irmãos das missões - RS",
  },
  {
    id: 1586,
    label: "Dois irmãos do buriti - MS",
  },
  {
    id: 1587,
    label: "Dois irmãos do tocantins - TO",
  },
  {
    id: 1588,
    label: "Dois lajeados - RS",
  },
  {
    id: 1589,
    label: "Dois riachos - AL",
  },
  {
    id: 1590,
    label: "Dois vizinhos - PR",
  },
  {
    id: 1591,
    label: "Dolcinópolis - SP",
  },
  {
    id: 1592,
    label: "Dom aquino - MT",
  },
  {
    id: 1593,
    label: "Dom basílio - BA",
  },
  {
    id: 1594,
    label: "Dom bosco - MG",
  },
  {
    id: 1595,
    label: "Dom cavati - MG",
  },
  {
    id: 1596,
    label: "Dom eliseu - PA",
  },
  {
    id: 1597,
    label: "Dom expedito lopes - PI",
  },
  {
    id: 1598,
    label: "Dom feliciano - RS",
  },
  {
    id: 1599,
    label: "Dom inocêncio - PI",
  },
  {
    id: 1600,
    label: "Dom joaquim - MG",
  },
  {
    id: 1601,
    label: "Dom macedo costa - BA",
  },
  {
    id: 1602,
    label: "Dom pedrito - RS",
  },
  {
    id: 1603,
    label: "Dom pedro - MA",
  },
  {
    id: 1604,
    label: "Dom pedro de alcântara - RS",
  },
  {
    id: 1605,
    label: "Dom silvério - MG",
  },
  {
    id: 1606,
    label: "Dom viçoso - MG",
  },
  {
    id: 1607,
    label: "Domingos martins - ES",
  },
  {
    id: 1608,
    label: "Domingos mourão - PI",
  },
  {
    id: 1609,
    label: "Dona emma - SC",
  },
  {
    id: 1610,
    label: "Dona eusébia - MG",
  },
  {
    id: 1611,
    label: "Dona francisca - RS",
  },
  {
    id: 1612,
    label: "Dona inês - PB",
  },
  {
    id: 1613,
    label: "Dores de campos - MG",
  },
  {
    id: 1614,
    label: "Dores de guanhães - MG",
  },
  {
    id: 1615,
    label: "Dores do indaiá - MG",
  },
  {
    id: 1616,
    label: "Dores do rio preto - ES",
  },
  {
    id: 1617,
    label: "Dores do turvo - MG",
  },
  {
    id: 1618,
    label: "Doresópolis - MG",
  },
  {
    id: 1619,
    label: "Dormentes - PE",
  },
  {
    id: 1620,
    label: "Douradina - MS",
  },
  {
    id: 1621,
    label: "Douradina - PR",
  },
  {
    id: 1622,
    label: "Dourado - SP",
  },
  {
    id: 1623,
    label: "Douradoquara - MG",
  },
  {
    id: 1624,
    label: "Dourados - MS",
  },
  {
    id: 1625,
    label: "Doutor camargo - PR",
  },
  {
    id: 1626,
    label: "Doutor maurício cardoso - RS",
  },
  {
    id: 1627,
    label: "Doutor pedrinho - SC",
  },
  {
    id: 1628,
    label: "Doutor ricardo - RS",
  },
  {
    id: 1629,
    label: "Doutor severiano - RN",
  },
  {
    id: 1630,
    label: "Doutor ulysses - PR",
  },
  {
    id: 1631,
    label: "Doverlândia - GO",
  },
  {
    id: 1632,
    label: "Dracena - SP",
  },
  {
    id: 1633,
    label: "Duartina - SP",
  },
  {
    id: 1634,
    label: "Duas barras - RJ",
  },
  {
    id: 1635,
    label: "Duas estradas - PB",
  },
  {
    id: 1636,
    label: "Dueré - TO",
  },
  {
    id: 1637,
    label: "Dumont - SP",
  },
  {
    id: 1638,
    label: "Duque bacelar - MA",
  },
  {
    id: 1639,
    label: "Duque de caxias - RJ",
  },
  {
    id: 1640,
    label: "Durandé - MG",
  },
  {
    id: 1641,
    label: "Echaporã - SP",
  },
  {
    id: 1642,
    label: "Ecoporanga - ES",
  },
  {
    id: 1643,
    label: "Edealina - GO",
  },
  {
    id: 1644,
    label: "Edéia - GO",
  },
  {
    id: 1645,
    label: "Eirunepé - AM",
  },
  {
    id: 1646,
    label: "Eldorado - MS",
  },
  {
    id: 1647,
    label: "Eldorado - SP",
  },
  {
    id: 1648,
    label: "Eldorado do sul - RS",
  },
  {
    id: 1649,
    label: "Eldorado dos carajás - PA",
  },
  {
    id: 1650,
    label: "Elesbão veloso - PI",
  },
  {
    id: 1651,
    label: "Elias fausto - SP",
  },
  {
    id: 1652,
    label: "Eliseu martins - PI",
  },
  {
    id: 1653,
    label: "Elisiário - SP",
  },
  {
    id: 1654,
    label: "Elísio medrado - BA",
  },
  {
    id: 1655,
    label: "Elói mendes - MG",
  },
  {
    id: 1656,
    label: "Emas - PB",
  },
  {
    id: 1657,
    label: "Embaúba - SP",
  },
  {
    id: 1658,
    label: "Embu das artes - SP",
  },
  {
    id: 1659,
    label: "Embu-guaçu - SP",
  },
  {
    id: 1660,
    label: "Emilianópolis - SP",
  },
  {
    id: 1661,
    label: "Encantado - RS",
  },
  {
    id: 1662,
    label: "Encanto - RN",
  },
  {
    id: 1663,
    label: "Encruzilhada - BA",
  },
  {
    id: 1664,
    label: "Encruzilhada do sul - RS",
  },
  {
    id: 1665,
    label: "Enéas marques - PR",
  },
  {
    id: 1666,
    label: "Engenheiro beltrão - PR",
  },
  {
    id: 1667,
    label: "Engenheiro caldas - MG",
  },
  {
    id: 1668,
    label: "Engenheiro coelho - SP",
  },
  {
    id: 1669,
    label: "Engenheiro navarro - MG",
  },
  {
    id: 1670,
    label: "Engenheiro paulo de frontin - RJ",
  },
  {
    id: 1671,
    label: "Engenho velho - RS",
  },
  {
    id: 1672,
    label: "Entre folhas - MG",
  },
  {
    id: 1673,
    label: "Entre rios - BA",
  },
  {
    id: 1674,
    label: "Entre rios - SC",
  },
  {
    id: 1675,
    label: "Entre rios de minas - MG",
  },
  {
    id: 1676,
    label: "Entre rios do oeste - PR",
  },
  {
    id: 1677,
    label: "Entre rios do sul - RS",
  },
  {
    id: 1678,
    label: "Entre-ijuís - RS",
  },
  {
    id: 1679,
    label: "Envira - AM",
  },
  {
    id: 1680,
    label: "Epitaciolândia - AC",
  },
  {
    id: 1681,
    label: "Equador - RN",
  },
  {
    id: 1682,
    label: "Erebango - RS",
  },
  {
    id: 1683,
    label: "Erechim - RS",
  },
  {
    id: 1684,
    label: "Ereré - CE",
  },
  {
    id: 1685,
    label: "Érico cardoso - BA",
  },
  {
    id: 1686,
    label: "Ermo - SC",
  },
  {
    id: 1687,
    label: "Ernestina - RS",
  },
  {
    id: 1688,
    label: "Erval grande - RS",
  },
  {
    id: 1689,
    label: "Erval seco - RS",
  },
  {
    id: 1690,
    label: "Erval velho - SC",
  },
  {
    id: 1691,
    label: "Ervália - MG",
  },
  {
    id: 1692,
    label: "Escada - PE",
  },
  {
    id: 1693,
    label: "Esmeralda - RS",
  },
  {
    id: 1694,
    label: "Esmeraldas - MG",
  },
  {
    id: 1695,
    label: "Espera feliz - MG",
  },
  {
    id: 1696,
    label: "Esperança - PB",
  },
  {
    id: 1697,
    label: "Esperança do sul - RS",
  },
  {
    id: 1698,
    label: "Esperança nova - PR",
  },
  {
    id: 1699,
    label: "Esperantina - PI",
  },
  {
    id: 1700,
    label: "Esperantina - TO",
  },
  {
    id: 1701,
    label: "Esperantinópolis - MA",
  },
  {
    id: 1702,
    label: "Espigão alto do iguaçu - PR",
  },
  {
    id: 1703,
    label: "Espigão do oeste - RO",
  },
  {
    id: 1704,
    label: "Espinosa - MG",
  },
  {
    id: 1705,
    label: "Espírito santo - RN",
  },
  {
    id: 1706,
    label: "Espírito santo do dourado - MG",
  },
  {
    id: 1707,
    label: "Espírito santo do pinhal - SP",
  },
  {
    id: 1708,
    label: "Espírito santo do turvo - SP",
  },
  {
    id: 1709,
    label: "Esplanada - BA",
  },
  {
    id: 1710,
    label: "Espumoso - RS",
  },
  {
    id: 1711,
    label: "Estação - RS",
  },
  {
    id: 1712,
    label: "Estância - SE",
  },
  {
    id: 1713,
    label: "Estância velha - RS",
  },
  {
    id: 1714,
    label: "Esteio - RS",
  },
  {
    id: 1715,
    label: "Estiva - MG",
  },
  {
    id: 1716,
    label: "Estiva gerbi - SP",
  },
  {
    id: 1717,
    label: "Estreito - MA",
  },
  {
    id: 1718,
    label: "Estrela - RS",
  },
  {
    id: 1719,
    label: "Estrela d'oeste - SP",
  },
  {
    id: 1720,
    label: "Estrela dalva - MG",
  },
  {
    id: 1721,
    label: "Estrela de alagoas - AL",
  },
  {
    id: 1722,
    label: "Estrela do indaiá - MG",
  },
  {
    id: 1723,
    label: "Estrela do norte - GO",
  },
  {
    id: 1724,
    label: "Estrela do norte - SP",
  },
  {
    id: 1725,
    label: "Estrela do sul - MG",
  },
  {
    id: 1726,
    label: "Estrela velha - RS",
  },
  {
    id: 1727,
    label: "Euclides da cunha - BA",
  },
  {
    id: 1728,
    label: "Euclides da cunha paulista - SP",
  },
  {
    id: 1729,
    label: "Eugênio de castro - RS",
  },
  {
    id: 1730,
    label: "Eugenópolis - MG",
  },
  {
    id: 1731,
    label: "Eunápolis - BA",
  },
  {
    id: 1732,
    label: "Eusébio - CE",
  },
  {
    id: 1733,
    label: "Ewbank da câmara - MG",
  },
  {
    id: 1734,
    label: "Extrema - MG",
  },
  {
    id: 1735,
    label: "Extremoz - RN",
  },
  {
    id: 1736,
    label: "Exu - PE",
  },
  {
    id: 1737,
    label: "Fagundes - PB",
  },
  {
    id: 1738,
    label: "Fagundes varela - RS",
  },
  {
    id: 1739,
    label: "Faina - GO",
  },
  {
    id: 1740,
    label: "Fama - MG",
  },
  {
    id: 1741,
    label: "Faria lemos - MG",
  },
  {
    id: 1742,
    label: "Farias brito - CE",
  },
  {
    id: 1743,
    label: "Faro - PA",
  },
  {
    id: 1744,
    label: "Farol - PR",
  },
  {
    id: 1745,
    label: "Farroupilha - RS",
  },
  {
    id: 1746,
    label: "Fartura - SP",
  },
  {
    id: 1747,
    label: "Fartura do piauí - PI",
  },
  {
    id: 1748,
    label: "Fátima - BA",
  },
  {
    id: 1749,
    label: "Fátima - TO",
  },
  {
    id: 1750,
    label: "Fátima do sul - MS",
  },
  {
    id: 1751,
    label: "Faxinal - PR",
  },
  {
    id: 1752,
    label: "Faxinal do soturno - RS",
  },
  {
    id: 1753,
    label: "Faxinal dos guedes - SC",
  },
  {
    id: 1754,
    label: "Faxinalzinho - RS",
  },
  {
    id: 1755,
    label: "Fazenda nova - GO",
  },
  {
    id: 1756,
    label: "Fazenda rio grande - PR",
  },
  {
    id: 1757,
    label: "Fazenda vilanova - RS",
  },
  {
    id: 1758,
    label: "Feijó - AC",
  },
  {
    id: 1759,
    label: "Feira da mata - BA",
  },
  {
    id: 1760,
    label: "Feira de santana - BA",
  },
  {
    id: 1761,
    label: "Feira grande - AL",
  },
  {
    id: 1762,
    label: "Feira nova - PE",
  },
  {
    id: 1763,
    label: "Feira nova - SE",
  },
  {
    id: 1764,
    label: "Feira nova do maranhão - MA",
  },
  {
    id: 1765,
    label: "Felício dos santos - MG",
  },
  {
    id: 1766,
    label: "Felipe guerra - RN",
  },
  {
    id: 1767,
    label: "Felisburgo - MG",
  },
  {
    id: 1768,
    label: "Felixlândia - MG",
  },
  {
    id: 1769,
    label: "Feliz - RS",
  },
  {
    id: 1770,
    label: "Feliz deserto - AL",
  },
  {
    id: 1771,
    label: "Feliz natal - MT",
  },
  {
    id: 1772,
    label: "Fênix - PR",
  },
  {
    id: 1773,
    label: "Fernandes pinheiro - PR",
  },
  {
    id: 1774,
    label: "Fernandes tourinho - MG",
  },
  {
    id: 1775,
    label: "Fernando de noronha - PE",
  },
  {
    id: 1776,
    label: "Fernando falcão - MA",
  },
  {
    id: 1777,
    label: "Fernando pedroza - RN",
  },
  {
    id: 1778,
    label: "Fernando prestes - SP",
  },
  {
    id: 1779,
    label: "Fernandópolis - SP",
  },
  {
    id: 1780,
    label: "Fernão - SP",
  },
  {
    id: 1781,
    label: "Ferraz de vasconcelos - SP",
  },
  {
    id: 1782,
    label: "Ferreira gomes - AP",
  },
  {
    id: 1783,
    label: "Ferreiros - PE",
  },
  {
    id: 1784,
    label: "Ferros - MG",
  },
  {
    id: 1785,
    label: "Fervedouro - MG",
  },
  {
    id: 1786,
    label: "Figueira - PR",
  },
  {
    id: 1787,
    label: "Figueirão - MS",
  },
  {
    id: 1788,
    label: "Figueirópolis - TO",
  },
  {
    id: 1789,
    label: "Figueirópolis d'oeste - MT",
  },
  {
    id: 1790,
    label: "Filadélfia - BA",
  },
  {
    id: 1791,
    label: "Filadélfia - TO",
  },
  {
    id: 1792,
    label: "Firmino alves - BA",
  },
  {
    id: 1793,
    label: "Firminópolis - GO",
  },
  {
    id: 1794,
    label: "Flexeiras - AL",
  },
  {
    id: 1795,
    label: "Flor da serra do sul - PR",
  },
  {
    id: 1796,
    label: "Flor do sertão - SC",
  },
  {
    id: 1797,
    label: "Flora rica - SP",
  },
  {
    id: 1798,
    label: "Floraí - PR",
  },
  {
    id: 1799,
    label: "Florânia - RN",
  },
  {
    id: 1800,
    label: "Floreal - SP",
  },
  {
    id: 1801,
    label: "Flores - PE",
  },
  {
    id: 1802,
    label: "Flores da cunha - RS",
  },
  {
    id: 1803,
    label: "Flores de goiás - GO",
  },
  {
    id: 1804,
    label: "Flores do piauí - PI",
  },
  {
    id: 1805,
    label: "Floresta - PE",
  },
  {
    id: 1806,
    label: "Floresta - PR",
  },
  {
    id: 1807,
    label: "Floresta azul - BA",
  },
  {
    id: 1808,
    label: "Floresta do araguaia - PA",
  },
  {
    id: 1809,
    label: "Floresta do piauí - PI",
  },
  {
    id: 1810,
    label: "Florestal - MG",
  },
  {
    id: 1811,
    label: "Florestópolis - PR",
  },
  {
    id: 1812,
    label: "Floriano - PI",
  },
  {
    id: 1813,
    label: "Floriano peixoto - RS",
  },
  {
    id: 1814,
    label: "Florianópolis - SC",
  },
  {
    id: 1815,
    label: "Flórida - PR",
  },
  {
    id: 1816,
    label: "Flórida paulista - SP",
  },
  {
    id: 1817,
    label: "Florínea - SP",
  },
  {
    id: 1818,
    label: "Fonte boa - AM",
  },
  {
    id: 1819,
    label: "Fontoura xavier - RS",
  },
  {
    id: 1820,
    label: "Formiga - MG",
  },
  {
    id: 1821,
    label: "Formigueiro - RS",
  },
  {
    id: 1822,
    label: "Formosa - GO",
  },
  {
    id: 1823,
    label: "Formosa da serra negra - MA",
  },
  {
    id: 1824,
    label: "Formosa do oeste - PR",
  },
  {
    id: 1825,
    label: "Formosa do rio preto - BA",
  },
  {
    id: 1826,
    label: "Formosa do sul - SC",
  },
  {
    id: 1827,
    label: "Formoso - GO",
  },
  {
    id: 1828,
    label: "Formoso - MG",
  },
  {
    id: 1829,
    label: "Formoso do araguaia - TO",
  },
  {
    id: 1830,
    label: "Forquetinha - RS",
  },
  {
    id: 1831,
    label: "Forquilha - CE",
  },
  {
    id: 1832,
    label: "Forquilhinha - SC",
  },
  {
    id: 1833,
    label: "Fortaleza - CE",
  },
  {
    id: 1834,
    label: "Fortaleza de minas - MG",
  },
  {
    id: 1835,
    label: "Fortaleza dos nogueiras - MA",
  },
  {
    id: 1836,
    label: "Fortaleza dos valos - RS",
  },
  {
    id: 1837,
    label: "Fortim - CE",
  },
  {
    id: 1838,
    label: "Fortuna - MA",
  },
  {
    id: 1839,
    label: "Fortuna de minas - MG",
  },
  {
    id: 1840,
    label: "Foz do iguaçu - PR",
  },
  {
    id: 1841,
    label: "Foz do jordão - PR",
  },
  {
    id: 1842,
    label: "Fraiburgo - SC",
  },
  {
    id: 1843,
    label: "Franca - SP",
  },
  {
    id: 1844,
    label: "Francinópolis - PI",
  },
  {
    id: 1845,
    label: "Francisco alves - PR",
  },
  {
    id: 1846,
    label: "Francisco ayres - PI",
  },
  {
    id: 1847,
    label: "Francisco badaró - MG",
  },
  {
    id: 1848,
    label: "Francisco beltrão - PR",
  },
  {
    id: 1849,
    label: "Francisco dantas - RN",
  },
  {
    id: 1850,
    label: "Francisco dumont - MG",
  },
  {
    id: 1851,
    label: "Francisco macedo - PI",
  },
  {
    id: 1852,
    label: "Francisco morato - SP",
  },
  {
    id: 1853,
    label: "Francisco sá - MG",
  },
  {
    id: 1854,
    label: "Francisco santos - PI",
  },
  {
    id: 1855,
    label: "Franciscópolis - MG",
  },
  {
    id: 1856,
    label: "Franco da rocha - SP",
  },
  {
    id: 1857,
    label: "Frecheirinha - CE",
  },
  {
    id: 1858,
    label: "Frederico westphalen - RS",
  },
  {
    id: 1859,
    label: "Frei gaspar - MG",
  },
  {
    id: 1860,
    label: "Frei inocêncio - MG",
  },
  {
    id: 1861,
    label: "Frei lagonegro - MG",
  },
  {
    id: 1862,
    label: "Frei martinho - PB",
  },
  {
    id: 1863,
    label: "Frei miguelinho - PE",
  },
  {
    id: 1864,
    label: "Frei paulo - SE",
  },
  {
    id: 1865,
    label: "Frei rogério - SC",
  },
  {
    id: 1866,
    label: "Fronteira - MG",
  },
  {
    id: 1867,
    label: "Fronteira dos vales - MG",
  },
  {
    id: 1868,
    label: "Fronteiras - PI",
  },
  {
    id: 1869,
    label: "Fruta de leite - MG",
  },
  {
    id: 1870,
    label: "Frutal - MG",
  },
  {
    id: 1871,
    label: "Frutuoso gomes - RN",
  },
  {
    id: 1872,
    label: "Fundão - ES",
  },
  {
    id: 1873,
    label: "Funilândia - MG",
  },
  {
    id: 1874,
    label: "Gabriel monteiro - SP",
  },
  {
    id: 1875,
    label: "Gado bravo - PB",
  },
  {
    id: 1876,
    label: "Gália - SP",
  },
  {
    id: 1877,
    label: "Galiléia - MG",
  },
  {
    id: 1878,
    label: "Galinhos - RN",
  },
  {
    id: 1879,
    label: "Galvão - SC",
  },
  {
    id: 1880,
    label: "Gameleira - PE",
  },
  {
    id: 1881,
    label: "Gameleira de goiás - GO",
  },
  {
    id: 1882,
    label: "Gameleiras - MG",
  },
  {
    id: 1883,
    label: "Gandu - BA",
  },
  {
    id: 1884,
    label: "Garanhuns - PE",
  },
  {
    id: 1885,
    label: "Gararu - SE",
  },
  {
    id: 1886,
    label: "Garça - SP",
  },
  {
    id: 1887,
    label: "Garibaldi - RS",
  },
  {
    id: 1888,
    label: "Garopaba - SC",
  },
  {
    id: 1889,
    label: "Garrafão do norte - PA",
  },
  {
    id: 1890,
    label: "Garruchos - RS",
  },
  {
    id: 1891,
    label: "Garuva - SC",
  },
  {
    id: 1892,
    label: "Gaspar - SC",
  },
  {
    id: 1893,
    label: "Gastão vidigal - SP",
  },
  {
    id: 1894,
    label: "Gaúcha do norte - MT",
  },
  {
    id: 1895,
    label: "Gaurama - RS",
  },
  {
    id: 1896,
    label: "Gavião - BA",
  },
  {
    id: 1897,
    label: "Gavião peixoto - SP",
  },
  {
    id: 1898,
    label: "Geminiano - PI",
  },
  {
    id: 1899,
    label: "General câmara - RS",
  },
  {
    id: 1900,
    label: "General carneiro - MT",
  },
  {
    id: 1901,
    label: "General carneiro - PR",
  },
  {
    id: 1902,
    label: "General maynard - SE",
  },
  {
    id: 1903,
    label: "General salgado - SP",
  },
  {
    id: 1904,
    label: "General sampaio - CE",
  },
  {
    id: 1905,
    label: "Gentil - RS",
  },
  {
    id: 1906,
    label: "Gentio do ouro - BA",
  },
  {
    id: 1907,
    label: "Getulina - SP",
  },
  {
    id: 1908,
    label: "Getúlio vargas - RS",
  },
  {
    id: 1909,
    label: "Gilbués - PI",
  },
  {
    id: 1910,
    label: "Girau do ponciano - AL",
  },
  {
    id: 1911,
    label: "Giruá - RS",
  },
  {
    id: 1912,
    label: "Glaucilândia - MG",
  },
  {
    id: 1913,
    label: "Glicério - SP",
  },
  {
    id: 1914,
    label: "Glória - BA",
  },
  {
    id: 1915,
    label: "Glória d'oeste - MT",
  },
  {
    id: 1916,
    label: "Glória de dourados - MS",
  },
  {
    id: 1917,
    label: "Glória do goitá - PE",
  },
  {
    id: 1918,
    label: "Glorinha - RS",
  },
  {
    id: 1919,
    label: "Godofredo viana - MA",
  },
  {
    id: 1920,
    label: "Godoy moreira - PR",
  },
  {
    id: 1921,
    label: "Goiabeira - MG",
  },
  {
    id: 1922,
    label: "Goiana - PE",
  },
  {
    id: 1923,
    label: "Goianá - MG",
  },
  {
    id: 1924,
    label: "Goianápolis - GO",
  },
  {
    id: 1925,
    label: "Goiandira - GO",
  },
  {
    id: 1926,
    label: "Goianésia - GO",
  },
  {
    id: 1927,
    label: "Goianésia do pará - PA",
  },
  {
    id: 1928,
    label: "Goiânia - GO",
  },
  {
    id: 1929,
    label: "Goianinha - RN",
  },
  {
    id: 1930,
    label: "Goianira - GO",
  },
  {
    id: 1931,
    label: "Goianorte - TO",
  },
  {
    id: 1932,
    label: "Goiás - GO",
  },
  {
    id: 1933,
    label: "Goiatins - TO",
  },
  {
    id: 1934,
    label: "Goiatuba - GO",
  },
  {
    id: 1935,
    label: "Goioerê - PR",
  },
  {
    id: 1936,
    label: "Goioxim - PR",
  },
  {
    id: 1937,
    label: "Gonçalves - MG",
  },
  {
    id: 1938,
    label: "Gonçalves dias - MA",
  },
  {
    id: 1939,
    label: "Gongogi - BA",
  },
  {
    id: 1940,
    label: "Gonzaga - MG",
  },
  {
    id: 1941,
    label: "Gouveia - MG",
  },
  {
    id: 1942,
    label: "Gouvelândia - GO",
  },
  {
    id: 1943,
    label: "Governador archer - MA",
  },
  {
    id: 1944,
    label: "Governador celso ramos - SC",
  },
  {
    id: 1945,
    label: "Governador dix-sept rosado - RN",
  },
  {
    id: 1946,
    label: "Governador edison lobão - MA",
  },
  {
    id: 1947,
    label: "Governador eugênio barros - MA",
  },
  {
    id: 1948,
    label: "Governador jorge teixeira - RO",
  },
  {
    id: 1949,
    label: "Governador lindenberg - ES",
  },
  {
    id: 1950,
    label: "Governador luiz rocha - MA",
  },
  {
    id: 1951,
    label: "Governador mangabeira - BA",
  },
  {
    id: 1952,
    label: "Governador newton bello - MA",
  },
  {
    id: 1953,
    label: "Governador nunes freire - MA",
  },
  {
    id: 1954,
    label: "Governador valadares - MG",
  },
  {
    id: 1955,
    label: "Graça - CE",
  },
  {
    id: 1956,
    label: "Graça aranha - MA",
  },
  {
    id: 1957,
    label: "Graccho cardoso - SE",
  },
  {
    id: 1958,
    label: "Grajaú - MA",
  },
  {
    id: 1959,
    label: "Gramado - RS",
  },
  {
    id: 1960,
    label: "Gramado dos loureiros - RS",
  },
  {
    id: 1961,
    label: "Gramado xavier - RS",
  },
  {
    id: 1962,
    label: "Grandes rios - PR",
  },
  {
    id: 1963,
    label: "Granito - PE",
  },
  {
    id: 1964,
    label: "Granja - CE",
  },
  {
    id: 1965,
    label: "Granjeiro - CE",
  },
  {
    id: 1966,
    label: "Grão mogol - MG",
  },
  {
    id: 1967,
    label: "Grão-pará - SC",
  },
  {
    id: 1968,
    label: "Gravatá - PE",
  },
  {
    id: 1969,
    label: "Gravataí - RS",
  },
  {
    id: 1970,
    label: "Gravatal - SC",
  },
  {
    id: 1971,
    label: "Groaíras - CE",
  },
  {
    id: 1972,
    label: "Grossos - RN",
  },
  {
    id: 1973,
    label: "Grupiara - MG",
  },
  {
    id: 1974,
    label: "Guabiju - RS",
  },
  {
    id: 1975,
    label: "Guabiruba - SC",
  },
  {
    id: 1976,
    label: "Guaçuí - ES",
  },
  {
    id: 1977,
    label: "Guadalupe - PI",
  },
  {
    id: 1978,
    label: "Guaíba - RS",
  },
  {
    id: 1979,
    label: "Guaiçara - SP",
  },
  {
    id: 1980,
    label: "Guaimbê - SP",
  },
  {
    id: 1981,
    label: "Guaíra - PR",
  },
  {
    id: 1982,
    label: "Guaíra - SP",
  },
  {
    id: 1983,
    label: "Guairaçá - PR",
  },
  {
    id: 1984,
    label: "Guaiúba - CE",
  },
  {
    id: 1985,
    label: "Guajará - AM",
  },
  {
    id: 1986,
    label: "Guajará-mirim - RO",
  },
  {
    id: 1987,
    label: "Guajeru - BA",
  },
  {
    id: 1988,
    label: "Guamaré - RN",
  },
  {
    id: 1989,
    label: "Guamiranga - PR",
  },
  {
    id: 1990,
    label: "Guanambi - BA",
  },
  {
    id: 1991,
    label: "Guanhães - MG",
  },
  {
    id: 1992,
    label: "Guapé - MG",
  },
  {
    id: 1993,
    label: "Guapiaçu - SP",
  },
  {
    id: 1994,
    label: "Guapiara - SP",
  },
  {
    id: 1995,
    label: "Guapimirim - RJ",
  },
  {
    id: 1996,
    label: "Guapirama - PR",
  },
  {
    id: 1997,
    label: "Guapó - GO",
  },
  {
    id: 1998,
    label: "Guaporé - RS",
  },
  {
    id: 1999,
    label: "Guaporema - PR",
  },
  {
    id: 2000,
    label: "Guará - SP",
  },
  {
    id: 2001,
    label: "Guarabira - PB",
  },
  {
    id: 2002,
    label: "Guaraçaí - SP",
  },
  {
    id: 2003,
    label: "Guaraci - PR",
  },
  {
    id: 2004,
    label: "Guaraci - SP",
  },
  {
    id: 2005,
    label: "Guaraciaba - MG",
  },
  {
    id: 2006,
    label: "Guaraciaba - SC",
  },
  {
    id: 2007,
    label: "Guaraciaba do norte - CE",
  },
  {
    id: 2008,
    label: "Guaraciama - MG",
  },
  {
    id: 2009,
    label: "Guaraí - TO",
  },
  {
    id: 2010,
    label: "Guaraíta - GO",
  },
  {
    id: 2011,
    label: "Guaramiranga - CE",
  },
  {
    id: 2012,
    label: "Guaramirim - SC",
  },
  {
    id: 2013,
    label: "Guaranésia - MG",
  },
  {
    id: 2014,
    label: "Guarani - MG",
  },
  {
    id: 2015,
    label: "Guarani d'oeste - SP",
  },
  {
    id: 2016,
    label: "Guarani das missões - RS",
  },
  {
    id: 2017,
    label: "Guarani de goiás - GO",
  },
  {
    id: 2018,
    label: "Guaraniaçu - PR",
  },
  {
    id: 2019,
    label: "Guarantã - SP",
  },
  {
    id: 2020,
    label: "Guarantã do norte - MT",
  },
  {
    id: 2021,
    label: "Guarapari - ES",
  },
  {
    id: 2022,
    label: "Guarapuava - PR",
  },
  {
    id: 2023,
    label: "Guaraqueçaba - PR",
  },
  {
    id: 2024,
    label: "Guarará - MG",
  },
  {
    id: 2025,
    label: "Guararapes - SP",
  },
  {
    id: 2026,
    label: "Guararema - SP",
  },
  {
    id: 2027,
    label: "Guaratinga - BA",
  },
  {
    id: 2028,
    label: "Guaratinguetá - SP",
  },
  {
    id: 2029,
    label: "Guaratuba - PR",
  },
  {
    id: 2030,
    label: "Guarda-mor - MG",
  },
  {
    id: 2031,
    label: "Guareí - SP",
  },
  {
    id: 2032,
    label: "Guariba - SP",
  },
  {
    id: 2033,
    label: "Guaribas - PI",
  },
  {
    id: 2034,
    label: "Guarinos - GO",
  },
  {
    id: 2035,
    label: "Guarujá - SP",
  },
  {
    id: 2036,
    label: "Guarujá do sul - SC",
  },
  {
    id: 2037,
    label: "Guarulhos - SP",
  },
  {
    id: 2038,
    label: "Guatambú - SC",
  },
  {
    id: 2039,
    label: "Guatapará - SP",
  },
  {
    id: 2040,
    label: "Guaxupé - MG",
  },
  {
    id: 2041,
    label: "Guia lopes da laguna - MS",
  },
  {
    id: 2042,
    label: "Guidoval - MG",
  },
  {
    id: 2043,
    label: "Guimarães - MA",
  },
  {
    id: 2044,
    label: "Guimarânia - MG",
  },
  {
    id: 2045,
    label: "Guiratinga - MT",
  },
  {
    id: 2046,
    label: "Guiricema - MG",
  },
  {
    id: 2047,
    label: "Gurinhatã - MG",
  },
  {
    id: 2048,
    label: "Gurinhém - PB",
  },
  {
    id: 2049,
    label: "Gurjão - PB",
  },
  {
    id: 2050,
    label: "Gurupá - PA",
  },
  {
    id: 2051,
    label: "Gurupi - TO",
  },
  {
    id: 2052,
    label: "Guzolândia - SP",
  },
  {
    id: 2053,
    label: "Harmonia - RS",
  },
  {
    id: 2054,
    label: "Heitoraí - GO",
  },
  {
    id: 2055,
    label: "Heliodora - MG",
  },
  {
    id: 2056,
    label: "Heliópolis - BA",
  },
  {
    id: 2057,
    label: "Herculândia - SP",
  },
  {
    id: 2058,
    label: "Herval - RS",
  },
  {
    id: 2059,
    label: "Herval d'oeste - SC",
  },
  {
    id: 2060,
    label: "Herveiras - RS",
  },
  {
    id: 2061,
    label: "Hidrolândia - CE",
  },
  {
    id: 2062,
    label: "Hidrolândia - GO",
  },
  {
    id: 2063,
    label: "Hidrolina - GO",
  },
  {
    id: 2064,
    label: "Holambra - SP",
  },
  {
    id: 2065,
    label: "Honório serpa - PR",
  },
  {
    id: 2066,
    label: "Horizonte - CE",
  },
  {
    id: 2067,
    label: "Horizontina - RS",
  },
  {
    id: 2068,
    label: "Hortolândia - SP",
  },
  {
    id: 2069,
    label: "Hugo napoleão - PI",
  },
  {
    id: 2070,
    label: "Hulha negra - RS",
  },
  {
    id: 2071,
    label: "Humaitá - AM",
  },
  {
    id: 2072,
    label: "Humaitá - RS",
  },
  {
    id: 2073,
    label: "Humberto de campos - MA",
  },
  {
    id: 2074,
    label: "Iacanga - SP",
  },
  {
    id: 2075,
    label: "Iaciara - GO",
  },
  {
    id: 2076,
    label: "Iacri - SP",
  },
  {
    id: 2077,
    label: "Iaçu - BA",
  },
  {
    id: 2078,
    label: "Iapu - MG",
  },
  {
    id: 2079,
    label: "Iaras - SP",
  },
  {
    id: 2080,
    label: "Iati - PE",
  },
  {
    id: 2081,
    label: "Ibaiti - PR",
  },
  {
    id: 2082,
    label: "Ibarama - RS",
  },
  {
    id: 2083,
    label: "Ibaretama - CE",
  },
  {
    id: 2084,
    label: "Ibaté - SP",
  },
  {
    id: 2085,
    label: "Ibateguara - AL",
  },
  {
    id: 2086,
    label: "Ibatiba - ES",
  },
  {
    id: 2087,
    label: "Ibema - PR",
  },
  {
    id: 2088,
    label: "Ibertioga - MG",
  },
  {
    id: 2089,
    label: "Ibiá - MG",
  },
  {
    id: 2090,
    label: "Ibiaçá - RS",
  },
  {
    id: 2091,
    label: "Ibiaí - MG",
  },
  {
    id: 2092,
    label: "Ibiam - SC",
  },
  {
    id: 2093,
    label: "Ibiapina - CE",
  },
  {
    id: 2094,
    label: "Ibiara - PB",
  },
  {
    id: 2095,
    label: "Ibiassucê - BA",
  },
  {
    id: 2096,
    label: "Ibicaraí - BA",
  },
  {
    id: 2097,
    label: "Ibicaré - SC",
  },
  {
    id: 2098,
    label: "Ibicoara - BA",
  },
  {
    id: 2099,
    label: "Ibicuí - BA",
  },
  {
    id: 2100,
    label: "Ibicuitinga - CE",
  },
  {
    id: 2101,
    label: "Ibimirim - PE",
  },
  {
    id: 2102,
    label: "Ibipeba - BA",
  },
  {
    id: 2103,
    label: "Ibipitanga - BA",
  },
  {
    id: 2104,
    label: "Ibiporã - PR",
  },
  {
    id: 2105,
    label: "Ibiquera - BA",
  },
  {
    id: 2106,
    label: "Ibirá - SP",
  },
  {
    id: 2107,
    label: "Ibiracatu - MG",
  },
  {
    id: 2108,
    label: "Ibiraci - MG",
  },
  {
    id: 2109,
    label: "Ibiraçu - ES",
  },
  {
    id: 2110,
    label: "Ibiraiaras - RS",
  },
  {
    id: 2111,
    label: "Ibirajuba - PE",
  },
  {
    id: 2112,
    label: "Ibirama - SC",
  },
  {
    id: 2113,
    label: "Ibirapitanga - BA",
  },
  {
    id: 2114,
    label: "Ibirapuã - BA",
  },
  {
    id: 2115,
    label: "Ibirapuitã - RS",
  },
  {
    id: 2116,
    label: "Ibirarema - SP",
  },
  {
    id: 2117,
    label: "Ibirataia - BA",
  },
  {
    id: 2118,
    label: "Ibirité - MG",
  },
  {
    id: 2119,
    label: "Ibirubá - RS",
  },
  {
    id: 2120,
    label: "Ibitiara - BA",
  },
  {
    id: 2121,
    label: "Ibitinga - SP",
  },
  {
    id: 2122,
    label: "Ibitirama - ES",
  },
  {
    id: 2123,
    label: "Ibititá - BA",
  },
  {
    id: 2124,
    label: "Ibitiúra de minas - MG",
  },
  {
    id: 2125,
    label: "Ibituruna - MG",
  },
  {
    id: 2126,
    label: "Ibiúna - SP",
  },
  {
    id: 2127,
    label: "Ibotirama - BA",
  },
  {
    id: 2128,
    label: "Icapuí - CE",
  },
  {
    id: 2129,
    label: "Içara - SC",
  },
  {
    id: 2130,
    label: "Icaraí de minas - MG",
  },
  {
    id: 2131,
    label: "Icaraíma - PR",
  },
  {
    id: 2132,
    label: "Icatu - MA",
  },
  {
    id: 2133,
    label: "Icém - SP",
  },
  {
    id: 2134,
    label: "Ichu - BA",
  },
  {
    id: 2135,
    label: "Icó - CE",
  },
  {
    id: 2136,
    label: "Iconha - ES",
  },
  {
    id: 2137,
    label: "Ielmo marinho - RN",
  },
  {
    id: 2138,
    label: "Iepê - SP",
  },
  {
    id: 2139,
    label: "Igaci - AL",
  },
  {
    id: 2140,
    label: "Igaporã - BA",
  },
  {
    id: 2141,
    label: "Igaraçu do tietê - SP",
  },
  {
    id: 2142,
    label: "Igaracy - PB",
  },
  {
    id: 2143,
    label: "Igarapava - SP",
  },
  {
    id: 2144,
    label: "Igarapé - MG",
  },
  {
    id: 2145,
    label: "Igarapé do meio - MA",
  },
  {
    id: 2146,
    label: "Igarapé grande - MA",
  },
  {
    id: 2147,
    label: "Igarapé-açu - PA",
  },
  {
    id: 2148,
    label: "Igarapé-miri - PA",
  },
  {
    id: 2149,
    label: "Igarassu - PE",
  },
  {
    id: 2150,
    label: "Igaratá - SP",
  },
  {
    id: 2151,
    label: "Igaratinga - MG",
  },
  {
    id: 2152,
    label: "Igrapiúna - BA",
  },
  {
    id: 2153,
    label: "Igreja nova - AL",
  },
  {
    id: 2154,
    label: "Igrejinha - RS",
  },
  {
    id: 2155,
    label: "Iguaba grande - RJ",
  },
  {
    id: 2156,
    label: "Iguaí - BA",
  },
  {
    id: 2157,
    label: "Iguape - SP",
  },
  {
    id: 2158,
    label: "Iguaraçu - PR",
  },
  {
    id: 2159,
    label: "Iguaracy - PE",
  },
  {
    id: 2160,
    label: "Iguatama - MG",
  },
  {
    id: 2161,
    label: "Iguatemi - MS",
  },
  {
    id: 2162,
    label: "Iguatu - CE",
  },
  {
    id: 2163,
    label: "Iguatu - PR",
  },
  {
    id: 2164,
    label: "Ijaci - MG",
  },
  {
    id: 2165,
    label: "Ijuí - RS",
  },
  {
    id: 2166,
    label: "Ilha comprida - SP",
  },
  {
    id: 2167,
    label: "Ilha das flores - SE",
  },
  {
    id: 2168,
    label: "Ilha de itamaracá - PE",
  },
  {
    id: 2169,
    label: "Ilha grande - PI",
  },
  {
    id: 2170,
    label: "Ilha solteira - SP",
  },
  {
    id: 2171,
    label: "Ilhabela - SP",
  },
  {
    id: 2172,
    label: "Ilhéus - BA",
  },
  {
    id: 2173,
    label: "Ilhota - SC",
  },
  {
    id: 2174,
    label: "Ilicínea - MG",
  },
  {
    id: 2175,
    label: "Ilópolis - RS",
  },
  {
    id: 2176,
    label: "Imaculada - PB",
  },
  {
    id: 2177,
    label: "Imaruí - SC",
  },
  {
    id: 2178,
    label: "Imbaú - PR",
  },
  {
    id: 2179,
    label: "Imbé - RS",
  },
  {
    id: 2180,
    label: "Imbé de minas - MG",
  },
  {
    id: 2181,
    label: "Imbituba - SC",
  },
  {
    id: 2182,
    label: "Imbituva - PR",
  },
  {
    id: 2183,
    label: "Imbuia - SC",
  },
  {
    id: 2184,
    label: "Imigrante - RS",
  },
  {
    id: 2185,
    label: "Imperatriz - MA",
  },
  {
    id: 2186,
    label: "Inácio martins - PR",
  },
  {
    id: 2187,
    label: "Inaciolândia - GO",
  },
  {
    id: 2188,
    label: "Inajá - PE",
  },
  {
    id: 2189,
    label: "Inajá - PR",
  },
  {
    id: 2190,
    label: "Inconfidentes - MG",
  },
  {
    id: 2191,
    label: "Indaiabira - MG",
  },
  {
    id: 2192,
    label: "Indaial - SC",
  },
  {
    id: 2193,
    label: "Indaiatuba - SP",
  },
  {
    id: 2194,
    label: "Independência - CE",
  },
  {
    id: 2195,
    label: "Independência - RS",
  },
  {
    id: 2196,
    label: "Indiana - SP",
  },
  {
    id: 2197,
    label: "Indianópolis - MG",
  },
  {
    id: 2198,
    label: "Indianópolis - PR",
  },
  {
    id: 2199,
    label: "Indiaporã - SP",
  },
  {
    id: 2200,
    label: "Indiara - GO",
  },
  {
    id: 2201,
    label: "Indiaroba - SE",
  },
  {
    id: 2202,
    label: "Indiavaí - MT",
  },
  {
    id: 2203,
    label: "Ingá - PB",
  },
  {
    id: 2204,
    label: "Ingaí - MG",
  },
  {
    id: 2205,
    label: "Ingazeira - PE",
  },
  {
    id: 2206,
    label: "Inhacorá - RS",
  },
  {
    id: 2207,
    label: "Inhambupe - BA",
  },
  {
    id: 2208,
    label: "Inhangapi - PA",
  },
  {
    id: 2209,
    label: "Inhapi - AL",
  },
  {
    id: 2210,
    label: "Inhapim - MG",
  },
  {
    id: 2211,
    label: "Inhaúma - MG",
  },
  {
    id: 2212,
    label: "Inhuma - PI",
  },
  {
    id: 2213,
    label: "Inhumas - GO",
  },
  {
    id: 2214,
    label: "Inimutaba - MG",
  },
  {
    id: 2215,
    label: "Inocência - MS",
  },
  {
    id: 2216,
    label: "Inúbia paulista - SP",
  },
  {
    id: 2217,
    label: "Iomerê - SC",
  },
  {
    id: 2218,
    label: "Ipaba - MG",
  },
  {
    id: 2219,
    label: "Ipameri - GO",
  },
  {
    id: 2220,
    label: "Ipanema - MG",
  },
  {
    id: 2221,
    label: "Ipanguaçu - RN",
  },
  {
    id: 2222,
    label: "Ipaporanga - CE",
  },
  {
    id: 2223,
    label: "Ipatinga - MG",
  },
  {
    id: 2224,
    label: "Ipaumirim - CE",
  },
  {
    id: 2225,
    label: "Ipaussu - SP",
  },
  {
    id: 2226,
    label: "Ipê - RS",
  },
  {
    id: 2227,
    label: "Ipecaetá - BA",
  },
  {
    id: 2228,
    label: "Iperó - SP",
  },
  {
    id: 2229,
    label: "Ipeúna - SP",
  },
  {
    id: 2230,
    label: "Ipiaçu - MG",
  },
  {
    id: 2231,
    label: "Ipiaú - BA",
  },
  {
    id: 2232,
    label: "Ipiguá - SP",
  },
  {
    id: 2233,
    label: "Ipira - SC",
  },
  {
    id: 2234,
    label: "Ipirá - BA",
  },
  {
    id: 2235,
    label: "Ipiranga - PR",
  },
  {
    id: 2236,
    label: "Ipiranga de goiás - GO",
  },
  {
    id: 2237,
    label: "Ipiranga do norte - MT",
  },
  {
    id: 2238,
    label: "Ipiranga do piauí - PI",
  },
  {
    id: 2239,
    label: "Ipiranga do sul - RS",
  },
  {
    id: 2240,
    label: "Ipixuna - AM",
  },
  {
    id: 2241,
    label: "Ipixuna do pará - PA",
  },
  {
    id: 2242,
    label: "Ipojuca - PE",
  },
  {
    id: 2243,
    label: "Iporá - GO",
  },
  {
    id: 2244,
    label: "Iporã - PR",
  },
  {
    id: 2245,
    label: "Iporã do oeste - SC",
  },
  {
    id: 2246,
    label: "Iporanga - SP",
  },
  {
    id: 2247,
    label: "Ipu - CE",
  },
  {
    id: 2248,
    label: "Ipuã - SP",
  },
  {
    id: 2249,
    label: "Ipuaçu - SC",
  },
  {
    id: 2250,
    label: "Ipubi - PE",
  },
  {
    id: 2251,
    label: "Ipueira - RN",
  },
  {
    id: 2252,
    label: "Ipueiras - CE",
  },
  {
    id: 2253,
    label: "Ipueiras - TO",
  },
  {
    id: 2254,
    label: "Ipuiúna - MG",
  },
  {
    id: 2255,
    label: "Ipumirim - SC",
  },
  {
    id: 2256,
    label: "Ipupiara - BA",
  },
  {
    id: 2257,
    label: "Iracema - CE",
  },
  {
    id: 2258,
    label: "Iracema - RR",
  },
  {
    id: 2259,
    label: "Iracema do oeste - PR",
  },
  {
    id: 2260,
    label: "Iracemápolis - SP",
  },
  {
    id: 2261,
    label: "Iraceminha - SC",
  },
  {
    id: 2262,
    label: "Iraí - RS",
  },
  {
    id: 2263,
    label: "Iraí de minas - MG",
  },
  {
    id: 2264,
    label: "Irajuba - BA",
  },
  {
    id: 2265,
    label: "Iramaia - BA",
  },
  {
    id: 2266,
    label: "Iranduba - AM",
  },
  {
    id: 2267,
    label: "Irani - SC",
  },
  {
    id: 2268,
    label: "Irapuã - SP",
  },
  {
    id: 2269,
    label: "Irapuru - SP",
  },
  {
    id: 2270,
    label: "Iraquara - BA",
  },
  {
    id: 2271,
    label: "Irará - BA",
  },
  {
    id: 2272,
    label: "Irati - PR",
  },
  {
    id: 2273,
    label: "Irati - SC",
  },
  {
    id: 2274,
    label: "Irauçuba - CE",
  },
  {
    id: 2275,
    label: "Irecê - BA",
  },
  {
    id: 2276,
    label: "Iretama - PR",
  },
  {
    id: 2277,
    label: "Irineópolis - SC",
  },
  {
    id: 2278,
    label: "Irituia - PA",
  },
  {
    id: 2279,
    label: "Irupi - ES",
  },
  {
    id: 2280,
    label: "Isaías coelho - PI",
  },
  {
    id: 2281,
    label: "Israelândia - GO",
  },
  {
    id: 2282,
    label: "Itá - SC",
  },
  {
    id: 2283,
    label: "Itaara - RS",
  },
  {
    id: 2284,
    label: "Itabaiana - PB",
  },
  {
    id: 2285,
    label: "Itabaiana - SE",
  },
  {
    id: 2286,
    label: "Itabaianinha - SE",
  },
  {
    id: 2287,
    label: "Itabela - BA",
  },
  {
    id: 2288,
    label: "Itaberá - SP",
  },
  {
    id: 2289,
    label: "Itaberaba - BA",
  },
  {
    id: 2290,
    label: "Itaberaí - GO",
  },
  {
    id: 2291,
    label: "Itabi - SE",
  },
  {
    id: 2292,
    label: "Itabira - MG",
  },
  {
    id: 2293,
    label: "Itabirinha - MG",
  },
  {
    id: 2294,
    label: "Itabirito - MG",
  },
  {
    id: 2295,
    label: "Itaboraí - RJ",
  },
  {
    id: 2296,
    label: "Itabuna - BA",
  },
  {
    id: 2297,
    label: "Itacajá - TO",
  },
  {
    id: 2298,
    label: "Itacambira - MG",
  },
  {
    id: 2299,
    label: "Itacarambi - MG",
  },
  {
    id: 2300,
    label: "Itacaré - BA",
  },
  {
    id: 2301,
    label: "Itacoatiara - AM",
  },
  {
    id: 2302,
    label: "Itacuruba - PE",
  },
  {
    id: 2303,
    label: "Itacurubi - RS",
  },
  {
    id: 2304,
    label: "Itaeté - BA",
  },
  {
    id: 2305,
    label: "Itagi - BA",
  },
  {
    id: 2306,
    label: "Itagibá - BA",
  },
  {
    id: 2307,
    label: "Itagimirim - BA",
  },
  {
    id: 2308,
    label: "Itaguaçu - ES",
  },
  {
    id: 2309,
    label: "Itaguaçu da bahia - BA",
  },
  {
    id: 2310,
    label: "Itaguaí - RJ",
  },
  {
    id: 2311,
    label: "Itaguajé - PR",
  },
  {
    id: 2312,
    label: "Itaguara - MG",
  },
  {
    id: 2313,
    label: "Itaguari - GO",
  },
  {
    id: 2314,
    label: "Itaguaru - GO",
  },
  {
    id: 2315,
    label: "Itaguatins - TO",
  },
  {
    id: 2316,
    label: "Itaí - SP",
  },
  {
    id: 2317,
    label: "Itaíba - PE",
  },
  {
    id: 2318,
    label: "Itaiçaba - CE",
  },
  {
    id: 2319,
    label: "Itainópolis - PI",
  },
  {
    id: 2320,
    label: "Itaiópolis - SC",
  },
  {
    id: 2321,
    label: "Itaipava do grajaú - MA",
  },
  {
    id: 2322,
    label: "Itaipé - MG",
  },
  {
    id: 2323,
    label: "Itaipulândia - PR",
  },
  {
    id: 2324,
    label: "Itaitinga - CE",
  },
  {
    id: 2325,
    label: "Itaituba - PA",
  },
  {
    id: 2326,
    label: "Itajá - GO",
  },
  {
    id: 2327,
    label: "Itajá - RN",
  },
  {
    id: 2328,
    label: "Itajaí - SC",
  },
  {
    id: 2329,
    label: "Itajobi - SP",
  },
  {
    id: 2330,
    label: "Itaju - SP",
  },
  {
    id: 2331,
    label: "Itaju do colônia - BA",
  },
  {
    id: 2332,
    label: "Itajubá - MG",
  },
  {
    id: 2333,
    label: "Itajuípe - BA",
  },
  {
    id: 2334,
    label: "Italva - RJ",
  },
  {
    id: 2335,
    label: "Itamaraju - BA",
  },
  {
    id: 2336,
    label: "Itamarandiba - MG",
  },
  {
    id: 2337,
    label: "Itamarati - AM",
  },
  {
    id: 2338,
    label: "Itamarati de minas - MG",
  },
  {
    id: 2339,
    label: "Itamari - BA",
  },
  {
    id: 2340,
    label: "Itambacuri - MG",
  },
  {
    id: 2341,
    label: "Itambaracá - PR",
  },
  {
    id: 2342,
    label: "Itambé - BA",
  },
  {
    id: 2343,
    label: "Itambé - PE",
  },
  {
    id: 2344,
    label: "Itambé - PR",
  },
  {
    id: 2345,
    label: "Itambé do mato dentro - MG",
  },
  {
    id: 2346,
    label: "Itamogi - MG",
  },
  {
    id: 2347,
    label: "Itamonte - MG",
  },
  {
    id: 2348,
    label: "Itanagra - BA",
  },
  {
    id: 2349,
    label: "Itanhaém - SP",
  },
  {
    id: 2350,
    label: "Itanhandu - MG",
  },
  {
    id: 2351,
    label: "Itanhangá - MT",
  },
  {
    id: 2352,
    label: "Itanhém - BA",
  },
  {
    id: 2353,
    label: "Itanhomi - MG",
  },
  {
    id: 2354,
    label: "Itaobim - MG",
  },
  {
    id: 2355,
    label: "Itaoca - SP",
  },
  {
    id: 2356,
    label: "Itaocara - RJ",
  },
  {
    id: 2357,
    label: "Itapaci - GO",
  },
  {
    id: 2358,
    label: "Itapagipe - MG",
  },
  {
    id: 2359,
    label: "Itapajé - CE",
  },
  {
    id: 2360,
    label: "Itaparica - BA",
  },
  {
    id: 2361,
    label: "Itapé - BA",
  },
  {
    id: 2362,
    label: "Itapebi - BA",
  },
  {
    id: 2363,
    label: "Itapecerica - MG",
  },
  {
    id: 2364,
    label: "Itapecerica da serra - SP",
  },
  {
    id: 2365,
    label: "Itapecuru mirim - MA",
  },
  {
    id: 2366,
    label: "Itapejara d'oeste - PR",
  },
  {
    id: 2367,
    label: "Itapema - SC",
  },
  {
    id: 2368,
    label: "Itapemirim - ES",
  },
  {
    id: 2369,
    label: "Itaperuçu - PR",
  },
  {
    id: 2370,
    label: "Itaperuna - RJ",
  },
  {
    id: 2371,
    label: "Itapetim - PE",
  },
  {
    id: 2372,
    label: "Itapetinga - BA",
  },
  {
    id: 2373,
    label: "Itapetininga - SP",
  },
  {
    id: 2374,
    label: "Itapeva - MG",
  },
  {
    id: 2375,
    label: "Itapeva - SP",
  },
  {
    id: 2376,
    label: "Itapevi - SP",
  },
  {
    id: 2377,
    label: "Itapicuru - BA",
  },
  {
    id: 2378,
    label: "Itapipoca - CE",
  },
  {
    id: 2379,
    label: "Itapira - SP",
  },
  {
    id: 2380,
    label: "Itapiranga - AM",
  },
  {
    id: 2381,
    label: "Itapiranga - SC",
  },
  {
    id: 2382,
    label: "Itapirapuã - GO",
  },
  {
    id: 2383,
    label: "Itapirapuã paulista - SP",
  },
  {
    id: 2384,
    label: "Itapiratins - TO",
  },
  {
    id: 2385,
    label: "Itapissuma - PE",
  },
  {
    id: 2386,
    label: "Itapitanga - BA",
  },
  {
    id: 2387,
    label: "Itapiúna - CE",
  },
  {
    id: 2388,
    label: "Itapoá - SC",
  },
  {
    id: 2389,
    label: "Itápolis - SP",
  },
  {
    id: 2390,
    label: "Itaporã - MS",
  },
  {
    id: 2391,
    label: "Itaporã do tocantins - TO",
  },
  {
    id: 2392,
    label: "Itaporanga - PB",
  },
  {
    id: 2393,
    label: "Itaporanga - SP",
  },
  {
    id: 2394,
    label: "Itaporanga d'ajuda - SE",
  },
  {
    id: 2395,
    label: "Itapororoca - PB",
  },
  {
    id: 2396,
    label: "Itapuã do oeste - RO",
  },
  {
    id: 2397,
    label: "Itapuca - RS",
  },
  {
    id: 2398,
    label: "Itapuí - SP",
  },
  {
    id: 2399,
    label: "Itapura - SP",
  },
  {
    id: 2400,
    label: "Itapuranga - GO",
  },
  {
    id: 2401,
    label: "Itaquaquecetuba - SP",
  },
  {
    id: 2402,
    label: "Itaquara - BA",
  },
  {
    id: 2403,
    label: "Itaqui - RS",
  },
  {
    id: 2404,
    label: "Itaquiraí - MS",
  },
  {
    id: 2405,
    label: "Itaquitinga - PE",
  },
  {
    id: 2406,
    label: "Itarana - ES",
  },
  {
    id: 2407,
    label: "Itarantim - BA",
  },
  {
    id: 2408,
    label: "Itararé - SP",
  },
  {
    id: 2409,
    label: "Itarema - CE",
  },
  {
    id: 2410,
    label: "Itariri - SP",
  },
  {
    id: 2411,
    label: "Itarumã - GO",
  },
  {
    id: 2412,
    label: "Itati - RS",
  },
  {
    id: 2413,
    label: "Itatiaia - RJ",
  },
  {
    id: 2414,
    label: "Itatiaiuçu - MG",
  },
  {
    id: 2415,
    label: "Itatiba - SP",
  },
  {
    id: 2416,
    label: "Itatiba do sul - RS",
  },
  {
    id: 2417,
    label: "Itatim - BA",
  },
  {
    id: 2418,
    label: "Itatinga - SP",
  },
  {
    id: 2419,
    label: "Itatira - CE",
  },
  {
    id: 2420,
    label: "Itatuba - PB",
  },
  {
    id: 2421,
    label: "Itaú - RN",
  },
  {
    id: 2422,
    label: "Itaú de minas - MG",
  },
  {
    id: 2423,
    label: "Itaúba - MT",
  },
  {
    id: 2424,
    label: "Itaubal - AP",
  },
  {
    id: 2425,
    label: "Itauçu - GO",
  },
  {
    id: 2426,
    label: "Itaueira - PI",
  },
  {
    id: 2427,
    label: "Itaúna - MG",
  },
  {
    id: 2428,
    label: "Itaúna do sul - PR",
  },
  {
    id: 2429,
    label: "Itaverava - MG",
  },
  {
    id: 2430,
    label: "Itinga - MG",
  },
  {
    id: 2431,
    label: "Itinga do maranhão - MA",
  },
  {
    id: 2432,
    label: "Itiquira - MT",
  },
  {
    id: 2433,
    label: "Itirapina - SP",
  },
  {
    id: 2434,
    label: "Itirapuã - SP",
  },
  {
    id: 2435,
    label: "Itiruçu - BA",
  },
  {
    id: 2436,
    label: "Itiúba - BA",
  },
  {
    id: 2437,
    label: "Itobi - SP",
  },
  {
    id: 2438,
    label: "Itororó - BA",
  },
  {
    id: 2439,
    label: "Itu - SP",
  },
  {
    id: 2440,
    label: "Ituaçu - BA",
  },
  {
    id: 2441,
    label: "Ituberá - BA",
  },
  {
    id: 2442,
    label: "Itueta - MG",
  },
  {
    id: 2443,
    label: "Ituiutaba - MG",
  },
  {
    id: 2444,
    label: "Itumbiara - GO",
  },
  {
    id: 2445,
    label: "Itumirim - MG",
  },
  {
    id: 2446,
    label: "Itupeva - SP",
  },
  {
    id: 2447,
    label: "Itupiranga - PA",
  },
  {
    id: 2448,
    label: "Ituporanga - SC",
  },
  {
    id: 2449,
    label: "Iturama - MG",
  },
  {
    id: 2450,
    label: "Itutinga - MG",
  },
  {
    id: 2451,
    label: "Ituverava - SP",
  },
  {
    id: 2452,
    label: "Iuiu - BA",
  },
  {
    id: 2453,
    label: "Iúna - ES",
  },
  {
    id: 2454,
    label: "Ivaí - PR",
  },
  {
    id: 2455,
    label: "Ivaiporã - PR",
  },
  {
    id: 2456,
    label: "Ivaté - PR",
  },
  {
    id: 2457,
    label: "Ivatuba - PR",
  },
  {
    id: 2458,
    label: "Ivinhema - MS",
  },
  {
    id: 2459,
    label: "Ivolândia - GO",
  },
  {
    id: 2460,
    label: "Ivorá - RS",
  },
  {
    id: 2461,
    label: "Ivoti - RS",
  },
  {
    id: 2462,
    label: "Jaboatão dos guararapes - PE",
  },
  {
    id: 2463,
    label: "Jaborá - SC",
  },
  {
    id: 2464,
    label: "Jaborandi - BA",
  },
  {
    id: 2465,
    label: "Jaborandi - SP",
  },
  {
    id: 2466,
    label: "Jaboti - PR",
  },
  {
    id: 2467,
    label: "Jaboticaba - RS",
  },
  {
    id: 2468,
    label: "Jaboticabal - SP",
  },
  {
    id: 2469,
    label: "Jaboticatubas - MG",
  },
  {
    id: 2470,
    label: "Jaçanã - RN",
  },
  {
    id: 2471,
    label: "Jacaraci - BA",
  },
  {
    id: 2472,
    label: "Jacaraú - PB",
  },
  {
    id: 2473,
    label: "Jacaré dos homens - AL",
  },
  {
    id: 2474,
    label: "Jacareacanga - PA",
  },
  {
    id: 2475,
    label: "Jacareí - SP",
  },
  {
    id: 2476,
    label: "Jacarezinho - PR",
  },
  {
    id: 2477,
    label: "Jaci - SP",
  },
  {
    id: 2478,
    label: "Jaciara - MT",
  },
  {
    id: 2479,
    label: "Jacinto - MG",
  },
  {
    id: 2480,
    label: "Jacinto machado - SC",
  },
  {
    id: 2481,
    label: "Jacobina - BA",
  },
  {
    id: 2482,
    label: "Jacobina do piauí - PI",
  },
  {
    id: 2483,
    label: "Jacuí - MG",
  },
  {
    id: 2484,
    label: "Jacuípe - AL",
  },
  {
    id: 2485,
    label: "Jacuizinho - RS",
  },
  {
    id: 2486,
    label: "Jacundá - PA",
  },
  {
    id: 2487,
    label: "Jacupiranga - SP",
  },
  {
    id: 2488,
    label: "Jacutinga - MG",
  },
  {
    id: 2489,
    label: "Jacutinga - RS",
  },
  {
    id: 2490,
    label: "Jaguapitã - PR",
  },
  {
    id: 2491,
    label: "Jaguaquara - BA",
  },
  {
    id: 2492,
    label: "Jaguaraçu - MG",
  },
  {
    id: 2493,
    label: "Jaguarão - RS",
  },
  {
    id: 2494,
    label: "Jaguarari - BA",
  },
  {
    id: 2495,
    label: "Jaguaré - ES",
  },
  {
    id: 2496,
    label: "Jaguaretama - CE",
  },
  {
    id: 2497,
    label: "Jaguari - RS",
  },
  {
    id: 2498,
    label: "Jaguariaíva - PR",
  },
  {
    id: 2499,
    label: "Jaguaribara - CE",
  },
  {
    id: 2500,
    label: "Jaguaribe - CE",
  },
  {
    id: 2501,
    label: "Jaguaripe - BA",
  },
  {
    id: 2502,
    label: "Jaguariúna - SP",
  },
  {
    id: 2503,
    label: "Jaguaruana - CE",
  },
  {
    id: 2504,
    label: "Jaguaruna - SC",
  },
  {
    id: 2505,
    label: "Jaíba - MG",
  },
  {
    id: 2506,
    label: "Jaicós - PI",
  },
  {
    id: 2507,
    label: "Jales - SP",
  },
  {
    id: 2508,
    label: "Jambeiro - SP",
  },
  {
    id: 2509,
    label: "Jampruca - MG",
  },
  {
    id: 2510,
    label: "Janaúba - MG",
  },
  {
    id: 2511,
    label: "Jandaia - GO",
  },
  {
    id: 2512,
    label: "Jandaia do sul - PR",
  },
  {
    id: 2513,
    label: "Jandaíra - BA",
  },
  {
    id: 2514,
    label: "Jandaíra - RN",
  },
  {
    id: 2515,
    label: "Jandira - SP",
  },
  {
    id: 2516,
    label: "Janduís - RN",
  },
  {
    id: 2517,
    label: "Jangada - MT",
  },
  {
    id: 2518,
    label: "Janiópolis - PR",
  },
  {
    id: 2519,
    label: "Januária - MG",
  },
  {
    id: 2520,
    label: "Japaraíba - MG",
  },
  {
    id: 2521,
    label: "Japaratinga - AL",
  },
  {
    id: 2522,
    label: "Japaratuba - SE",
  },
  {
    id: 2523,
    label: "Japeri - RJ",
  },
  {
    id: 2524,
    label: "Japi - RN",
  },
  {
    id: 2525,
    label: "Japira - PR",
  },
  {
    id: 2526,
    label: "Japoatã - SE",
  },
  {
    id: 2527,
    label: "Japonvar - MG",
  },
  {
    id: 2528,
    label: "Japorã - MS",
  },
  {
    id: 2529,
    label: "Japurá - AM",
  },
  {
    id: 2530,
    label: "Japurá - PR",
  },
  {
    id: 2531,
    label: "Jaqueira - PE",
  },
  {
    id: 2532,
    label: "Jaquirana - RS",
  },
  {
    id: 2533,
    label: "Jaraguá - GO",
  },
  {
    id: 2534,
    label: "Jaraguá do sul - SC",
  },
  {
    id: 2535,
    label: "Jaraguari - MS",
  },
  {
    id: 2536,
    label: "Jaramataia - AL",
  },
  {
    id: 2537,
    label: "Jardim - CE",
  },
  {
    id: 2538,
    label: "Jardim - MS",
  },
  {
    id: 2539,
    label: "Jardim alegre - PR",
  },
  {
    id: 2540,
    label: "Jardim de angicos - RN",
  },
  {
    id: 2541,
    label: "Jardim de piranhas - RN",
  },
  {
    id: 2542,
    label: "Jardim do mulato - PI",
  },
  {
    id: 2543,
    label: "Jardim do seridó - RN",
  },
  {
    id: 2544,
    label: "Jardim olinda - PR",
  },
  {
    id: 2545,
    label: "Jardinópolis - SC",
  },
  {
    id: 2546,
    label: "Jardinópolis - SP",
  },
  {
    id: 2547,
    label: "Jari - RS",
  },
  {
    id: 2548,
    label: "Jarinu - SP",
  },
  {
    id: 2549,
    label: "Jaru - RO",
  },
  {
    id: 2550,
    label: "Jataí - GO",
  },
  {
    id: 2551,
    label: "Jataizinho - PR",
  },
  {
    id: 2552,
    label: "Jataúba - PE",
  },
  {
    id: 2553,
    label: "Jateí - MS",
  },
  {
    id: 2554,
    label: "Jati - CE",
  },
  {
    id: 2555,
    label: "Jatobá - MA",
  },
  {
    id: 2556,
    label: "Jatobá - PE",
  },
  {
    id: 2557,
    label: "Jatobá do piauí - PI",
  },
  {
    id: 2558,
    label: "Jaú - SP",
  },
  {
    id: 2559,
    label: "Jaú do tocantins - TO",
  },
  {
    id: 2560,
    label: "Jaupaci - GO",
  },
  {
    id: 2561,
    label: "Jauru - MT",
  },
  {
    id: 2562,
    label: "Jeceaba - MG",
  },
  {
    id: 2563,
    label: "Jenipapo de minas - MG",
  },
  {
    id: 2564,
    label: "Jenipapo dos vieiras - MA",
  },
  {
    id: 2565,
    label: "Jequeri - MG",
  },
  {
    id: 2566,
    label: "Jequiá da praia - AL",
  },
  {
    id: 2567,
    label: "Jequié - BA",
  },
  {
    id: 2568,
    label: "Jequitaí - MG",
  },
  {
    id: 2569,
    label: "Jequitibá - MG",
  },
  {
    id: 2570,
    label: "Jequitinhonha - MG",
  },
  {
    id: 2571,
    label: "Jeremoabo - BA",
  },
  {
    id: 2572,
    label: "Jericó - PB",
  },
  {
    id: 2573,
    label: "Jeriquara - SP",
  },
  {
    id: 2574,
    label: "Jerônimo monteiro - ES",
  },
  {
    id: 2575,
    label: "Jerumenha - PI",
  },
  {
    id: 2576,
    label: "Jesuânia - MG",
  },
  {
    id: 2577,
    label: "Jesuítas - PR",
  },
  {
    id: 2578,
    label: "Jesúpolis - GO",
  },
  {
    id: 2579,
    label: "Ji-paraná - RO",
  },
  {
    id: 2580,
    label: "Jijoca de jericoacoara - CE",
  },
  {
    id: 2581,
    label: "Jiquiriçá - BA",
  },
  {
    id: 2582,
    label: "Jitaúna - BA",
  },
  {
    id: 2583,
    label: "Joaçaba - SC",
  },
  {
    id: 2584,
    label: "Joaíma - MG",
  },
  {
    id: 2585,
    label: "Joanésia - MG",
  },
  {
    id: 2586,
    label: "Joanópolis - SP",
  },
  {
    id: 2587,
    label: "João alfredo - PE",
  },
  {
    id: 2588,
    label: "João câmara - RN",
  },
  {
    id: 2589,
    label: "João costa - PI",
  },
  {
    id: 2590,
    label: "João dias - RN",
  },
  {
    id: 2591,
    label: "João dourado - BA",
  },
  {
    id: 2592,
    label: "João lisboa - MA",
  },
  {
    id: 2593,
    label: "João monlevade - MG",
  },
  {
    id: 2594,
    label: "João neiva - ES",
  },
  {
    id: 2595,
    label: "João pessoa - PB",
  },
  {
    id: 2596,
    label: "João pinheiro - MG",
  },
  {
    id: 2597,
    label: "João ramalho - SP",
  },
  {
    id: 2598,
    label: "Joaquim felício - MG",
  },
  {
    id: 2599,
    label: "Joaquim gomes - AL",
  },
  {
    id: 2600,
    label: "Joaquim nabuco - PE",
  },
  {
    id: 2601,
    label: "Joaquim pires - PI",
  },
  {
    id: 2602,
    label: "Joaquim távora - PR",
  },
  {
    id: 2603,
    label: "Joca claudino - PB",
  },
  {
    id: 2604,
    label: "Joca marques - PI",
  },
  {
    id: 2605,
    label: "Jóia - RS",
  },
  {
    id: 2606,
    label: "Joinville - SC",
  },
  {
    id: 2607,
    label: "Jordânia - MG",
  },
  {
    id: 2608,
    label: "Jordão - AC",
  },
  {
    id: 2609,
    label: "José boiteux - SC",
  },
  {
    id: 2610,
    label: "José bonifácio - SP",
  },
  {
    id: 2611,
    label: "José da penha - RN",
  },
  {
    id: 2612,
    label: "José de freitas - PI",
  },
  {
    id: 2613,
    label: "José gonçalves de minas - MG",
  },
  {
    id: 2614,
    label: "José raydan - MG",
  },
  {
    id: 2615,
    label: "Joselândia - MA",
  },
  {
    id: 2616,
    label: "Josenópolis - MG",
  },
  {
    id: 2617,
    label: "Joviânia - GO",
  },
  {
    id: 2618,
    label: "Juara - MT",
  },
  {
    id: 2619,
    label: "Juarez távora - PB",
  },
  {
    id: 2620,
    label: "Juarina - TO",
  },
  {
    id: 2621,
    label: "Juatuba - MG",
  },
  {
    id: 2622,
    label: "Juazeirinho - PB",
  },
  {
    id: 2623,
    label: "Juazeiro - BA",
  },
  {
    id: 2624,
    label: "Juazeiro do norte - CE",
  },
  {
    id: 2625,
    label: "Juazeiro do piauí - PI",
  },
  {
    id: 2626,
    label: "Jucás - CE",
  },
  {
    id: 2627,
    label: "Jucati - PE",
  },
  {
    id: 2628,
    label: "Jucuruçu - BA",
  },
  {
    id: 2629,
    label: "Jucurutu - RN",
  },
  {
    id: 2630,
    label: "Juína - MT",
  },
  {
    id: 2631,
    label: "Juiz de fora - MG",
  },
  {
    id: 2632,
    label: "Júlio borges - PI",
  },
  {
    id: 2633,
    label: "Júlio de castilhos - RS",
  },
  {
    id: 2634,
    label: "Júlio mesquita - SP",
  },
  {
    id: 2635,
    label: "Jumirim - SP",
  },
  {
    id: 2636,
    label: "Junco do maranhão - MA",
  },
  {
    id: 2637,
    label: "Junco do seridó - PB",
  },
  {
    id: 2638,
    label: "Jundiá - AL",
  },
  {
    id: 2639,
    label: "Jundiá - RN",
  },
  {
    id: 2640,
    label: "Jundiaí - SP",
  },
  {
    id: 2641,
    label: "Jundiaí do sul - PR",
  },
  {
    id: 2642,
    label: "Junqueiro - AL",
  },
  {
    id: 2643,
    label: "Junqueirópolis - SP",
  },
  {
    id: 2644,
    label: "Jupi - PE",
  },
  {
    id: 2645,
    label: "Jupiá - SC",
  },
  {
    id: 2646,
    label: "Juquiá - SP",
  },
  {
    id: 2647,
    label: "Juquitiba - SP",
  },
  {
    id: 2648,
    label: "Juramento - MG",
  },
  {
    id: 2649,
    label: "Juranda - PR",
  },
  {
    id: 2650,
    label: "Jurema - PE",
  },
  {
    id: 2651,
    label: "Jurema - PI",
  },
  {
    id: 2652,
    label: "Juripiranga - PB",
  },
  {
    id: 2653,
    label: "Juru - PB",
  },
  {
    id: 2654,
    label: "Juruá - AM",
  },
  {
    id: 2655,
    label: "Juruaia - MG",
  },
  {
    id: 2656,
    label: "Juruena - MT",
  },
  {
    id: 2657,
    label: "Juruti - PA",
  },
  {
    id: 2658,
    label: "Juscimeira - MT",
  },
  {
    id: 2659,
    label: "Jussara - BA",
  },
  {
    id: 2660,
    label: "Jussara - GO",
  },
  {
    id: 2661,
    label: "Jussara - PR",
  },
  {
    id: 2662,
    label: "Jussari - BA",
  },
  {
    id: 2663,
    label: "Jussiape - BA",
  },
  {
    id: 2664,
    label: "Jutaí - AM",
  },
  {
    id: 2665,
    label: "Juti - MS",
  },
  {
    id: 2666,
    label: "Juvenília - MG",
  },
  {
    id: 2667,
    label: "Kaloré - PR",
  },
  {
    id: 2668,
    label: "Lábrea - AM",
  },
  {
    id: 2669,
    label: "Lacerdópolis - SC",
  },
  {
    id: 2670,
    label: "Ladainha - MG",
  },
  {
    id: 2671,
    label: "Ladário - MS",
  },
  {
    id: 2672,
    label: "Lafaiete coutinho - BA",
  },
  {
    id: 2673,
    label: "Lagamar - MG",
  },
  {
    id: 2674,
    label: "Lagarto - SE",
  },
  {
    id: 2675,
    label: "Lages - SC",
  },
  {
    id: 2676,
    label: "Lago da pedra - MA",
  },
  {
    id: 2677,
    label: "Lago do junco - MA",
  },
  {
    id: 2678,
    label: "Lago dos rodrigues - MA",
  },
  {
    id: 2679,
    label: "Lago verde - MA",
  },
  {
    id: 2680,
    label: "Lagoa - PB",
  },
  {
    id: 2681,
    label: "Lagoa alegre - PI",
  },
  {
    id: 2682,
    label: "Lagoa bonita do sul - RS",
  },
  {
    id: 2683,
    label: "Lagoa d'anta - RN",
  },
  {
    id: 2684,
    label: "Lagoa da canoa - AL",
  },
  {
    id: 2685,
    label: "Lagoa da confusão - TO",
  },
  {
    id: 2686,
    label: "Lagoa da prata - MG",
  },
  {
    id: 2687,
    label: "Lagoa de dentro - PB",
  },
  {
    id: 2688,
    label: "Lagoa de itaenga - PE",
  },
  {
    id: 2689,
    label: "Lagoa de pedras - RN",
  },
  {
    id: 2690,
    label: "Lagoa de são francisco - PI",
  },
  {
    id: 2691,
    label: "Lagoa de velhos - RN",
  },
  {
    id: 2692,
    label: "Lagoa do barro do piauí - PI",
  },
  {
    id: 2693,
    label: "Lagoa do carro - PE",
  },
  {
    id: 2694,
    label: "Lagoa do mato - MA",
  },
  {
    id: 2695,
    label: "Lagoa do ouro - PE",
  },
  {
    id: 2696,
    label: "Lagoa do piauí - PI",
  },
  {
    id: 2697,
    label: "Lagoa do sítio - PI",
  },
  {
    id: 2698,
    label: "Lagoa do tocantins - TO",
  },
  {
    id: 2699,
    label: "Lagoa dos gatos - PE",
  },
  {
    id: 2700,
    label: "Lagoa dos patos - MG",
  },
  {
    id: 2701,
    label: "Lagoa dos três cantos - RS",
  },
  {
    id: 2702,
    label: "Lagoa dourada - MG",
  },
  {
    id: 2703,
    label: "Lagoa formosa - MG",
  },
  {
    id: 2704,
    label: "Lagoa grande - MG",
  },
  {
    id: 2705,
    label: "Lagoa grande - PE",
  },
  {
    id: 2706,
    label: "Lagoa grande do maranhão - MA",
  },
  {
    id: 2707,
    label: "Lagoa nova - RN",
  },
  {
    id: 2708,
    label: "Lagoa real - BA",
  },
  {
    id: 2709,
    label: "Lagoa salgada - RN",
  },
  {
    id: 2710,
    label: "Lagoa santa - GO",
  },
  {
    id: 2711,
    label: "Lagoa santa - MG",
  },
  {
    id: 2712,
    label: "Lagoa seca - PB",
  },
  {
    id: 2713,
    label: "Lagoa vermelha - RS",
  },
  {
    id: 2714,
    label: "Lagoão - RS",
  },
  {
    id: 2715,
    label: "Lagoinha - SP",
  },
  {
    id: 2716,
    label: "Lagoinha do piauí - PI",
  },
  {
    id: 2717,
    label: "Laguna - SC",
  },
  {
    id: 2718,
    label: "Laguna carapã - MS",
  },
  {
    id: 2719,
    label: "Laje - BA",
  },
  {
    id: 2720,
    label: "Laje do muriaé - RJ",
  },
  {
    id: 2721,
    label: "Lajeado - RS",
  },
  {
    id: 2722,
    label: "Lajeado - TO",
  },
  {
    id: 2723,
    label: "Lajeado do bugre - RS",
  },
  {
    id: 2724,
    label: "Lajeado grande - SC",
  },
  {
    id: 2725,
    label: "Lajeado novo - MA",
  },
  {
    id: 2726,
    label: "Lajedão - BA",
  },
  {
    id: 2727,
    label: "Lajedinho - BA",
  },
  {
    id: 2728,
    label: "Lajedo - PE",
  },
  {
    id: 2729,
    label: "Lajedo do tabocal - BA",
  },
  {
    id: 2730,
    label: "Lajes - RN",
  },
  {
    id: 2731,
    label: "Lajes pintadas - RN",
  },
  {
    id: 2732,
    label: "Lajinha - MG",
  },
  {
    id: 2733,
    label: "Lamarão - BA",
  },
  {
    id: 2734,
    label: "Lambari - MG",
  },
  {
    id: 2735,
    label: "Lambari d'oeste - MT",
  },
  {
    id: 2736,
    label: "Lamim - MG",
  },
  {
    id: 2737,
    label: "Landri sales - PI",
  },
  {
    id: 2738,
    label: "Lapa - PR",
  },
  {
    id: 2739,
    label: "Lapão - BA",
  },
  {
    id: 2740,
    label: "Laranja da terra - ES",
  },
  {
    id: 2741,
    label: "Laranjal - MG",
  },
  {
    id: 2742,
    label: "Laranjal - PR",
  },
  {
    id: 2743,
    label: "Laranjal do jari - AP",
  },
  {
    id: 2744,
    label: "Laranjal paulista - SP",
  },
  {
    id: 2745,
    label: "Laranjeiras - SE",
  },
  {
    id: 2746,
    label: "Laranjeiras do sul - PR",
  },
  {
    id: 2747,
    label: "Lassance - MG",
  },
  {
    id: 2748,
    label: "Lastro - PB",
  },
  {
    id: 2749,
    label: "Laurentino - SC",
  },
  {
    id: 2750,
    label: "Lauro de freitas - BA",
  },
  {
    id: 2751,
    label: "Lauro müller - SC",
  },
  {
    id: 2752,
    label: "Lavandeira - TO",
  },
  {
    id: 2753,
    label: "Lavínia - SP",
  },
  {
    id: 2754,
    label: "Lavras - MG",
  },
  {
    id: 2755,
    label: "Lavras da mangabeira - CE",
  },
  {
    id: 2756,
    label: "Lavras do sul - RS",
  },
  {
    id: 2757,
    label: "Lavrinhas - SP",
  },
  {
    id: 2758,
    label: "Leandro ferreira - MG",
  },
  {
    id: 2759,
    label: "Lebon régis - SC",
  },
  {
    id: 2760,
    label: "Leme - SP",
  },
  {
    id: 2761,
    label: "Leme do prado - MG",
  },
  {
    id: 2762,
    label: "Lençóis - BA",
  },
  {
    id: 2763,
    label: "Lençóis paulista - SP",
  },
  {
    id: 2764,
    label: "Leoberto leal - SC",
  },
  {
    id: 2765,
    label: "Leopoldina - MG",
  },
  {
    id: 2766,
    label: "Leopoldo de bulhões - GO",
  },
  {
    id: 2767,
    label: "Leópolis - PR",
  },
  {
    id: 2768,
    label: "Liberato salzano - RS",
  },
  {
    id: 2769,
    label: "Liberdade - MG",
  },
  {
    id: 2770,
    label: "Licínio de almeida - BA",
  },
  {
    id: 2771,
    label: "Lidianópolis - PR",
  },
  {
    id: 2772,
    label: "Lima campos - MA",
  },
  {
    id: 2773,
    label: "Lima duarte - MG",
  },
  {
    id: 2774,
    label: "Limeira - SP",
  },
  {
    id: 2775,
    label: "Limeira do oeste - MG",
  },
  {
    id: 2776,
    label: "Limoeiro - PE",
  },
  {
    id: 2777,
    label: "Limoeiro de anadia - AL",
  },
  {
    id: 2778,
    label: "Limoeiro do ajuru - PA",
  },
  {
    id: 2779,
    label: "Limoeiro do norte - CE",
  },
  {
    id: 2780,
    label: "Lindoeste - PR",
  },
  {
    id: 2781,
    label: "Lindóia - SP",
  },
  {
    id: 2782,
    label: "Lindóia do sul - SC",
  },
  {
    id: 2783,
    label: "Lindolfo collor - RS",
  },
  {
    id: 2784,
    label: "Linha nova - RS",
  },
  {
    id: 2785,
    label: "Linhares - ES",
  },
  {
    id: 2786,
    label: "Lins - SP",
  },
  {
    id: 2787,
    label: "Livramento - PB",
  },
  {
    id: 2788,
    label: "Livramento de nossa senhora - BA",
  },
  {
    id: 2789,
    label: "Lizarda - TO",
  },
  {
    id: 2790,
    label: "Loanda - PR",
  },
  {
    id: 2791,
    label: "Lobato - PR",
  },
  {
    id: 2792,
    label: "Logradouro - PB",
  },
  {
    id: 2793,
    label: "Londrina - PR",
  },
  {
    id: 2794,
    label: "Lontra - MG",
  },
  {
    id: 2795,
    label: "Lontras - SC",
  },
  {
    id: 2796,
    label: "Lorena - SP",
  },
  {
    id: 2797,
    label: "Loreto - MA",
  },
  {
    id: 2798,
    label: "Lourdes - SP",
  },
  {
    id: 2799,
    label: "Louveira - SP",
  },
  {
    id: 2800,
    label: "Lucas do rio verde - MT",
  },
  {
    id: 2801,
    label: "Lucélia - SP",
  },
  {
    id: 2802,
    label: "Lucena - PB",
  },
  {
    id: 2803,
    label: "Lucianópolis - SP",
  },
  {
    id: 2804,
    label: "Luciara - MT",
  },
  {
    id: 2805,
    label: "Lucrécia - RN",
  },
  {
    id: 2806,
    label: "Luís antônio - SP",
  },
  {
    id: 2807,
    label: "Luis correia - PI",
  },
  {
    id: 2808,
    label: "Luís domingues - MA",
  },
  {
    id: 2809,
    label: "Luís eduardo magalhães - BA",
  },
  {
    id: 2810,
    label: "Luís gomes - RN",
  },
  {
    id: 2811,
    label: "Luisburgo - MG",
  },
  {
    id: 2812,
    label: "Luislândia - MG",
  },
  {
    id: 2813,
    label: "Luiz alves - SC",
  },
  {
    id: 2814,
    label: "Luiziana - PR",
  },
  {
    id: 2815,
    label: "Luiziânia - SP",
  },
  {
    id: 2816,
    label: "Luminárias - MG",
  },
  {
    id: 2817,
    label: "Lunardelli - PR",
  },
  {
    id: 2818,
    label: "Lupércio - SP",
  },
  {
    id: 2819,
    label: "Lupionópolis - PR",
  },
  {
    id: 2820,
    label: "Lutécia - SP",
  },
  {
    id: 2821,
    label: "Luz - MG",
  },
  {
    id: 2822,
    label: "Luzerna - SC",
  },
  {
    id: 2823,
    label: "Luziânia - GO",
  },
  {
    id: 2824,
    label: "Luzilândia - PI",
  },
  {
    id: 2825,
    label: "Luzinópolis - TO",
  },
  {
    id: 2826,
    label: "Macaé - RJ",
  },
  {
    id: 2827,
    label: "Macaíba - RN",
  },
  {
    id: 2828,
    label: "Macajuba - BA",
  },
  {
    id: 2829,
    label: "Maçambará - RS",
  },
  {
    id: 2830,
    label: "Macambira - SE",
  },
  {
    id: 2831,
    label: "Macapá - AP",
  },
  {
    id: 2832,
    label: "Macaparana - PE",
  },
  {
    id: 2833,
    label: "Macarani - BA",
  },
  {
    id: 2834,
    label: "Macatuba - SP",
  },
  {
    id: 2835,
    label: "Macau - RN",
  },
  {
    id: 2836,
    label: "Macaubal - SP",
  },
  {
    id: 2837,
    label: "Macaúbas - BA",
  },
  {
    id: 2838,
    label: "Macedônia - SP",
  },
  {
    id: 2839,
    label: "Maceió - AL",
  },
  {
    id: 2840,
    label: "Machacalis - MG",
  },
  {
    id: 2841,
    label: "Machadinho - RS",
  },
  {
    id: 2842,
    label: "Machadinho d'oeste - RO",
  },
  {
    id: 2843,
    label: "Machado - MG",
  },
  {
    id: 2844,
    label: "Machados - PE",
  },
  {
    id: 2845,
    label: "Macieira - SC",
  },
  {
    id: 2846,
    label: "Macuco - RJ",
  },
  {
    id: 2847,
    label: "Macururé - BA",
  },
  {
    id: 2848,
    label: "Madalena - CE",
  },
  {
    id: 2849,
    label: "Madeiro - PI",
  },
  {
    id: 2850,
    label: "Madre de deus - BA",
  },
  {
    id: 2851,
    label: "Madre de deus de minas - MG",
  },
  {
    id: 2852,
    label: "Mãe d'água - PB",
  },
  {
    id: 2853,
    label: "Mãe do rio - PA",
  },
  {
    id: 2854,
    label: "Maetinga - BA",
  },
  {
    id: 2855,
    label: "Mafra - SC",
  },
  {
    id: 2856,
    label: "Magalhães barata - PA",
  },
  {
    id: 2857,
    label: "Magalhães de almeida - MA",
  },
  {
    id: 2858,
    label: "Magda - SP",
  },
  {
    id: 2859,
    label: "Magé - RJ",
  },
  {
    id: 2860,
    label: "Maiquinique - BA",
  },
  {
    id: 2861,
    label: "Mairi - BA",
  },
  {
    id: 2862,
    label: "Mairinque - SP",
  },
  {
    id: 2863,
    label: "Mairiporã - SP",
  },
  {
    id: 2864,
    label: "Mairipotaba - GO",
  },
  {
    id: 2865,
    label: "Major gercino - SC",
  },
  {
    id: 2866,
    label: "Major isidoro - AL",
  },
  {
    id: 2867,
    label: "Major sales - RN",
  },
  {
    id: 2868,
    label: "Major vieira - SC",
  },
  {
    id: 2869,
    label: "Malacacheta - MG",
  },
  {
    id: 2870,
    label: "Malhada - BA",
  },
  {
    id: 2871,
    label: "Malhada de pedras - BA",
  },
  {
    id: 2872,
    label: "Malhada dos bois - SE",
  },
  {
    id: 2873,
    label: "Malhador - SE",
  },
  {
    id: 2874,
    label: "Mallet - PR",
  },
  {
    id: 2875,
    label: "Malta - PB",
  },
  {
    id: 2876,
    label: "Mamanguape - PB",
  },
  {
    id: 2877,
    label: "Mambaí - GO",
  },
  {
    id: 2878,
    label: "Mamborê - PR",
  },
  {
    id: 2879,
    label: "Mamonas - MG",
  },
  {
    id: 2880,
    label: "Mampituba - RS",
  },
  {
    id: 2881,
    label: "Manacapuru - AM",
  },
  {
    id: 2882,
    label: "Manaíra - PB",
  },
  {
    id: 2883,
    label: "Manaquiri - AM",
  },
  {
    id: 2884,
    label: "Manari - PE",
  },
  {
    id: 2885,
    label: "Manaus - AM",
  },
  {
    id: 2886,
    label: "Mâncio lima - AC",
  },
  {
    id: 2887,
    label: "Mandaguaçu - PR",
  },
  {
    id: 2888,
    label: "Mandaguari - PR",
  },
  {
    id: 2889,
    label: "Mandirituba - PR",
  },
  {
    id: 2890,
    label: "Manduri - SP",
  },
  {
    id: 2891,
    label: "Manfrinópolis - PR",
  },
  {
    id: 2892,
    label: "Manga - MG",
  },
  {
    id: 2893,
    label: "Mangaratiba - RJ",
  },
  {
    id: 2894,
    label: "Mangueirinha - PR",
  },
  {
    id: 2895,
    label: "Manhuaçu - MG",
  },
  {
    id: 2896,
    label: "Manhumirim - MG",
  },
  {
    id: 2897,
    label: "Manicoré - AM",
  },
  {
    id: 2898,
    label: "Manoel emídio - PI",
  },
  {
    id: 2899,
    label: "Manoel ribas - PR",
  },
  {
    id: 2900,
    label: "Manoel urbano - AC",
  },
  {
    id: 2901,
    label: "Manoel viana - RS",
  },
  {
    id: 2902,
    label: "Manoel vitorino - BA",
  },
  {
    id: 2903,
    label: "Mansidão - BA",
  },
  {
    id: 2904,
    label: "Mantena - MG",
  },
  {
    id: 2905,
    label: "Mantenópolis - ES",
  },
  {
    id: 2906,
    label: "Maquiné - RS",
  },
  {
    id: 2907,
    label: "Mar de espanha - MG",
  },
  {
    id: 2908,
    label: "Mar vermelho - AL",
  },
  {
    id: 2909,
    label: "Mara rosa - GO",
  },
  {
    id: 2910,
    label: "Maraã - AM",
  },
  {
    id: 2911,
    label: "Marabá - PA",
  },
  {
    id: 2912,
    label: "Marabá paulista - SP",
  },
  {
    id: 2913,
    label: "Maracaçumé - MA",
  },
  {
    id: 2914,
    label: "Maracaí - SP",
  },
  {
    id: 2915,
    label: "Maracajá - SC",
  },
  {
    id: 2916,
    label: "Maracaju - MS",
  },
  {
    id: 2917,
    label: "Maracanã - PA",
  },
  {
    id: 2918,
    label: "Maracanaú - CE",
  },
  {
    id: 2919,
    label: "Maracás - BA",
  },
  {
    id: 2920,
    label: "Maragogi - AL",
  },
  {
    id: 2921,
    label: "Maragogipe - BA",
  },
  {
    id: 2922,
    label: "Maraial - PE",
  },
  {
    id: 2923,
    label: "Marajá do sena - MA",
  },
  {
    id: 2924,
    label: "Maranguape - CE",
  },
  {
    id: 2925,
    label: "Maranhãozinho - MA",
  },
  {
    id: 2926,
    label: "Marapanim - PA",
  },
  {
    id: 2927,
    label: "Marapoama - SP",
  },
  {
    id: 2928,
    label: "Maratá - RS",
  },
  {
    id: 2929,
    label: "Marataízes - ES",
  },
  {
    id: 2930,
    label: "Marau - RS",
  },
  {
    id: 2931,
    label: "Maraú - BA",
  },
  {
    id: 2932,
    label: "Maravilha - AL",
  },
  {
    id: 2933,
    label: "Maravilha - SC",
  },
  {
    id: 2934,
    label: "Maravilhas - MG",
  },
  {
    id: 2935,
    label: "Marcação - PB",
  },
  {
    id: 2936,
    label: "Marcelândia - MT",
  },
  {
    id: 2937,
    label: "Marcelino ramos - RS",
  },
  {
    id: 2938,
    label: "Marcelino vieira - RN",
  },
  {
    id: 2939,
    label: "Marcionílio souza - BA",
  },
  {
    id: 2940,
    label: "Marco - CE",
  },
  {
    id: 2941,
    label: "Marcolândia - PI",
  },
  {
    id: 2942,
    label: "Marcos parente - PI",
  },
  {
    id: 2943,
    label: "Marechal cândido rondon - PR",
  },
  {
    id: 2944,
    label: "Marechal deodoro - AL",
  },
  {
    id: 2945,
    label: "Marechal floriano - ES",
  },
  {
    id: 2946,
    label: "Marechal thaumaturgo - AC",
  },
  {
    id: 2947,
    label: "Marema - SC",
  },
  {
    id: 2948,
    label: "Mari - PB",
  },
  {
    id: 2949,
    label: "Maria da fé - MG",
  },
  {
    id: 2950,
    label: "Maria helena - PR",
  },
  {
    id: 2951,
    label: "Marialva - PR",
  },
  {
    id: 2952,
    label: "Mariana - MG",
  },
  {
    id: 2953,
    label: "Mariana pimentel - RS",
  },
  {
    id: 2954,
    label: "Mariano moro - RS",
  },
  {
    id: 2955,
    label: "Marianópolis do tocantins - TO",
  },
  {
    id: 2956,
    label: "Mariápolis - SP",
  },
  {
    id: 2957,
    label: "Maribondo - AL",
  },
  {
    id: 2958,
    label: "Maricá - RJ",
  },
  {
    id: 2959,
    label: "Marilac - MG",
  },
  {
    id: 2960,
    label: "Marilândia - ES",
  },
  {
    id: 2961,
    label: "Marilândia do sul - PR",
  },
  {
    id: 2962,
    label: "Marilena - PR",
  },
  {
    id: 2963,
    label: "Marília - SP",
  },
  {
    id: 2964,
    label: "Mariluz - PR",
  },
  {
    id: 2965,
    label: "Maringá - PR",
  },
  {
    id: 2966,
    label: "Marinópolis - SP",
  },
  {
    id: 2967,
    label: "Mário campos - MG",
  },
  {
    id: 2968,
    label: "Mariópolis - PR",
  },
  {
    id: 2969,
    label: "Maripá - PR",
  },
  {
    id: 2970,
    label: "Maripá de minas - MG",
  },
  {
    id: 2971,
    label: "Marituba - PA",
  },
  {
    id: 2972,
    label: "Marizópolis - PB",
  },
  {
    id: 2973,
    label: "Marliéria - MG",
  },
  {
    id: 2974,
    label: "Marmeleiro - PR",
  },
  {
    id: 2975,
    label: "Marmelópolis - MG",
  },
  {
    id: 2976,
    label: "Marques de souza - RS",
  },
  {
    id: 2977,
    label: "Marquinho - PR",
  },
  {
    id: 2978,
    label: "Martinho campos - MG",
  },
  {
    id: 2979,
    label: "Martinópole - CE",
  },
  {
    id: 2980,
    label: "Martinópolis - SP",
  },
  {
    id: 2981,
    label: "Martins - RN",
  },
  {
    id: 2982,
    label: "Martins soares - MG",
  },
  {
    id: 2983,
    label: "Maruim - SE",
  },
  {
    id: 2984,
    label: "Marumbi - PR",
  },
  {
    id: 2985,
    label: "Marzagão - GO",
  },
  {
    id: 2986,
    label: "Mascote - BA",
  },
  {
    id: 2987,
    label: "Massapê - CE",
  },
  {
    id: 2988,
    label: "Massapê do piauí - PI",
  },
  {
    id: 2989,
    label: "Massaranduba - PB",
  },
  {
    id: 2990,
    label: "Massaranduba - SC",
  },
  {
    id: 2991,
    label: "Mata - RS",
  },
  {
    id: 2992,
    label: "Mata de são joão - BA",
  },
  {
    id: 2993,
    label: "Mata grande - AL",
  },
  {
    id: 2994,
    label: "Mata roma - MA",
  },
  {
    id: 2995,
    label: "Mata verde - MG",
  },
  {
    id: 2996,
    label: "Matão - SP",
  },
  {
    id: 2997,
    label: "Mataraca - PB",
  },
  {
    id: 2998,
    label: "Mateiros - TO",
  },
  {
    id: 2999,
    label: "Matelândia - PR",
  },
  {
    id: 3000,
    label: "Materlândia - MG",
  },
  {
    id: 3001,
    label: "Mateus leme - MG",
  },
  {
    id: 3002,
    label: "Mathias lobato - MG",
  },
  {
    id: 3003,
    label: "Matias barbosa - MG",
  },
  {
    id: 3004,
    label: "Matias cardoso - MG",
  },
  {
    id: 3005,
    label: "Matias olímpio - PI",
  },
  {
    id: 3006,
    label: "Matina - BA",
  },
  {
    id: 3007,
    label: "Matinha - MA",
  },
  {
    id: 3008,
    label: "Matinhas - PB",
  },
  {
    id: 3009,
    label: "Matinhos - PR",
  },
  {
    id: 3010,
    label: "Matipó - MG",
  },
  {
    id: 3011,
    label: "Mato castelhano - RS",
  },
  {
    id: 3012,
    label: "Mato grosso - PB",
  },
  {
    id: 3013,
    label: "Mato leitão - RS",
  },
  {
    id: 3014,
    label: "Mato queimado - RS",
  },
  {
    id: 3015,
    label: "Mato rico - PR",
  },
  {
    id: 3016,
    label: "Mato verde - MG",
  },
  {
    id: 3017,
    label: "Matões - MA",
  },
  {
    id: 3018,
    label: "Matões do norte - MA",
  },
  {
    id: 3019,
    label: "Matos costa - SC",
  },
  {
    id: 3020,
    label: "Matozinhos - MG",
  },
  {
    id: 3021,
    label: "Matrinchã - GO",
  },
  {
    id: 3022,
    label: "Matriz de camaragibe - AL",
  },
  {
    id: 3023,
    label: "Matupá - MT",
  },
  {
    id: 3024,
    label: "Maturéia - PB",
  },
  {
    id: 3025,
    label: "Matutina - MG",
  },
  {
    id: 3026,
    label: "Mauá - SP",
  },
  {
    id: 3027,
    label: "Mauá da serra - PR",
  },
  {
    id: 3028,
    label: "Maués - AM",
  },
  {
    id: 3029,
    label: "Maurilândia - GO",
  },
  {
    id: 3030,
    label: "Maurilândia do tocantins - TO",
  },
  {
    id: 3031,
    label: "Mauriti - CE",
  },
  {
    id: 3032,
    label: "Maxaranguape - RN",
  },
  {
    id: 3033,
    label: "Maximiliano de almeida - RS",
  },
  {
    id: 3034,
    label: "Mazagão - AP",
  },
  {
    id: 3035,
    label: "Medeiros - MG",
  },
  {
    id: 3036,
    label: "Medeiros neto - BA",
  },
  {
    id: 3037,
    label: "Medianeira - PR",
  },
  {
    id: 3038,
    label: "Medicilândia - PA",
  },
  {
    id: 3039,
    label: "Medina - MG",
  },
  {
    id: 3040,
    label: "Meleiro - SC",
  },
  {
    id: 3041,
    label: "Melgaço - PA",
  },
  {
    id: 3042,
    label: "Mendes - RJ",
  },
  {
    id: 3043,
    label: "Mendes pimentel - MG",
  },
  {
    id: 3044,
    label: "Mendonça - SP",
  },
  {
    id: 3045,
    label: "Mercedes - PR",
  },
  {
    id: 3046,
    label: "Mercês - MG",
  },
  {
    id: 3047,
    label: "Meridiano - SP",
  },
  {
    id: 3048,
    label: "Meruoca - CE",
  },
  {
    id: 3049,
    label: "Mesópolis - SP",
  },
  {
    id: 3050,
    label: "Mesquita - MG",
  },
  {
    id: 3051,
    label: "Mesquita - RJ",
  },
  {
    id: 3052,
    label: "Messias - AL",
  },
  {
    id: 3053,
    label: "Messias targino - RN",
  },
  {
    id: 3054,
    label: "Miguel alves - PI",
  },
  {
    id: 3055,
    label: "Miguel calmon - BA",
  },
  {
    id: 3056,
    label: "Miguel leão - PI",
  },
  {
    id: 3057,
    label: "Miguel pereira - RJ",
  },
  {
    id: 3058,
    label: "Miguelópolis - SP",
  },
  {
    id: 3059,
    label: "Milagres - BA",
  },
  {
    id: 3060,
    label: "Milagres - CE",
  },
  {
    id: 3061,
    label: "Milagres do maranhão - MA",
  },
  {
    id: 3062,
    label: "Milhã - CE",
  },
  {
    id: 3063,
    label: "Milton brandão - PI",
  },
  {
    id: 3064,
    label: "Mimoso de goiás - GO",
  },
  {
    id: 3065,
    label: "Mimoso do sul - ES",
  },
  {
    id: 3066,
    label: "Minaçu - GO",
  },
  {
    id: 3067,
    label: "Minador do negrão - AL",
  },
  {
    id: 3068,
    label: "Minas do leão - RS",
  },
  {
    id: 3069,
    label: "Minas novas - MG",
  },
  {
    id: 3070,
    label: "Minduri - MG",
  },
  {
    id: 3071,
    label: "Mineiros - GO",
  },
  {
    id: 3072,
    label: "Mineiros do tietê - SP",
  },
  {
    id: 3073,
    label: "Ministro andreazza - RO",
  },
  {
    id: 3074,
    label: "Mira estrela - SP",
  },
  {
    id: 3075,
    label: "Mirabela - MG",
  },
  {
    id: 3076,
    label: "Miracatu - SP",
  },
  {
    id: 3077,
    label: "Miracema - RJ",
  },
  {
    id: 3078,
    label: "Miracema do tocantins - TO",
  },
  {
    id: 3079,
    label: "Mirador - MA",
  },
  {
    id: 3080,
    label: "Mirador - PR",
  },
  {
    id: 3081,
    label: "Miradouro - MG",
  },
  {
    id: 3082,
    label: "Miraguaí - RS",
  },
  {
    id: 3083,
    label: "Miraí - MG",
  },
  {
    id: 3084,
    label: "Miraíma - CE",
  },
  {
    id: 3085,
    label: "Miranda - MS",
  },
  {
    id: 3086,
    label: "Miranda do norte - MA",
  },
  {
    id: 3087,
    label: "Mirandiba - PE",
  },
  {
    id: 3088,
    label: "Mirandópolis - SP",
  },
  {
    id: 3089,
    label: "Mirangaba - BA",
  },
  {
    id: 3090,
    label: "Miranorte - TO",
  },
  {
    id: 3091,
    label: "Mirante - BA",
  },
  {
    id: 3092,
    label: "Mirante da serra - RO",
  },
  {
    id: 3093,
    label: "Mirante do paranapanema - SP",
  },
  {
    id: 3094,
    label: "Miraselva - PR",
  },
  {
    id: 3095,
    label: "Mirassol - SP",
  },
  {
    id: 3096,
    label: "Mirassol d'oeste - MT",
  },
  {
    id: 3097,
    label: "Mirassolândia - SP",
  },
  {
    id: 3098,
    label: "Miravânia - MG",
  },
  {
    id: 3099,
    label: "Mirim doce - SC",
  },
  {
    id: 3100,
    label: "Mirinzal - MA",
  },
  {
    id: 3101,
    label: "Missal - PR",
  },
  {
    id: 3102,
    label: "Missão velha - CE",
  },
  {
    id: 3103,
    label: "Mocajuba - PA",
  },
  {
    id: 3104,
    label: "Mococa - SP",
  },
  {
    id: 3105,
    label: "Modelo - SC",
  },
  {
    id: 3106,
    label: "Moeda - MG",
  },
  {
    id: 3107,
    label: "Moema - MG",
  },
  {
    id: 3108,
    label: "Mogeiro - PB",
  },
  {
    id: 3109,
    label: "Mogi das cruzes - SP",
  },
  {
    id: 3110,
    label: "Mogi guaçu - SP",
  },
  {
    id: 3111,
    label: "Mogi mirim - SP",
  },
  {
    id: 3112,
    label: "Moiporá - GO",
  },
  {
    id: 3113,
    label: "Moita bonita - SE",
  },
  {
    id: 3114,
    label: "Moju - PA",
  },
  {
    id: 3115,
    label: "Mojuí dos campos - PA",
  },
  {
    id: 3116,
    label: "Mombaça - CE",
  },
  {
    id: 3117,
    label: "Mombuca - SP",
  },
  {
    id: 3118,
    label: "Monção - MA",
  },
  {
    id: 3119,
    label: "Monções - SP",
  },
  {
    id: 3120,
    label: "Mondaí - SC",
  },
  {
    id: 3121,
    label: "Mongaguá - SP",
  },
  {
    id: 3122,
    label: "Monjolos - MG",
  },
  {
    id: 3123,
    label: "Monsenhor gil - PI",
  },
  {
    id: 3124,
    label: "Monsenhor hipólito - PI",
  },
  {
    id: 3125,
    label: "Monsenhor paulo - MG",
  },
  {
    id: 3126,
    label: "Monsenhor tabosa - CE",
  },
  {
    id: 3127,
    label: "Montadas - PB",
  },
  {
    id: 3128,
    label: "Montalvânia - MG",
  },
  {
    id: 3129,
    label: "Montanha - ES",
  },
  {
    id: 3130,
    label: "Montanhas - RN",
  },
  {
    id: 3131,
    label: "Montauri - RS",
  },
  {
    id: 3132,
    label: "Monte alegre - PA",
  },
  {
    id: 3133,
    label: "Monte alegre - RN",
  },
  {
    id: 3134,
    label: "Monte alegre de goiás - GO",
  },
  {
    id: 3135,
    label: "Monte alegre de minas - MG",
  },
  {
    id: 3136,
    label: "Monte alegre de sergipe - SE",
  },
  {
    id: 3137,
    label: "Monte alegre do piauí - PI",
  },
  {
    id: 3138,
    label: "Monte alegre do sul - SP",
  },
  {
    id: 3139,
    label: "Monte alegre dos campos - RS",
  },
  {
    id: 3140,
    label: "Monte alto - SP",
  },
  {
    id: 3141,
    label: "Monte aprazível - SP",
  },
  {
    id: 3142,
    label: "Monte azul - MG",
  },
  {
    id: 3143,
    label: "Monte azul paulista - SP",
  },
  {
    id: 3144,
    label: "Monte belo - MG",
  },
  {
    id: 3145,
    label: "Monte belo do sul - RS",
  },
  {
    id: 3146,
    label: "Monte carlo - SC",
  },
  {
    id: 3147,
    label: "Monte carmelo - MG",
  },
  {
    id: 3148,
    label: "Monte castelo - SC",
  },
  {
    id: 3149,
    label: "Monte castelo - SP",
  },
  {
    id: 3150,
    label: "Monte das gameleiras - RN",
  },
  {
    id: 3151,
    label: "Monte do carmo - TO",
  },
  {
    id: 3152,
    label: "Monte formoso - MG",
  },
  {
    id: 3153,
    label: "Monte horebe - PB",
  },
  {
    id: 3154,
    label: "Monte mor - SP",
  },
  {
    id: 3155,
    label: "Monte negro - RO",
  },
  {
    id: 3156,
    label: "Monte santo - BA",
  },
  {
    id: 3157,
    label: "Monte santo de minas - MG",
  },
  {
    id: 3158,
    label: "Monte santo do tocantins - TO",
  },
  {
    id: 3159,
    label: "Monte sião - MG",
  },
  {
    id: 3160,
    label: "Monteiro - PB",
  },
  {
    id: 3161,
    label: "Monteiro lobato - SP",
  },
  {
    id: 3162,
    label: "Monteirópolis - AL",
  },
  {
    id: 3163,
    label: "Montenegro - RS",
  },
  {
    id: 3164,
    label: "Montes altos - MA",
  },
  {
    id: 3165,
    label: "Montes claros - MG",
  },
  {
    id: 3166,
    label: "Montes claros de goiás - GO",
  },
  {
    id: 3167,
    label: "Montezuma - MG",
  },
  {
    id: 3168,
    label: "Montividiu - GO",
  },
  {
    id: 3169,
    label: "Montividiu do norte - GO",
  },
  {
    id: 3170,
    label: "Morada nova - CE",
  },
  {
    id: 3171,
    label: "Morada nova de minas - MG",
  },
  {
    id: 3172,
    label: "Moraújo - CE",
  },
  {
    id: 3173,
    label: "Moreilândia - PE",
  },
  {
    id: 3174,
    label: "Moreira sales - PR",
  },
  {
    id: 3175,
    label: "Moreno - PE",
  },
  {
    id: 3176,
    label: "Mormaço - RS",
  },
  {
    id: 3177,
    label: "Morpará - BA",
  },
  {
    id: 3178,
    label: "Morretes - PR",
  },
  {
    id: 3179,
    label: "Morrinhos - CE",
  },
  {
    id: 3180,
    label: "Morrinhos - GO",
  },
  {
    id: 3181,
    label: "Morrinhos do sul - RS",
  },
  {
    id: 3182,
    label: "Morro agudo - SP",
  },
  {
    id: 3183,
    label: "Morro agudo de goiás - GO",
  },
  {
    id: 3184,
    label: "Morro cabeça no tempo - PI",
  },
  {
    id: 3185,
    label: "Morro da fumaça - SC",
  },
  {
    id: 3186,
    label: "Morro da garça - MG",
  },
  {
    id: 3187,
    label: "Morro do chapéu - BA",
  },
  {
    id: 3188,
    label: "Morro do chapéu do piauí - PI",
  },
  {
    id: 3189,
    label: "Morro do pilar - MG",
  },
  {
    id: 3190,
    label: "Morro grande - SC",
  },
  {
    id: 3191,
    label: "Morro redondo - RS",
  },
  {
    id: 3192,
    label: "Morro reuter - RS",
  },
  {
    id: 3193,
    label: "Morros - MA",
  },
  {
    id: 3194,
    label: "Mortugaba - BA",
  },
  {
    id: 3195,
    label: "Morungaba - SP",
  },
  {
    id: 3196,
    label: "Mossâmedes - GO",
  },
  {
    id: 3197,
    label: "Mossoró - RN",
  },
  {
    id: 3198,
    label: "Mostardas - RS",
  },
  {
    id: 3199,
    label: "Motuca - SP",
  },
  {
    id: 3200,
    label: "Mozarlândia - GO",
  },
  {
    id: 3201,
    label: "Muaná - PA",
  },
  {
    id: 3202,
    label: "Mucajaí - RR",
  },
  {
    id: 3203,
    label: "Mucambo - CE",
  },
  {
    id: 3204,
    label: "Mucugê - BA",
  },
  {
    id: 3205,
    label: "Muçum - RS",
  },
  {
    id: 3206,
    label: "Mucuri - BA",
  },
  {
    id: 3207,
    label: "Mucurici - ES",
  },
  {
    id: 3208,
    label: "Muitos capões - RS",
  },
  {
    id: 3209,
    label: "Muliterno - RS",
  },
  {
    id: 3210,
    label: "Mulungu - CE",
  },
  {
    id: 3211,
    label: "Mulungu - PB",
  },
  {
    id: 3212,
    label: "Mulungu do morro - BA",
  },
  {
    id: 3213,
    label: "Mundo novo - BA",
  },
  {
    id: 3214,
    label: "Mundo novo - GO",
  },
  {
    id: 3215,
    label: "Mundo novo - MS",
  },
  {
    id: 3216,
    label: "Munhoz - MG",
  },
  {
    id: 3217,
    label: "Munhoz de melo - PR",
  },
  {
    id: 3218,
    label: "Muniz ferreira - BA",
  },
  {
    id: 3219,
    label: "Muniz freire - ES",
  },
  {
    id: 3220,
    label: "Muquém do são francisco - BA",
  },
  {
    id: 3221,
    label: "Muqui - ES",
  },
  {
    id: 3222,
    label: "Muriaé - MG",
  },
  {
    id: 3223,
    label: "Muribeca - SE",
  },
  {
    id: 3224,
    label: "Murici - AL",
  },
  {
    id: 3225,
    label: "Murici dos portelas - PI",
  },
  {
    id: 3226,
    label: "Muricilândia - TO",
  },
  {
    id: 3227,
    label: "Muritiba - BA",
  },
  {
    id: 3228,
    label: "Murutinga do sul - SP",
  },
  {
    id: 3229,
    label: "Mutuípe - BA",
  },
  {
    id: 3230,
    label: "Mutum - MG",
  },
  {
    id: 3231,
    label: "Mutunópolis - GO",
  },
  {
    id: 3232,
    label: "Muzambinho - MG",
  },
  {
    id: 3233,
    label: "Nacip raydan - MG",
  },
  {
    id: 3234,
    label: "Nantes - SP",
  },
  {
    id: 3235,
    label: "Nanuque - MG",
  },
  {
    id: 3236,
    label: "Não-me-toque - RS",
  },
  {
    id: 3237,
    label: "Naque - MG",
  },
  {
    id: 3238,
    label: "Narandiba - SP",
  },
  {
    id: 3239,
    label: "Natal - RN",
  },
  {
    id: 3240,
    label: "Natalândia - MG",
  },
  {
    id: 3241,
    label: "Natércia - MG",
  },
  {
    id: 3242,
    label: "Natividade - RJ",
  },
  {
    id: 3243,
    label: "Natividade - TO",
  },
  {
    id: 3244,
    label: "Natividade da serra - SP",
  },
  {
    id: 3245,
    label: "Natuba - PB",
  },
  {
    id: 3246,
    label: "Navegantes - SC",
  },
  {
    id: 3247,
    label: "Naviraí - MS",
  },
  {
    id: 3248,
    label: "Nazaré - BA",
  },
  {
    id: 3249,
    label: "Nazaré - TO",
  },
  {
    id: 3250,
    label: "Nazaré da mata - PE",
  },
  {
    id: 3251,
    label: "Nazaré do piauí - PI",
  },
  {
    id: 3252,
    label: "Nazaré paulista - SP",
  },
  {
    id: 3253,
    label: "Nazareno - MG",
  },
  {
    id: 3254,
    label: "Nazarezinho - PB",
  },
  {
    id: 3255,
    label: "Nazária - PI",
  },
  {
    id: 3256,
    label: "Nazário - GO",
  },
  {
    id: 3257,
    label: "Neópolis - SE",
  },
  {
    id: 3258,
    label: "Nepomuceno - MG",
  },
  {
    id: 3259,
    label: "Nerópolis - GO",
  },
  {
    id: 3260,
    label: "Neves paulista - SP",
  },
  {
    id: 3261,
    label: "Nhamundá - AM",
  },
  {
    id: 3262,
    label: "Nhandeara - SP",
  },
  {
    id: 3263,
    label: "Nicolau vergueiro - RS",
  },
  {
    id: 3264,
    label: "Nilo peçanha - BA",
  },
  {
    id: 3265,
    label: "Nilópolis - RJ",
  },
  {
    id: 3266,
    label: "Nina rodrigues - MA",
  },
  {
    id: 3267,
    label: "Ninheira - MG",
  },
  {
    id: 3268,
    label: "Nioaque - MS",
  },
  {
    id: 3269,
    label: "Nipoã - SP",
  },
  {
    id: 3270,
    label: "Niquelândia - GO",
  },
  {
    id: 3271,
    label: "Nísia floresta - RN",
  },
  {
    id: 3272,
    label: "Niterói - RJ",
  },
  {
    id: 3273,
    label: "Nobres - MT",
  },
  {
    id: 3274,
    label: "Nonoai - RS",
  },
  {
    id: 3275,
    label: "Nordestina - BA",
  },
  {
    id: 3276,
    label: "Normandia - RR",
  },
  {
    id: 3277,
    label: "Nortelândia - MT",
  },
  {
    id: 3278,
    label: "Nossa senhora aparecida - SE",
  },
  {
    id: 3279,
    label: "Nossa senhora da glória - SE",
  },
  {
    id: 3280,
    label: "Nossa senhora das dores - SE",
  },
  {
    id: 3281,
    label: "Nossa senhora das graças - PR",
  },
  {
    id: 3282,
    label: "Nossa senhora de lourdes - SE",
  },
  {
    id: 3283,
    label: "Nossa senhora de nazaré - PI",
  },
  {
    id: 3284,
    label: "Nossa senhora do livramento - MT",
  },
  {
    id: 3285,
    label: "Nossa senhora do socorro - SE",
  },
  {
    id: 3286,
    label: "Nossa senhora dos remédios - PI",
  },
  {
    id: 3287,
    label: "Nova aliança - SP",
  },
  {
    id: 3288,
    label: "Nova aliança do ivaí - PR",
  },
  {
    id: 3289,
    label: "Nova alvorada - RS",
  },
  {
    id: 3290,
    label: "Nova alvorada do sul - MS",
  },
  {
    id: 3291,
    label: "Nova américa - GO",
  },
  {
    id: 3292,
    label: "Nova américa da colina - PR",
  },
  {
    id: 3293,
    label: "Nova andradina - MS",
  },
  {
    id: 3294,
    label: "Nova araçá - RS",
  },
  {
    id: 3295,
    label: "Nova aurora - GO",
  },
  {
    id: 3296,
    label: "Nova aurora - PR",
  },
  {
    id: 3297,
    label: "Nova bandeirantes - MT",
  },
  {
    id: 3298,
    label: "Nova bassano - RS",
  },
  {
    id: 3299,
    label: "Nova belém - MG",
  },
  {
    id: 3300,
    label: "Nova boa vista - RS",
  },
  {
    id: 3301,
    label: "Nova brasilândia - MT",
  },
  {
    id: 3302,
    label: "Nova brasilândia d'oeste - RO",
  },
  {
    id: 3303,
    label: "Nova bréscia - RS",
  },
  {
    id: 3304,
    label: "Nova campina - SP",
  },
  {
    id: 3305,
    label: "Nova canaã - BA",
  },
  {
    id: 3306,
    label: "Nova canaã do norte - MT",
  },
  {
    id: 3307,
    label: "Nova canaã paulista - SP",
  },
  {
    id: 3308,
    label: "Nova candelária - RS",
  },
  {
    id: 3309,
    label: "Nova cantu - PR",
  },
  {
    id: 3310,
    label: "Nova castilho - SP",
  },
  {
    id: 3311,
    label: "Nova colinas - MA",
  },
  {
    id: 3312,
    label: "Nova crixás - GO",
  },
  {
    id: 3313,
    label: "Nova cruz - RN",
  },
  {
    id: 3314,
    label: "Nova era - MG",
  },
  {
    id: 3315,
    label: "Nova erechim - SC",
  },
  {
    id: 3316,
    label: "Nova esperança - PR",
  },
  {
    id: 3317,
    label: "Nova esperança do piriá - PA",
  },
  {
    id: 3318,
    label: "Nova esperança do sudoeste - PR",
  },
  {
    id: 3319,
    label: "Nova esperança do sul - RS",
  },
  {
    id: 3320,
    label: "Nova europa - SP",
  },
  {
    id: 3321,
    label: "Nova fátima - BA",
  },
  {
    id: 3322,
    label: "Nova fátima - PR",
  },
  {
    id: 3323,
    label: "Nova floresta - PB",
  },
  {
    id: 3324,
    label: "Nova friburgo - RJ",
  },
  {
    id: 3325,
    label: "Nova glória - GO",
  },
  {
    id: 3326,
    label: "Nova granada - SP",
  },
  {
    id: 3327,
    label: "Nova guarita - MT",
  },
  {
    id: 3328,
    label: "Nova guataporanga - SP",
  },
  {
    id: 3329,
    label: "Nova hartz - RS",
  },
  {
    id: 3330,
    label: "Nova ibiá - BA",
  },
  {
    id: 3331,
    label: "Nova iguaçu - RJ",
  },
  {
    id: 3332,
    label: "Nova iguaçu de goiás - GO",
  },
  {
    id: 3333,
    label: "Nova independência - SP",
  },
  {
    id: 3334,
    label: "Nova iorque - MA",
  },
  {
    id: 3335,
    label: "Nova ipixuna - PA",
  },
  {
    id: 3336,
    label: "Nova itaberaba - SC",
  },
  {
    id: 3337,
    label: "Nova itarana - BA",
  },
  {
    id: 3338,
    label: "Nova lacerda - MT",
  },
  {
    id: 3339,
    label: "Nova laranjeiras - PR",
  },
  {
    id: 3340,
    label: "Nova lima - MG",
  },
  {
    id: 3341,
    label: "Nova londrina - PR",
  },
  {
    id: 3342,
    label: "Nova luzitânia - SP",
  },
  {
    id: 3343,
    label: "Nova mamoré - RO",
  },
  {
    id: 3344,
    label: "Nova marilândia - MT",
  },
  {
    id: 3345,
    label: "Nova maringá - MT",
  },
  {
    id: 3346,
    label: "Nova módica - MG",
  },
  {
    id: 3347,
    label: "Nova monte verde - MT",
  },
  {
    id: 3348,
    label: "Nova mutum - MT",
  },
  {
    id: 3349,
    label: "Nova nazaré - MT",
  },
  {
    id: 3350,
    label: "Nova odessa - SP",
  },
  {
    id: 3351,
    label: "Nova olímpia - MT",
  },
  {
    id: 3352,
    label: "Nova olímpia - PR",
  },
  {
    id: 3353,
    label: "Nova olinda - CE",
  },
  {
    id: 3354,
    label: "Nova olinda - PB",
  },
  {
    id: 3355,
    label: "Nova olinda - TO",
  },
  {
    id: 3356,
    label: "Nova olinda do maranhão - MA",
  },
  {
    id: 3357,
    label: "Nova olinda do norte - AM",
  },
  {
    id: 3358,
    label: "Nova pádua - RS",
  },
  {
    id: 3359,
    label: "Nova palma - RS",
  },
  {
    id: 3360,
    label: "Nova palmeira - PB",
  },
  {
    id: 3361,
    label: "Nova petrópolis - RS",
  },
  {
    id: 3362,
    label: "Nova ponte - MG",
  },
  {
    id: 3363,
    label: "Nova porteirinha - MG",
  },
  {
    id: 3364,
    label: "Nova prata - RS",
  },
  {
    id: 3365,
    label: "Nova prata do iguaçu - PR",
  },
  {
    id: 3366,
    label: "Nova ramada - RS",
  },
  {
    id: 3367,
    label: "Nova redenção - BA",
  },
  {
    id: 3368,
    label: "Nova resende - MG",
  },
  {
    id: 3369,
    label: "Nova roma - GO",
  },
  {
    id: 3370,
    label: "Nova roma do sul - RS",
  },
  {
    id: 3371,
    label: "Nova rosalândia - TO",
  },
  {
    id: 3372,
    label: "Nova russas - CE",
  },
  {
    id: 3373,
    label: "Nova santa bárbara - PR",
  },
  {
    id: 3374,
    label: "Nova santa helena - MT",
  },
  {
    id: 3375,
    label: "Nova santa rita - PI",
  },
  {
    id: 3376,
    label: "Nova santa rita - RS",
  },
  {
    id: 3377,
    label: "Nova santa rosa - PR",
  },
  {
    id: 3378,
    label: "Nova serrana - MG",
  },
  {
    id: 3379,
    label: "Nova soure - BA",
  },
  {
    id: 3380,
    label: "Nova tebas - PR",
  },
  {
    id: 3381,
    label: "Nova timboteua - PA",
  },
  {
    id: 3382,
    label: "Nova trento - SC",
  },
  {
    id: 3383,
    label: "Nova ubiratã - MT",
  },
  {
    id: 3384,
    label: "Nova união - MG",
  },
  {
    id: 3385,
    label: "Nova união - RO",
  },
  {
    id: 3386,
    label: "Nova venécia - ES",
  },
  {
    id: 3387,
    label: "Nova veneza - GO",
  },
  {
    id: 3388,
    label: "Nova veneza - SC",
  },
  {
    id: 3389,
    label: "Nova viçosa - BA",
  },
  {
    id: 3390,
    label: "Nova xavantina - MT",
  },
  {
    id: 3391,
    label: "Novais - SP",
  },
  {
    id: 3392,
    label: "Novo acordo - TO",
  },
  {
    id: 3393,
    label: "Novo airão - AM",
  },
  {
    id: 3394,
    label: "Novo alegre - TO",
  },
  {
    id: 3395,
    label: "Novo aripuanã - AM",
  },
  {
    id: 3396,
    label: "Novo barreiro - RS",
  },
  {
    id: 3397,
    label: "Novo brasil - GO",
  },
  {
    id: 3398,
    label: "Novo cabrais - RS",
  },
  {
    id: 3399,
    label: "Novo cruzeiro - MG",
  },
  {
    id: 3400,
    label: "Novo gama - GO",
  },
  {
    id: 3401,
    label: "Novo hamburgo - RS",
  },
  {
    id: 3402,
    label: "Novo horizonte - BA",
  },
  {
    id: 3403,
    label: "Novo horizonte - SC",
  },
  {
    id: 3404,
    label: "Novo horizonte - SP",
  },
  {
    id: 3405,
    label: "Novo horizonte do norte - MT",
  },
  {
    id: 3406,
    label: "Novo horizonte do oeste - RO",
  },
  {
    id: 3407,
    label: "Novo horizonte do sul - MS",
  },
  {
    id: 3408,
    label: "Novo itacolomi - PR",
  },
  {
    id: 3409,
    label: "Novo jardim - TO",
  },
  {
    id: 3410,
    label: "Novo lino - AL",
  },
  {
    id: 3411,
    label: "Novo machado - RS",
  },
  {
    id: 3412,
    label: "Novo mundo - MT",
  },
  {
    id: 3413,
    label: "Novo oriente - CE",
  },
  {
    id: 3414,
    label: "Novo oriente de minas - MG",
  },
  {
    id: 3415,
    label: "Novo oriente do piauí - PI",
  },
  {
    id: 3416,
    label: "Novo planalto - GO",
  },
  {
    id: 3417,
    label: "Novo progresso - PA",
  },
  {
    id: 3418,
    label: "Novo repartimento - PA",
  },
  {
    id: 3419,
    label: "Novo santo antônio - MT",
  },
  {
    id: 3420,
    label: "Novo santo antônio - PI",
  },
  {
    id: 3421,
    label: "Novo são joaquim - MT",
  },
  {
    id: 3422,
    label: "Novo tiradentes - RS",
  },
  {
    id: 3423,
    label: "Novo triunfo - BA",
  },
  {
    id: 3424,
    label: "Novo xingu - RS",
  },
  {
    id: 3425,
    label: "Novorizonte - MG",
  },
  {
    id: 3426,
    label: "Nuporanga - SP",
  },
  {
    id: 3427,
    label: "Óbidos - PA",
  },
  {
    id: 3428,
    label: "Ocara - CE",
  },
  {
    id: 3429,
    label: "Ocauçu - SP",
  },
  {
    id: 3430,
    label: "Oeiras - PI",
  },
  {
    id: 3431,
    label: "Oeiras do pará - PA",
  },
  {
    id: 3432,
    label: "Oiapoque - AP",
  },
  {
    id: 3433,
    label: "Olaria - MG",
  },
  {
    id: 3434,
    label: "Óleo - SP",
  },
  {
    id: 3435,
    label: "Olho d'água - PB",
  },
  {
    id: 3436,
    label: "Olho d'água das cunhãs - MA",
  },
  {
    id: 3437,
    label: "Olho d'água das flores - AL",
  },
  {
    id: 3438,
    label: "Olho d'água do borges - RN",
  },
  {
    id: 3439,
    label: "Olho d'água do casado - AL",
  },
  {
    id: 3440,
    label: "Olho d'água do piauí - PI",
  },
  {
    id: 3441,
    label: "Olho d'água grande - AL",
  },
  {
    id: 3442,
    label: "Olhos d'água - MG",
  },
  {
    id: 3443,
    label: "Olímpia - SP",
  },
  {
    id: 3444,
    label: "Olímpio noronha - MG",
  },
  {
    id: 3445,
    label: "Olinda - PE",
  },
  {
    id: 3446,
    label: "Olinda nova do maranhão - MA",
  },
  {
    id: 3447,
    label: "Olindina - BA",
  },
  {
    id: 3448,
    label: "Olivedos - PB",
  },
  {
    id: 3449,
    label: "Oliveira - MG",
  },
  {
    id: 3450,
    label: "Oliveira de fátima - TO",
  },
  {
    id: 3451,
    label: "Oliveira dos brejinhos - BA",
  },
  {
    id: 3452,
    label: "Oliveira fortes - MG",
  },
  {
    id: 3453,
    label: "Olivença - AL",
  },
  {
    id: 3454,
    label: "Onça de pitangui - MG",
  },
  {
    id: 3455,
    label: "Onda verde - SP",
  },
  {
    id: 3456,
    label: "Oratórios - MG",
  },
  {
    id: 3457,
    label: "Oriente - SP",
  },
  {
    id: 3458,
    label: "Orindiúva - SP",
  },
  {
    id: 3459,
    label: "Oriximiná - PA",
  },
  {
    id: 3460,
    label: "Orizânia - MG",
  },
  {
    id: 3461,
    label: "Orizona - GO",
  },
  {
    id: 3462,
    label: "Orlândia - SP",
  },
  {
    id: 3463,
    label: "Orleans - SC",
  },
  {
    id: 3464,
    label: "Orobó - PE",
  },
  {
    id: 3465,
    label: "Orocó - PE",
  },
  {
    id: 3466,
    label: "Orós - CE",
  },
  {
    id: 3467,
    label: "Ortigueira - PR",
  },
  {
    id: 3468,
    label: "Osasco - SP",
  },
  {
    id: 3469,
    label: "Oscar bressane - SP",
  },
  {
    id: 3470,
    label: "Osório - RS",
  },
  {
    id: 3471,
    label: "Osvaldo cruz - SP",
  },
  {
    id: 3472,
    label: "Otacílio costa - SC",
  },
  {
    id: 3473,
    label: "Ourém - PA",
  },
  {
    id: 3474,
    label: "Ouriçangas - BA",
  },
  {
    id: 3475,
    label: "Ouricuri - PE",
  },
  {
    id: 3476,
    label: "Ourilândia do norte - PA",
  },
  {
    id: 3477,
    label: "Ourinhos - SP",
  },
  {
    id: 3478,
    label: "Ourizona - PR",
  },
  {
    id: 3479,
    label: "Ouro - SC",
  },
  {
    id: 3480,
    label: "Ouro branco - AL",
  },
  {
    id: 3481,
    label: "Ouro branco - MG",
  },
  {
    id: 3482,
    label: "Ouro branco - RN",
  },
  {
    id: 3483,
    label: "Ouro fino - MG",
  },
  {
    id: 3484,
    label: "Ouro preto - MG",
  },
  {
    id: 3485,
    label: "Ouro preto do oeste - RO",
  },
  {
    id: 3486,
    label: "Ouro velho - PB",
  },
  {
    id: 3487,
    label: "Ouro verde - SC",
  },
  {
    id: 3488,
    label: "Ouro verde - SP",
  },
  {
    id: 3489,
    label: "Ouro verde de goiás - GO",
  },
  {
    id: 3490,
    label: "Ouro verde de minas - MG",
  },
  {
    id: 3491,
    label: "Ouro verde do oeste - PR",
  },
  {
    id: 3492,
    label: "Ouroeste - SP",
  },
  {
    id: 3493,
    label: "Ourolândia - BA",
  },
  {
    id: 3494,
    label: "Ouvidor - GO",
  },
  {
    id: 3495,
    label: "Pacaembu - SP",
  },
  {
    id: 3496,
    label: "Pacajá - PA",
  },
  {
    id: 3497,
    label: "Pacajus - CE",
  },
  {
    id: 3498,
    label: "Pacaraima - RR",
  },
  {
    id: 3499,
    label: "Pacatuba - CE",
  },
  {
    id: 3500,
    label: "Pacatuba - SE",
  },
  {
    id: 3501,
    label: "Paço do lumiar - MA",
  },
  {
    id: 3502,
    label: "Pacoti - CE",
  },
  {
    id: 3503,
    label: "Pacujá - CE",
  },
  {
    id: 3504,
    label: "Padre bernardo - GO",
  },
  {
    id: 3505,
    label: "Padre carvalho - MG",
  },
  {
    id: 3506,
    label: "Padre marcos - PI",
  },
  {
    id: 3507,
    label: "Padre paraíso - MG",
  },
  {
    id: 3508,
    label: "Paes landim - PI",
  },
  {
    id: 3509,
    label: "Pai pedro - MG",
  },
  {
    id: 3510,
    label: "Paial - SC",
  },
  {
    id: 3511,
    label: "Paiçandu - PR",
  },
  {
    id: 3512,
    label: "Paim filho - RS",
  },
  {
    id: 3513,
    label: "Paineiras - MG",
  },
  {
    id: 3514,
    label: "Painel - SC",
  },
  {
    id: 3515,
    label: "Pains - MG",
  },
  {
    id: 3516,
    label: "Paiva - MG",
  },
  {
    id: 3517,
    label: "Pajeú do piauí - PI",
  },
  {
    id: 3518,
    label: "Palestina - AL",
  },
  {
    id: 3519,
    label: "Palestina - SP",
  },
  {
    id: 3520,
    label: "Palestina de goiás - GO",
  },
  {
    id: 3521,
    label: "Palestina do pará - PA",
  },
  {
    id: 3522,
    label: "Palhano - CE",
  },
  {
    id: 3523,
    label: "Palhoça - SC",
  },
  {
    id: 3524,
    label: "Palma - MG",
  },
  {
    id: 3525,
    label: "Palma sola - SC",
  },
  {
    id: 3526,
    label: "Palmácia - CE",
  },
  {
    id: 3527,
    label: "Palmares - PE",
  },
  {
    id: 3528,
    label: "Palmares do sul - RS",
  },
  {
    id: 3529,
    label: "Palmares paulista - SP",
  },
  {
    id: 3530,
    label: "Palmas - PR",
  },
  {
    id: 3531,
    label: "Palmas - TO",
  },
  {
    id: 3532,
    label: "Palmas de monte alto - BA",
  },
  {
    id: 3533,
    label: "Palmeira - PR",
  },
  {
    id: 3534,
    label: "Palmeira - SC",
  },
  {
    id: 3535,
    label: "Palmeira d'oeste - SP",
  },
  {
    id: 3536,
    label: "Palmeira das missões - RS",
  },
  {
    id: 3537,
    label: "Palmeira do piauí - PI",
  },
  {
    id: 3538,
    label: "Palmeira dos índios - AL",
  },
  {
    id: 3539,
    label: "Palmeirais - PI",
  },
  {
    id: 3540,
    label: "Palmeirândia - MA",
  },
  {
    id: 3541,
    label: "Palmeirante - TO",
  },
  {
    id: 3542,
    label: "Palmeiras - BA",
  },
  {
    id: 3543,
    label: "Palmeiras de goiás - GO",
  },
  {
    id: 3544,
    label: "Palmeiras do tocantins - TO",
  },
  {
    id: 3545,
    label: "Palmeirina - PE",
  },
  {
    id: 3546,
    label: "Palmeirópolis - TO",
  },
  {
    id: 3547,
    label: "Palmelo - GO",
  },
  {
    id: 3548,
    label: "Palminópolis - GO",
  },
  {
    id: 3549,
    label: "Palmital - PR",
  },
  {
    id: 3550,
    label: "Palmital - SP",
  },
  {
    id: 3551,
    label: "Palmitinho - RS",
  },
  {
    id: 3552,
    label: "Palmitos - SC",
  },
  {
    id: 3553,
    label: "Palmópolis - MG",
  },
  {
    id: 3554,
    label: "Palotina - PR",
  },
  {
    id: 3555,
    label: "Panamá - GO",
  },
  {
    id: 3556,
    label: "Panambi - RS",
  },
  {
    id: 3557,
    label: "Pancas - ES",
  },
  {
    id: 3558,
    label: "Panelas - PE",
  },
  {
    id: 3559,
    label: "Panorama - SP",
  },
  {
    id: 3560,
    label: "Pantano grande - RS",
  },
  {
    id: 3561,
    label: "Pão de açúcar - AL",
  },
  {
    id: 3562,
    label: "Papagaios - MG",
  },
  {
    id: 3563,
    label: "Papanduva - SC",
  },
  {
    id: 3564,
    label: "Paquetá - PI",
  },
  {
    id: 3565,
    label: "Pará de minas - MG",
  },
  {
    id: 3566,
    label: "Paracambi - RJ",
  },
  {
    id: 3567,
    label: "Paracatu - MG",
  },
  {
    id: 3568,
    label: "Paracuru - CE",
  },
  {
    id: 3569,
    label: "Paragominas - PA",
  },
  {
    id: 3570,
    label: "Paraguaçu - MG",
  },
  {
    id: 3571,
    label: "Paraguaçu paulista - SP",
  },
  {
    id: 3572,
    label: "Paraí - RS",
  },
  {
    id: 3573,
    label: "Paraíba do sul - RJ",
  },
  {
    id: 3574,
    label: "Paraibano - MA",
  },
  {
    id: 3575,
    label: "Paraibuna - SP",
  },
  {
    id: 3576,
    label: "Paraipaba - CE",
  },
  {
    id: 3577,
    label: "Paraíso - SC",
  },
  {
    id: 3578,
    label: "Paraíso - SP",
  },
  {
    id: 3579,
    label: "Paraíso das águas - MS",
  },
  {
    id: 3580,
    label: "Paraíso do norte - PR",
  },
  {
    id: 3581,
    label: "Paraíso do sul - RS",
  },
  {
    id: 3582,
    label: "Paraíso do tocantins - TO",
  },
  {
    id: 3583,
    label: "Paraisópolis - MG",
  },
  {
    id: 3584,
    label: "Parambu - CE",
  },
  {
    id: 3585,
    label: "Paramirim - BA",
  },
  {
    id: 3586,
    label: "Paramoti - CE",
  },
  {
    id: 3587,
    label: "Paraná - RN",
  },
  {
    id: 3588,
    label: "Paranã - TO",
  },
  {
    id: 3589,
    label: "Paranacity - PR",
  },
  {
    id: 3590,
    label: "Paranaguá - PR",
  },
  {
    id: 3591,
    label: "Paranaíba - MS",
  },
  {
    id: 3592,
    label: "Paranaiguara - GO",
  },
  {
    id: 3593,
    label: "Paranaíta - MT",
  },
  {
    id: 3594,
    label: "Paranapanema - SP",
  },
  {
    id: 3595,
    label: "Paranapoema - PR",
  },
  {
    id: 3596,
    label: "Paranapuã - SP",
  },
  {
    id: 3597,
    label: "Paranatama - PE",
  },
  {
    id: 3598,
    label: "Paranatinga - MT",
  },
  {
    id: 3599,
    label: "Paranavaí - PR",
  },
  {
    id: 3600,
    label: "Paranhos - MS",
  },
  {
    id: 3601,
    label: "Paraopeba - MG",
  },
  {
    id: 3602,
    label: "Parapuã - SP",
  },
  {
    id: 3603,
    label: "Parari - PB",
  },
  {
    id: 3604,
    label: "Paratinga - BA",
  },
  {
    id: 3605,
    label: "Paraty - RJ",
  },
  {
    id: 3606,
    label: "Paraú - RN",
  },
  {
    id: 3607,
    label: "Parauapebas - PA",
  },
  {
    id: 3608,
    label: "Paraúna - GO",
  },
  {
    id: 3609,
    label: "Parazinho - RN",
  },
  {
    id: 3610,
    label: "Pardinho - SP",
  },
  {
    id: 3611,
    label: "Pareci novo - RS",
  },
  {
    id: 3612,
    label: "Parecis - RO",
  },
  {
    id: 3613,
    label: "Parelhas - RN",
  },
  {
    id: 3614,
    label: "Pariconha - AL",
  },
  {
    id: 3615,
    label: "Parintins - AM",
  },
  {
    id: 3616,
    label: "Paripiranga - BA",
  },
  {
    id: 3617,
    label: "Paripueira - AL",
  },
  {
    id: 3618,
    label: "Pariquera-açu - SP",
  },
  {
    id: 3619,
    label: "Parisi - SP",
  },
  {
    id: 3620,
    label: "Parnaguá - PI",
  },
  {
    id: 3621,
    label: "Parnaíba - PI",
  },
  {
    id: 3622,
    label: "Parnamirim - PE",
  },
  {
    id: 3623,
    label: "Parnamirim - RN",
  },
  {
    id: 3624,
    label: "Parnarama - MA",
  },
  {
    id: 3625,
    label: "Parobé - RS",
  },
  {
    id: 3626,
    label: "Passa e fica - RN",
  },
  {
    id: 3627,
    label: "Passa quatro - MG",
  },
  {
    id: 3628,
    label: "Passa sete - RS",
  },
  {
    id: 3629,
    label: "Passa tempo - MG",
  },
  {
    id: 3630,
    label: "Passa vinte - MG",
  },
  {
    id: 3631,
    label: "Passabém - MG",
  },
  {
    id: 3632,
    label: "Passagem - PB",
  },
  {
    id: 3633,
    label: "Passagem - RN",
  },
  {
    id: 3634,
    label: "Passagem franca - MA",
  },
  {
    id: 3635,
    label: "Passagem franca do piauí - PI",
  },
  {
    id: 3636,
    label: "Passira - PE",
  },
  {
    id: 3637,
    label: "Passo de camaragibe - AL",
  },
  {
    id: 3638,
    label: "Passo de torres - SC",
  },
  {
    id: 3639,
    label: "Passo do sobrado - RS",
  },
  {
    id: 3640,
    label: "Passo fundo - RS",
  },
  {
    id: 3641,
    label: "Passos - MG",
  },
  {
    id: 3642,
    label: "Passos maia - SC",
  },
  {
    id: 3643,
    label: "Pastos bons - MA",
  },
  {
    id: 3644,
    label: "Patis - MG",
  },
  {
    id: 3645,
    label: "Pato bragado - PR",
  },
  {
    id: 3646,
    label: "Pato branco - PR",
  },
  {
    id: 3647,
    label: "Patos - PB",
  },
  {
    id: 3648,
    label: "Patos de minas - MG",
  },
  {
    id: 3649,
    label: "Patos do piauí - PI",
  },
  {
    id: 3650,
    label: "Patrocínio - MG",
  },
  {
    id: 3651,
    label: "Patrocínio do muriaé - MG",
  },
  {
    id: 3652,
    label: "Patrocínio paulista - SP",
  },
  {
    id: 3653,
    label: "Patu - RN",
  },
  {
    id: 3654,
    label: "Paty do alferes - RJ",
  },
  {
    id: 3655,
    label: "Pau brasil - BA",
  },
  {
    id: 3656,
    label: "Pau d'arco - PA",
  },
  {
    id: 3657,
    label: "Pau d'arco - TO",
  },
  {
    id: 3658,
    label: "Pau d'arco do piauí - PI",
  },
  {
    id: 3659,
    label: "Pau dos ferros - RN",
  },
  {
    id: 3660,
    label: "Paudalho - PE",
  },
  {
    id: 3661,
    label: "Pauini - AM",
  },
  {
    id: 3662,
    label: "Paula cândido - MG",
  },
  {
    id: 3663,
    label: "Paula freitas - PR",
  },
  {
    id: 3664,
    label: "Paulicéia - SP",
  },
  {
    id: 3665,
    label: "Paulínia - SP",
  },
  {
    id: 3666,
    label: "Paulino neves - MA",
  },
  {
    id: 3667,
    label: "Paulista - PB",
  },
  {
    id: 3668,
    label: "Paulista - PE",
  },
  {
    id: 3669,
    label: "Paulistana - PI",
  },
  {
    id: 3670,
    label: "Paulistânia - SP",
  },
  {
    id: 3671,
    label: "Paulistas - MG",
  },
  {
    id: 3672,
    label: "Paulo afonso - BA",
  },
  {
    id: 3673,
    label: "Paulo bento - RS",
  },
  {
    id: 3674,
    label: "Paulo de faria - SP",
  },
  {
    id: 3675,
    label: "Paulo frontin - PR",
  },
  {
    id: 3676,
    label: "Paulo jacinto - AL",
  },
  {
    id: 3677,
    label: "Paulo lopes - SC",
  },
  {
    id: 3678,
    label: "Paulo ramos - MA",
  },
  {
    id: 3679,
    label: "Pavão - MG",
  },
  {
    id: 3680,
    label: "Paverama - RS",
  },
  {
    id: 3681,
    label: "Pavussu - PI",
  },
  {
    id: 3682,
    label: "Pé de serra - BA",
  },
  {
    id: 3683,
    label: "Peabiru - PR",
  },
  {
    id: 3684,
    label: "Peçanha - MG",
  },
  {
    id: 3685,
    label: "Pederneiras - SP",
  },
  {
    id: 3686,
    label: "Pedra - PE",
  },
  {
    id: 3687,
    label: "Pedra azul - MG",
  },
  {
    id: 3688,
    label: "Pedra bela - SP",
  },
  {
    id: 3689,
    label: "Pedra bonita - MG",
  },
  {
    id: 3690,
    label: "Pedra branca - CE",
  },
  {
    id: 3691,
    label: "Pedra branca - PB",
  },
  {
    id: 3692,
    label: "Pedra branca do amapari - AP",
  },
  {
    id: 3693,
    label: "Pedra do anta - MG",
  },
  {
    id: 3694,
    label: "Pedra do indaiá - MG",
  },
  {
    id: 3695,
    label: "Pedra dourada - MG",
  },
  {
    id: 3696,
    label: "Pedra grande - RN",
  },
  {
    id: 3697,
    label: "Pedra lavrada - PB",
  },
  {
    id: 3698,
    label: "Pedra mole - SE",
  },
  {
    id: 3699,
    label: "Pedra preta - MT",
  },
  {
    id: 3700,
    label: "Pedra preta - RN",
  },
  {
    id: 3701,
    label: "Pedralva - MG",
  },
  {
    id: 3702,
    label: "Pedranópolis - SP",
  },
  {
    id: 3703,
    label: "Pedrão - BA",
  },
  {
    id: 3704,
    label: "Pedras altas - RS",
  },
  {
    id: 3705,
    label: "Pedras de fogo - PB",
  },
  {
    id: 3706,
    label: "Pedras de maria da cruz - MG",
  },
  {
    id: 3707,
    label: "Pedras grandes - SC",
  },
  {
    id: 3708,
    label: "Pedregulho - SP",
  },
  {
    id: 3709,
    label: "Pedreira - SP",
  },
  {
    id: 3710,
    label: "Pedreiras - MA",
  },
  {
    id: 3711,
    label: "Pedrinhas - SE",
  },
  {
    id: 3712,
    label: "Pedrinhas paulista - SP",
  },
  {
    id: 3713,
    label: "Pedrinópolis - MG",
  },
  {
    id: 3714,
    label: "Pedro afonso - TO",
  },
  {
    id: 3715,
    label: "Pedro alexandre - BA",
  },
  {
    id: 3716,
    label: "Pedro avelino - RN",
  },
  {
    id: 3717,
    label: "Pedro canário - ES",
  },
  {
    id: 3718,
    label: "Pedro de toledo - SP",
  },
  {
    id: 3719,
    label: "Pedro do rosário - MA",
  },
  {
    id: 3720,
    label: "Pedro gomes - MS",
  },
  {
    id: 3721,
    label: "Pedro ii - PI",
  },
  {
    id: 3722,
    label: "Pedro laurentino - PI",
  },
  {
    id: 3723,
    label: "Pedro leopoldo - MG",
  },
  {
    id: 3724,
    label: "Pedro osório - RS",
  },
  {
    id: 3725,
    label: "Pedro régis - PB",
  },
  {
    id: 3726,
    label: "Pedro teixeira - MG",
  },
  {
    id: 3727,
    label: "Pedro velho - RN",
  },
  {
    id: 3728,
    label: "Peixe - TO",
  },
  {
    id: 3729,
    label: "Peixe-boi - PA",
  },
  {
    id: 3730,
    label: "Peixoto de azevedo - MT",
  },
  {
    id: 3731,
    label: "Pejuçara - RS",
  },
  {
    id: 3732,
    label: "Pelotas - RS",
  },
  {
    id: 3733,
    label: "Penaforte - CE",
  },
  {
    id: 3734,
    label: "Penalva - MA",
  },
  {
    id: 3735,
    label: "Penápolis - SP",
  },
  {
    id: 3736,
    label: "Pendências - RN",
  },
  {
    id: 3737,
    label: "Penedo - AL",
  },
  {
    id: 3738,
    label: "Penha - SC",
  },
  {
    id: 3739,
    label: "Pentecoste - CE",
  },
  {
    id: 3740,
    label: "Pequeri - MG",
  },
  {
    id: 3741,
    label: "Pequi - MG",
  },
  {
    id: 3742,
    label: "Pequizeiro - TO",
  },
  {
    id: 3743,
    label: "Perdigão - MG",
  },
  {
    id: 3744,
    label: "Perdizes - MG",
  },
  {
    id: 3745,
    label: "Perdões - MG",
  },
  {
    id: 3746,
    label: "Pereira barreto - SP",
  },
  {
    id: 3747,
    label: "Pereiras - SP",
  },
  {
    id: 3748,
    label: "Pereiro - CE",
  },
  {
    id: 3749,
    label: "Peri mirim - MA",
  },
  {
    id: 3750,
    label: "Periquito - MG",
  },
  {
    id: 3751,
    label: "Peritiba - SC",
  },
  {
    id: 3752,
    label: "Peritoró - MA",
  },
  {
    id: 3753,
    label: "Perobal - PR",
  },
  {
    id: 3754,
    label: "Pérola - PR",
  },
  {
    id: 3755,
    label: "Pérola d'oeste - PR",
  },
  {
    id: 3756,
    label: "Perolândia - GO",
  },
  {
    id: 3757,
    label: "Peruíbe - SP",
  },
  {
    id: 3758,
    label: "Pescador - MG",
  },
  {
    id: 3759,
    label: "Pescaria brava - SC",
  },
  {
    id: 3760,
    label: "Pesqueira - PE",
  },
  {
    id: 3761,
    label: "Petrolândia - PE",
  },
  {
    id: 3762,
    label: "Petrolândia - SC",
  },
  {
    id: 3763,
    label: "Petrolina - PE",
  },
  {
    id: 3764,
    label: "Petrolina de goiás - GO",
  },
  {
    id: 3765,
    label: "Petrópolis - RJ",
  },
  {
    id: 3766,
    label: "Piaçabuçu - AL",
  },
  {
    id: 3767,
    label: "Piacatu - SP",
  },
  {
    id: 3768,
    label: "Piancó - PB",
  },
  {
    id: 3769,
    label: "Piatã - BA",
  },
  {
    id: 3770,
    label: "Piau - MG",
  },
  {
    id: 3771,
    label: "Picada café - RS",
  },
  {
    id: 3772,
    label: "Piçarra - PA",
  },
  {
    id: 3773,
    label: "Picos - PI",
  },
  {
    id: 3774,
    label: "Picuí - PB",
  },
  {
    id: 3775,
    label: "Piedade - SP",
  },
  {
    id: 3776,
    label: "Piedade de caratinga - MG",
  },
  {
    id: 3777,
    label: "Piedade de ponte nova - MG",
  },
  {
    id: 3778,
    label: "Piedade do rio grande - MG",
  },
  {
    id: 3779,
    label: "Piedade dos gerais - MG",
  },
  {
    id: 3780,
    label: "Piên - PR",
  },
  {
    id: 3781,
    label: "Pilão arcado - BA",
  },
  {
    id: 3782,
    label: "Pilar - AL",
  },
  {
    id: 3783,
    label: "Pilar - PB",
  },
  {
    id: 3784,
    label: "Pilar de goiás - GO",
  },
  {
    id: 3785,
    label: "Pilar do sul - SP",
  },
  {
    id: 3786,
    label: "Pilões - PB",
  },
  {
    id: 3787,
    label: "Pilões - RN",
  },
  {
    id: 3788,
    label: "Pilõezinhos - PB",
  },
  {
    id: 3789,
    label: "Pimenta - MG",
  },
  {
    id: 3790,
    label: "Pimenta bueno - RO",
  },
  {
    id: 3791,
    label: "Pimenteiras - PI",
  },
  {
    id: 3792,
    label: "Pimenteiras do oeste - RO",
  },
  {
    id: 3793,
    label: "Pindaí - BA",
  },
  {
    id: 3794,
    label: "Pindamonhangaba - SP",
  },
  {
    id: 3795,
    label: "Pindaré-mirim - MA",
  },
  {
    id: 3796,
    label: "Pindoba - AL",
  },
  {
    id: 3797,
    label: "Pindobaçu - BA",
  },
  {
    id: 3798,
    label: "Pindorama - SP",
  },
  {
    id: 3799,
    label: "Pindorama do tocantins - TO",
  },
  {
    id: 3800,
    label: "Pindoretama - CE",
  },
  {
    id: 3801,
    label: "Pingo d'água - MG",
  },
  {
    id: 3802,
    label: "Pinhais - PR",
  },
  {
    id: 3803,
    label: "Pinhal - RS",
  },
  {
    id: 3804,
    label: "Pinhal da serra - RS",
  },
  {
    id: 3805,
    label: "Pinhal de são bento - PR",
  },
  {
    id: 3806,
    label: "Pinhal grande - RS",
  },
  {
    id: 3807,
    label: "Pinhalão - PR",
  },
  {
    id: 3808,
    label: "Pinhalzinho - SC",
  },
  {
    id: 3809,
    label: "Pinhalzinho - SP",
  },
  {
    id: 3810,
    label: "Pinhão - PR",
  },
  {
    id: 3811,
    label: "Pinhão - SE",
  },
  {
    id: 3812,
    label: "Pinheiral - RJ",
  },
  {
    id: 3813,
    label: "Pinheirinho do vale - RS",
  },
  {
    id: 3814,
    label: "Pinheiro - MA",
  },
  {
    id: 3815,
    label: "Pinheiro machado - RS",
  },
  {
    id: 3816,
    label: "Pinheiro preto - SC",
  },
  {
    id: 3817,
    label: "Pinheiros - ES",
  },
  {
    id: 3818,
    label: "Pintadas - BA",
  },
  {
    id: 3819,
    label: "Pinto bandeira - RS",
  },
  {
    id: 3820,
    label: "Pintópolis - MG",
  },
  {
    id: 3821,
    label: "Pio ix - PI",
  },
  {
    id: 3822,
    label: "Pio xii - MA",
  },
  {
    id: 3823,
    label: "Piquerobi - SP",
  },
  {
    id: 3824,
    label: "Piquet carneiro - CE",
  },
  {
    id: 3825,
    label: "Piquete - SP",
  },
  {
    id: 3826,
    label: "Piracaia - SP",
  },
  {
    id: 3827,
    label: "Piracanjuba - GO",
  },
  {
    id: 3828,
    label: "Piracema - MG",
  },
  {
    id: 3829,
    label: "Piracicaba - SP",
  },
  {
    id: 3830,
    label: "Piracuruca - PI",
  },
  {
    id: 3831,
    label: "Piraí - RJ",
  },
  {
    id: 3832,
    label: "Piraí do norte - BA",
  },
  {
    id: 3833,
    label: "Piraí do sul - PR",
  },
  {
    id: 3834,
    label: "Piraju - SP",
  },
  {
    id: 3835,
    label: "Pirajuba - MG",
  },
  {
    id: 3836,
    label: "Pirajuí - SP",
  },
  {
    id: 3837,
    label: "Pirambu - SE",
  },
  {
    id: 3838,
    label: "Piranga - MG",
  },
  {
    id: 3839,
    label: "Pirangi - SP",
  },
  {
    id: 3840,
    label: "Piranguçu - MG",
  },
  {
    id: 3841,
    label: "Piranguinho - MG",
  },
  {
    id: 3842,
    label: "Piranhas - AL",
  },
  {
    id: 3843,
    label: "Piranhas - GO",
  },
  {
    id: 3844,
    label: "Pirapemas - MA",
  },
  {
    id: 3845,
    label: "Pirapetinga - MG",
  },
  {
    id: 3846,
    label: "Pirapó - RS",
  },
  {
    id: 3847,
    label: "Pirapora - MG",
  },
  {
    id: 3848,
    label: "Pirapora do bom jesus - SP",
  },
  {
    id: 3849,
    label: "Pirapozinho - SP",
  },
  {
    id: 3850,
    label: "Piraquara - PR",
  },
  {
    id: 3851,
    label: "Piraquê - TO",
  },
  {
    id: 3852,
    label: "Pirassununga - SP",
  },
  {
    id: 3853,
    label: "Piratini - RS",
  },
  {
    id: 3854,
    label: "Piratininga - SP",
  },
  {
    id: 3855,
    label: "Piratuba - SC",
  },
  {
    id: 3856,
    label: "Piraúba - MG",
  },
  {
    id: 3857,
    label: "Pirenópolis - GO",
  },
  {
    id: 3858,
    label: "Pires do rio - GO",
  },
  {
    id: 3859,
    label: "Pires ferreira - CE",
  },
  {
    id: 3860,
    label: "Piripá - BA",
  },
  {
    id: 3861,
    label: "Piripiri - PI",
  },
  {
    id: 3862,
    label: "Piritiba - BA",
  },
  {
    id: 3863,
    label: "Pirpirituba - PB",
  },
  {
    id: 3864,
    label: "Pitanga - PR",
  },
  {
    id: 3865,
    label: "Pitangueiras - PR",
  },
  {
    id: 3866,
    label: "Pitangueiras - SP",
  },
  {
    id: 3867,
    label: "Pitangui - MG",
  },
  {
    id: 3868,
    label: "Pitimbu - PB",
  },
  {
    id: 3869,
    label: "Pium - TO",
  },
  {
    id: 3870,
    label: "Piúma - ES",
  },
  {
    id: 3871,
    label: "Piumhi - MG",
  },
  {
    id: 3872,
    label: "Placas - PA",
  },
  {
    id: 3873,
    label: "Plácido de castro - AC",
  },
  {
    id: 3874,
    label: "Planaltina - GO",
  },
  {
    id: 3875,
    label: "Planaltina do paraná - PR",
  },
  {
    id: 3876,
    label: "Planaltino - BA",
  },
  {
    id: 3877,
    label: "Planalto - BA",
  },
  {
    id: 3878,
    label: "Planalto - PR",
  },
  {
    id: 3879,
    label: "Planalto - RS",
  },
  {
    id: 3880,
    label: "Planalto - SP",
  },
  {
    id: 3881,
    label: "Planalto alegre - SC",
  },
  {
    id: 3882,
    label: "Planalto da serra - MT",
  },
  {
    id: 3883,
    label: "Planura - MG",
  },
  {
    id: 3884,
    label: "Platina - SP",
  },
  {
    id: 3885,
    label: "Poá - SP",
  },
  {
    id: 3886,
    label: "Poção - PE",
  },
  {
    id: 3887,
    label: "Poção de pedras - MA",
  },
  {
    id: 3888,
    label: "Pocinhos - PB",
  },
  {
    id: 3889,
    label: "Poço branco - RN",
  },
  {
    id: 3890,
    label: "Poço dantas - PB",
  },
  {
    id: 3891,
    label: "Poço das antas - RS",
  },
  {
    id: 3892,
    label: "Poço das trincheiras - AL",
  },
  {
    id: 3893,
    label: "Poço de josé de moura - PB",
  },
  {
    id: 3894,
    label: "Poço fundo - MG",
  },
  {
    id: 3895,
    label: "Poço redondo - SE",
  },
  {
    id: 3896,
    label: "Poço verde - SE",
  },
  {
    id: 3897,
    label: "Poções - BA",
  },
  {
    id: 3898,
    label: "Poconé - MT",
  },
  {
    id: 3899,
    label: "Poços de caldas - MG",
  },
  {
    id: 3900,
    label: "Pocrane - MG",
  },
  {
    id: 3901,
    label: "Pojuca - BA",
  },
  {
    id: 3902,
    label: "Poloni - SP",
  },
  {
    id: 3903,
    label: "Pombal - PB",
  },
  {
    id: 3904,
    label: "Pombos - PE",
  },
  {
    id: 3905,
    label: "Pomerode - SC",
  },
  {
    id: 3906,
    label: "Pompéia - SP",
  },
  {
    id: 3907,
    label: "Pompéu - MG",
  },
  {
    id: 3908,
    label: "Pongaí - SP",
  },
  {
    id: 3909,
    label: "Ponta de pedras - PA",
  },
  {
    id: 3910,
    label: "Ponta grossa - PR",
  },
  {
    id: 3911,
    label: "Ponta porã - MS",
  },
  {
    id: 3912,
    label: "Pontal - SP",
  },
  {
    id: 3913,
    label: "Pontal do araguaia - MT",
  },
  {
    id: 3914,
    label: "Pontal do paraná - PR",
  },
  {
    id: 3915,
    label: "Pontalina - GO",
  },
  {
    id: 3916,
    label: "Pontalinda - SP",
  },
  {
    id: 3917,
    label: "Pontão - RS",
  },
  {
    id: 3918,
    label: "Ponte alta - SC",
  },
  {
    id: 3919,
    label: "Ponte alta do bom jesus - TO",
  },
  {
    id: 3920,
    label: "Ponte alta do norte - SC",
  },
  {
    id: 3921,
    label: "Ponte alta do tocantins - TO",
  },
  {
    id: 3922,
    label: "Ponte branca - MT",
  },
  {
    id: 3923,
    label: "Ponte nova - MG",
  },
  {
    id: 3924,
    label: "Ponte preta - RS",
  },
  {
    id: 3925,
    label: "Ponte serrada - SC",
  },
  {
    id: 3926,
    label: "Pontes e lacerda - MT",
  },
  {
    id: 3927,
    label: "Pontes gestal - SP",
  },
  {
    id: 3928,
    label: "Ponto belo - ES",
  },
  {
    id: 3929,
    label: "Ponto chique - MG",
  },
  {
    id: 3930,
    label: "Ponto dos volantes - MG",
  },
  {
    id: 3931,
    label: "Ponto novo - BA",
  },
  {
    id: 3932,
    label: "Populina - SP",
  },
  {
    id: 3933,
    label: "Poranga - CE",
  },
  {
    id: 3934,
    label: "Porangaba - SP",
  },
  {
    id: 3935,
    label: "Porangatu - GO",
  },
  {
    id: 3936,
    label: "Porciúncula - RJ",
  },
  {
    id: 3937,
    label: "Porecatu - PR",
  },
  {
    id: 3938,
    label: "Portalegre - RN",
  },
  {
    id: 3939,
    label: "Portão - RS",
  },
  {
    id: 3940,
    label: "Porteirão - GO",
  },
  {
    id: 3941,
    label: "Porteiras - CE",
  },
  {
    id: 3942,
    label: "Porteirinha - MG",
  },
  {
    id: 3943,
    label: "Portel - PA",
  },
  {
    id: 3944,
    label: "Portelândia - GO",
  },
  {
    id: 3945,
    label: "Porto - PI",
  },
  {
    id: 3946,
    label: "Porto acre - AC",
  },
  {
    id: 3947,
    label: "Porto alegre - RS",
  },
  {
    id: 3948,
    label: "Porto alegre do norte - MT",
  },
  {
    id: 3949,
    label: "Porto alegre do piauí - PI",
  },
  {
    id: 3950,
    label: "Porto alegre do tocantins - TO",
  },
  {
    id: 3951,
    label: "Porto amazonas - PR",
  },
  {
    id: 3952,
    label: "Porto barreiro - PR",
  },
  {
    id: 3953,
    label: "Porto belo - SC",
  },
  {
    id: 3954,
    label: "Porto calvo - AL",
  },
  {
    id: 3955,
    label: "Porto da folha - SE",
  },
  {
    id: 3956,
    label: "Porto de moz - PA",
  },
  {
    id: 3957,
    label: "Porto de pedras - AL",
  },
  {
    id: 3958,
    label: "Porto do mangue - RN",
  },
  {
    id: 3959,
    label: "Porto dos gaúchos - MT",
  },
  {
    id: 3960,
    label: "Porto esperidião - MT",
  },
  {
    id: 3961,
    label: "Porto estrela - MT",
  },
  {
    id: 3962,
    label: "Porto feliz - SP",
  },
  {
    id: 3963,
    label: "Porto ferreira - SP",
  },
  {
    id: 3964,
    label: "Porto firme - MG",
  },
  {
    id: 3965,
    label: "Porto franco - MA",
  },
  {
    id: 3966,
    label: "Porto grande - AP",
  },
  {
    id: 3967,
    label: "Porto lucena - RS",
  },
  {
    id: 3968,
    label: "Porto mauá - RS",
  },
  {
    id: 3969,
    label: "Porto murtinho - MS",
  },
  {
    id: 3970,
    label: "Porto nacional - TO",
  },
  {
    id: 3971,
    label: "Porto real - RJ",
  },
  {
    id: 3972,
    label: "Porto real do colégio - AL",
  },
  {
    id: 3973,
    label: "Porto rico - PR",
  },
  {
    id: 3974,
    label: "Porto rico do maranhão - MA",
  },
  {
    id: 3975,
    label: "Porto seguro - BA",
  },
  {
    id: 3976,
    label: "Porto união - SC",
  },
  {
    id: 3977,
    label: "Porto velho - RO",
  },
  {
    id: 3978,
    label: "Porto vera cruz - RS",
  },
  {
    id: 3979,
    label: "Porto vitória - PR",
  },
  {
    id: 3980,
    label: "Porto walter - AC",
  },
  {
    id: 3981,
    label: "Porto xavier - RS",
  },
  {
    id: 3982,
    label: "Posse - GO",
  },
  {
    id: 3983,
    label: "Poté - MG",
  },
  {
    id: 3984,
    label: "Potengi - CE",
  },
  {
    id: 3985,
    label: "Potim - SP",
  },
  {
    id: 3986,
    label: "Potiraguá - BA",
  },
  {
    id: 3987,
    label: "Potirendaba - SP",
  },
  {
    id: 3988,
    label: "Potiretama - CE",
  },
  {
    id: 3989,
    label: "Pouso alegre - MG",
  },
  {
    id: 3990,
    label: "Pouso alto - MG",
  },
  {
    id: 3991,
    label: "Pouso novo - RS",
  },
  {
    id: 3992,
    label: "Pouso redondo - SC",
  },
  {
    id: 3993,
    label: "Poxoréu - MT",
  },
  {
    id: 3994,
    label: "Pracinha - SP",
  },
  {
    id: 3995,
    label: "Pracuúba - AP",
  },
  {
    id: 3996,
    label: "Prado - BA",
  },
  {
    id: 3997,
    label: "Prado ferreira - PR",
  },
  {
    id: 3998,
    label: "Pradópolis - SP",
  },
  {
    id: 3999,
    label: "Prados - MG",
  },
  {
    id: 4000,
    label: "Praia grande - SC",
  },
  {
    id: 4001,
    label: "Praia grande - SP",
  },
  {
    id: 4002,
    label: "Praia norte - TO",
  },
  {
    id: 4003,
    label: "Prainha - PA",
  },
  {
    id: 4004,
    label: "Pranchita - PR",
  },
  {
    id: 4005,
    label: "Prata - MG",
  },
  {
    id: 4006,
    label: "Prata - PB",
  },
  {
    id: 4007,
    label: "Prata do piauí - PI",
  },
  {
    id: 4008,
    label: "Pratânia - SP",
  },
  {
    id: 4009,
    label: "Pratápolis - MG",
  },
  {
    id: 4010,
    label: "Pratinha - MG",
  },
  {
    id: 4011,
    label: "Presidente alves - SP",
  },
  {
    id: 4012,
    label: "Presidente bernardes - MG",
  },
  {
    id: 4013,
    label: "Presidente bernardes - SP",
  },
  {
    id: 4014,
    label: "Presidente castello branco - SC",
  },
  {
    id: 4015,
    label: "Presidente castelo branco - PR",
  },
  {
    id: 4016,
    label: "Presidente dutra - BA",
  },
  {
    id: 4017,
    label: "Presidente dutra - MA",
  },
  {
    id: 4018,
    label: "Presidente epitácio - SP",
  },
  {
    id: 4019,
    label: "Presidente figueiredo - AM",
  },
  {
    id: 4020,
    label: "Presidente getúlio - SC",
  },
  {
    id: 4021,
    label: "Presidente jânio quadros - BA",
  },
  {
    id: 4022,
    label: "Presidente juscelino - MA",
  },
  {
    id: 4023,
    label: "Presidente juscelino - MG",
  },
  {
    id: 4024,
    label: "Presidente kennedy - ES",
  },
  {
    id: 4025,
    label: "Presidente kennedy - TO",
  },
  {
    id: 4026,
    label: "Presidente kubitschek - MG",
  },
  {
    id: 4027,
    label: "Presidente lucena - RS",
  },
  {
    id: 4028,
    label: "Presidente médici - MA",
  },
  {
    id: 4029,
    label: "Presidente médici - RO",
  },
  {
    id: 4030,
    label: "Presidente nereu - SC",
  },
  {
    id: 4031,
    label: "Presidente olegário - MG",
  },
  {
    id: 4032,
    label: "Presidente prudente - SP",
  },
  {
    id: 4033,
    label: "Presidente sarney - MA",
  },
  {
    id: 4034,
    label: "Presidente tancredo neves - BA",
  },
  {
    id: 4035,
    label: "Presidente vargas - MA",
  },
  {
    id: 4036,
    label: "Presidente venceslau - SP",
  },
  {
    id: 4037,
    label: "Primavera - PA",
  },
  {
    id: 4038,
    label: "Primavera - PE",
  },
  {
    id: 4039,
    label: "Primavera de rondônia - RO",
  },
  {
    id: 4040,
    label: "Primavera do leste - MT",
  },
  {
    id: 4041,
    label: "Primeira cruz - MA",
  },
  {
    id: 4042,
    label: "Primeiro de maio - PR",
  },
  {
    id: 4043,
    label: "Princesa - SC",
  },
  {
    id: 4044,
    label: "Princesa isabel - PB",
  },
  {
    id: 4045,
    label: "Professor jamil - GO",
  },
  {
    id: 4046,
    label: "Progresso - RS",
  },
  {
    id: 4047,
    label: "Promissão - SP",
  },
  {
    id: 4048,
    label: "Propriá - SE",
  },
  {
    id: 4049,
    label: "Protásio alves - RS",
  },
  {
    id: 4050,
    label: "Prudente de morais - MG",
  },
  {
    id: 4051,
    label: "Prudentópolis - PR",
  },
  {
    id: 4052,
    label: "Pugmil - TO",
  },
  {
    id: 4053,
    label: "Pureza - RN",
  },
  {
    id: 4054,
    label: "Putinga - RS",
  },
  {
    id: 4055,
    label: "Puxinanã - PB",
  },
  {
    id: 4056,
    label: "Quadra - SP",
  },
  {
    id: 4057,
    label: "Quaraí - RS",
  },
  {
    id: 4058,
    label: "Quartel geral - MG",
  },
  {
    id: 4059,
    label: "Quarto centenário - PR",
  },
  {
    id: 4060,
    label: "Quatá - SP",
  },
  {
    id: 4061,
    label: "Quatiguá - PR",
  },
  {
    id: 4062,
    label: "Quatipuru - PA",
  },
  {
    id: 4063,
    label: "Quatis - RJ",
  },
  {
    id: 4064,
    label: "Quatro barras - PR",
  },
  {
    id: 4065,
    label: "Quatro irmãos - RS",
  },
  {
    id: 4066,
    label: "Quatro pontes - PR",
  },
  {
    id: 4067,
    label: "Quebrangulo - AL",
  },
  {
    id: 4068,
    label: "Quedas do iguaçu - PR",
  },
  {
    id: 4069,
    label: "Queimada nova - PI",
  },
  {
    id: 4070,
    label: "Queimadas - BA",
  },
  {
    id: 4071,
    label: "Queimadas - PB",
  },
  {
    id: 4072,
    label: "Queimados - RJ",
  },
  {
    id: 4073,
    label: "Queiroz - SP",
  },
  {
    id: 4074,
    label: "Queluz - SP",
  },
  {
    id: 4075,
    label: "Queluzito - MG",
  },
  {
    id: 4076,
    label: "Querência - MT",
  },
  {
    id: 4077,
    label: "Querência do norte - PR",
  },
  {
    id: 4078,
    label: "Quevedos - RS",
  },
  {
    id: 4079,
    label: "Quijingue - BA",
  },
  {
    id: 4080,
    label: "Quilombo - SC",
  },
  {
    id: 4081,
    label: "Quinta do sol - PR",
  },
  {
    id: 4082,
    label: "Quintana - SP",
  },
  {
    id: 4083,
    label: "Quinze de novembro - RS",
  },
  {
    id: 4084,
    label: "Quipapá - PE",
  },
  {
    id: 4085,
    label: "Quirinópolis - GO",
  },
  {
    id: 4086,
    label: "Quissamã - RJ",
  },
  {
    id: 4087,
    label: "Quitandinha - PR",
  },
  {
    id: 4088,
    label: "Quiterianópolis - CE",
  },
  {
    id: 4089,
    label: "Quixaba - PB",
  },
  {
    id: 4090,
    label: "Quixaba - PE",
  },
  {
    id: 4091,
    label: "Quixabeira - BA",
  },
  {
    id: 4092,
    label: "Quixadá - CE",
  },
  {
    id: 4093,
    label: "Quixelô - CE",
  },
  {
    id: 4094,
    label: "Quixeramobim - CE",
  },
  {
    id: 4095,
    label: "Quixeré - CE",
  },
  {
    id: 4096,
    label: "Rafael fernandes - RN",
  },
  {
    id: 4097,
    label: "Rafael godeiro - RN",
  },
  {
    id: 4098,
    label: "Rafael jambeiro - BA",
  },
  {
    id: 4099,
    label: "Rafard - SP",
  },
  {
    id: 4100,
    label: "Ramilândia - PR",
  },
  {
    id: 4101,
    label: "Rancharia - SP",
  },
  {
    id: 4102,
    label: "Rancho alegre - PR",
  },
  {
    id: 4103,
    label: "Rancho alegre d'oeste - PR",
  },
  {
    id: 4104,
    label: "Rancho queimado - SC",
  },
  {
    id: 4105,
    label: "Raposa - MA",
  },
  {
    id: 4106,
    label: "Raposos - MG",
  },
  {
    id: 4107,
    label: "Raul soares - MG",
  },
  {
    id: 4108,
    label: "Realeza - PR",
  },
  {
    id: 4109,
    label: "Rebouças - PR",
  },
  {
    id: 4110,
    label: "Recife - PE",
  },
  {
    id: 4111,
    label: "Recreio - MG",
  },
  {
    id: 4112,
    label: "Recursolândia - TO",
  },
  {
    id: 4113,
    label: "Redenção - CE",
  },
  {
    id: 4114,
    label: "Redenção - PA",
  },
  {
    id: 4115,
    label: "Redenção da serra - SP",
  },
  {
    id: 4116,
    label: "Redenção do gurguéia - PI",
  },
  {
    id: 4117,
    label: "Redentora - RS",
  },
  {
    id: 4118,
    label: "Reduto - MG",
  },
  {
    id: 4119,
    label: "Regeneração - PI",
  },
  {
    id: 4120,
    label: "Regente feijó - SP",
  },
  {
    id: 4121,
    label: "Reginópolis - SP",
  },
  {
    id: 4122,
    label: "Registro - SP",
  },
  {
    id: 4123,
    label: "Relvado - RS",
  },
  {
    id: 4124,
    label: "Remanso - BA",
  },
  {
    id: 4125,
    label: "Remígio - PB",
  },
  {
    id: 4126,
    label: "Renascença - PR",
  },
  {
    id: 4127,
    label: "Reriutaba - CE",
  },
  {
    id: 4128,
    label: "Resende - RJ",
  },
  {
    id: 4129,
    label: "Resende costa - MG",
  },
  {
    id: 4130,
    label: "Reserva - PR",
  },
  {
    id: 4131,
    label: "Reserva do cabaçal - MT",
  },
  {
    id: 4132,
    label: "Reserva do iguaçu - PR",
  },
  {
    id: 4133,
    label: "Resplendor - MG",
  },
  {
    id: 4134,
    label: "Ressaquinha - MG",
  },
  {
    id: 4135,
    label: "Restinga - SP",
  },
  {
    id: 4136,
    label: "Restinga sêca - RS",
  },
  {
    id: 4137,
    label: "Retirolândia - BA",
  },
  {
    id: 4138,
    label: "Riachão - MA",
  },
  {
    id: 4139,
    label: "Riachão - PB",
  },
  {
    id: 4140,
    label: "Riachão das neves - BA",
  },
  {
    id: 4141,
    label: "Riachão do bacamarte - PB",
  },
  {
    id: 4142,
    label: "Riachão do dantas - SE",
  },
  {
    id: 4143,
    label: "Riachão do jacuípe - BA",
  },
  {
    id: 4144,
    label: "Riachão do poço - PB",
  },
  {
    id: 4145,
    label: "Riachinho - MG",
  },
  {
    id: 4146,
    label: "Riachinho - TO",
  },
  {
    id: 4147,
    label: "Riacho da cruz - RN",
  },
  {
    id: 4148,
    label: "Riacho das almas - PE",
  },
  {
    id: 4149,
    label: "Riacho de santana - BA",
  },
  {
    id: 4150,
    label: "Riacho de santana - RN",
  },
  {
    id: 4151,
    label: "Riacho de santo antônio - PB",
  },
  {
    id: 4152,
    label: "Riacho dos cavalos - PB",
  },
  {
    id: 4153,
    label: "Riacho dos machados - MG",
  },
  {
    id: 4154,
    label: "Riacho frio - PI",
  },
  {
    id: 4155,
    label: "Riachuelo - RN",
  },
  {
    id: 4156,
    label: "Riachuelo - SE",
  },
  {
    id: 4157,
    label: "Rialma - GO",
  },
  {
    id: 4158,
    label: "Rianápolis - GO",
  },
  {
    id: 4159,
    label: "Ribamar fiquene - MA",
  },
  {
    id: 4160,
    label: "Ribas do rio pardo - MS",
  },
  {
    id: 4161,
    label: "Ribeira - SP",
  },
  {
    id: 4162,
    label: "Ribeira do amparo - BA",
  },
  {
    id: 4163,
    label: "Ribeira do piauí - PI",
  },
  {
    id: 4164,
    label: "Ribeira do pombal - BA",
  },
  {
    id: 4165,
    label: "Ribeirão - PE",
  },
  {
    id: 4166,
    label: "Ribeirão bonito - SP",
  },
  {
    id: 4167,
    label: "Ribeirão branco - SP",
  },
  {
    id: 4168,
    label: "Ribeirão cascalheira - MT",
  },
  {
    id: 4169,
    label: "Ribeirão claro - PR",
  },
  {
    id: 4170,
    label: "Ribeirão corrente - SP",
  },
  {
    id: 4171,
    label: "Ribeirão das neves - MG",
  },
  {
    id: 4172,
    label: "Ribeirão do largo - BA",
  },
  {
    id: 4173,
    label: "Ribeirão do pinhal - PR",
  },
  {
    id: 4174,
    label: "Ribeirão do sul - SP",
  },
  {
    id: 4175,
    label: "Ribeirão dos índios - SP",
  },
  {
    id: 4176,
    label: "Ribeirão grande - SP",
  },
  {
    id: 4177,
    label: "Ribeirão pires - SP",
  },
  {
    id: 4178,
    label: "Ribeirão preto - SP",
  },
  {
    id: 4179,
    label: "Ribeirão vermelho - MG",
  },
  {
    id: 4180,
    label: "Ribeirãozinho - MT",
  },
  {
    id: 4181,
    label: "Ribeiro gonçalves - PI",
  },
  {
    id: 4182,
    label: "Ribeirópolis - SE",
  },
  {
    id: 4183,
    label: "Rifaina - SP",
  },
  {
    id: 4184,
    label: "Rincão - SP",
  },
  {
    id: 4185,
    label: "Rinópolis - SP",
  },
  {
    id: 4186,
    label: "Rio acima - MG",
  },
  {
    id: 4187,
    label: "Rio azul - PR",
  },
  {
    id: 4188,
    label: "Rio bananal - ES",
  },
  {
    id: 4189,
    label: "Rio bom - PR",
  },
  {
    id: 4190,
    label: "Rio bonito - RJ",
  },
  {
    id: 4191,
    label: "Rio bonito do iguaçu - PR",
  },
  {
    id: 4192,
    label: "Rio branco - AC",
  },
  {
    id: 4193,
    label: "Rio branco - MT",
  },
  {
    id: 4194,
    label: "Rio branco do ivaí - PR",
  },
  {
    id: 4195,
    label: "Rio branco do sul - PR",
  },
  {
    id: 4196,
    label: "Rio brilhante - MS",
  },
  {
    id: 4197,
    label: "Rio casca - MG",
  },
  {
    id: 4198,
    label: "Rio claro - RJ",
  },
  {
    id: 4199,
    label: "Rio claro - SP",
  },
  {
    id: 4200,
    label: "Rio crespo - RO",
  },
  {
    id: 4201,
    label: "Rio da conceição - TO",
  },
  {
    id: 4202,
    label: "Rio das antas - SC",
  },
  {
    id: 4203,
    label: "Rio das flores - RJ",
  },
  {
    id: 4204,
    label: "Rio das ostras - RJ",
  },
  {
    id: 4205,
    label: "Rio das pedras - SP",
  },
  {
    id: 4206,
    label: "Rio de contas - BA",
  },
  {
    id: 4207,
    label: "Rio de janeiro - RJ",
  },
  {
    id: 4208,
    label: "Rio do antônio - BA",
  },
  {
    id: 4209,
    label: "Rio do campo - SC",
  },
  {
    id: 4210,
    label: "Rio do fogo - RN",
  },
  {
    id: 4211,
    label: "Rio do oeste - SC",
  },
  {
    id: 4212,
    label: "Rio do pires - BA",
  },
  {
    id: 4213,
    label: "Rio do prado - MG",
  },
  {
    id: 4214,
    label: "Rio do sul - SC",
  },
  {
    id: 4215,
    label: "Rio doce - MG",
  },
  {
    id: 4216,
    label: "Rio dos bois - TO",
  },
  {
    id: 4217,
    label: "Rio dos cedros - SC",
  },
  {
    id: 4218,
    label: "Rio dos índios - RS",
  },
  {
    id: 4219,
    label: "Rio espera - MG",
  },
  {
    id: 4220,
    label: "Rio formoso - PE",
  },
  {
    id: 4221,
    label: "Rio fortuna - SC",
  },
  {
    id: 4222,
    label: "Rio grande - RS",
  },
  {
    id: 4223,
    label: "Rio grande da serra - SP",
  },
  {
    id: 4224,
    label: "Rio grande do piauí - PI",
  },
  {
    id: 4225,
    label: "Rio largo - AL",
  },
  {
    id: 4226,
    label: "Rio manso - MG",
  },
  {
    id: 4227,
    label: "Rio maria - PA",
  },
  {
    id: 4228,
    label: "Rio negrinho - SC",
  },
  {
    id: 4229,
    label: "Rio negro - MS",
  },
  {
    id: 4230,
    label: "Rio negro - PR",
  },
  {
    id: 4231,
    label: "Rio novo - MG",
  },
  {
    id: 4232,
    label: "Rio novo do sul - ES",
  },
  {
    id: 4233,
    label: "Rio paranaíba - MG",
  },
  {
    id: 4234,
    label: "Rio pardo - RS",
  },
  {
    id: 4235,
    label: "Rio pardo de minas - MG",
  },
  {
    id: 4236,
    label: "Rio piracicaba - MG",
  },
  {
    id: 4237,
    label: "Rio pomba - MG",
  },
  {
    id: 4238,
    label: "Rio preto - MG",
  },
  {
    id: 4239,
    label: "Rio preto da eva - AM",
  },
  {
    id: 4240,
    label: "Rio quente - GO",
  },
  {
    id: 4241,
    label: "Rio real - BA",
  },
  {
    id: 4242,
    label: "Rio rufino - SC",
  },
  {
    id: 4243,
    label: "Rio sono - TO",
  },
  {
    id: 4244,
    label: "Rio tinto - PB",
  },
  {
    id: 4245,
    label: "Rio verde - GO",
  },
  {
    id: 4246,
    label: "Rio verde de mato grosso - MS",
  },
  {
    id: 4247,
    label: "Rio vermelho - MG",
  },
  {
    id: 4248,
    label: "Riolândia - SP",
  },
  {
    id: 4249,
    label: "Riozinho - RS",
  },
  {
    id: 4250,
    label: "Riqueza - SC",
  },
  {
    id: 4251,
    label: "Ritápolis - MG",
  },
  {
    id: 4252,
    label: "Riversul - SP",
  },
  {
    id: 4253,
    label: "Roca sales - RS",
  },
  {
    id: 4254,
    label: "Rochedo - MS",
  },
  {
    id: 4255,
    label: "Rochedo de minas - MG",
  },
  {
    id: 4256,
    label: "Rodeio - SC",
  },
  {
    id: 4257,
    label: "Rodeio bonito - RS",
  },
  {
    id: 4258,
    label: "Rodeiro - MG",
  },
  {
    id: 4259,
    label: "Rodelas - BA",
  },
  {
    id: 4260,
    label: "Rodolfo fernandes - RN",
  },
  {
    id: 4261,
    label: "Rodrigues alves - AC",
  },
  {
    id: 4262,
    label: "Rolador - RS",
  },
  {
    id: 4263,
    label: "Rolândia - PR",
  },
  {
    id: 4264,
    label: "Rolante - RS",
  },
  {
    id: 4265,
    label: "Rolim de moura - RO",
  },
  {
    id: 4266,
    label: "Romaria - MG",
  },
  {
    id: 4267,
    label: "Romelândia - SC",
  },
  {
    id: 4268,
    label: "Roncador - PR",
  },
  {
    id: 4269,
    label: "Ronda alta - RS",
  },
  {
    id: 4270,
    label: "Rondinha - RS",
  },
  {
    id: 4271,
    label: "Rondolândia - MT",
  },
  {
    id: 4272,
    label: "Rondon - PR",
  },
  {
    id: 4273,
    label: "Rondon do pará - PA",
  },
  {
    id: 4274,
    label: "Rondonópolis - MT",
  },
  {
    id: 4275,
    label: "Roque gonzales - RS",
  },
  {
    id: 4276,
    label: "Rorainópolis - RR",
  },
  {
    id: 4277,
    label: "Rosana - SP",
  },
  {
    id: 4278,
    label: "Rosário - MA",
  },
  {
    id: 4279,
    label: "Rosário da limeira - MG",
  },
  {
    id: 4280,
    label: "Rosário do catete - SE",
  },
  {
    id: 4281,
    label: "Rosário do ivaí - PR",
  },
  {
    id: 4282,
    label: "Rosário do sul - RS",
  },
  {
    id: 4283,
    label: "Rosário oeste - MT",
  },
  {
    id: 4284,
    label: "Roseira - SP",
  },
  {
    id: 4285,
    label: "Roteiro - AL",
  },
  {
    id: 4286,
    label: "Rubelita - MG",
  },
  {
    id: 4287,
    label: "Rubiácea - SP",
  },
  {
    id: 4288,
    label: "Rubiataba - GO",
  },
  {
    id: 4289,
    label: "Rubim - MG",
  },
  {
    id: 4290,
    label: "Rubinéia - SP",
  },
  {
    id: 4291,
    label: "Rurópolis - PA",
  },
  {
    id: 4292,
    label: "Russas - CE",
  },
  {
    id: 4293,
    label: "Ruy barbosa - BA",
  },
  {
    id: 4294,
    label: "Ruy barbosa - RN",
  },
  {
    id: 4295,
    label: "Sabará - MG",
  },
  {
    id: 4296,
    label: "Sabáudia - PR",
  },
  {
    id: 4297,
    label: "Sabino - SP",
  },
  {
    id: 4298,
    label: "Sabinópolis - MG",
  },
  {
    id: 4299,
    label: "Saboeiro - CE",
  },
  {
    id: 4300,
    label: "Sacramento - MG",
  },
  {
    id: 4301,
    label: "Sagrada família - RS",
  },
  {
    id: 4302,
    label: "Sagres - SP",
  },
  {
    id: 4303,
    label: "Sairé - PE",
  },
  {
    id: 4304,
    label: "Saldanha marinho - RS",
  },
  {
    id: 4305,
    label: "Sales - SP",
  },
  {
    id: 4306,
    label: "Sales oliveira - SP",
  },
  {
    id: 4307,
    label: "Salesópolis - SP",
  },
  {
    id: 4308,
    label: "Salete - SC",
  },
  {
    id: 4309,
    label: "Salgadinho - PB",
  },
  {
    id: 4310,
    label: "Salgadinho - PE",
  },
  {
    id: 4311,
    label: "Salgado - SE",
  },
  {
    id: 4312,
    label: "Salgado de são félix - PB",
  },
  {
    id: 4313,
    label: "Salgado filho - PR",
  },
  {
    id: 4314,
    label: "Salgueiro - PE",
  },
  {
    id: 4315,
    label: "Salinas - MG",
  },
  {
    id: 4316,
    label: "Salinas da margarida - BA",
  },
  {
    id: 4317,
    label: "Salinópolis - PA",
  },
  {
    id: 4318,
    label: "Salitre - CE",
  },
  {
    id: 4319,
    label: "Salmourão - SP",
  },
  {
    id: 4320,
    label: "Saloá - PE",
  },
  {
    id: 4321,
    label: "Saltinho - SC",
  },
  {
    id: 4322,
    label: "Saltinho - SP",
  },
  {
    id: 4323,
    label: "Salto - SP",
  },
  {
    id: 4324,
    label: "Salto da divisa - MG",
  },
  {
    id: 4325,
    label: "Salto de pirapora - SP",
  },
  {
    id: 4326,
    label: "Salto do céu - MT",
  },
  {
    id: 4327,
    label: "Salto do itararé - PR",
  },
  {
    id: 4328,
    label: "Salto do jacuí - RS",
  },
  {
    id: 4329,
    label: "Salto do lontra - PR",
  },
  {
    id: 4330,
    label: "Salto grande - SP",
  },
  {
    id: 4331,
    label: "Salto veloso - SC",
  },
  {
    id: 4332,
    label: "Salvador - BA",
  },
  {
    id: 4333,
    label: "Salvador das missões - RS",
  },
  {
    id: 4334,
    label: "Salvador do sul - RS",
  },
  {
    id: 4335,
    label: "Salvaterra - PA",
  },
  {
    id: 4336,
    label: "Sambaíba - MA",
  },
  {
    id: 4337,
    label: "Sampaio - TO",
  },
  {
    id: 4338,
    label: "Sananduva - RS",
  },
  {
    id: 4339,
    label: "Sanclerlândia - GO",
  },
  {
    id: 4340,
    label: "Sandolândia - TO",
  },
  {
    id: 4341,
    label: "Sandovalina - SP",
  },
  {
    id: 4342,
    label: "Sangão - SC",
  },
  {
    id: 4343,
    label: "Sanharó - PE",
  },
  {
    id: 4344,
    label: "Sant'ana do livramento - RS",
  },
  {
    id: 4345,
    label: "Santa adélia - SP",
  },
  {
    id: 4346,
    label: "Santa albertina - SP",
  },
  {
    id: 4347,
    label: "Santa amélia - PR",
  },
  {
    id: 4348,
    label: "Santa bárbara - BA",
  },
  {
    id: 4349,
    label: "Santa bárbara - MG",
  },
  {
    id: 4350,
    label: "Santa bárbara d'oeste - SP",
  },
  {
    id: 4351,
    label: "Santa bárbara de goiás - GO",
  },
  {
    id: 4352,
    label: "Santa bárbara do leste - MG",
  },
  {
    id: 4353,
    label: "Santa bárbara do monte verde - MG",
  },
  {
    id: 4354,
    label: "Santa bárbara do pará - PA",
  },
  {
    id: 4355,
    label: "Santa bárbara do sul - RS",
  },
  {
    id: 4356,
    label: "Santa bárbara do tugúrio - MG",
  },
  {
    id: 4357,
    label: "Santa branca - SP",
  },
  {
    id: 4358,
    label: "Santa brígida - BA",
  },
  {
    id: 4359,
    label: "Santa carmem - MT",
  },
  {
    id: 4360,
    label: "Santa cecília - PB",
  },
  {
    id: 4361,
    label: "Santa cecília - SC",
  },
  {
    id: 4362,
    label: "Santa cecília do pavão - PR",
  },
  {
    id: 4363,
    label: "Santa cecília do sul - RS",
  },
  {
    id: 4364,
    label: "Santa clara d'oeste - SP",
  },
  {
    id: 4365,
    label: "Santa clara do sul - RS",
  },
  {
    id: 4366,
    label: "Santa cruz - PB",
  },
  {
    id: 4367,
    label: "Santa cruz - PE",
  },
  {
    id: 4368,
    label: "Santa cruz - RN",
  },
  {
    id: 4369,
    label: "Santa cruz cabrália - BA",
  },
  {
    id: 4370,
    label: "Santa cruz da baixa verde - PE",
  },
  {
    id: 4371,
    label: "Santa cruz da conceição - SP",
  },
  {
    id: 4372,
    label: "Santa cruz da esperança - SP",
  },
  {
    id: 4373,
    label: "Santa cruz da vitória - BA",
  },
  {
    id: 4374,
    label: "Santa cruz das palmeiras - SP",
  },
  {
    id: 4375,
    label: "Santa cruz de goiás - GO",
  },
  {
    id: 4376,
    label: "Santa cruz de minas - MG",
  },
  {
    id: 4377,
    label: "Santa cruz de monte castelo - PR",
  },
  {
    id: 4378,
    label: "Santa cruz de salinas - MG",
  },
  {
    id: 4379,
    label: "Santa cruz do arari - PA",
  },
  {
    id: 4380,
    label: "Santa cruz do capibaribe - PE",
  },
  {
    id: 4381,
    label: "Santa cruz do escalvado - MG",
  },
  {
    id: 4382,
    label: "Santa cruz do piauí - PI",
  },
  {
    id: 4383,
    label: "Santa cruz do rio pardo - SP",
  },
  {
    id: 4384,
    label: "Santa cruz do sul - RS",
  },
  {
    id: 4385,
    label: "Santa cruz do xingu - MT",
  },
  {
    id: 4386,
    label: "Santa cruz dos milagres - PI",
  },
  {
    id: 4387,
    label: "Santa efigênia de minas - MG",
  },
  {
    id: 4388,
    label: "Santa ernestina - SP",
  },
  {
    id: 4389,
    label: "Santa fé - PR",
  },
  {
    id: 4390,
    label: "Santa fé de goiás - GO",
  },
  {
    id: 4391,
    label: "Santa fé de minas - MG",
  },
  {
    id: 4392,
    label: "Santa fé do araguaia - TO",
  },
  {
    id: 4393,
    label: "Santa fé do sul - SP",
  },
  {
    id: 4394,
    label: "Santa filomena - PE",
  },
  {
    id: 4395,
    label: "Santa filomena - PI",
  },
  {
    id: 4396,
    label: "Santa filomena do maranhão - MA",
  },
  {
    id: 4397,
    label: "Santa gertrudes - SP",
  },
  {
    id: 4398,
    label: "Santa helena - MA",
  },
  {
    id: 4399,
    label: "Santa helena - PB",
  },
  {
    id: 4400,
    label: "Santa helena - PR",
  },
  {
    id: 4401,
    label: "Santa helena - SC",
  },
  {
    id: 4402,
    label: "Santa helena de goiás - GO",
  },
  {
    id: 4403,
    label: "Santa helena de minas - MG",
  },
  {
    id: 4404,
    label: "Santa inês - BA",
  },
  {
    id: 4405,
    label: "Santa inês - MA",
  },
  {
    id: 4406,
    label: "Santa inês - PB",
  },
  {
    id: 4407,
    label: "Santa inês - PR",
  },
  {
    id: 4408,
    label: "Santa isabel - GO",
  },
  {
    id: 4409,
    label: "Santa isabel - SP",
  },
  {
    id: 4410,
    label: "Santa isabel do ivaí - PR",
  },
  {
    id: 4411,
    label: "Santa isabel do pará - PA",
  },
  {
    id: 4412,
    label: "Santa isabel do rio negro - AM",
  },
  {
    id: 4413,
    label: "Santa izabel do oeste - PR",
  },
  {
    id: 4414,
    label: "Santa juliana - MG",
  },
  {
    id: 4415,
    label: "Santa leopoldina - ES",
  },
  {
    id: 4416,
    label: "Santa lúcia - PR",
  },
  {
    id: 4417,
    label: "Santa lúcia - SP",
  },
  {
    id: 4418,
    label: "Santa luz - PI",
  },
  {
    id: 4419,
    label: "Santa luzia - BA",
  },
  {
    id: 4420,
    label: "Santa luzia - MA",
  },
  {
    id: 4421,
    label: "Santa luzia - MG",
  },
  {
    id: 4422,
    label: "Santa luzia - PB",
  },
  {
    id: 4423,
    label: "Santa luzia d'oeste - RO",
  },
  {
    id: 4424,
    label: "Santa luzia do itanhy - SE",
  },
  {
    id: 4425,
    label: "Santa luzia do norte - AL",
  },
  {
    id: 4426,
    label: "Santa luzia do pará - PA",
  },
  {
    id: 4427,
    label: "Santa luzia do paruá - MA",
  },
  {
    id: 4428,
    label: "Santa margarida - MG",
  },
  {
    id: 4429,
    label: "Santa margarida do sul - RS",
  },
  {
    id: 4430,
    label: "Santa maria - RN",
  },
  {
    id: 4431,
    label: "Santa maria - RS",
  },
  {
    id: 4432,
    label: "Santa maria da boa vista - PE",
  },
  {
    id: 4433,
    label: "Santa maria da serra - SP",
  },
  {
    id: 4434,
    label: "Santa maria da vitória - BA",
  },
  {
    id: 4435,
    label: "Santa maria das barreiras - PA",
  },
  {
    id: 4436,
    label: "Santa maria de itabira - MG",
  },
  {
    id: 4437,
    label: "Santa maria de jetibá - ES",
  },
  {
    id: 4438,
    label: "Santa maria do cambucá - PE",
  },
  {
    id: 4439,
    label: "Santa maria do herval - RS",
  },
  {
    id: 4440,
    label: "Santa maria do oeste - PR",
  },
  {
    id: 4441,
    label: "Santa maria do pará - PA",
  },
  {
    id: 4442,
    label: "Santa maria do salto - MG",
  },
  {
    id: 4443,
    label: "Santa maria do suaçuí - MG",
  },
  {
    id: 4444,
    label: "Santa maria do tocantins - TO",
  },
  {
    id: 4445,
    label: "Santa maria madalena - RJ",
  },
  {
    id: 4446,
    label: "Santa mariana - PR",
  },
  {
    id: 4447,
    label: "Santa mercedes - SP",
  },
  {
    id: 4448,
    label: "Santa mônica - PR",
  },
  {
    id: 4449,
    label: "Santa quitéria - CE",
  },
  {
    id: 4450,
    label: "Santa quitéria do maranhão - MA",
  },
  {
    id: 4451,
    label: "Santa rita - MA",
  },
  {
    id: 4452,
    label: "Santa rita - PB",
  },
  {
    id: 4453,
    label: "Santa rita d'oeste - SP",
  },
  {
    id: 4454,
    label: "Santa rita de caldas - MG",
  },
  {
    id: 4455,
    label: "Santa rita de cássia - BA",
  },
  {
    id: 4456,
    label: "Santa rita de ibitipoca - MG",
  },
  {
    id: 4457,
    label: "Santa rita de jacutinga - MG",
  },
  {
    id: 4458,
    label: "Santa rita de minas - MG",
  },
  {
    id: 4459,
    label: "Santa rita do araguaia - GO",
  },
  {
    id: 4460,
    label: "Santa rita do itueto - MG",
  },
  {
    id: 4461,
    label: "Santa rita do novo destino - GO",
  },
  {
    id: 4462,
    label: "Santa rita do pardo - MS",
  },
  {
    id: 4463,
    label: "Santa rita do passa quatro - SP",
  },
  {
    id: 4464,
    label: "Santa rita do sapucaí - MG",
  },
  {
    id: 4465,
    label: "Santa rita do tocantins - TO",
  },
  {
    id: 4466,
    label: "Santa rita do trivelato - MT",
  },
  {
    id: 4467,
    label: "Santa rosa - RS",
  },
  {
    id: 4468,
    label: "Santa rosa da serra - MG",
  },
  {
    id: 4469,
    label: "Santa rosa de goiás - GO",
  },
  {
    id: 4470,
    label: "Santa rosa de lima - SC",
  },
  {
    id: 4471,
    label: "Santa rosa de lima - SE",
  },
  {
    id: 4472,
    label: "Santa rosa de viterbo - SP",
  },
  {
    id: 4473,
    label: "Santa rosa do piauí - PI",
  },
  {
    id: 4474,
    label: "Santa rosa do purus - AC",
  },
  {
    id: 4475,
    label: "Santa rosa do sul - SC",
  },
  {
    id: 4476,
    label: "Santa rosa do tocantins - TO",
  },
  {
    id: 4477,
    label: "Santa salete - SP",
  },
  {
    id: 4478,
    label: "Santa teresa - ES",
  },
  {
    id: 4479,
    label: "Santa teresinha - PB",
  },
  {
    id: 4480,
    label: "Santa tereza - RS",
  },
  {
    id: 4481,
    label: "Santa tereza de goiás - GO",
  },
  {
    id: 4482,
    label: "Santa tereza do oeste - PR",
  },
  {
    id: 4483,
    label: "Santa tereza do tocantins - TO",
  },
  {
    id: 4484,
    label: "Santa terezinha - BA",
  },
  {
    id: 4485,
    label: "Santa terezinha - MT",
  },
  {
    id: 4486,
    label: "Santa terezinha - PE",
  },
  {
    id: 4487,
    label: "Santa terezinha - SC",
  },
  {
    id: 4488,
    label: "Santa terezinha de goiás - GO",
  },
  {
    id: 4489,
    label: "Santa terezinha de itaipu - PR",
  },
  {
    id: 4490,
    label: "Santa terezinha do progresso - SC",
  },
  {
    id: 4491,
    label: "Santa terezinha do tocantins - TO",
  },
  {
    id: 4492,
    label: "Santa vitória - MG",
  },
  {
    id: 4493,
    label: "Santa vitória do palmar - RS",
  },
  {
    id: 4494,
    label: "Santaluz - BA",
  },
  {
    id: 4495,
    label: "Santana - AP",
  },
  {
    id: 4496,
    label: "Santana - BA",
  },
  {
    id: 4497,
    label: "Santana da boa vista - RS",
  },
  {
    id: 4498,
    label: "Santana da ponte pensa - SP",
  },
  {
    id: 4499,
    label: "Santana da vargem - MG",
  },
  {
    id: 4500,
    label: "Santana de cataguases - MG",
  },
  {
    id: 4501,
    label: "Santana de mangueira - PB",
  },
  {
    id: 4502,
    label: "Santana de parnaíba - SP",
  },
  {
    id: 4503,
    label: "Santana de pirapama - MG",
  },
  {
    id: 4504,
    label: "Santana do acaraú - CE",
  },
  {
    id: 4505,
    label: "Santana do araguaia - PA",
  },
  {
    id: 4506,
    label: "Santana do cariri - CE",
  },
  {
    id: 4507,
    label: "Santana do deserto - MG",
  },
  {
    id: 4508,
    label: "Santana do garambéu - MG",
  },
  {
    id: 4509,
    label: "Santana do ipanema - AL",
  },
  {
    id: 4510,
    label: "Santana do itararé - PR",
  },
  {
    id: 4511,
    label: "Santana do jacaré - MG",
  },
  {
    id: 4512,
    label: "Santana do manhuaçu - MG",
  },
  {
    id: 4513,
    label: "Santana do maranhão - MA",
  },
  {
    id: 4514,
    label: "Santana do matos - RN",
  },
  {
    id: 4515,
    label: "Santana do mundaú - AL",
  },
  {
    id: 4516,
    label: "Santana do paraíso - MG",
  },
  {
    id: 4517,
    label: "Santana do piauí - PI",
  },
  {
    id: 4518,
    label: "Santana do riacho - MG",
  },
  {
    id: 4519,
    label: "Santana do são francisco - SE",
  },
  {
    id: 4520,
    label: "Santana do seridó - RN",
  },
  {
    id: 4521,
    label: "Santana dos garrotes - PB",
  },
  {
    id: 4522,
    label: "Santana dos montes - MG",
  },
  {
    id: 4523,
    label: "Santanópolis - BA",
  },
  {
    id: 4524,
    label: "Santarém - PA",
  },
  {
    id: 4525,
    label: "Santarém novo - PA",
  },
  {
    id: 4526,
    label: "Santiago - RS",
  },
  {
    id: 4527,
    label: "Santiago do sul - SC",
  },
  {
    id: 4528,
    label: "Santo afonso - MT",
  },
  {
    id: 4529,
    label: "Santo amaro - BA",
  },
  {
    id: 4530,
    label: "Santo amaro da imperatriz - SC",
  },
  {
    id: 4531,
    label: "Santo amaro das brotas - SE",
  },
  {
    id: 4532,
    label: "Santo amaro do maranhão - MA",
  },
  {
    id: 4533,
    label: "Santo anastácio - SP",
  },
  {
    id: 4534,
    label: "Santo andré - PB",
  },
  {
    id: 4535,
    label: "Santo andré - SP",
  },
  {
    id: 4536,
    label: "Santo ângelo - RS",
  },
  {
    id: 4537,
    label: "Santo antônio - RN",
  },
  {
    id: 4538,
    label: "Santo antônio da alegria - SP",
  },
  {
    id: 4539,
    label: "Santo antônio da barra - GO",
  },
  {
    id: 4540,
    label: "Santo antônio da patrulha - RS",
  },
  {
    id: 4541,
    label: "Santo antônio da platina - PR",
  },
  {
    id: 4542,
    label: "Santo antônio das missões - RS",
  },
  {
    id: 4543,
    label: "Santo antônio de goiás - GO",
  },
  {
    id: 4544,
    label: "Santo antônio de jesus - BA",
  },
  {
    id: 4545,
    label: "Santo antônio de lisboa - PI",
  },
  {
    id: 4546,
    label: "Santo antônio de pádua - RJ",
  },
  {
    id: 4547,
    label: "Santo antônio de posse - SP",
  },
  {
    id: 4548,
    label: "Santo antônio do amparo - MG",
  },
  {
    id: 4549,
    label: "Santo antônio do aracanguá - SP",
  },
  {
    id: 4550,
    label: "Santo antônio do aventureiro - MG",
  },
  {
    id: 4551,
    label: "Santo antonio do caiuá - PR",
  },
  {
    id: 4552,
    label: "Santo antônio do descoberto - GO",
  },
  {
    id: 4553,
    label: "Santo antônio do grama - MG",
  },
  {
    id: 4554,
    label: "Santo antônio do içá - AM",
  },
  {
    id: 4555,
    label: "Santo antônio do itambé - MG",
  },
  {
    id: 4556,
    label: "Santo antônio do jacinto - MG",
  },
  {
    id: 4557,
    label: "Santo antônio do jardim - SP",
  },
  {
    id: 4558,
    label: "Santo antônio do leste - MT",
  },
  {
    id: 4559,
    label: "Santo antônio do leverger - MT",
  },
  {
    id: 4560,
    label: "Santo antônio do monte - MG",
  },
  {
    id: 4561,
    label: "Santo antônio do palma - RS",
  },
  {
    id: 4562,
    label: "Santo antônio do paraíso - PR",
  },
  {
    id: 4563,
    label: "Santo antônio do pinhal - SP",
  },
  {
    id: 4564,
    label: "Santo antônio do planalto - RS",
  },
  {
    id: 4565,
    label: "Santo antônio do retiro - MG",
  },
  {
    id: 4566,
    label: "Santo antônio do rio abaixo - MG",
  },
  {
    id: 4567,
    label: "Santo antônio do sudoeste - PR",
  },
  {
    id: 4568,
    label: "Santo antônio do tauá - PA",
  },
  {
    id: 4569,
    label: "Santo antônio dos lopes - MA",
  },
  {
    id: 4570,
    label: "Santo antônio dos milagres - PI",
  },
  {
    id: 4571,
    label: "Santo augusto - RS",
  },
  {
    id: 4572,
    label: "Santo cristo - RS",
  },
  {
    id: 4573,
    label: "Santo estevão - BA",
  },
  {
    id: 4574,
    label: "Santo expedito - SP",
  },
  {
    id: 4575,
    label: "Santo expedito do sul - RS",
  },
  {
    id: 4576,
    label: "Santo hipólito - MG",
  },
  {
    id: 4577,
    label: "Santo inácio - PR",
  },
  {
    id: 4578,
    label: "Santo inácio do piauí - PI",
  },
  {
    id: 4579,
    label: "Santópolis do aguapeí - SP",
  },
  {
    id: 4580,
    label: "Santos - SP",
  },
  {
    id: 4581,
    label: "Santos dumont - MG",
  },
  {
    id: 4582,
    label: "São benedito - CE",
  },
  {
    id: 4583,
    label: "São benedito do rio preto - MA",
  },
  {
    id: 4584,
    label: "São benedito do sul - PE",
  },
  {
    id: 4585,
    label: "São bentinho - PB",
  },
  {
    id: 4586,
    label: "São bento - MA",
  },
  {
    id: 4587,
    label: "São bento - PB",
  },
  {
    id: 4588,
    label: "São bento abade - MG",
  },
  {
    id: 4589,
    label: "São bento do norte - RN",
  },
  {
    id: 4590,
    label: "São bento do sapucaí - SP",
  },
  {
    id: 4591,
    label: "São bento do sul - SC",
  },
  {
    id: 4592,
    label: "São bento do tocantins - TO",
  },
  {
    id: 4593,
    label: "São bento do trairí - RN",
  },
  {
    id: 4594,
    label: "São bento do una - PE",
  },
  {
    id: 4595,
    label: "São bernardino - SC",
  },
  {
    id: 4596,
    label: "São bernardo - MA",
  },
  {
    id: 4597,
    label: "São bernardo do campo - SP",
  },
  {
    id: 4598,
    label: "São bonifácio - SC",
  },
  {
    id: 4599,
    label: "São borja - RS",
  },
  {
    id: 4600,
    label: "São brás - AL",
  },
  {
    id: 4601,
    label: "São brás do suaçuí - MG",
  },
  {
    id: 4602,
    label: "São braz do piauí - PI",
  },
  {
    id: 4603,
    label: "São caetano de odivelas - PA",
  },
  {
    id: 4604,
    label: "São caetano do sul - SP",
  },
  {
    id: 4605,
    label: "São caitano - PE",
  },
  {
    id: 4606,
    label: "São carlos - SC",
  },
  {
    id: 4607,
    label: "São carlos - SP",
  },
  {
    id: 4608,
    label: "São carlos do ivaí - PR",
  },
  {
    id: 4609,
    label: "São cristóvão - SE",
  },
  {
    id: 4610,
    label: "São cristóvão do sul - SC",
  },
  {
    id: 4611,
    label: "São desidério - BA",
  },
  {
    id: 4612,
    label: "São domingos - BA",
  },
  {
    id: 4613,
    label: "São domingos - GO",
  },
  {
    id: 4614,
    label: "São domingos - PB",
  },
  {
    id: 4615,
    label: "São domingos - SC",
  },
  {
    id: 4616,
    label: "São domingos - SE",
  },
  {
    id: 4617,
    label: "São domingos das dores - MG",
  },
  {
    id: 4618,
    label: "São domingos do araguaia - PA",
  },
  {
    id: 4619,
    label: "São domingos do azeitão - MA",
  },
  {
    id: 4620,
    label: "São domingos do capim - PA",
  },
  {
    id: 4621,
    label: "São domingos do cariri - PB",
  },
  {
    id: 4622,
    label: "São domingos do maranhão - MA",
  },
  {
    id: 4623,
    label: "São domingos do norte - ES",
  },
  {
    id: 4624,
    label: "São domingos do prata - MG",
  },
  {
    id: 4625,
    label: "São domingos do sul - RS",
  },
  {
    id: 4626,
    label: "São felipe - BA",
  },
  {
    id: 4627,
    label: "São felipe d'oeste - RO",
  },
  {
    id: 4628,
    label: "São félix - BA",
  },
  {
    id: 4629,
    label: "São félix de balsas - MA",
  },
  {
    id: 4630,
    label: "São félix de minas - MG",
  },
  {
    id: 4631,
    label: "São félix do araguaia - MT",
  },
  {
    id: 4632,
    label: "São félix do coribe - BA",
  },
  {
    id: 4633,
    label: "São félix do piauí - PI",
  },
  {
    id: 4634,
    label: "São félix do tocantins - TO",
  },
  {
    id: 4635,
    label: "São félix do xingu - PA",
  },
  {
    id: 4636,
    label: "São fernando - RN",
  },
  {
    id: 4637,
    label: "São fidélis - RJ",
  },
  {
    id: 4638,
    label: "São francisco - MG",
  },
  {
    id: 4639,
    label: "São francisco - PB",
  },
  {
    id: 4640,
    label: "São francisco - SE",
  },
  {
    id: 4641,
    label: "São francisco - SP",
  },
  {
    id: 4642,
    label: "São francisco de assis - RS",
  },
  {
    id: 4643,
    label: "São francisco de assis do piauí - PI",
  },
  {
    id: 4644,
    label: "São francisco de goiás - GO",
  },
  {
    id: 4645,
    label: "São francisco de itabapoana - RJ",
  },
  {
    id: 4646,
    label: "São francisco de paula - MG",
  },
  {
    id: 4647,
    label: "São francisco de paula - RS",
  },
  {
    id: 4648,
    label: "São francisco de sales - MG",
  },
  {
    id: 4649,
    label: "São francisco do brejão - MA",
  },
  {
    id: 4650,
    label: "São francisco do conde - BA",
  },
  {
    id: 4651,
    label: "São francisco do glória - MG",
  },
  {
    id: 4652,
    label: "São francisco do guaporé - RO",
  },
  {
    id: 4653,
    label: "São francisco do maranhão - MA",
  },
  {
    id: 4654,
    label: "São francisco do oeste - RN",
  },
  {
    id: 4655,
    label: "São francisco do pará - PA",
  },
  {
    id: 4656,
    label: "São francisco do piauí - PI",
  },
  {
    id: 4657,
    label: "São francisco do sul - SC",
  },
  {
    id: 4658,
    label: "São gabriel - BA",
  },
  {
    id: 4659,
    label: "São gabriel - RS",
  },
  {
    id: 4660,
    label: "São gabriel da cachoeira - AM",
  },
  {
    id: 4661,
    label: "São gabriel da palha - ES",
  },
  {
    id: 4662,
    label: "São gabriel do oeste - MS",
  },
  {
    id: 4663,
    label: "São geraldo - MG",
  },
  {
    id: 4664,
    label: "São geraldo da piedade - MG",
  },
  {
    id: 4665,
    label: "São geraldo do araguaia - PA",
  },
  {
    id: 4666,
    label: "São geraldo do baixio - MG",
  },
  {
    id: 4667,
    label: "São gonçalo - RJ",
  },
  {
    id: 4668,
    label: "São gonçalo do abaeté - MG",
  },
  {
    id: 4669,
    label: "São gonçalo do amarante - CE",
  },
  {
    id: 4670,
    label: "São gonçalo do amarante - RN",
  },
  {
    id: 4671,
    label: "São gonçalo do gurguéia - PI",
  },
  {
    id: 4672,
    label: "São gonçalo do pará - MG",
  },
  {
    id: 4673,
    label: "São gonçalo do piauí - PI",
  },
  {
    id: 4674,
    label: "São gonçalo do rio abaixo - MG",
  },
  {
    id: 4675,
    label: "São gonçalo do rio preto - MG",
  },
  {
    id: 4676,
    label: "São gonçalo do sapucaí - MG",
  },
  {
    id: 4677,
    label: "São gonçalo dos campos - BA",
  },
  {
    id: 4678,
    label: "São gotardo - MG",
  },
  {
    id: 4679,
    label: "São jerônimo - RS",
  },
  {
    id: 4680,
    label: "São jerônimo da serra - PR",
  },
  {
    id: 4681,
    label: "São joão - PE",
  },
  {
    id: 4682,
    label: "São joão - PR",
  },
  {
    id: 4683,
    label: "São joão batista - MA",
  },
  {
    id: 4684,
    label: "São joão batista - SC",
  },
  {
    id: 4685,
    label: "São joão batista do glória - MG",
  },
  {
    id: 4686,
    label: "São joão d'aliança - GO",
  },
  {
    id: 4687,
    label: "São joão da baliza - RR",
  },
  {
    id: 4688,
    label: "São joão da barra - RJ",
  },
  {
    id: 4689,
    label: "São joão da boa vista - SP",
  },
  {
    id: 4690,
    label: "São joão da canabrava - PI",
  },
  {
    id: 4691,
    label: "São joão da fronteira - PI",
  },
  {
    id: 4692,
    label: "São joão da lagoa - MG",
  },
  {
    id: 4693,
    label: "São joão da mata - MG",
  },
  {
    id: 4694,
    label: "São joão da paraúna - GO",
  },
  {
    id: 4695,
    label: "São joão da ponta - PA",
  },
  {
    id: 4696,
    label: "São joão da ponte - MG",
  },
  {
    id: 4697,
    label: "São joão da serra - PI",
  },
  {
    id: 4698,
    label: "São joão da urtiga - RS",
  },
  {
    id: 4699,
    label: "São joão da varjota - PI",
  },
  {
    id: 4700,
    label: "São joão das duas pontes - SP",
  },
  {
    id: 4701,
    label: "São joão das missões - MG",
  },
  {
    id: 4702,
    label: "São joão de iracema - SP",
  },
  {
    id: 4703,
    label: "São joão de meriti - RJ",
  },
  {
    id: 4704,
    label: "São joão de pirabas - PA",
  },
  {
    id: 4705,
    label: "São joão del rei - MG",
  },
  {
    id: 4706,
    label: "São joão do araguaia - PA",
  },
  {
    id: 4707,
    label: "São joão do arraial - PI",
  },
  {
    id: 4708,
    label: "São joão do caiuá - PR",
  },
  {
    id: 4709,
    label: "São joão do cariri - PB",
  },
  {
    id: 4710,
    label: "São joão do carú - MA",
  },
  {
    id: 4711,
    label: "São joão do itaperiú - SC",
  },
  {
    id: 4712,
    label: "São joão do ivaí - PR",
  },
  {
    id: 4713,
    label: "São joão do jaguaribe - CE",
  },
  {
    id: 4714,
    label: "São joão do manhuaçu - MG",
  },
  {
    id: 4715,
    label: "São joão do manteninha - MG",
  },
  {
    id: 4716,
    label: "São joão do oeste - SC",
  },
  {
    id: 4717,
    label: "São joão do oriente - MG",
  },
  {
    id: 4718,
    label: "São joão do pacuí - MG",
  },
  {
    id: 4719,
    label: "São joão do paraíso - MA",
  },
  {
    id: 4720,
    label: "São joão do paraíso - MG",
  },
  {
    id: 4721,
    label: "São joão do pau d'alho - SP",
  },
  {
    id: 4722,
    label: "São joão do piauí - PI",
  },
  {
    id: 4723,
    label: "São joão do polêsine - RS",
  },
  {
    id: 4724,
    label: "São joão do rio do peixe - PB",
  },
  {
    id: 4725,
    label: "São joão do sabugi - RN",
  },
  {
    id: 4726,
    label: "São joão do soter - MA",
  },
  {
    id: 4727,
    label: "São joão do sul - SC",
  },
  {
    id: 4728,
    label: "São joão do tigre - PB",
  },
  {
    id: 4729,
    label: "São joão do triunfo - PR",
  },
  {
    id: 4730,
    label: "São joão dos patos - MA",
  },
  {
    id: 4731,
    label: "São joão evangelista - MG",
  },
  {
    id: 4732,
    label: "São joão nepomuceno - MG",
  },
  {
    id: 4733,
    label: "São joaquim - SC",
  },
  {
    id: 4734,
    label: "São joaquim da barra - SP",
  },
  {
    id: 4735,
    label: "São joaquim de bicas - MG",
  },
  {
    id: 4736,
    label: "São joaquim do monte - PE",
  },
  {
    id: 4737,
    label: "São jorge - RS",
  },
  {
    id: 4738,
    label: "São jorge d'oeste - PR",
  },
  {
    id: 4739,
    label: "São jorge do ivaí - PR",
  },
  {
    id: 4740,
    label: "São jorge do patrocínio - PR",
  },
  {
    id: 4741,
    label: "São josé - SC",
  },
  {
    id: 4742,
    label: "São josé da barra - MG",
  },
  {
    id: 4743,
    label: "São josé da bela vista - SP",
  },
  {
    id: 4744,
    label: "São josé da boa vista - PR",
  },
  {
    id: 4745,
    label: "São josé da coroa grande - PE",
  },
  {
    id: 4746,
    label: "São josé da lagoa tapada - PB",
  },
  {
    id: 4747,
    label: "São josé da laje - AL",
  },
  {
    id: 4748,
    label: "São josé da lapa - MG",
  },
  {
    id: 4749,
    label: "São josé da safira - MG",
  },
  {
    id: 4750,
    label: "São josé da tapera - AL",
  },
  {
    id: 4751,
    label: "São josé da varginha - MG",
  },
  {
    id: 4752,
    label: "São josé da vitória - BA",
  },
  {
    id: 4753,
    label: "São josé das missões - RS",
  },
  {
    id: 4754,
    label: "São josé das palmeiras - PR",
  },
  {
    id: 4755,
    label: "São josé de caiana - PB",
  },
  {
    id: 4756,
    label: "São josé de espinharas - PB",
  },
  {
    id: 4757,
    label: "São josé de mipibu - RN",
  },
  {
    id: 4758,
    label: "São josé de piranhas - PB",
  },
  {
    id: 4759,
    label: "São josé de princesa - PB",
  },
  {
    id: 4760,
    label: "São josé de ribamar - MA",
  },
  {
    id: 4761,
    label: "São josé de ubá - RJ",
  },
  {
    id: 4762,
    label: "São josé do alegre - MG",
  },
  {
    id: 4763,
    label: "São josé do barreiro - SP",
  },
  {
    id: 4764,
    label: "São josé do belmonte - PE",
  },
  {
    id: 4765,
    label: "São josé do bonfim - PB",
  },
  {
    id: 4766,
    label: "São josé do brejo do cruz - PB",
  },
  {
    id: 4767,
    label: "São josé do calçado - ES",
  },
  {
    id: 4768,
    label: "São josé do campestre - RN",
  },
  {
    id: 4769,
    label: "São josé do cedro - SC",
  },
  {
    id: 4770,
    label: "São josé do cerrito - SC",
  },
  {
    id: 4771,
    label: "São josé do divino - MG",
  },
  {
    id: 4772,
    label: "São josé do divino - PI",
  },
  {
    id: 4773,
    label: "São josé do egito - PE",
  },
  {
    id: 4774,
    label: "São josé do goiabal - MG",
  },
  {
    id: 4775,
    label: "São josé do herval - RS",
  },
  {
    id: 4776,
    label: "São josé do hortêncio - RS",
  },
  {
    id: 4777,
    label: "São josé do inhacorá - RS",
  },
  {
    id: 4778,
    label: "São josé do jacuípe - BA",
  },
  {
    id: 4779,
    label: "São josé do jacuri - MG",
  },
  {
    id: 4780,
    label: "São josé do mantimento - MG",
  },
  {
    id: 4781,
    label: "São josé do norte - RS",
  },
  {
    id: 4782,
    label: "São josé do ouro - RS",
  },
  {
    id: 4783,
    label: "São josé do peixe - PI",
  },
  {
    id: 4784,
    label: "São josé do piauí - PI",
  },
  {
    id: 4785,
    label: "São josé do povo - MT",
  },
  {
    id: 4786,
    label: "São josé do rio claro - MT",
  },
  {
    id: 4787,
    label: "São josé do rio pardo - SP",
  },
  {
    id: 4788,
    label: "São josé do rio preto - SP",
  },
  {
    id: 4789,
    label: "São josé do sabugi - PB",
  },
  {
    id: 4790,
    label: "São josé do seridó - RN",
  },
  {
    id: 4791,
    label: "São josé do sul - RS",
  },
  {
    id: 4792,
    label: "São josé do vale do rio preto - RJ",
  },
  {
    id: 4793,
    label: "São josé do xingu - MT",
  },
  {
    id: 4794,
    label: "São josé dos ausentes - RS",
  },
  {
    id: 4795,
    label: "São josé dos basílios - MA",
  },
  {
    id: 4796,
    label: "São josé dos campos - SP",
  },
  {
    id: 4797,
    label: "São josé dos cordeiros - PB",
  },
  {
    id: 4798,
    label: "São josé dos pinhais - PR",
  },
  {
    id: 4799,
    label: "São josé dos quatro marcos - MT",
  },
  {
    id: 4800,
    label: "São josé dos ramos - PB",
  },
  {
    id: 4801,
    label: "São julião - PI",
  },
  {
    id: 4802,
    label: "São leopoldo - RS",
  },
  {
    id: 4803,
    label: "São lourenço - MG",
  },
  {
    id: 4804,
    label: "São lourenço da mata - PE",
  },
  {
    id: 4805,
    label: "São lourenço da serra - SP",
  },
  {
    id: 4806,
    label: "São lourenço do oeste - SC",
  },
  {
    id: 4807,
    label: "São lourenço do piauí - PI",
  },
  {
    id: 4808,
    label: "São lourenço do sul - RS",
  },
  {
    id: 4809,
    label: "São ludgero - SC",
  },
  {
    id: 4810,
    label: "São luís - MA",
  },
  {
    id: 4811,
    label: "São luís de montes belos - GO",
  },
  {
    id: 4812,
    label: "São luís do curu - CE",
  },
  {
    id: 4813,
    label: "São luís do paraitinga - SP",
  },
  {
    id: 4814,
    label: "São luis do piauí - PI",
  },
  {
    id: 4815,
    label: "São luís do quitunde - AL",
  },
  {
    id: 4816,
    label: "São luís gonzaga do maranhão - MA",
  },
  {
    id: 4817,
    label: "São luiz - RR",
  },
  {
    id: 4818,
    label: "São luiz do norte - GO",
  },
  {
    id: 4819,
    label: "São luiz gonzaga - RS",
  },
  {
    id: 4820,
    label: "São mamede - PB",
  },
  {
    id: 4821,
    label: "São manoel do paraná - PR",
  },
  {
    id: 4822,
    label: "São manuel - SP",
  },
  {
    id: 4823,
    label: "São marcos - RS",
  },
  {
    id: 4824,
    label: "São martinho - RS",
  },
  {
    id: 4825,
    label: "São martinho - SC",
  },
  {
    id: 4826,
    label: "São martinho da serra - RS",
  },
  {
    id: 4827,
    label: "São mateus - ES",
  },
  {
    id: 4828,
    label: "São mateus do maranhão - MA",
  },
  {
    id: 4829,
    label: "São mateus do sul - PR",
  },
  {
    id: 4830,
    label: "São miguel - RN",
  },
  {
    id: 4831,
    label: "São miguel arcanjo - SP",
  },
  {
    id: 4832,
    label: "São miguel da baixa grande - PI",
  },
  {
    id: 4833,
    label: "São miguel da boa vista - SC",
  },
  {
    id: 4834,
    label: "São miguel das matas - BA",
  },
  {
    id: 4835,
    label: "São miguel das missões - RS",
  },
  {
    id: 4836,
    label: "São miguel de taipu - PB",
  },
  {
    id: 4837,
    label: "São miguel do aleixo - SE",
  },
  {
    id: 4838,
    label: "São miguel do anta - MG",
  },
  {
    id: 4839,
    label: "São miguel do araguaia - GO",
  },
  {
    id: 4840,
    label: "São miguel do fidalgo - PI",
  },
  {
    id: 4841,
    label: "São miguel do gostoso - RN",
  },
  {
    id: 4842,
    label: "São miguel do guamá - PA",
  },
  {
    id: 4843,
    label: "São miguel do guaporé - RO",
  },
  {
    id: 4844,
    label: "São miguel do iguaçu - PR",
  },
  {
    id: 4845,
    label: "São miguel do oeste - SC",
  },
  {
    id: 4846,
    label: "São miguel do passa quatro - GO",
  },
  {
    id: 4847,
    label: "São miguel do tapuio - PI",
  },
  {
    id: 4848,
    label: "São miguel do tocantins - TO",
  },
  {
    id: 4849,
    label: "São miguel dos campos - AL",
  },
  {
    id: 4850,
    label: "São miguel dos milagres - AL",
  },
  {
    id: 4851,
    label: "São nicolau - RS",
  },
  {
    id: 4852,
    label: "São patrício - GO",
  },
  {
    id: 4853,
    label: "São paulo - SP",
  },
  {
    id: 4854,
    label: "São paulo das missões - RS",
  },
  {
    id: 4855,
    label: "São paulo de olivença - AM",
  },
  {
    id: 4856,
    label: "São paulo do potengi - RN",
  },
  {
    id: 4857,
    label: "São pedro - RN",
  },
  {
    id: 4858,
    label: "São pedro - SP",
  },
  {
    id: 4859,
    label: "São pedro da água branca - MA",
  },
  {
    id: 4860,
    label: "São pedro da aldeia - RJ",
  },
  {
    id: 4861,
    label: "São pedro da cipa - MT",
  },
  {
    id: 4862,
    label: "São pedro da serra - RS",
  },
  {
    id: 4863,
    label: "São pedro da união - MG",
  },
  {
    id: 4864,
    label: "São pedro das missões - RS",
  },
  {
    id: 4865,
    label: "São pedro de alcântara - SC",
  },
  {
    id: 4866,
    label: "São pedro do butiá - RS",
  },
  {
    id: 4867,
    label: "São pedro do iguaçu - PR",
  },
  {
    id: 4868,
    label: "São pedro do ivaí - PR",
  },
  {
    id: 4869,
    label: "São pedro do paraná - PR",
  },
  {
    id: 4870,
    label: "São pedro do piauí - PI",
  },
  {
    id: 4871,
    label: "São pedro do suaçuí - MG",
  },
  {
    id: 4872,
    label: "São pedro do sul - RS",
  },
  {
    id: 4873,
    label: "São pedro do turvo - SP",
  },
  {
    id: 4874,
    label: "São pedro dos crentes - MA",
  },
  {
    id: 4875,
    label: "São pedro dos ferros - MG",
  },
  {
    id: 4876,
    label: "São rafael - RN",
  },
  {
    id: 4877,
    label: "São raimundo das mangabeiras - MA",
  },
  {
    id: 4878,
    label: "São raimundo do doca bezerra - MA",
  },
  {
    id: 4879,
    label: "São raimundo nonato - PI",
  },
  {
    id: 4880,
    label: "São roberto - MA",
  },
  {
    id: 4881,
    label: "São romão - MG",
  },
  {
    id: 4882,
    label: "São roque - SP",
  },
  {
    id: 4883,
    label: "São roque de minas - MG",
  },
  {
    id: 4884,
    label: "São roque do canaã - ES",
  },
  {
    id: 4885,
    label: "São salvador do tocantins - TO",
  },
  {
    id: 4886,
    label: "São sebastião - AL",
  },
  {
    id: 4887,
    label: "São sebastião - SP",
  },
  {
    id: 4888,
    label: "São sebastião da amoreira - PR",
  },
  {
    id: 4889,
    label: "São sebastião da bela vista - MG",
  },
  {
    id: 4890,
    label: "São sebastião da boa vista - PA",
  },
  {
    id: 4891,
    label: "São sebastião da grama - SP",
  },
  {
    id: 4892,
    label: "São sebastião da vargem alegre - MG",
  },
  {
    id: 4893,
    label: "São sebastião de lagoa de roça - PB",
  },
  {
    id: 4894,
    label: "São sebastião do alto - RJ",
  },
  {
    id: 4895,
    label: "São sebastião do anta - MG",
  },
  {
    id: 4896,
    label: "São sebastião do caí - RS",
  },
  {
    id: 4897,
    label: "São sebastião do maranhão - MG",
  },
  {
    id: 4898,
    label: "São sebastião do oeste - MG",
  },
  {
    id: 4899,
    label: "São sebastião do paraíso - MG",
  },
  {
    id: 4900,
    label: "São sebastião do passé - BA",
  },
  {
    id: 4901,
    label: "São sebastião do rio preto - MG",
  },
  {
    id: 4902,
    label: "São sebastião do rio verde - MG",
  },
  {
    id: 4903,
    label: "São sebastião do tocantins - TO",
  },
  {
    id: 4904,
    label: "São sebastião do uatumã - AM",
  },
  {
    id: 4905,
    label: "São sebastião do umbuzeiro - PB",
  },
  {
    id: 4906,
    label: "São sepé - RS",
  },
  {
    id: 4907,
    label: "São simão - GO",
  },
  {
    id: 4908,
    label: "São simão - SP",
  },
  {
    id: 4909,
    label: "São thomé das letras - MG",
  },
  {
    id: 4910,
    label: "São tiago - MG",
  },
  {
    id: 4911,
    label: "São tomás de aquino - MG",
  },
  {
    id: 4912,
    label: "São tomé - PR",
  },
  {
    id: 4913,
    label: "São tomé - RN",
  },
  {
    id: 4914,
    label: "São valentim - RS",
  },
  {
    id: 4915,
    label: "São valentim do sul - RS",
  },
  {
    id: 4916,
    label: "São valério - TO",
  },
  {
    id: 4917,
    label: "São valério do sul - RS",
  },
  {
    id: 4918,
    label: "São vendelino - RS",
  },
  {
    id: 4919,
    label: "São vicente - RN",
  },
  {
    id: 4920,
    label: "São vicente - SP",
  },
  {
    id: 4921,
    label: "São vicente de minas - MG",
  },
  {
    id: 4922,
    label: "São vicente do seridó - PB",
  },
  {
    id: 4923,
    label: "São vicente do sul - RS",
  },
  {
    id: 4924,
    label: "São vicente ferrer - MA",
  },
  {
    id: 4925,
    label: "São vicente férrer - PE",
  },
  {
    id: 4926,
    label: "Sapé - PB",
  },
  {
    id: 4927,
    label: "Sapeaçu - BA",
  },
  {
    id: 4928,
    label: "Sapezal - MT",
  },
  {
    id: 4929,
    label: "Sapiranga - RS",
  },
  {
    id: 4930,
    label: "Sapopema - PR",
  },
  {
    id: 4931,
    label: "Sapucaí-mirim - MG",
  },
  {
    id: 4932,
    label: "Sapucaia - PA",
  },
  {
    id: 4933,
    label: "Sapucaia - RJ",
  },
  {
    id: 4934,
    label: "Sapucaia do sul - RS",
  },
  {
    id: 4935,
    label: "Saquarema - RJ",
  },
  {
    id: 4936,
    label: "Sarandi - PR",
  },
  {
    id: 4937,
    label: "Sarandi - RS",
  },
  {
    id: 4938,
    label: "Sarapuí - SP",
  },
  {
    id: 4939,
    label: "Sardoá - MG",
  },
  {
    id: 4940,
    label: "Sarutaiá - SP",
  },
  {
    id: 4941,
    label: "Sarzedo - MG",
  },
  {
    id: 4942,
    label: "Sátiro dias - BA",
  },
  {
    id: 4943,
    label: "Satuba - AL",
  },
  {
    id: 4944,
    label: "Satubinha - MA",
  },
  {
    id: 4945,
    label: "Saubara - BA",
  },
  {
    id: 4946,
    label: "Saudade do iguaçu - PR",
  },
  {
    id: 4947,
    label: "Saudades - SC",
  },
  {
    id: 4948,
    label: "Saúde - BA",
  },
  {
    id: 4949,
    label: "Schroeder - SC",
  },
  {
    id: 4950,
    label: "Seabra - BA",
  },
  {
    id: 4951,
    label: "Seara - SC",
  },
  {
    id: 4952,
    label: "Sebastianópolis do sul - SP",
  },
  {
    id: 4953,
    label: "Sebastião barros - PI",
  },
  {
    id: 4954,
    label: "Sebastião laranjeiras - BA",
  },
  {
    id: 4955,
    label: "Sebastião leal - PI",
  },
  {
    id: 4956,
    label: "Seberi - RS",
  },
  {
    id: 4957,
    label: "Sede nova - RS",
  },
  {
    id: 4958,
    label: "Segredo - RS",
  },
  {
    id: 4959,
    label: "Selbach - RS",
  },
  {
    id: 4960,
    label: "Selvíria - MS",
  },
  {
    id: 4961,
    label: "Sem peixe - MG",
  },
  {
    id: 4962,
    label: "Sena madureira - AC",
  },
  {
    id: 4963,
    label: "Senador alexandre costa - MA",
  },
  {
    id: 4964,
    label: "Senador amaral - MG",
  },
  {
    id: 4965,
    label: "Senador canedo - GO",
  },
  {
    id: 4966,
    label: "Senador cortes - MG",
  },
  {
    id: 4967,
    label: "Senador elói de souza - RN",
  },
  {
    id: 4968,
    label: "Senador firmino - MG",
  },
  {
    id: 4969,
    label: "Senador georgino avelino - RN",
  },
  {
    id: 4970,
    label: "Senador guiomard - AC",
  },
  {
    id: 4971,
    label: "Senador josé bento - MG",
  },
  {
    id: 4972,
    label: "Senador josé porfírio - PA",
  },
  {
    id: 4973,
    label: "Senador la rocque - MA",
  },
  {
    id: 4974,
    label: "Senador modestino gonçalves - MG",
  },
  {
    id: 4975,
    label: "Senador pompeu - CE",
  },
  {
    id: 4976,
    label: "Senador rui palmeira - AL",
  },
  {
    id: 4977,
    label: "Senador sá - CE",
  },
  {
    id: 4978,
    label: "Senador salgado filho - RS",
  },
  {
    id: 4979,
    label: "Sengés - PR",
  },
  {
    id: 4980,
    label: "Senhor do bonfim - BA",
  },
  {
    id: 4981,
    label: "Senhora de oliveira - MG",
  },
  {
    id: 4982,
    label: "Senhora do porto - MG",
  },
  {
    id: 4983,
    label: "Senhora dos remédios - MG",
  },
  {
    id: 4984,
    label: "Sentinela do sul - RS",
  },
  {
    id: 4985,
    label: "Sento sé - BA",
  },
  {
    id: 4986,
    label: "Serafina corrêa - RS",
  },
  {
    id: 4987,
    label: "Sericita - MG",
  },
  {
    id: 4988,
    label: "Seringueiras - RO",
  },
  {
    id: 4989,
    label: "Sério - RS",
  },
  {
    id: 4990,
    label: "Seritinga - MG",
  },
  {
    id: 4991,
    label: "Seropédica - RJ",
  },
  {
    id: 4992,
    label: "Serra - ES",
  },
  {
    id: 4993,
    label: "Serra alta - SC",
  },
  {
    id: 4994,
    label: "Serra azul - SP",
  },
  {
    id: 4995,
    label: "Serra azul de minas - MG",
  },
  {
    id: 4996,
    label: "Serra branca - PB",
  },
  {
    id: 4997,
    label: "Serra caiada - RN",
  },
  {
    id: 4998,
    label: "Serra da raiz - PB",
  },
  {
    id: 4999,
    label: "Serra da saudade - MG",
  },
  {
    id: 5000,
    label: "Serra de são bento - RN",
  },
  {
    id: 5001,
    label: "Serra do mel - RN",
  },
  {
    id: 5002,
    label: "Serra do navio - AP",
  },
  {
    id: 5003,
    label: "Serra do ramalho - BA",
  },
  {
    id: 5004,
    label: "Serra do salitre - MG",
  },
  {
    id: 5005,
    label: "Serra dos aimorés - MG",
  },
  {
    id: 5006,
    label: "Serra dourada - BA",
  },
  {
    id: 5007,
    label: "Serra grande - PB",
  },
  {
    id: 5008,
    label: "Serra negra - SP",
  },
  {
    id: 5009,
    label: "Serra negra do norte - RN",
  },
  {
    id: 5010,
    label: "Serra nova dourada - MT",
  },
  {
    id: 5011,
    label: "Serra preta - BA",
  },
  {
    id: 5012,
    label: "Serra redonda - PB",
  },
  {
    id: 5013,
    label: "Serra talhada - PE",
  },
  {
    id: 5014,
    label: "Serrana - SP",
  },
  {
    id: 5015,
    label: "Serrania - MG",
  },
  {
    id: 5016,
    label: "Serrano do maranhão - MA",
  },
  {
    id: 5017,
    label: "Serranópolis - GO",
  },
  {
    id: 5018,
    label: "Serranópolis de minas - MG",
  },
  {
    id: 5019,
    label: "Serranópolis do iguaçu - PR",
  },
  {
    id: 5020,
    label: "Serranos - MG",
  },
  {
    id: 5021,
    label: "Serraria - PB",
  },
  {
    id: 5022,
    label: "Serrinha - BA",
  },
  {
    id: 5023,
    label: "Serrinha - RN",
  },
  {
    id: 5024,
    label: "Serrinha dos pintos - RN",
  },
  {
    id: 5025,
    label: "Serrita - PE",
  },
  {
    id: 5026,
    label: "Serro - MG",
  },
  {
    id: 5027,
    label: "Serrolândia - BA",
  },
  {
    id: 5028,
    label: "Sertaneja - PR",
  },
  {
    id: 5029,
    label: "Sertânia - PE",
  },
  {
    id: 5030,
    label: "Sertanópolis - PR",
  },
  {
    id: 5031,
    label: "Sertão - RS",
  },
  {
    id: 5032,
    label: "Sertão santana - RS",
  },
  {
    id: 5033,
    label: "Sertãozinho - PB",
  },
  {
    id: 5034,
    label: "Sertãozinho - SP",
  },
  {
    id: 5035,
    label: "Sete barras - SP",
  },
  {
    id: 5036,
    label: "Sete de setembro - RS",
  },
  {
    id: 5037,
    label: "Sete lagoas - MG",
  },
  {
    id: 5038,
    label: "Sete quedas - MS",
  },
  {
    id: 5039,
    label: "Setubinha - MG",
  },
  {
    id: 5040,
    label: "Severiano de almeida - RS",
  },
  {
    id: 5041,
    label: "Severiano melo - RN",
  },
  {
    id: 5042,
    label: "Severínia - SP",
  },
  {
    id: 5043,
    label: "Siderópolis - SC",
  },
  {
    id: 5044,
    label: "Sidrolândia - MS",
  },
  {
    id: 5045,
    label: "Sigefredo pacheco - PI",
  },
  {
    id: 5046,
    label: "Silva jardim - RJ",
  },
  {
    id: 5047,
    label: "Silvânia - GO",
  },
  {
    id: 5048,
    label: "Silvanópolis - TO",
  },
  {
    id: 5049,
    label: "Silveira martins - RS",
  },
  {
    id: 5050,
    label: "Silveirânia - MG",
  },
  {
    id: 5051,
    label: "Silveiras - SP",
  },
  {
    id: 5052,
    label: "Silves - AM",
  },
  {
    id: 5053,
    label: "Silvianópolis - MG",
  },
  {
    id: 5054,
    label: "Simão dias - SE",
  },
  {
    id: 5055,
    label: "Simão pereira - MG",
  },
  {
    id: 5056,
    label: "Simões - PI",
  },
  {
    id: 5057,
    label: "Simões filho - BA",
  },
  {
    id: 5058,
    label: "Simolândia - GO",
  },
  {
    id: 5059,
    label: "Simonésia - MG",
  },
  {
    id: 5060,
    label: "Simplício mendes - PI",
  },
  {
    id: 5061,
    label: "Sinimbu - RS",
  },
  {
    id: 5062,
    label: "Sinop - MT",
  },
  {
    id: 5063,
    label: "Siqueira campos - PR",
  },
  {
    id: 5064,
    label: "Sirinhaém - PE",
  },
  {
    id: 5065,
    label: "Siriri - SE",
  },
  {
    id: 5066,
    label: "Sítio d'abadia - GO",
  },
  {
    id: 5067,
    label: "Sítio do mato - BA",
  },
  {
    id: 5068,
    label: "Sítio do quinto - BA",
  },
  {
    id: 5069,
    label: "Sítio novo - MA",
  },
  {
    id: 5070,
    label: "Sítio novo - RN",
  },
  {
    id: 5071,
    label: "Sítio novo do tocantins - TO",
  },
  {
    id: 5072,
    label: "Sobradinho - BA",
  },
  {
    id: 5073,
    label: "Sobradinho - RS",
  },
  {
    id: 5074,
    label: "Sobrado - PB",
  },
  {
    id: 5075,
    label: "Sobral - CE",
  },
  {
    id: 5076,
    label: "Sobrália - MG",
  },
  {
    id: 5077,
    label: "Socorro - SP",
  },
  {
    id: 5078,
    label: "Socorro do piauí - PI",
  },
  {
    id: 5079,
    label: "Solânea - PB",
  },
  {
    id: 5080,
    label: "Soledade - PB",
  },
  {
    id: 5081,
    label: "Soledade - RS",
  },
  {
    id: 5082,
    label: "Soledade de minas - MG",
  },
  {
    id: 5083,
    label: "Solidão - PE",
  },
  {
    id: 5084,
    label: "Solonópole - CE",
  },
  {
    id: 5085,
    label: "Sombrio - SC",
  },
  {
    id: 5086,
    label: "Sonora - MS",
  },
  {
    id: 5087,
    label: "Sooretama - ES",
  },
  {
    id: 5088,
    label: "Sorocaba - SP",
  },
  {
    id: 5089,
    label: "Sorriso - MT",
  },
  {
    id: 5090,
    label: "Sossêgo - PB",
  },
  {
    id: 5091,
    label: "Soure - PA",
  },
  {
    id: 5092,
    label: "Sousa - PB",
  },
  {
    id: 5093,
    label: "Souto soares - BA",
  },
  {
    id: 5094,
    label: "Sucupira - TO",
  },
  {
    id: 5095,
    label: "Sucupira do norte - MA",
  },
  {
    id: 5096,
    label: "Sucupira do riachão - MA",
  },
  {
    id: 5097,
    label: "Sud mennucci - SP",
  },
  {
    id: 5098,
    label: "Sul brasil - SC",
  },
  {
    id: 5099,
    label: "Sulina - PR",
  },
  {
    id: 5100,
    label: "Sumaré - SP",
  },
  {
    id: 5101,
    label: "Sumé - PB",
  },
  {
    id: 5102,
    label: "Sumidouro - RJ",
  },
  {
    id: 5103,
    label: "Surubim - PE",
  },
  {
    id: 5104,
    label: "Sussuapara - PI",
  },
  {
    id: 5105,
    label: "Suzanápolis - SP",
  },
  {
    id: 5106,
    label: "Suzano - SP",
  },
  {
    id: 5107,
    label: "Tabaí - RS",
  },
  {
    id: 5108,
    label: "Tabaporã - MT",
  },
  {
    id: 5109,
    label: "Tabapuã - SP",
  },
  {
    id: 5110,
    label: "Tabatinga - AM",
  },
  {
    id: 5111,
    label: "Tabatinga - SP",
  },
  {
    id: 5112,
    label: "Tabira - PE",
  },
  {
    id: 5113,
    label: "Taboão da serra - SP",
  },
  {
    id: 5114,
    label: "Tabocão - TO",
  },
  {
    id: 5115,
    label: "Tabocas do brejo velho - BA",
  },
  {
    id: 5116,
    label: "Taboleiro grande - RN",
  },
  {
    id: 5117,
    label: "Tabuleiro - MG",
  },
  {
    id: 5118,
    label: "Tabuleiro do norte - CE",
  },
  {
    id: 5119,
    label: "Tacaimbó - PE",
  },
  {
    id: 5120,
    label: "Tacaratu - PE",
  },
  {
    id: 5121,
    label: "Taciba - SP",
  },
  {
    id: 5122,
    label: "Tacima - PB",
  },
  {
    id: 5123,
    label: "Tacuru - MS",
  },
  {
    id: 5124,
    label: "Taguaí - SP",
  },
  {
    id: 5125,
    label: "Taguatinga - TO",
  },
  {
    id: 5126,
    label: "Taiaçu - SP",
  },
  {
    id: 5127,
    label: "Tailândia - PA",
  },
  {
    id: 5128,
    label: "Taió - SC",
  },
  {
    id: 5129,
    label: "Taiobeiras - MG",
  },
  {
    id: 5130,
    label: "Taipas do tocantins - TO",
  },
  {
    id: 5131,
    label: "Taipu - RN",
  },
  {
    id: 5132,
    label: "Taiúva - SP",
  },
  {
    id: 5133,
    label: "Talismã - TO",
  },
  {
    id: 5134,
    label: "Tamandaré - PE",
  },
  {
    id: 5135,
    label: "Tamarana - PR",
  },
  {
    id: 5136,
    label: "Tambaú - SP",
  },
  {
    id: 5137,
    label: "Tamboara - PR",
  },
  {
    id: 5138,
    label: "Tamboril - CE",
  },
  {
    id: 5139,
    label: "Tamboril do piauí - PI",
  },
  {
    id: 5140,
    label: "Tanabi - SP",
  },
  {
    id: 5141,
    label: "Tangará - RN",
  },
  {
    id: 5142,
    label: "Tangará - SC",
  },
  {
    id: 5143,
    label: "Tangará da serra - MT",
  },
  {
    id: 5144,
    label: "Tanguá - RJ",
  },
  {
    id: 5145,
    label: "Tanhaçu - BA",
  },
  {
    id: 5146,
    label: "Tanque d'arca - AL",
  },
  {
    id: 5147,
    label: "Tanque do piauí - PI",
  },
  {
    id: 5148,
    label: "Tanque novo - BA",
  },
  {
    id: 5149,
    label: "Tanquinho - BA",
  },
  {
    id: 5150,
    label: "Taparuba - MG",
  },
  {
    id: 5151,
    label: "Tapauá - AM",
  },
  {
    id: 5152,
    label: "Tapejara - PR",
  },
  {
    id: 5153,
    label: "Tapejara - RS",
  },
  {
    id: 5154,
    label: "Tapera - RS",
  },
  {
    id: 5155,
    label: "Taperoá - BA",
  },
  {
    id: 5156,
    label: "Taperoá - PB",
  },
  {
    id: 5157,
    label: "Tapes - RS",
  },
  {
    id: 5158,
    label: "Tapira - MG",
  },
  {
    id: 5159,
    label: "Tapira - PR",
  },
  {
    id: 5160,
    label: "Tapiraí - MG",
  },
  {
    id: 5161,
    label: "Tapiraí - SP",
  },
  {
    id: 5162,
    label: "Tapiramutá - BA",
  },
  {
    id: 5163,
    label: "Tapiratiba - SP",
  },
  {
    id: 5164,
    label: "Tapurah - MT",
  },
  {
    id: 5165,
    label: "Taquara - RS",
  },
  {
    id: 5166,
    label: "Taquaraçu de minas - MG",
  },
  {
    id: 5167,
    label: "Taquaral - SP",
  },
  {
    id: 5168,
    label: "Taquaral de goiás - GO",
  },
  {
    id: 5169,
    label: "Taquarana - AL",
  },
  {
    id: 5170,
    label: "Taquari - RS",
  },
  {
    id: 5171,
    label: "Taquaritinga - SP",
  },
  {
    id: 5172,
    label: "Taquaritinga do norte - PE",
  },
  {
    id: 5173,
    label: "Taquarituba - SP",
  },
  {
    id: 5174,
    label: "Taquarivaí - SP",
  },
  {
    id: 5175,
    label: "Taquaruçu do sul - RS",
  },
  {
    id: 5176,
    label: "Taquarussu - MS",
  },
  {
    id: 5177,
    label: "Tarabai - SP",
  },
  {
    id: 5178,
    label: "Tarauacá - AC",
  },
  {
    id: 5179,
    label: "Tarrafas - CE",
  },
  {
    id: 5180,
    label: "Tartarugalzinho - AP",
  },
  {
    id: 5181,
    label: "Tarumã - SP",
  },
  {
    id: 5182,
    label: "Tarumirim - MG",
  },
  {
    id: 5183,
    label: "Tasso fragoso - MA",
  },
  {
    id: 5184,
    label: "Tatuí - SP",
  },
  {
    id: 5185,
    label: "Tauá - CE",
  },
  {
    id: 5186,
    label: "Taubaté - SP",
  },
  {
    id: 5187,
    label: "Tavares - PB",
  },
  {
    id: 5188,
    label: "Tavares - RS",
  },
  {
    id: 5189,
    label: "Tefé - AM",
  },
  {
    id: 5190,
    label: "Teixeira - PB",
  },
  {
    id: 5191,
    label: "Teixeira de freitas - BA",
  },
  {
    id: 5192,
    label: "Teixeira soares - PR",
  },
  {
    id: 5193,
    label: "Teixeiras - MG",
  },
  {
    id: 5194,
    label: "Teixeirópolis - RO",
  },
  {
    id: 5195,
    label: "Tejuçuoca - CE",
  },
  {
    id: 5196,
    label: "Tejupá - SP",
  },
  {
    id: 5197,
    label: "Telêmaco borba - PR",
  },
  {
    id: 5198,
    label: "Telha - SE",
  },
  {
    id: 5199,
    label: "Tenente ananias - RN",
  },
  {
    id: 5200,
    label: "Tenente laurentino cruz - RN",
  },
  {
    id: 5201,
    label: "Tenente portela - RS",
  },
  {
    id: 5202,
    label: "Tenório - PB",
  },
  {
    id: 5203,
    label: "Teodoro sampaio - BA",
  },
  {
    id: 5204,
    label: "Teodoro sampaio - SP",
  },
  {
    id: 5205,
    label: "Teofilândia - BA",
  },
  {
    id: 5206,
    label: "Teófilo otoni - MG",
  },
  {
    id: 5207,
    label: "Teolândia - BA",
  },
  {
    id: 5208,
    label: "Teotônio vilela - AL",
  },
  {
    id: 5209,
    label: "Terenos - MS",
  },
  {
    id: 5210,
    label: "Teresina - PI",
  },
  {
    id: 5211,
    label: "Teresina de goiás - GO",
  },
  {
    id: 5212,
    label: "Teresópolis - RJ",
  },
  {
    id: 5213,
    label: "Terezinha - PE",
  },
  {
    id: 5214,
    label: "Terezópolis de goiás - GO",
  },
  {
    id: 5215,
    label: "Terra alta - PA",
  },
  {
    id: 5216,
    label: "Terra boa - PR",
  },
  {
    id: 5217,
    label: "Terra de areia - RS",
  },
  {
    id: 5218,
    label: "Terra nova - BA",
  },
  {
    id: 5219,
    label: "Terra nova - PE",
  },
  {
    id: 5220,
    label: "Terra nova do norte - MT",
  },
  {
    id: 5221,
    label: "Terra rica - PR",
  },
  {
    id: 5222,
    label: "Terra roxa - PR",
  },
  {
    id: 5223,
    label: "Terra roxa - SP",
  },
  {
    id: 5224,
    label: "Terra santa - PA",
  },
  {
    id: 5225,
    label: "Tesouro - MT",
  },
  {
    id: 5226,
    label: "Teutônia - RS",
  },
  {
    id: 5227,
    label: "Theobroma - RO",
  },
  {
    id: 5228,
    label: "Tianguá - CE",
  },
  {
    id: 5229,
    label: "Tibagi - PR",
  },
  {
    id: 5230,
    label: "Tibau - RN",
  },
  {
    id: 5231,
    label: "Tibau do sul - RN",
  },
  {
    id: 5232,
    label: "Tietê - SP",
  },
  {
    id: 5233,
    label: "Tigrinhos - SC",
  },
  {
    id: 5234,
    label: "Tijucas - SC",
  },
  {
    id: 5235,
    label: "Tijucas do sul - PR",
  },
  {
    id: 5236,
    label: "Timbaúba - PE",
  },
  {
    id: 5237,
    label: "Timbaúba dos batistas - RN",
  },
  {
    id: 5238,
    label: "Timbé do sul - SC",
  },
  {
    id: 5239,
    label: "Timbiras - MA",
  },
  {
    id: 5240,
    label: "Timbó - SC",
  },
  {
    id: 5241,
    label: "Timbó grande - SC",
  },
  {
    id: 5242,
    label: "Timburi - SP",
  },
  {
    id: 5243,
    label: "Timon - MA",
  },
  {
    id: 5244,
    label: "Timóteo - MG",
  },
  {
    id: 5245,
    label: "Tio hugo - RS",
  },
  {
    id: 5246,
    label: "Tiradentes - MG",
  },
  {
    id: 5247,
    label: "Tiradentes do sul - RS",
  },
  {
    id: 5248,
    label: "Tiros - MG",
  },
  {
    id: 5249,
    label: "Tobias barreto - SE",
  },
  {
    id: 5250,
    label: "Tocantínia - TO",
  },
  {
    id: 5251,
    label: "Tocantinópolis - TO",
  },
  {
    id: 5252,
    label: "Tocantins - MG",
  },
  {
    id: 5253,
    label: "Tocos do moji - MG",
  },
  {
    id: 5254,
    label: "Toledo - MG",
  },
  {
    id: 5255,
    label: "Toledo - PR",
  },
  {
    id: 5256,
    label: "Tomar do geru - SE",
  },
  {
    id: 5257,
    label: "Tomazina - PR",
  },
  {
    id: 5258,
    label: "Tombos - MG",
  },
  {
    id: 5259,
    label: "Tomé-açu - PA",
  },
  {
    id: 5260,
    label: "Tonantins - AM",
  },
  {
    id: 5261,
    label: "Toritama - PE",
  },
  {
    id: 5262,
    label: "Torixoréu - MT",
  },
  {
    id: 5263,
    label: "Toropi - RS",
  },
  {
    id: 5264,
    label: "Torre de pedra - SP",
  },
  {
    id: 5265,
    label: "Torres - RS",
  },
  {
    id: 5266,
    label: "Torrinha - SP",
  },
  {
    id: 5267,
    label: "Touros - RN",
  },
  {
    id: 5268,
    label: "Trabiju - SP",
  },
  {
    id: 5269,
    label: "Tracuateua - PA",
  },
  {
    id: 5270,
    label: "Tracunhaém - PE",
  },
  {
    id: 5271,
    label: "Traipu - AL",
  },
  {
    id: 5272,
    label: "Trairão - PA",
  },
  {
    id: 5273,
    label: "Trairi - CE",
  },
  {
    id: 5274,
    label: "Trajano de moraes - RJ",
  },
  {
    id: 5275,
    label: "Tramandaí - RS",
  },
  {
    id: 5276,
    label: "Travesseiro - RS",
  },
  {
    id: 5277,
    label: "Tremedal - BA",
  },
  {
    id: 5278,
    label: "Tremembé - SP",
  },
  {
    id: 5279,
    label: "Três arroios - RS",
  },
  {
    id: 5280,
    label: "Três barras - SC",
  },
  {
    id: 5281,
    label: "Três barras do paraná - PR",
  },
  {
    id: 5282,
    label: "Três cachoeiras - RS",
  },
  {
    id: 5283,
    label: "Três corações - MG",
  },
  {
    id: 5284,
    label: "Três coroas - RS",
  },
  {
    id: 5285,
    label: "Três de maio - RS",
  },
  {
    id: 5286,
    label: "Três forquilhas - RS",
  },
  {
    id: 5287,
    label: "Três fronteiras - SP",
  },
  {
    id: 5288,
    label: "Três lagoas - MS",
  },
  {
    id: 5289,
    label: "Três marias - MG",
  },
  {
    id: 5290,
    label: "Três palmeiras - RS",
  },
  {
    id: 5291,
    label: "Três passos - RS",
  },
  {
    id: 5292,
    label: "Três pontas - MG",
  },
  {
    id: 5293,
    label: "Três ranchos - GO",
  },
  {
    id: 5294,
    label: "Três rios - RJ",
  },
  {
    id: 5295,
    label: "Treviso - SC",
  },
  {
    id: 5296,
    label: "Treze de maio - SC",
  },
  {
    id: 5297,
    label: "Treze tílias - SC",
  },
  {
    id: 5298,
    label: "Trindade - GO",
  },
  {
    id: 5299,
    label: "Trindade - PE",
  },
  {
    id: 5300,
    label: "Trindade do sul - RS",
  },
  {
    id: 5301,
    label: "Triunfo - PB",
  },
  {
    id: 5302,
    label: "Triunfo - PE",
  },
  {
    id: 5303,
    label: "Triunfo - RS",
  },
  {
    id: 5304,
    label: "Triunfo potiguar - RN",
  },
  {
    id: 5305,
    label: "Trizidela do vale - MA",
  },
  {
    id: 5306,
    label: "Trombas - GO",
  },
  {
    id: 5307,
    label: "Trombudo central - SC",
  },
  {
    id: 5308,
    label: "Tubarão - SC",
  },
  {
    id: 5309,
    label: "Tucano - BA",
  },
  {
    id: 5310,
    label: "Tucumã - PA",
  },
  {
    id: 5311,
    label: "Tucunduva - RS",
  },
  {
    id: 5312,
    label: "Tucuruí - PA",
  },
  {
    id: 5313,
    label: "Tufilândia - MA",
  },
  {
    id: 5314,
    label: "Tuiuti - SP",
  },
  {
    id: 5315,
    label: "Tumiritinga - MG",
  },
  {
    id: 5316,
    label: "Tunápolis - SC",
  },
  {
    id: 5317,
    label: "Tunas - RS",
  },
  {
    id: 5318,
    label: "Tunas do paraná - PR",
  },
  {
    id: 5319,
    label: "Tuneiras do oeste - PR",
  },
  {
    id: 5320,
    label: "Tuntum - MA",
  },
  {
    id: 5321,
    label: "Tupã - SP",
  },
  {
    id: 5322,
    label: "Tupaciguara - MG",
  },
  {
    id: 5323,
    label: "Tupanatinga - PE",
  },
  {
    id: 5324,
    label: "Tupanci do sul - RS",
  },
  {
    id: 5325,
    label: "Tupanciretã - RS",
  },
  {
    id: 5326,
    label: "Tupandi - RS",
  },
  {
    id: 5327,
    label: "Tuparendi - RS",
  },
  {
    id: 5328,
    label: "Tuparetama - PE",
  },
  {
    id: 5329,
    label: "Tupãssi - PR",
  },
  {
    id: 5330,
    label: "Tupi paulista - SP",
  },
  {
    id: 5331,
    label: "Tupirama - TO",
  },
  {
    id: 5332,
    label: "Tupiratins - TO",
  },
  {
    id: 5333,
    label: "Turiaçu - MA",
  },
  {
    id: 5334,
    label: "Turilândia - MA",
  },
  {
    id: 5335,
    label: "Turiúba - SP",
  },
  {
    id: 5336,
    label: "Turmalina - MG",
  },
  {
    id: 5337,
    label: "Turmalina - SP",
  },
  {
    id: 5338,
    label: "Turuçu - RS",
  },
  {
    id: 5339,
    label: "Tururu - CE",
  },
  {
    id: 5340,
    label: "Turvânia - GO",
  },
  {
    id: 5341,
    label: "Turvelândia - GO",
  },
  {
    id: 5342,
    label: "Turvo - PR",
  },
  {
    id: 5343,
    label: "Turvo - SC",
  },
  {
    id: 5344,
    label: "Turvolândia - MG",
  },
  {
    id: 5345,
    label: "Tutóia - MA",
  },
  {
    id: 5346,
    label: "Uarini - AM",
  },
  {
    id: 5347,
    label: "Uauá - BA",
  },
  {
    id: 5348,
    label: "Ubá - MG",
  },
  {
    id: 5349,
    label: "Ubaí - MG",
  },
  {
    id: 5350,
    label: "Ubaíra - BA",
  },
  {
    id: 5351,
    label: "Ubaitaba - BA",
  },
  {
    id: 5352,
    label: "Ubajara - CE",
  },
  {
    id: 5353,
    label: "Ubaporanga - MG",
  },
  {
    id: 5354,
    label: "Ubarana - SP",
  },
  {
    id: 5355,
    label: "Ubatã - BA",
  },
  {
    id: 5356,
    label: "Ubatuba - SP",
  },
  {
    id: 5357,
    label: "Uberaba - MG",
  },
  {
    id: 5358,
    label: "Uberlândia - MG",
  },
  {
    id: 5359,
    label: "Ubirajara - SP",
  },
  {
    id: 5360,
    label: "Ubiratã - PR",
  },
  {
    id: 5361,
    label: "Ubiretama - RS",
  },
  {
    id: 5362,
    label: "Uchoa - SP",
  },
  {
    id: 5363,
    label: "Uibaí - BA",
  },
  {
    id: 5364,
    label: "Uiramutã - RR",
  },
  {
    id: 5365,
    label: "Uirapuru - GO",
  },
  {
    id: 5366,
    label: "Uiraúna - PB",
  },
  {
    id: 5367,
    label: "Ulianópolis - PA",
  },
  {
    id: 5368,
    label: "Umari - CE",
  },
  {
    id: 5369,
    label: "Umarizal - RN",
  },
  {
    id: 5370,
    label: "Umbaúba - SE",
  },
  {
    id: 5371,
    label: "Umburanas - BA",
  },
  {
    id: 5372,
    label: "Umburatiba - MG",
  },
  {
    id: 5373,
    label: "Umbuzeiro - PB",
  },
  {
    id: 5374,
    label: "Umirim - CE",
  },
  {
    id: 5375,
    label: "Umuarama - PR",
  },
  {
    id: 5376,
    label: "Una - BA",
  },
  {
    id: 5377,
    label: "Unaí - MG",
  },
  {
    id: 5378,
    label: "União - PI",
  },
  {
    id: 5379,
    label: "União da serra - RS",
  },
  {
    id: 5380,
    label: "União da vitória - PR",
  },
  {
    id: 5381,
    label: "União de minas - MG",
  },
  {
    id: 5382,
    label: "União do oeste - SC",
  },
  {
    id: 5383,
    label: "União do sul - MT",
  },
  {
    id: 5384,
    label: "União dos palmares - AL",
  },
  {
    id: 5385,
    label: "União paulista - SP",
  },
  {
    id: 5386,
    label: "Uniflor - PR",
  },
  {
    id: 5387,
    label: "Unistalda - RS",
  },
  {
    id: 5388,
    label: "Upanema - RN",
  },
  {
    id: 5389,
    label: "Uraí - PR",
  },
  {
    id: 5390,
    label: "Urandi - BA",
  },
  {
    id: 5391,
    label: "Urânia - SP",
  },
  {
    id: 5392,
    label: "Urbano santos - MA",
  },
  {
    id: 5393,
    label: "Uru - SP",
  },
  {
    id: 5394,
    label: "Uruaçu - GO",
  },
  {
    id: 5395,
    label: "Uruana - GO",
  },
  {
    id: 5396,
    label: "Uruana de minas - MG",
  },
  {
    id: 5397,
    label: "Uruará - PA",
  },
  {
    id: 5398,
    label: "Urubici - SC",
  },
  {
    id: 5399,
    label: "Uruburetama - CE",
  },
  {
    id: 5400,
    label: "Urucânia - MG",
  },
  {
    id: 5401,
    label: "Urucará - AM",
  },
  {
    id: 5402,
    label: "Uruçuca - BA",
  },
  {
    id: 5403,
    label: "Uruçuí - PI",
  },
  {
    id: 5404,
    label: "Urucuia - MG",
  },
  {
    id: 5405,
    label: "Urucurituba - AM",
  },
  {
    id: 5406,
    label: "Uruguaiana - RS",
  },
  {
    id: 5407,
    label: "Uruoca - CE",
  },
  {
    id: 5408,
    label: "Urupá - RO",
  },
  {
    id: 5409,
    label: "Urupema - SC",
  },
  {
    id: 5410,
    label: "Urupês - SP",
  },
  {
    id: 5411,
    label: "Urussanga - SC",
  },
  {
    id: 5412,
    label: "Urutaí - GO",
  },
  {
    id: 5413,
    label: "Utinga - BA",
  },
  {
    id: 5414,
    label: "Vacaria - RS",
  },
  {
    id: 5415,
    label: "Vale de são domingos - MT",
  },
  {
    id: 5416,
    label: "Vale do anari - RO",
  },
  {
    id: 5417,
    label: "Vale do paraíso - RO",
  },
  {
    id: 5418,
    label: "Vale do sol - RS",
  },
  {
    id: 5419,
    label: "Vale real - RS",
  },
  {
    id: 5420,
    label: "Vale verde - RS",
  },
  {
    id: 5421,
    label: "Valença - BA",
  },
  {
    id: 5422,
    label: "Valença - RJ",
  },
  {
    id: 5423,
    label: "Valença do piauí - PI",
  },
  {
    id: 5424,
    label: "Valente - BA",
  },
  {
    id: 5425,
    label: "Valentim gentil - SP",
  },
  {
    id: 5426,
    label: "Valinhos - SP",
  },
  {
    id: 5427,
    label: "Valparaíso - SP",
  },
  {
    id: 5428,
    label: "Valparaíso de goiás - GO",
  },
  {
    id: 5429,
    label: "Vanini - RS",
  },
  {
    id: 5430,
    label: "Vargeão - SC",
  },
  {
    id: 5431,
    label: "Vargem - SC",
  },
  {
    id: 5432,
    label: "Vargem - SP",
  },
  {
    id: 5433,
    label: "Vargem alegre - MG",
  },
  {
    id: 5434,
    label: "Vargem alta - ES",
  },
  {
    id: 5435,
    label: "Vargem bonita - MG",
  },
  {
    id: 5436,
    label: "Vargem bonita - SC",
  },
  {
    id: 5437,
    label: "Vargem grande - MA",
  },
  {
    id: 5438,
    label: "Vargem grande do rio pardo - MG",
  },
  {
    id: 5439,
    label: "Vargem grande do sul - SP",
  },
  {
    id: 5440,
    label: "Vargem grande paulista - SP",
  },
  {
    id: 5441,
    label: "Varginha - MG",
  },
  {
    id: 5442,
    label: "Varjão - GO",
  },
  {
    id: 5443,
    label: "Varjão de minas - MG",
  },
  {
    id: 5444,
    label: "Varjota - CE",
  },
  {
    id: 5445,
    label: "Varre-sai - RJ",
  },
  {
    id: 5446,
    label: "Várzea - PB",
  },
  {
    id: 5447,
    label: "Várzea - RN",
  },
  {
    id: 5448,
    label: "Várzea alegre - CE",
  },
  {
    id: 5449,
    label: "Várzea branca - PI",
  },
  {
    id: 5450,
    label: "Várzea da palma - MG",
  },
  {
    id: 5451,
    label: "Várzea da roça - BA",
  },
  {
    id: 5452,
    label: "Várzea do poço - BA",
  },
  {
    id: 5453,
    label: "Várzea grande - MT",
  },
  {
    id: 5454,
    label: "Várzea grande - PI",
  },
  {
    id: 5455,
    label: "Várzea nova - BA",
  },
  {
    id: 5456,
    label: "Várzea paulista - SP",
  },
  {
    id: 5457,
    label: "Varzedo - BA",
  },
  {
    id: 5458,
    label: "Varzelândia - MG",
  },
  {
    id: 5459,
    label: "Vassouras - RJ",
  },
  {
    id: 5460,
    label: "Vazante - MG",
  },
  {
    id: 5461,
    label: "Venâncio aires - RS",
  },
  {
    id: 5462,
    label: "Venda nova do imigrante - ES",
  },
  {
    id: 5463,
    label: "Venha-ver - RN",
  },
  {
    id: 5464,
    label: "Ventania - PR",
  },
  {
    id: 5465,
    label: "Venturosa - PE",
  },
  {
    id: 5466,
    label: "Vera - MT",
  },
  {
    id: 5467,
    label: "Vera cruz - BA",
  },
  {
    id: 5468,
    label: "Vera cruz - RN",
  },
  {
    id: 5469,
    label: "Vera cruz - RS",
  },
  {
    id: 5470,
    label: "Vera cruz - SP",
  },
  {
    id: 5471,
    label: "Vera cruz do oeste - PR",
  },
  {
    id: 5472,
    label: "Vera mendes - PI",
  },
  {
    id: 5473,
    label: "Veranópolis - RS",
  },
  {
    id: 5474,
    label: "Verdejante - PE",
  },
  {
    id: 5475,
    label: "Verdelândia - MG",
  },
  {
    id: 5476,
    label: "Verê - PR",
  },
  {
    id: 5477,
    label: "Vereda - BA",
  },
  {
    id: 5478,
    label: "Veredinha - MG",
  },
  {
    id: 5479,
    label: "Veríssimo - MG",
  },
  {
    id: 5480,
    label: "Vermelho novo - MG",
  },
  {
    id: 5481,
    label: "Vertente do lério - PE",
  },
  {
    id: 5482,
    label: "Vertentes - PE",
  },
  {
    id: 5483,
    label: "Vespasiano - MG",
  },
  {
    id: 5484,
    label: "Vespasiano corrêa - RS",
  },
  {
    id: 5485,
    label: "Viadutos - RS",
  },
  {
    id: 5486,
    label: "Viamão - RS",
  },
  {
    id: 5487,
    label: "Viana - ES",
  },
  {
    id: 5488,
    label: "Viana - MA",
  },
  {
    id: 5489,
    label: "Vianópolis - GO",
  },
  {
    id: 5490,
    label: "Vicência - PE",
  },
  {
    id: 5491,
    label: "Vicente dutra - RS",
  },
  {
    id: 5492,
    label: "Vicentina - MS",
  },
  {
    id: 5493,
    label: "Vicentinópolis - GO",
  },
  {
    id: 5494,
    label: "Viçosa - AL",
  },
  {
    id: 5495,
    label: "Viçosa - MG",
  },
  {
    id: 5496,
    label: "Viçosa - RN",
  },
  {
    id: 5497,
    label: "Viçosa do ceará - CE",
  },
  {
    id: 5498,
    label: "Victor graeff - RS",
  },
  {
    id: 5499,
    label: "Vidal ramos - SC",
  },
  {
    id: 5500,
    label: "Videira - SC",
  },
  {
    id: 5501,
    label: "Vieiras - MG",
  },
  {
    id: 5502,
    label: "Vieirópolis - PB",
  },
  {
    id: 5503,
    label: "Vigia - PA",
  },
  {
    id: 5504,
    label: "Vila bela da santíssima trindade - MT",
  },
  {
    id: 5505,
    label: "Vila boa - GO",
  },
  {
    id: 5506,
    label: "Vila flor - RN",
  },
  {
    id: 5507,
    label: "Vila flores - RS",
  },
  {
    id: 5508,
    label: "Vila lângaro - RS",
  },
  {
    id: 5509,
    label: "Vila maria - RS",
  },
  {
    id: 5510,
    label: "Vila nova do piauí - PI",
  },
  {
    id: 5511,
    label: "Vila nova do sul - RS",
  },
  {
    id: 5512,
    label: "Vila nova dos martírios - MA",
  },
  {
    id: 5513,
    label: "Vila pavão - ES",
  },
  {
    id: 5514,
    label: "Vila propício - GO",
  },
  {
    id: 5515,
    label: "Vila rica - MT",
  },
  {
    id: 5516,
    label: "Vila valério - ES",
  },
  {
    id: 5517,
    label: "Vila velha - ES",
  },
  {
    id: 5518,
    label: "Vilhena - RO",
  },
  {
    id: 5519,
    label: "Vinhedo - SP",
  },
  {
    id: 5520,
    label: "Viradouro - SP",
  },
  {
    id: 5521,
    label: "Virgem da lapa - MG",
  },
  {
    id: 5522,
    label: "Virgínia - MG",
  },
  {
    id: 5523,
    label: "Virginópolis - MG",
  },
  {
    id: 5524,
    label: "Virgolândia - MG",
  },
  {
    id: 5525,
    label: "Virmond - PR",
  },
  {
    id: 5526,
    label: "Visconde do rio branco - MG",
  },
  {
    id: 5527,
    label: "Viseu - PA",
  },
  {
    id: 5528,
    label: "Vista alegre - RS",
  },
  {
    id: 5529,
    label: "Vista alegre do alto - SP",
  },
  {
    id: 5530,
    label: "Vista alegre do prata - RS",
  },
  {
    id: 5531,
    label: "Vista gaúcha - RS",
  },
  {
    id: 5532,
    label: "Vista serrana - PB",
  },
  {
    id: 5533,
    label: "Vitor meireles - SC",
  },
  {
    id: 5534,
    label: "Vitória - ES",
  },
  {
    id: 5535,
    label: "Vitória brasil - SP",
  },
  {
    id: 5536,
    label: "Vitória da conquista - BA",
  },
  {
    id: 5537,
    label: "Vitória das missões - RS",
  },
  {
    id: 5538,
    label: "Vitória de santo antão - PE",
  },
  {
    id: 5539,
    label: "Vitória do jari - AP",
  },
  {
    id: 5540,
    label: "Vitória do mearim - MA",
  },
  {
    id: 5541,
    label: "Vitória do xingu - PA",
  },
  {
    id: 5542,
    label: "Vitorino - PR",
  },
  {
    id: 5543,
    label: "Vitorino freire - MA",
  },
  {
    id: 5544,
    label: "Volta grande - MG",
  },
  {
    id: 5545,
    label: "Volta redonda - RJ",
  },
  {
    id: 5546,
    label: "Votorantim - SP",
  },
  {
    id: 5547,
    label: "Votuporanga - SP",
  },
  {
    id: 5548,
    label: "Wagner - BA",
  },
  {
    id: 5549,
    label: "Wall ferraz - PI",
  },
  {
    id: 5550,
    label: "Wanderlândia - TO",
  },
  {
    id: 5551,
    label: "Wanderley - BA",
  },
  {
    id: 5552,
    label: "Wenceslau braz - MG",
  },
  {
    id: 5553,
    label: "Wenceslau braz - PR",
  },
  {
    id: 5554,
    label: "Wenceslau guimarães - BA",
  },
  {
    id: 5555,
    label: "Westfalia - RS",
  },
  {
    id: 5556,
    label: "Witmarsum - SC",
  },
  {
    id: 5557,
    label: "Xambioá - TO",
  },
  {
    id: 5558,
    label: "Xambrê - PR",
  },
  {
    id: 5559,
    label: "Xangri-lá - RS",
  },
  {
    id: 5560,
    label: "Xanxerê - SC",
  },
  {
    id: 5561,
    label: "Xapuri - AC",
  },
  {
    id: 5562,
    label: "Xavantina - SC",
  },
  {
    id: 5563,
    label: "Xaxim - SC",
  },
  {
    id: 5564,
    label: "Xexéu - PE",
  },
  {
    id: 5565,
    label: "Xinguara - PA",
  },
  {
    id: 5566,
    label: "Xique-xique - BA",
  },
  {
    id: 5567,
    label: "Zabelê - PB",
  },
  {
    id: 5568,
    label: "Zacarias - SP",
  },
  {
    id: 5569,
    label: "Zé doca - MA",
  },
  {
    id: 5570,
    label: "Zortéa - SC",
  },
];
