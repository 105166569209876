import { useEffect, useRef, useState } from "react";
import { IonModal } from "@ionic/react";
import { Box, Text } from "@astrolabe-ui/react";
import { CaretRight } from "@phosphor-icons/react";

import { SelectItem, ReactPortal } from "@/components";
import { useCities } from "../hooks/use-cities";
import { cn } from "@/lib/utils";

interface SelectCityProps {
  selected?: string;
  title: string;
  error?: string;
  disabled?: boolean;
  onChange: (region: string) => void;
}

export function SelectCity({
  selected,
  disabled = false,
  title,
  error,
  onChange,
}: SelectCityProps) {
  const modal = useRef<HTMLIonModalElement>(null);

  const [selectedCity, setSelectedCity] = useState<string | null>(selected ?? null);

  useEffect(() => setSelectedCity(selected ?? null), [selected]);

  const { data: cities } = useCities();

  function handleOnSelected(item: string) {
    setSelectedCity(item);
    onChange(item);
  }

  function handleOpenModal() {
    if (!disabled) {
      modal.current?.present();
    }
  }

  function handleCloseModal() {
    modal.current?.dismiss();
  }

  return (
    <>
      <div>
        <Box
          px={4}
          rounded="md"
          onClick={handleOpenModal}
          className={cn("flex h-[53px] cursor-pointer items-center justify-between border-thin", {
            "cursor-not-allowed bg-slate-100": disabled,
            "border-red-500": !!error,
          })}
        >
          {selected ? (
            <Text size="sm" color="slate-700">
              {selected}
            </Text>
          ) : (
            <Text size="xs">{title}</Text>
          )}

          <CaretRight
            size={14}
            weight="bold"
            className={cn("text-slate-600", {
              "text-slate-400": disabled,
            })}
          />
        </Box>

        {error ? (
          <Text size="xs" color="red-500" className="mt-1.5">
            {error}
          </Text>
        ) : null}
      </div>

      <ReactPortal>
        <IonModal ref={modal}>
          <SelectItem
            items={cities ?? []}
            selectedItem={selectedCity}
            initialSearch={selectedCity ?? ""}
            title={title}
            virtualized
            onCancel={handleCloseModal}
            onChange={(value) => {
              handleOnSelected(value);
              handleCloseModal();
            }}
          />
        </IonModal>
      </ReactPortal>
    </>
  );
}
