import { useSetAtom } from "jotai";
import { IconButton } from "@astrolabe-ui/react";
import { CaretLeft } from "@phosphor-icons/react";

import { CurrentStepLocationAtom } from "@/components/location-input/atoms/current-step-location-atom";

export function BackButton() {
  const setCurrentStepLocation = useSetAtom(CurrentStepLocationAtom);

  function handleGoBack() {
    setCurrentStepLocation("form");
  }

  return (
    <IconButton
      type="button"
      size="lg"
      rounded="full"
      icon={<CaretLeft size={20} className="text-primary-500" />}
      onClick={handleGoBack}
      className="absolute left-4 top-[calc(var(--safe-area-inset-top)+1rem)] z-[500] drop-shadow-lg"
    />
  );
}
