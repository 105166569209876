import { useAtom, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";

import { Button } from "@/components";
import { getAddressByLocation } from "@/services/cities";
import { CurrentStepLocationAtom } from "@/components/location-input/atoms/current-step-location-atom";
import { DataLocationAtom } from "@/components/location-input/atoms/data-location-atom";

export function Footer() {
  const { t } = useTranslation();

  const setCurrentStep = useSetAtom(CurrentStepLocationAtom);
  const [dataLocation, setDataLocation] = useAtom(DataLocationAtom);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ({ latitude, longitude }: { latitude: number; longitude: number }) =>
      getAddressByLocation({ latitude, longitude }),
  });

  async function handleConfirm() {
    if (dataLocation.address.city) {
      setCurrentStep("confirm");
      return;
    }

    const address = await mutateAsync({
      latitude: dataLocation.location.latitude,
      longitude: dataLocation.location.longitude,
    });

    setDataLocation((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        city: address.city,
        neighborhood: address.neighborhood,
        street: address.street,
        postcode: address.postcode,
      },
    }));

    setCurrentStep("confirm");
  }

  return (
    <footer className="absolute inset-x-0 bottom-0 z-[1000] flex w-full flex-col items-center gap-6 rounded-t-lg px-4 pb-[calc(var(--safe-area-inset-bottom)+1.25rem)] pt-5">
      <Button
        type="button"
        size="lg"
        full
        className="max-w-80 drop-shadow-2xl"
        loading={isLoading}
        onClick={handleConfirm}
      >
        {t("location.Confirmar localização")}
      </Button>
    </footer>
  );
}
