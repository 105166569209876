import axios from "axios";

type GetAddresByPostCodeResponse = {
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  erro: string;
};

export async function getAddressByPostCode({ postCode }: { postCode: string }) {
  try {
    const response = await axios.get<GetAddresByPostCodeResponse>(
      `https://viacep.com.br/ws/${postCode}/json`,
    );

    const data = response.data;

    if (!data || data.erro) {
      return null;
    }

    return {
      street: data.logradouro,
      city: `${data.localidade} - ${data.uf}`,
      neighborhood: data.bairro,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
