import { atom } from "jotai";

export const dataLocationInitialValues = {
  address: {
    postcode: "",
    city: "",
    neighborhood: "",
    street: "",
    number: "",
    complement: "",
    landmark: "",
  },
  location: {
    latitude: 0,
    longitude: 0,
  },
};

export const DataLocationAtom = atom(dataLocationInitialValues);
