import { useSetAtom } from "jotai";
import { useMap, useMapEvents } from "react-leaflet";

import { DataLocationAtom } from "@/components/location-input/atoms/data-location-atom";
import { IsDraggingMap } from "../atoms/is-dragging-map";

export function MapController() {
  const map = useMap();

  const setDataLocation = useSetAtom(DataLocationAtom);
  const setIsDragging = useSetAtom(IsDraggingMap);

  useMapEvents({
    dragstart: () => setIsDragging(true),
    dragend: () => setIsDragging(false),
    move: () =>
      setDataLocation((prev) => {
        const { lat, lng } = map.getCenter();

        return {
          ...prev,
          location: {
            latitude: lat,
            longitude: lng,
          },
        };
      }),
  });

  return null;
}
