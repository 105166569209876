import axios from "axios";

export async function getLocationByAddress({ address }: { address: string }) {
  try {
    const response = await axios.get<
      Array<{
        lat: number;
        lon: number;
      }>
    >("https://nominatim.openstreetmap.org/search.php", {
      params: {
        polygon_geojson: 1,
        format: "jsonv2",
        q: address,
      },
    });

    if (!response.data.length) {
      return null;
    }

    return { lat: response.data[0].lat, lng: response.data[0].lon };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
