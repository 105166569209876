import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Text } from "@astrolabe-ui/react";

import { FormData } from "../form-location-input";

export function Address() {
  const { control } = useFormContext<FormData>();

  const street =
    useWatch({
      control,
      name: "street",
    }) || "Rua";

  const number =
    useWatch({
      control,
      name: "number",
    }) || "s/n";

  const neighborhood =
    useWatch({
      control,
      name: "neighborhood",
    }) || "Bairro";

  const city =
    useWatch({
      control,
      name: "city",
    }) || "Cidade";

  const address = useMemo(() => {
    return {
      street: `${street}, ${number}`,
      complement: `${neighborhood}, ${city}`,
    };
  }, [street, number, neighborhood, city]);

  return (
    <div className="flex flex-col text-center">
      <Text color="slate-700">{address.street}</Text>
      <Text color="slate-400">{address.complement}</Text>
    </div>
  );
}
