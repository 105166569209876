import { useAtomValue } from "jotai";

import { IsDraggingMap } from "../atoms/is-dragging-map";
import { cn } from "@/lib/utils";

export function MapPin() {
  const isDragging = useAtomValue(IsDraggingMap);

  return (
    <div
      className="absolute left-1/2 top-1/2 z-[500] -translate-x-1/2 -translate-y-[calc(50%+16px)]
      after:absolute after:bottom-0 after:left-1/2 after:-z-10 after:block after:h-[5px] after:w-[11px] after:-translate-x-1/2 after:translate-y-[1px] after:rounded-[100%] after:bg-slate-700/30 after:content-['']"
    >
      <svg
        width={36}
        height={36}
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
        className={cn("fill-primary-500 transition-transform", {
          "-translate-y-[5px] fill-slate-700": isDragging,
        })}
      >
        <path
          d="M18 2.25A12.39 12.39 0 005.625 14.625c0 10.59 11.25 18.586 11.73 18.921a1.125 1.125 0 001.29 0c.48-.335 11.73-8.332 11.73-18.921A12.389 12.389 0 0018 2.25zm0 7.875a4.5 4.5 0 110 9 4.5 4.5 0 010-9z"
          className="transition-colors"
        />
      </svg>
    </div>
  );
}
