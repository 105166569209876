import { useMemo, MouseEvent } from "react";
import { useSetAtom } from "jotai";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Text } from "@astrolabe-ui/react";
import { Compass, X } from "@phosphor-icons/react";

import { LocationInputIsOpenAtom } from "@/components/location-input/atoms/location-input-is-open-atom";
import { LocationInput } from "@/components/location-input/location-input";
import { cn } from "@/lib/utils";

import mapIcon from "@/assets/illustrations/map.svg";

type DataLocationInput = {
  address: {
    city: string;
    uf: string;
    neighborhood: string;
    street: string;
    number?: string;
    postcode: string;
    complement?: string;
    landmark?: string;
  };
  location: {
    latitude: number;
    longitude: number;
  };
};

type Address = {
  city: string;
  uf: string;
  neighborhood: string;
  street: string;
  number?: string;
  postcode: string;
  complement?: string;
  landmark?: string;
  address: string;
  lat: number;
  lng: number;
};

type LocationFieldProps = {
  id: number;
  field: string;
  hint: string | null;
  required: boolean;
};

type FormProcolData = {
  [key: string]: Address | undefined;
};

export function LocationField({ id, field, hint, required }: LocationFieldProps) {
  const { t } = useTranslation();

  const setLocationInputIsOpen = useSetAtom(LocationInputIsOpenAtom);

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormProcolData>();

  function handleOnChange({ address, location }: DataLocationInput) {
    const addressFormatted = `${address.street}, ${address.number || "s/n"}${
      address.complement ? ` ${address.complement}` : ""
    }, ${address.neighborhood}, ${address.city} - ${address.uf}, ${address.postcode}${
      address.landmark ? `, ${address.landmark}` : ""
    }`;

    setValue(
      String(id),
      {
        city: address.city,
        uf: address.uf,
        neighborhood: address.neighborhood,
        street: address.street,
        number: address.number,
        postcode: address.postcode,
        complement: address.complement,
        landmark: address.landmark,
        lat: location.latitude,
        lng: location.longitude,
        address: addressFormatted,
      },
      {
        shouldValidate: true,
      },
    );
  }

  const value = useWatch({
    control,
    name: String(id),
  });

  const error = errors[String(id)]?.message ? String(errors[String(id)]?.message) : undefined;

  return (
    <Controller
      control={control}
      name={String(id)}
      rules={{
        required: {
          value: required,
          message: t("error.Campo obrigatório."),
        },
      }}
      render={() => (
        <div className="flex flex-col gap-1">
          <button
            type="button"
            aria-haspopup="dialog"
            onClick={() => setLocationInputIsOpen(true)}
            className={cn(
              "flex w-full cursor-pointer flex-col items-center rounded-md border border-slate-200 p-4 outline-none transition-colors hover:bg-slate-50",
              {
                "border-red-500": !!error,
                "focus:border-primary-500": !error,
              },
            )}
          >
            <div className="flex items-center gap-2">
              <Compass size={16} className="text-primary-500" />
              <Text asChild size="xs" weight="medium" color="primary-500">
                <strong>{field}</strong>
              </Text>
            </div>

            {value ? <InfoAddress id={id} data={value} /> : null}
          </button>

          {error ? (
            <Text size="xs" color="red-500" className="ml-1 mt-1">
              {error}
            </Text>
          ) : null}

          {!error && !!hint ? (
            <Text size="xs" color="slate-400" className="ml-1 mt-1">
              {hint}
            </Text>
          ) : null}

          <LocationInput handleOnChange={handleOnChange} />
        </div>
      )}
    />
  );
}

type InfoAddressProps = {
  id: number;
  data: Address;
};

function InfoAddress({ id, data }: InfoAddressProps) {
  const { setValue } = useFormContext<FormProcolData>();

  const address = useMemo(
    () =>
      `${data.street}, ${data.number || "s/n"}, ${data.neighborhood}, ${data.city} - ${data.uf}, ${
        data.postcode
      }`,
    [data],
  );

  function handleRemove(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    event.stopPropagation();

    setValue(String(id), undefined, { shouldValidate: true });
  }

  return (
    <div className="mt-4 flex w-full items-center justify-between gap-2">
      <div className="flex max-w-lg items-center gap-3 text-left">
        <img src={mapIcon} alt="Mapa com marcador azul" className="h-11 w-11 flex-shrink-0" />
        <Text className="line-clamp-3">{address}</Text>
      </div>

      <button
        type="button"
        onClick={handleRemove}
        className="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-sm bg-red-50"
      >
        <X size={16} className="text-red-500" />
      </button>
    </div>
  );
}
